import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import AddMassNotifications from "./AddMassNotifications";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";

const MassNotifications = () => {
  const [open, setOpen] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterData, setFilterData] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAllMassNotifications = () => {
    setLoading(true);
    DataService.get(Api.Admin.getAllMassNotificationList)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          setNotificationsData(response.data.data);
          setFilterData(response.data.data);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getAllMassNotifications();
  }, []);


  const handleFilterData = (e) => {
    if (e.target.value === "") {
      setFilterData(notificationsData);
    } else {
      const result = notificationsData?.filter((item) => {
        return (
          item?.user_type
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          item?.notification_type
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          item?.title?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
          item?.text?.toLowerCase()
            .includes(e.target.value?.toLowerCase())
        );
      });

      setFilterData(result);
      setPage(0);
    }
  };

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Mass Notifications
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="view-btn-main border-btn-main">
                        <Index.Box className="user-search-main">
                          <Index.Box className="user-search-box">
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Search item"
                                onChange={handleFilterData}
                              />

                              <img
                                src={Index.Svg.search}
                                className="search-grey-img"
                                alt="search grey img"
                              ></img>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Button
                          className="view-order-btn border-btn"
                          onClick={() => {
                            handleOpen();
                          }}
                        >
                          Add
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main food-item-table">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                User Type
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Notification Type
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Title
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Description
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData.length > 0 ? (
                              filterData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                ?.map((row, index) => {
                                  return (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.user_type || "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.notification_type || "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.title || "-"}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.text || "-"}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  );
                                })
                            ) : (
                              <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell align="center" colSpan={5}>
                                No record found
                              </Index.TableCell>
                            </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>

                      <Index.TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={filterData?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />

                      {open && (
                        <AddMassNotifications
                          open={open}
                          handleClose={handleClose}
                          getAllMassNotifications={getAllMassNotifications}
                          //   row={cuisineRow}
                        />
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default MassNotifications;
