import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import EditCuisines from "./EditCuisines";
import DeleteModal from "../../../../../component/common/DeleteModal";

const Cuisines = () => {
  const [cuisineData, setCuisineData] = useState([]);
  const [foodTypeData, setFoodTypeData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [cuisineRow, setCuisineRow] = useState("");
  const [loading, setLoading] = useState(false);

  const getAllFoodType = async () => {
    await DataService.get(Api.Common.getAllFoodType).then((response) => {
      if (response.data.status === 200) {
        setFoodTypeData(response.data.data);
      }
    });
  };

  const getAllCuisine = async () => {
    setLoading(true);
    await DataService.get(Api.Common.getAllCuisine)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          setCuisineData(response.data.data);
        }
      })
      .catch((e) => {
        Index.toast.error(e.message);
        setLoading(false);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const hanldeDeleteReocrd = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", cuisineRow?._id);
    await DataService.post(Api.Admin.deleteCuisine, urlencoded)
      .then((res) => {
        if (res.data.status === 200) {
          handleDeleteClose();
          setCuisineRow("");
          getAllCuisine();
          Index.toast.success("Cuisine deleted successfully");
        }
      })
      .catch((e) => {
        Index.toast.error(e.response.data.message);
      });
  };

  useEffect(() => {
    getAllCuisine();
    getAllFoodType();
  }, []);

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Cuisines
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="view-btn-main border-btn-main">
                        <Index.Button
                          className="view-order-btn border-btn"
                          onClick={() => {
                            handleOpen();
                            setCuisineRow("");
                          }}
                        >
                          Add
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main food-item-table">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                NO
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                CUISINES
                              </Index.TableCell>
                              {/* <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            FOOD TYPE
                          </Index.TableCell> */}
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {cuisineData.length > 0 ? (
                              cuisineData.map((row, index) => (
                                <Index.TableRow
                                  key={row?._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    {index + 1}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.cuisine ? row?.cuisine : "-"}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    <Index.Button
                                      className="table-btn"
                                      onClick={() => {
                                        handleOpen();
                                        setCuisineRow(row);
                                      }}
                                    >
                                      <img
                                        src={Index.Svg.blueedit}
                                        alt="edit-icon"
                                      ></img>
                                    </Index.Button>
                                    <Index.Button
                                      className="table-btn"
                                      onClick={() => {
                                        handleDeleteOpen();
                                        setCuisineRow(row);
                                      }}
                                    >
                                      <img
                                        src={Index.Svg.trash}
                                        alt="delete-icon"
                                      ></img>
                                    </Index.Button>
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                No Data Found
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {open && (
            <EditCuisines
              open={open}
              handleClose={handleClose}
              getAllCuisine={getAllCuisine}
              row={cuisineRow}
              foodTypeData={foodTypeData}
            />
          )}
          {deleteOpen && (
            <DeleteModal
              deleteOpen={deleteOpen}
              handleDeleteClose={handleDeleteClose}
              hanldeDeleteReocrd={hanldeDeleteReocrd}
            />
          )}
        </Index.Box>
      )}
    </>
  );
};

export default Cuisines;
