import "./App.css";
import Routers from "./routes/Routes";
import style from "./assets/style/admin.css";
import meet from "./assets/style/meet.css";
import responsive from "./assets/style/responsive.css";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { MyContextProvider } from "./container/admin/pages/notification/MyContextProvider";

function App() {
  return (
    <div className="App">
      <MyContextProvider>
        <ToastContainer />
        <Routers />
      </MyContextProvider>
    </div>
  );
}

export default App;
