import React from "react";
import { useState, useEffect } from "react";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import Index from "../../../../component/Index";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ViewSingleTransactionDetail from "./ViewSingleTransactionDetail";

const RestaurantView = () => {
  const [transactionList, setTransactionList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState({});

  const [startDate, setStartDate] = useState(
    Index.moment().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(Index.moment().format("YYYY-MM-DD"));
  const [branchId, setBranchId] = useState("");
  const [branches, setBranches] = useState([]);

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggleOpen = () => {
    setOpen(!open);
  };

  const getAllTransactionList = (startDate, endDate, branchId) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("startDate", startDate);
    urlencoded.append("endDate", endDate);
    branchId && urlencoded.append("branchId", branchId);

    DataService.post(Api.Admin.transactionList, urlencoded)
      .then((response) => {
        if (response.data.status === 200) {
          setTransactionList(response?.data?.data);
          setFilterData(response?.data?.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        // Index.toast.error(
        //   e.response.data.message ? e.response.data.message : e.message
        // );
        setLoading(false);
      });
  };

  const getAllBranches = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("type", "branch");
    DataService.post(Api.Admin.getResturantDetail, urlencoded)
      .then((response) => {
        if (response.data.status === 200) {
          setBranches(response.data.data);
        }
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getAllBranches();
    getAllTransactionList(startDate, endDate, branchId);
  }, []);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const changedNameData = apiData?.map((row) => {
      return {
        "Order Id": Index.displayValue(row?.order_id?.branch_order),
        "Unique Id": Index.displayValue(
          row?.order_type === "additional"
            ? row?.additional_order_id?._id
            : row?.order_id?._id
        ),
        Date: Index.displayDateValue(row?.order_id?.date),
        Time: Index.displayValue(row?.order_id?.time),
        "Order Type": Index.displayValue(row?.order_id?.order_type),
        "Branch Name": Index.displayValue(row?.branch_id?.branch_name),
        "Booking Status": Index.displayValue(
          row?.order_type === "additional"
            ? row?.additional_order_id?.order_status
            : row?.order_id?.booking_status
        ),
        "Payment Type": Index.displayValue(row?.payment_type),
        "Actual Food Price Amount": Index.displayNumberValueWithToFixed(row?.actual_amount),
        "Discount Given by Mealord":
          Index.displayNumberValueWithToFixed(row?.mealord_promo),
        "Discount Given by Restaurant":
          Index.displayNumberValueWithToFixed(row?.restaurant_promo),
        
        "Order Total after Discount": Index.displayNumberValueWithToFixed(row?.after_offer),
        "GST applied": Index.displayNumberValueWithToFixed(row?.total_gst),
        "Amount Applicable for Restaurant": Index.displayNumberValueWithToFixed(
          row?.deduction_for_restaurant
        ),
        "GST (deduction)": Index.displayNumberValueWithToFixed(
          row?.restaurant_gst
        ),
        "Commision (7% of Amount Applicable for Restaurant)":
          Index.displayNumberValueWithToFixed(row?.commission_of_mealord),
        "Commision GST (18% of Commission Amount)":
          Index.displayNumberValueWithToFixed(row?.gst_on_commission),
        "TDS (1% of the Amount Applicable for Restaurant)":
          Index.displayNumberValueWithToFixed(row?.tds),
        "Refund Amount": Index.displayNumberValueWithToFixed(
          row?.refund_amount_restaurant
        ),
        Penalty: Index.displayNumberValueWithToFixed(row?.penalty_amount),
        "Transaction fees": Index.displayNumberValueWithToFixed(
          row?.transaction_fees
        ),
        "Net Amount Receivable by Restaurant": Index.displayNumberValueWithToFixed(
          row?.amount_before_transaction_fee
        ),
      };
    });

    const ws = XLSX.utils.json_to_sheet(changedNameData);
    // delete ws["H1"];
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="view-btn-main border-btn-main filter-btn export-transaction-btn">
                    {/* <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.MailIcon />}
                      disabled={
                        !filterData.length || !branchId || !filterButtonClicked
                      }
                      onClick={() => generateReport(filterData)}
                    >
                      Generate
                    </Index.Button> */}

                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!filterData.length}
                      onClick={() =>
                        exportToCSV(filterData, "Transaction report")
                      }
                    >
                      Export
                    </Index.Button>
                  </Index.Box>

                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Transaction History
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group table-head-date-main">
                            <Index.Box className="branch-name">
                              <Index.Typography>Branch :</Index.Typography>
                              <Index.Select
                                value={branchId}
                                displayEmpty
                                onChange={(e) => {
                                  setBranchId(e.target.value);
                                }}
                                className="form-control"
                              >
                                <Index.MenuItem value="" disabled>
                                  Select branch
                                </Index.MenuItem>
                                {branches &&
                                  branches?.map((row) => {
                                    return (
                                      <Index.MenuItem value={row?._id}>
                                        {row?.branch_name}
                                      </Index.MenuItem>
                                    );
                                  })}
                              </Index.Select>
                            </Index.Box>
                            <Index.Box className="from-to-date-table">
                              <Index.Box className="from-date-label fromto-date-label-same">
                                <Index.Typography>From :</Index.Typography>
                                <Index.TextField
                                  type="date"
                                  value={startDate}
                                  onChange={(e) => {
                                    setStartDate(
                                      Index.moment(e.target.value).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                    setEndDate("");
                                  }}
                                />
                              </Index.Box>
                              <Index.Box className="to-date-label fromto-date-label-same">
                                <Index.Typography>To :</Index.Typography>
                                <Index.TextField
                                  type="date"
                                  value={endDate}
                                  onChange={(e) => {
                                    setEndDate(
                                      Index.moment(e.target.value).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                  }}
                                  inputProps={{
                                    min: startDate,
                                  }}
                                />
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="view-btn-main border-btn-main filter-btn">
                              <Index.Button
                                className="view-order-btn border-btn"
                                onClick={() => {
                                  getAllTransactionList(
                                    startDate,
                                    endDate,
                                    branchId
                                  );
                                }}
                              >
                                Filter
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main transaction-history-table">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Order Id
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Unique Id
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Date
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Time
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Order Type
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Branch Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Booking Status
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Payment Type
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Actual Food Price Amount
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Discount Given by Mealord
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                               Discount Given by Restaurant
                                {/* <Index.Tooltip
                                  title="Promo applied by restaurant (Deduction)"
                                  placement="top-start"
                                  arrow
                                >
                                  <Index.InfoIcon className="info-icon" />
                                </Index.Tooltip> */}
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Order Total after Discount
                                {/* <Index.Tooltip
                                  title="Promo applied by mealord (not applied)"
                                  placement="top-start"
                                >
                                  <Index.InfoIcon className="info-icon" />
                                </Index.Tooltip> */}
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                GST applied
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Amount Applicable for Restaurant 
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                GST (deduction)
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Commission
                                <Index.Tooltip
                                  title="(7% of Amount Applicable for Restaurant)"
                                  placement="top-start"
                                  arrow
                                >
                                  <Index.InfoIcon className="info-icon" />
                                </Index.Tooltip>
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Commission GST
                                <Index.Tooltip
                                  title="(18% of Commission Amount)"
                                  placement="top-start"
                                  arrow
                                >
                                  <Index.InfoIcon className="info-icon" />
                                </Index.Tooltip>
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                TDS
                                <Index.Tooltip
                                  title="(1% of the Amount Applicable for Restaurant)"
                                  placement="top-start"
                                >
                                  <Index.InfoIcon className="info-icon" />
                                </Index.Tooltip>
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Refund Amount
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Penalty
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Transaction fees
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Net Amount Receivable by Restaurant
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData.length > 0 ? (
                              filterData &&
                              paginate(filterData, rowsPerPage, page + 1).map(
                                (row, index) => (
                                  <Index.TableRow
                                    key={row?._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Button
                                        className="table-btn"
                                        onClick={() => {
                                          setRow(row);
                                          handleToggleOpen();
                                        }}
                                      >
                                        <img
                                          src={Index.Svg.yelloweye}
                                          alt="view-icon"
                                        ></img>
                                      </Index.Button>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {Index.displayValue(
                                        row?.order_id?.branch_order
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {Index.displayValue(
                                        row?.order_type === "additional"
                                          ? row?.additional_order_id?._id
                                          : row?.order_id?._id
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {Index.displayDateValue(
                                        row?.order_id?.date
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {Index.displayValue(row?.order_id?.time)}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayValue(
                                        row?.order_id?.order_type
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayValue(
                                        row?.branch_id?.branch_name
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayValue(
                                        row?.order_type === "additional"
                                          ? row?.additional_order_id
                                              ?.order_status
                                          : row?.order_id?.booking_status
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayValue(
                                        row?.payment_type
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValueWithToFixed(
                                        row?.actual_amount
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValueWithToFixed(
                                        row?.mealord_promo
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValueWithToFixed(
                                        row?.restaurant_promo
                                      )}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValueWithToFixed(
                                        row?.after_offer
                                      )}
                                    </Index.TableCell>

                                   

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValueWithToFixed(
                                        row?.total_gst
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValueWithToFixed(
                                        row?.deduction_for_restaurant
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValueWithToFixed(
                                        row?.restaurant_gst
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValueWithToFixed(
                                        row?.commission_of_mealord
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValueWithToFixed(
                                        row?.gst_on_commission
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValueWithToFixed(
                                        row?.tds
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValueWithToFixed(
                                        row?.refund_amount_restaurant
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                        {row?.penalty_amount > 0 ? (-Index.displayNumberValueWithToFixed(row?.penalty_amount)) : 0 }
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValueWithToFixed(
                                        row?.transaction_fees
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValueWithToFixed(
                                        row?.amount_before_transaction_fee-row?.penalty_amount
                                      )}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                )
                              )
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell align="center" colSpan={10}>
                                  No record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filterData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Index.Box>

          {open && (
            <ViewSingleTransactionDetail
              open={open}
              handleToggleOpen={handleToggleOpen}
              row={row}
              displayAmount={Index.displayNumberValueWithToFixed}
              forType="restaurant"
            />
          )}
        </Index.Box>
      )}
    </>
  );
};

export default RestaurantView;
