import { MuiOtpInput } from "mui-one-time-password-input";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Api } from "../../../config/Api";
import DataService from "../../../config/DataService";
import Index from "../../../component/Index";

export default function OtpVerify() {
  const navigate = Index.useNavigate();
  let adminData = JSON.parse(localStorage.getItem("admin-details"));

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("otp", data?.otp);
    urlencoded.append("id", adminData._id);

    await DataService.post(Api.Admin.verifyOtp, urlencoded).then((res) => {
      if (res.data.status === 200) {
        Index.toast.success(`${res.data.message}`);
        navigate("/reset-password");
        reset();
      }
    }).catch((e)=>{
      Index.toast.error(e.response.data.message ? e.response.data.message : e.message );
    });
  };

  return (
    <>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <Index.Box className="login-left-bg"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Please Verify Otp
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                </Index.Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Index.Box className="input-box">
                    <Index.FormHelperText className="form-lable">
                      Otp
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Controller
                        name="otp"
                        control={control}
                        rules={{
                          required: "Otp is required",
                          minLength: {
                            value: 4,
                            message: `Min length is 4 characters`,
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <MuiOtpInput
                            length={4}
                            value={value}
                            onChange={onChange}
                            name="otp"
                          />
                        )}
                      />
                    </Index.Box>
                    <Index.FormHelperText error id="accountId-error">
                      {errors.otp?.message ? errors.otp?.message : ""}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="btn-main-primary otp-btn-main">
                    <Index.Button className="btn-primary otp-btn" type="submit">
                      Verify Otp
                    </Index.Button>
                  </Index.Box>
                </form>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
