import React, { useState } from "react";
import DataService from "../../../config/DataService";
import Index from "../../../component/Index";
import { useForm } from "react-hook-form";
import { Api } from "../../../config/Api";
import { handleMouseDown } from "../../../component/common/CommonFunctions";
import { useEffect } from "react";
import { messaging } from "../../../firebase";
import { getToken } from "firebase/messaging";

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const navigate = Index.useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [fcmToken, setFcmToken] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // firebase push notifications
  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BDfMeIuvoSC-sNsw68Z-ODttGIEABFwWitd4SM9edI50k_Me0Js1DHRa0Sj1HIul9wQiyTwuxF4UEV-JXLZV-yk",
      });
      setFcmToken(token);
      console.log("Token Gen>>>", token);
    } else if (permission === "denied") {  
      alert("You denied for the notification");
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  const onSubmit = async (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", data.email);
    urlencoded.append("password", data.password);
    if (fcmToken) {
      urlencoded.append("fcm_token", fcmToken + "");
    }
    await DataService.post(Api.Admin.login, urlencoded)
      .then((res) => {
        if (res.data.status === 200) {
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("admin-details", JSON.stringify(res.data.data));
          localStorage.setItem(
            "role-id",
            JSON.stringify(res.data.data.role_id)
          );
          if (data.rememberme) {
            localStorage.setItem("remember-me", data.rememberme);
            localStorage.setItem("username", data.email);
            localStorage.setItem("password", data.password);
          } else {
            localStorage.removeItem("remember-me", data.rememberme);
            localStorage.removeItem("username", data.email);
            localStorage.removeItem("password", data.password);
          }
          navigate("/");
          // window.location.replace('/dashboard')
          Index.successToast(`${res.data.message}`);
        }
      })
      .catch((e) => {
        Index.errorToast(e);
      });
  };

  useEffect(() => {
    const remember = localStorage.getItem("remember-me");
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");

    if (remember) {
      setValue("email", username);
      setValue("passsword", password);
      setValue("rememberme", remember);
    }
  }, []);
  return (
    <div>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <Index.Box className="login-left-bg"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Sign In!
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  Please enter your credentials to sign in!
                </Index.Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Index.Box className="input-box">
                    <Index.FormHelperText className="form-lable">
                      Email
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        sx={{
                          "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                        }}
                        fullWidth
                        autoComplete="new-username"
                        id="fullWidth"
                        className="form-control"
                        name="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                            message: "Please enter a valid email",
                          },
                        })}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box">
                    <Index.FormHelperText className="form-lable">
                      Password
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.OutlinedInput
                        autoComplete="new-username"
                        className="form-control-eye"
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        {...register("password", {
                          validate: (value) =>
                            Index.validateWithNoWhiteSpace(value, "Password"),
                        })}
                        error={Boolean(errors.password)}
                        helperText={errors.password?.message}
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDown}
                              edge="end"
                            >
                              {showPassword ? (
                                <Index.Visibility />
                              ) : (
                                <Index.VisibilityOff />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                      <Index.FormHelperText error id="accountId-error">
                        {errors.password?.message
                          ? errors.password?.message
                          : ""}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="flex-all forgot-row">
                    <Index.Box className="checkbox-main">
                      <Index.FormControlLabel
                        control={<Index.Checkbox defaultChecked />}
                        label="Remember Me"
                        className="checkbox-lable"
                        {...register("rememberme")}
                        checked={watch("rememberme") ? true : false}
                      />
                    </Index.Box>
                    <Index.ReactLink
                      to="/forgot-password"
                      underline="none"
                      className="admin-forgot-para common-para"
                    >
                      Forgot Password?
                    </Index.ReactLink>
                  </Index.Box>
                  <Index.Box className="btn-main-primary login-btn-main">
                    <Index.Button
                      className="btn-primary login-btn"
                      type="submit"
                    >
                      Sign In
                    </Index.Button>
                  </Index.Box>
                </form>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
