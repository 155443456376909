import React, { useState, useEffect } from "react";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import Index from "../../../../../component/Index";
import {
  downloadPdfRepport,
  downloadReport,
} from "../../../../../common/functions";

const BranchPromotion = () => {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cityList, setCityList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const typeOptions = [
    {
      key: "weekly",
      name: "Weekly",
    },
    {
      key: "monthly",
      name: "Monthly",
    },
    {
      key: "quarterly",
      name: "Quarterly",
    },
    {
      key: "yearly",
      name: "Yearly",
    },
  ];

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const getAllReportList = async (cityName, selectedType) => {
    setLoading(true);
    const data = {
      cityName,
      type: selectedType,
    };
    await DataService.post(Api.Admin.branchPromotion, data)
      .then((response) => {
        if (response.data.status === 200) {
          setReportData(response.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {   
        setReportData([]);
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadReport = () => {
    const data = reportData.map((item) => {
      return {
        "Branch Name": item?.branch_name,
        "Total offer": item?.total_offer.toFixed(2) || "0",
        "No of orders": item.total_order_of_offers || "0",
      };
    });
    downloadReport(data, "City wise branch promotion");
  };

  const handleExportPDF = () => {
    const tableHeaders = ["Branch Name", "Total offer", "No of orders"];
    const tableData = reportData.map((row) => [
      row.branch_name,
      row.total_offer.toFixed(2)|| "0",
      row.total_order_of_offers || "0",
    ]);
    downloadPdfRepport(tableHeaders, tableData, "City wise branch promotion");
  };

  const getAllCityList = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("type", "city");
    DataService.post(Api.Common.getAllCityOrBranchesByCity, urlencoded).then(
      (res) => {
        setCityList(
          res.data.data.sort((a, b) => {
            const cityA = a.city ? a.city.toLowerCase() : "";
            const cityB = b.city ? b.city.toLowerCase() : "";

            return cityA.localeCompare(cityB);
          })
        );
      }
    );
  };

  useEffect(() => {
    getAllCityList();
  }, []);

  console.log(cityList, "cityList");
  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="view-btn-main border-btn-main filter-btn export-transaction-btn">
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!reportData.length}
                      onClick={() => {
                        handleDownloadReport();
                      }}
                    >
                      Export
                    </Index.Button>
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!reportData.length}
                      onClick={() => {
                        handleExportPDF();
                      }}
                    >
                      Export PDF
                    </Index.Button>
                  </Index.Box>

                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Branch Promotion
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group table-head-date-main"></Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group table-head-date-main">
                            <Index.Box className="branch-name">
                              <Index.Typography>City:</Index.Typography>
                              <Index.Select
                                value={selectedCity}
                                displayEmpty
                                onChange={(e) =>
                                  setSelectedCity(e.target.value)
                                }
                                className="form-control"
                              >
                                <Index.MenuItem value="" disabled>
                                  Select City
                                </Index.MenuItem>
                                {cityList?.map((row) => {
                                  return (
                                    <Index.MenuItem value={row?.city}>
                                      {row?.city}
                                    </Index.MenuItem>
                                  );
                                })}
                              </Index.Select>
                            </Index.Box>

                            <Index.Box className="branch-name">
                              <Index.Typography>Type:</Index.Typography>
                              <Index.Select
                                value={selectedType}
                                displayEmpty
                                disabled={!selectedCity}
                                onChange={(e) =>
                                  setSelectedType(e.target.value)
                                }
                                className="form-control"
                              >
                                <Index.MenuItem value="">
                                  Select Type
                                </Index.MenuItem>
                                {typeOptions?.map((row) => {
                                  return (
                                    <Index.MenuItem value={row?.key}>
                                      {row?.name}
                                    </Index.MenuItem>
                                  );
                                })}
                              </Index.Select>
                            </Index.Box>

                            {/* <Index.Box className="from-to-date-table">
                            <Index.Box className="from-date-label fromto-date-label-same">
                              <Index.Typography>From :</Index.Typography>
                              <Index.TextField
                                type="date"
                                value={startDate}
                                onChange={(e) => {
                                  setStartDate(
                                    Index.moment(e.target.value).format(
                                      "YYYY-MM-DD"
                                    )
                                  );
                                  setEndDate("");
                                }}
                              />
                            </Index.Box>
                            <Index.Box className="to-date-label fromto-date-label-same">
                              <Index.Typography>To :</Index.Typography>
                              <Index.TextField
                                type="date"
                                value={endDate}
                                onChange={(e) => {
                                  setEndDate(
                                    Index.moment(e.target.value).format(
                                      "YYYY-MM-DD"
                                    )
                                  );
                                }}
                                inputProps={{
                                  min: startDate,
                                }}
                              />
                            </Index.Box>
                          </Index.Box> */}

                            <Index.Box className="view-btn-main border-btn-main filter-btn">
                              <Index.Button
                                className="view-order-btn border-btn"
                                onClick={() =>
                                  getAllReportList(selectedCity, selectedType)
                                }
                              >
                                Filter
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Branch Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Total offers
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                No of orders
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {reportData.length > 0 ? (
                              reportData &&
                              paginate(reportData, rowsPerPage, page + 1).map(
                                (row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.branch_name || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.total_offer || "0"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.total_order_of_offers || "0"}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                )
                              )
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell align="center" colSpan={3}>
                                  No record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={reportData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default BranchPromotion;
