import React, { useState,useEffect } from "react";
import Index from "../../../../../component/Index";
import { Api } from "../../../../../config/Api";
import DataService from "../../../../../config/DataService";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "TextValidator",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const LocationConfiguration = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [locationData, setlocationData] = useState("");
  const [locationKm, setLocationKm] = useState("");
  const [locationKmError, setLocationKmError] = useState("");

  const getLocationDetails = () => {
    DataService.get(Api.Common.getLocationUpdate).then((res) => {
      const data = res?.data?.data;
      setlocationData(data);
      setLocationKm(data?.km);
    }).catch(()=>{})
  };

  useEffect(() => {
    getLocationDetails();
  }, []);

  const handleEditLocationKm = () => {
    if (locationKm === "") {
      setLocationKmError("Location KM is required");
    } else {
      const urlencoded = new URLSearchParams();
    //   urlencoded.append("id", locationData?._id);
      urlencoded.append("km", locationKm);
      DataService.post(Api.Admin.locationUpdate, urlencoded)
        .then((res) => {
          Index.toast.success(res.data.message);
          setOpen(false);
          getLocationDetails();
        })
        .catch((e) => {
          Index.toast.error(e.response.data.message || e.message);
        });
    }
  };

  return (
    <Index.Box className="dashboard-content booking-configuration-main">
      <Index.Box className="booking-border-main">
        <Index.Box className="booking-configuration-titles">
          <Index.Typography
            className="common-para booking-text-main"
            component="p"
            variant="p"
          >
            Location Updates
          </Index.Typography>
        
        </Index.Box>
        <Index.Box className="border-btn-main booking-edit-btn-main">
          <Index.Button
            className="border-btn booking-edit-btn"
            onClick={() => setOpen(true)}
          >
            <img src={Index.Svg.edit} className="booking-ediit-icon" />
            Edit
          </Index.Button>
        </Index.Box>
        <Index.Box className="admin-booking-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Location KM
                  </Index.FormHelperText>
                  <Index.TextField
                    disabled
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={locationData?.km}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Edit Location KM
          </Index.Typography>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Location KM
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className={classes.input}
                value={locationKm}
                type="number"
                onChange={(e) => {
                  setLocationKm(e.target.value);
                  setLocationKmError("");
                }}
              />
            </Index.Box>

            <Index.FormHelperText>
              <Index.Typography className="error-msg" variant="p">
                {locationKmError}
              </Index.Typography>
            </Index.FormHelperText>
          </Index.Box>
          <Index.Box className="center-button">
            <Index.Button
              variant="contained"
              onClick={() => handleEditLocationKm()}
            >
              Submit
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
};

export default LocationConfiguration;
