import React, { useState } from "react";
import Index from "../../../../component/Index";
import { toast } from "react-toastify";
import { Api } from "../../../../config/Api";
import DataService from "../../../../config/DataService";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { handleMouseDown } from "../../../../component/common/CommonFunctions";
import { FormHelperText } from "@mui/material";

export default function ChangePassword() {
  const [adminDetails, setAdminDetails] = useState(
    JSON.parse(localStorage.getItem("admin-details"))
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleClickShowPassword3= () => setShowPassword3((show) => !show);

  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (data.newPassword !== data.confirmPassword) {
      toast.error("New password and confirm password should be same");
    } else {
      const urlencoded = new URLSearchParams();
      urlencoded.append("oldPassword", data.oldPassword);
      urlencoded.append("newPass", data.newPassword);

      await DataService.patch(
        `${Api.Admin.adminChangePassword}/${adminDetails._id}`,
        urlencoded
      )
        .then((res) => {
          if (res.data.status === 201) {
            toast.success("Password has been changes successfully");
            navigate("/login");
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message);
        });
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box className="tabpanel-main">
          <Index.Box className="change-pass-main">
            <Index.Typography
              className="common-para edit-highlight-text"
              component="p"
              variant="p"
            >
              Password
            </Index.Typography>
            <Index.Typography
              className="common-para edit-para-text"
              component="p"
              variant="p"
            >
              Enter your current & new password to reset your password
            </Index.Typography>
            <Index.Box className="input-box change-pass-input-box">
              <Index.FormHelperText className="form-lable">
                Old Password
              </Index.FormHelperText>
              <Index.Box className="form-group">
                <Index.OutlinedInput
                  className="form-control-eye"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  name="oldPassword"
                  {...register("oldPassword", {
                    required: "Old Password is required",
                  })}
                  error={Boolean(errors.oldPassword)}
                  helperText={errors.oldPassword?.message}
                  endAdornment={
                    <Index.InputAdornment position="end">
                      <Index.IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDown}
                        edge="end"
                      >
                        {showPassword ? (
                          <Index.Visibility />
                        ) : (
                          <Index.VisibilityOff />
                        )}
                      </Index.IconButton>
                    </Index.InputAdornment>
                  }
                />
                <Index.FormHelperText error id="accountId-error">
                  {errors.oldPassword?.message ? errors.oldPassword?.message : ""}
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
            <Index.Box className="input-box change-pass-input-box">
              <Index.FormHelperText className="form-lable">
                New Password
              </Index.FormHelperText>
              <Index.Box className="form-group">
                <Index.OutlinedInput
                  className="form-control-eye"
                  id="outlined-adornment-password"
                  type={showPassword2 ? "text" : "password"}
                  name="newPassword"
                  {...register("newPassword", {
                    required: "New Password is required",
                  })}
                  error={Boolean(errors.newPassword)}
                  helperText={errors.newPassword?.message}
                  endAdornment={
                    <Index.InputAdornment position="end">
                      <Index.IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDown}
                        edge="end"
                      >
                        {showPassword2 ? (
                          <Index.Visibility />
                        ) : (
                          <Index.VisibilityOff />
                        )}
                      </Index.IconButton>
                    </Index.InputAdornment>
                  }
                />
                <Index.FormHelperText error id="accountId-error">
                  {errors.newPassword?.message ? errors.newPassword?.message : ""}
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
            <Index.Box className="input-box change-pass-input-box">
              <Index.FormHelperText className="form-lable">
                Confirm Password
              </Index.FormHelperText>
              <Index.Box className="form-group">
                <Index.OutlinedInput
                  className="form-control-eye"
                  id="outlined-adornment-password"
                  type={showPassword3 ? "text" : "password"}
                  name="confirmPassword"
                  {...register("confirmPassword", {
                    required: "Confirm Password is required",
                    validate:(val)=>{
                      if(watch("newPassword")!==val)
                      {
                        return "Newpassword and confirmPassword should be same"
                      }
                    }
                  })}
                  error={Boolean(errors.confirmPassword)}
                  helperText={errors.confirmPassword?.message}
                  endAdornment={
                    <Index.InputAdornment position="end">
                      <Index.IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword3}
                        onMouseDown={handleMouseDown}
                        edge="end"
                      >
                        {showPassword3 ? (
                          <Index.Visibility />
                        ) : (
                          <Index.VisibilityOff />
                        )}
                      </Index.IconButton>
                    </Index.InputAdornment>
                  }
                />
                <Index.FormHelperText error id="accountId-error">
                  {errors.confirmPassword?.message ? errors.confirmPassword?.message : ""}
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
            <Index.Box className="user-btn-flex change-pass-flex">
              <Index.Box className="discard-btn-main border-btn-main">
                <Index.Button className="discard-user-btn border-btn">
                  Discard
                </Index.Button>
              </Index.Box>
              <Index.Box className="save-btn-main border-btn-main">
                <Index.Button
                  className="save-user-btn border-btn"
                  type="submit"
                >
                  <img src={Index.Svg.save} className="user-save-icon"></img>
                  Save
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </form>
    </div>
  );
}
