import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import AddFoodType from "./AddFoodType";
import DeleteModal from "../../../../../component/common/DeleteModal";

const FoodType = () => {
  const [foodTypeData, setFoodTypeData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [foodTypeRow, setFoodTypeRow] = useState("");
  const [loading, setLoading] = useState(false);

  const getAllFoodType = async () => {
    setLoading(true);
    await DataService.get(Api.Common.getAllFoodType)
      .then((response) => {
        if (response.data.status === 200) {
          setFoodTypeData(response.data.data);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const hanldeDeleteReocrd = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", foodTypeRow?._id);
    await DataService.post(Api.Admin.deleteFoodType, urlencoded).then((res) => {
      if (res.data.status === 200) {
        handleDeleteClose();
        setFoodTypeRow("");
        getAllFoodType();
        Index.toast.success("Food type deleted successfully");
      }
    });
  };

  useEffect(() => {
    getAllFoodType();
  }, []);

  return (
    <>
      {loading ? (
        <Index.Loader/>
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Food Type
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="view-btn-main border-btn-main">
                        <Index.Button
                          className="view-order-btn border-btn"
                          onClick={() => {
                            handleOpen();
                            setFoodTypeRow("");
                          }}
                        >
                          Add
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main food-item-table">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                NO
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Image
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                TYPE
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {foodTypeData.length > 0 ? (
                              foodTypeData.map((row, index) => (
                                <Index.TableRow
                                  key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    {index + 1}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    <img
                                      src={
                                        Index.Constant.IMAGE_URL +
                                        `${row.image}`
                                      }
                                      height="80px"
                                      width="120px"
                                      alt="food-type-img"
                                    />
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row.type}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    <Index.Button
                                      className="table-btn"
                                      onClick={() => {
                                        handleOpen();
                                        setFoodTypeRow(row);
                                      }}
                                    >
                                      <img
                                        src={Index.Svg.blueedit}
                                        alt="edit-icon"
                                      ></img>
                                    </Index.Button>
                                    <Index.Button
                                      className="table-btn"
                                      onClick={() => {
                                        handleDeleteOpen();
                                        setFoodTypeRow(row);
                                      }}
                                    >
                                      <img
                                        src={Index.Svg.trash}
                                        alt="delete-icon"
                                      ></img>
                                    </Index.Button>
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                No Data Found
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>

                      {open && (
                        <AddFoodType
                          open={open}
                          handleClose={handleClose}
                          getAllFoodType={getAllFoodType}
                          row={foodTypeRow}
                        />
                      )}

                      {deleteOpen && (
                        <DeleteModal
                          deleteOpen={deleteOpen}
                          handleDeleteClose={handleDeleteClose}
                          hanldeDeleteReocrd={hanldeDeleteReocrd}
                        />
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default FoodType;
