import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { Api } from "../../../../config/Api";
import DataService from "../../../../config/DataService";

const SubscriberList = () => {
  const [campaignManagementData, setCampaignManagementData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [requestFormRow, setRequestFormRow] = useState({});
  const [loaderLoading, setLoaderLoading] = useState(false);

  const getAllCampaign = async () => {
    setLoaderLoading(true);
    await DataService.get(Api.Admin.campaignSubcriberList)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          setCampaignManagementData(res.data.data);
          setFilterData(res.data.data);
          setLoaderLoading(false);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoaderLoading(false);
      });
  };

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterData = (e) => {
    if (e.target.value === "") {
      setFilterData(campaignManagementData);
    } else {
      const result = campaignManagementData.filter((item) => {
        return (
          item?.branch_id?.branch_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          item?.campaign_subscription_id?.campaign_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          item?.campaign_subscription_id?.campaign_price
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase())
        );
      });
      setFilterData(result);
      setPage(0);
    }
  };

  useEffect(() => {
    getAllCampaign();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateStatus = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", requestFormRow?._id);
    urlencoded.append("status", action === "Accept" ? 1 : 2);

    await DataService.post(Api.Admin.approveRejectCampagin, urlencoded)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          Index.toast.success(res.data.message);
          setOpen(false);
          setRequestFormRow("");
          setAction("");
          getAllCampaign();
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setOpen(false);
      });
  };

  return (
    <>
      {loaderLoading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="flex-all admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Campaign Subscriber List
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="view-btn-main border-btn-main">
                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Search subscriber"
                              onChange={(e) => handleFilterData(e)}
                            />

                            <img
                              src={Index.Svg.search}
                              className="search-grey-img"
                              alt="search grey img"
                            ></img>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="page-table-main suscriber-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 500 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Branch name
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Campaign name
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Time period
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Price
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Start Date
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              End Date
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Expire status
                            </Index.TableCell>


                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Status
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filterData?.length > 0
                            ? filterData &&
                              paginate(filterData, rowsPerPage, page + 1).map(
                                (row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.branch_id?.branch_name
                                        ? row?.branch_id?.branch_name
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.campaign_subscription_id
                                        ?.campaign_name
                                        ? row?.campaign_subscription_id
                                            ?.campaign_name
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.campaign_subscription_id
                                        ?.campaign_time_period
                                        ? row?.campaign_subscription_id
                                            ?.campaign_time_period
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.campaign_subscription_id
                                        ?.campaign_price
                                        ? row?.campaign_subscription_id
                                            ?.campaign_price
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.start_date
                                        ? Index.moment(row?.start_date).format(
                                            "L"
                                          )
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.end_date
                                        ? Index.moment(row?.end_date).format(
                                            "L"
                                          )
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.expired_status
                                        ? "Expired"
                                        : "Ongoing" }
                                    </Index.TableCell>
                                    
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.status === 1
                                        ? "Approved"
                                        : row.status === 2
                                        ? "Rejected"
                                        : "Pending"}
                                    </Index.TableCell>

                                    {row?.status === 0 ? (
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td  accpt-rjct-btn-main"
                                      >
                                        <Index.Button
                                          variant="contained"
                                          className="accpt-rjct-btn succes-btn"
                                          size="small"
                                          onClick={() => {
                                            handleOpen();
                                            setAction("Accept");
                                            setRequestFormRow(row);
                                          }}
                                        >
                                          Accept
                                        </Index.Button>

                                        {/* <Index.Button
                                          variant="contained"
                                          className="accpt-rjct-btn reject-btn"
                                          color="error"
                                          size="small"
                                          onClick={() => {
                                            handleOpen();
                                            setAction("Reject");
                                            setRequestFormRow(row);
                                          }}
                                          style={{ marginLeft: "5px" }}
                                        >
                                          Reject
                                        </Index.Button> */}
                                      </Index.TableCell>
                                    ) : (
                                      <Index.TableCell></Index.TableCell>
                                    )}
                                  </Index.TableRow>
                                )
                              )
                            : "No Data Found"}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>

                    <Index.TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={filterData?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete"
      >
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="delete-modal-inner-main"
        >
          <Index.Box className="modal-circle-main">
            <img
              src={
                action === "Accept"
                  ? Index.Svg.checkmark
                  : Index.Svg.closecircle
              }
              className="user-circle-img"
            />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            {action === "Accept"
              ? "Do you really want to Accept these record ? "
              : "Do you really want to Reject these record ?"}
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={handleClose}
            >
              Cancel
            </Index.Button>
            <Index.Button
              className={
                action === "Accept"
                  ? "modal-success-btn modal-btn"
                  : "modal-delete-btn modal-btn"
              }
              onClick={handleUpdateStatus}
            >
              {action}
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default SubscriberList;
