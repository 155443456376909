import { useContext } from "react";
import Index from "../../../../component/Index";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { useEffect, useState } from "react";
import { MyContext } from "../notification/MyContextProvider";
import { onMessageListener } from "../../../../firebase";

export default function Home() {
  const [dashboardData, setDashboardData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderType, setOrderType] = useState("");
  const [branchId, setBranchId] = useState("");
  const [branches, setBranches] = useState([]);
  const navigate = Index.useNavigate();
  const [notificationData, setNotificationData] = useState({});

  // firebase get notification using usecontext
  const { data, setData } = useContext(MyContext);

  onMessageListener().then((payload) => {
    setData({
      title: payload.notification.title,
      body: payload.notification.body,
    });
    setNotificationData(payload);
  });

  const orderTypeList = ["table", "walkin", "qr"];
  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getDashDoardData = (type) => {
    setLoading(true);
    DataService.post(Api.Admin.dashBaordApi, { type })
      .then((res) => {
        setLoading(false);
        setDashboardData(res.data.data);
        setFilterData(
          branchId
            ? res.data.data.orders.filter((row) => {
                let branchid =
                  row?.placeOrderDetails?.branch_id?._id || row?.branch_id?._id;
                return branchid === branchId;
              })
            : res.data.data.orders
        );
      })
      .catch((e) => {});
  };

  const getAllBranches = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("type", "branch");
    DataService.post(Api.Admin.getResturantDetail, urlencoded)
      .then((response) => {
        if (response.data.status === 200) {
          setBranches(response.data.data);
        }
      })
      .catch((e) => {});
  };

  const handleBranchChange = (e) => {
    setBranchId(e.target.value);
    setFilterData(
      e.target.value
        ? dashboardData.orders.filter((row) => {
            let branchid =
              row?.placeOrderDetails?.branch_id?._id || row?.branch_id?._id;
            return branchid === e.target.value;
          })
        : dashboardData.orders
    );
  };
  useEffect(() => {
    getDashDoardData(orderType);
    getAllBranches();
  }, [orderType, notificationData]);
  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="dashboard-content">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            Dashboard
          </Index.Typography>
          <Index.Box className="admin-dashboad-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total orders
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {dashboardData?.orders?.length}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon1}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                  onClick={() => navigate("/order-table-list")}
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Table orders
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {dashboardData?.tableOrder}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon2}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                  onClick={() => navigate("/order-walkin-list")}
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Walkin orders
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {dashboardData?.walkInOrder}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon3}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                  onClick={() => navigate("/qr-order-list")}
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Qr orders
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {dashboardData?.qrOrder}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon3}
                          className="dash-icons"
                          alt="dashboard icon"
                        />

                        {/* <img
                        src={Index.Svg.dashicon4}
                        className="dash-icons"
                        alt="dashboard icon"
                      /> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="flex-all admin-dashboard-table-row">
                        <Index.Box className="admin-sub-title-main">
                          <Index.Typography
                            className="admin-sub-title"
                            component="h3"
                            variant="h3"
                          >
                            Order List
                          </Index.Typography>
                        </Index.Box>

                        <Index.Box className="user-search-main">
                          <Index.Box className="user-search-box">
                            <Index.Box className="form-group table-head-date-main">
                              <Index.Box
                                className="branch-name"
                                sx={{ marginRight: "5px" }}
                              >
                                <Index.Typography>Branch</Index.Typography>
                                <Index.Select
                                  value={branchId}
                                  displayEmpty
                                  onChange={(e) => handleBranchChange(e)}
                                  className="form-control"
                                >
                                  <Index.MenuItem value="">All</Index.MenuItem>
                                  {branches &&
                                    branches?.map((row) => {
                                      return (
                                        <Index.MenuItem value={row._id}>
                                          {row.branch_name}
                                        </Index.MenuItem>
                                      );
                                    })}
                                </Index.Select>
                              </Index.Box>
                              <Index.Box className="branch-name">
                                <Index.Typography>Order Type</Index.Typography>
                                <Index.Select
                                  value={orderType}
                                  displayEmpty
                                  onChange={(e) => setOrderType(e.target.value)}
                                  className="form-control"
                                >
                                  <Index.MenuItem value="">All</Index.MenuItem>
                                  {orderTypeList &&
                                    orderTypeList?.map((row) => {
                                      return (
                                        <Index.MenuItem value={row}>
                                          {row}
                                        </Index.MenuItem>
                                      );
                                    })}
                                </Index.Select>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        {/* <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Search order"
                              onChange={(e) => handleFilterData(e)}
                            />

                            <img
                              src={Index.Svg.search}
                              className="search-grey-img"
                              alt="search grey img"
                            ></img>
                          </Index.Box>
                          
                        </Index.Box>
                      </Index.Box> */}
                      </Index.Box>
                      <Index.Box className="page-table-main food-item-table table-order-list-res">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 500 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Order Id
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Type
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  User Name
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Branch Name
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Date
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Time
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Persons
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  FoodItems
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Amount
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  Status
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {filterData.length > 0 ? (
                                filterData &&
                                paginate(filterData, rowsPerPage, page + 1).map(
                                  (row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {Index.displayValue(
                                          row?.placeOrderDetails?._id ||
                                            row?._id
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayValue(
                                          row?.placeOrderDetails?.order_type ||
                                            row?.walkIn_type
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayValue(
                                          row?.placeOrderDetails?.created_by
                                            ?.name || row?.created_by?.name
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayValue(
                                          row?.placeOrderDetails?.branch_id
                                            ?.branch_name ||
                                            row?.branch_id?.branch_name
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayDateValue(
                                          row?.placeOrderDetails?.createdAt ||
                                            row?.createdAt
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayValue(
                                          row?.placeOrderDetails?.time ||
                                            row?.time
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        {Index.displayValue(
                                          row?.placeOrderDetails?.persons ||
                                            row?.persons
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayValue(
                                          row?.placeOrderDetails?.food_items
                                            .length
                                            ? row?.placeOrderDetails?.food_items
                                                .length
                                            : row?.food_items.length
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayNumberValueWithToFixed(
                                          row?.placeOrderDetails?.total_amount
                                            ? row?.placeOrderDetails
                                                ?.total_amount
                                            : row?.total_amount
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayValue(
                                          row?.placeOrderDetails
                                            ?.booking_status ||
                                            row?.booking_status
                                        )}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  )
                                )
                              ) : (
                                <Index.TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell align="center" colSpan={6}>
                                    No record found
                                  </Index.TableCell>
                                </Index.TableRow>
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={filterData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
}
