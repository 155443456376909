import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Index from "../../../../../component/Index";
import { Api } from "../../../../../config/Api.js";
import DataService from "../../../../../config/DataService.js";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import QrCode from "./tabs/QrCode";
import BankDetails from "./tabs/BankDetails";
import Documents from "./tabs/Documents";
import Bookings from "./tabs/Bookings";
import Menu from "./tabs/Menu";
// import Credential from "./tabs/Credential";
import EditBranch from "./EditBranch";
import Gst from "./tabs/Gst";
import Offer from "./tabs/Offer";
import PaymentInterval from "./tabs/PaymentInterval";
import Ratings from "./tabs/Ratings";

const BranchDetail = () => {
  const params = Index.useParams();
  const [restaurantDetail, setRestaurantDetail] = useState({});
  const [Open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState("1");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getRestaurantData = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", params.id);
    setLoading(true);
    await DataService.post(Api.Common.getBranchDetail, urlencoded)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          setRestaurantDetail(response.data.data);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  const handleApprove = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("branchId", params?.id);
    urlencoded.append("status", 1);
    await DataService.post(Api.Admin.branchApprove, urlencoded)
      .then((response) => {
        if (response.data.status == 201 || response.data.status == 200) {
          Index.toast.success(response.data.message);
          getRestaurantData();
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
      });
  };

  useEffect(() => {
    getRestaurantData();
  }, [params]);

  const onSubmit = async (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("branchId", params?.id);
    urlencoded.append("status", 2);
    urlencoded.append("reasonOfRejection", data.Reason);
    await DataService.post(Api.Admin.branchApprove, urlencoded)
      .then((response) => {
        if (response.data.status == 201 || response.data.status == 200) {
          Index.toast.success(response.data.message);
          setOpen(false);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
      });
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = () => {
    console.log("close call");
    setEditOpen(false);
  };

  const getBranchCredential = () =>{
    const urlencoded = new URLSearchParams();
    urlencoded.append("branchId", params?.id);
    DataService.post(Api.Admin.getBranchCred, urlencoded)
      .then((response) => {
          Index.toast.success(response.data.message);
        }
      )
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
      });
  }

  const disableBranch = () =>{
    const urlencoded = new URLSearchParams();
    urlencoded.append("branchId", params?.id);
    DataService.post(Api.Admin.disableBranch, urlencoded)
      .then((response) => {
          Index.toast.success(response.data.message);
          getRestaurantData();
        }
      )
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
      });
  }
  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box className="header-button">
            {restaurantDetail?.status === 0 && (
              <Index.Box className="approve-reject-btn">
                <Index.Box className="view-btn-main border-btn-main">
                  <Index.Button
                    className="view-Approve-btn border-btn"
                    onClick={() => {
                      handleApprove();
                    }}
                  >
                    Approve
                  </Index.Button>
                </Index.Box>
                <Index.Box className="view-btn-main border-btn-main">
                  <Index.Button
                    className="view-Reject-btn border-btn"
                    onClick={() => {
                      // handleReject();
                      setOpen(!Open);
                    }}
                  >
                    Reject
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            )}

            <Index.Box className="view-btn-main border-btn-main">
              <Index.Button
                className="view-Approve-btn border-btn"
                onClick={() => {
                  window.open(`https://restaurant.mealord.in/admin-login/${params.id}`)
                }}
              >
                Login
              </Index.Button>
            </Index.Box>

            <Index.Box className="view-btn-main border-btn-main">
              <Index.Button
                className="view-Approve-btn border-btn"
                onClick={() => {
                  window.open(`https://restaurant.mealord.in/restaurant-login/${params.id}`)
                }}
              >
                Restaurant login
              </Index.Button>
            </Index.Box>

            <Index.Box className="view-btn-main border-btn-main">
              <Index.Button
                className="view-Approve-btn border-btn"
                onClick={() => {
                  getBranchCredential(params.id)
                }}
              >
                Credential
              </Index.Button>
            </Index.Box>

            <Index.Box className="approve-reject-btn">
              <Index.Box className="view-btn-main border-btn-main" sx={{mr:"10px"}}>
                <Index.Button
                  className="border-btn"
                  onClick={() => {
                    handleEditOpen();
                  }}
                >
                  Edit
                </Index.Button>
              </Index.Box>
              <Index.Box className="view-btn-main border-btn-main">
                <Index.Button className="border-btn" onClick={disableBranch}>Disable</Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-reastuarant-detail">
            <Index.Box className="reastuarant-Image-box">
              <img
                //  src="https://images.pexels.com/photos/1579739/pexels-photo-1579739.jpeg?auto=compress&cs=tinysrgb&w=600"

                src={
                  Index.Constant.IMAGE_URL +
                  restaurantDetail?.documents?.front_rest_image
                }
                width="65%"
                height={"200px"}
              />
            </Index.Box>
            <Index.Box className="reastuarant-detail-box">
              <Index.Box className="title">
                <Index.Typography variant="h6">
                  {restaurantDetail?.branch_name}
                </Index.Typography>
              </Index.Box>
              <br />
              <Index.Box className="form-group-input">
                <Index.Typography>Branch Code</Index.Typography>
                <Index.TextField
                  size="small"
                  id="standard-basic"
                  variant="standard"
                  disabled
                  // fullWidth
                  value={restaurantDetail?.branch_code}
                />
              </Index.Box>

              <Index.Box className="form-group-input">
                <Index.Typography>Mobile Number</Index.Typography>
                <Index.TextField
                  size="small"
                  variant="standard"
                  disabled
                  // fullWidth
                  id="fullWidth"
                  value={restaurantDetail?.branch_mobile_number}
                />
              </Index.Box>

              {/* <Index.Box className="form-group-input">
            <Index.Typography>Email</Index.Typography>
            <Index.TextField
              size="small"
              variant="standard"
              disabled
              // fullWidth
              id="fullWidth"
              value={restaurantDetail?.email ? restaurantDetail?.email : "-"}
            />
          </Index.Box> */}

              <Index.Box className="form-group-input">
                <Index.Typography>Address</Index.Typography>
                <Index.TextField
                  size="small"
                  variant="standard"
                  disabled
                  // multiline
                  // maxRows={4}
                  value={restaurantDetail?.location}
                  id="fullWidth"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="main-card-section">
            <Index.Box className="user-container-mobile">
              <Index.Box className="main-tab">
                <Index.Box sx={{ width: "100%" }} className="tab-content">
                  <TabContext value={value}>
                    <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        className="tab-ul"
                      >
                        <Tab label="QR Code" value="1" className="tab-li" />
                        {/* <Tab label="Credential" value="11" className="tab-li" /> */}
                        <Tab label="Documents" value="2" className="tab-li" />
                        <Tab label="Statistics" value="3" className="tab-li" />
                        <Tab label="Menu" value="4" className="tab-li" />
                        <Tab label="Bookings" value="5" className="tab-li" />
                        <Tab label="Offers" value="6" className="tab-li" />
                        <Tab label="Ratings" value="7" className="tab-li" />
                        <Tab
                          label="Bank Details"
                          value="8"
                          className="tab-li"
                        />
                        <Tab
                          label="Payment Intervals"
                          value="9"
                          className="tab-li"
                        />
                        <Tab label="Device" value="10" className="tab-li" />
                        <Tab label="GST" value="11" className="tab-li" />
                      </TabList>
                    </Index.Box>

                    <TabPanel value="1">
                      <QrCode
                        branchId={params.id}
                        qrCode={restaurantDetail?.qr_code?.qr_url}
                        qrCodeLink={restaurantDetail?.qrLink}
                      />
                    </TabPanel>
                    <TabPanel value="2">
                      <Documents documents={restaurantDetail?.documents} />
                    </TabPanel>
                    <TabPanel value="3">Statistics</TabPanel>
                    <TabPanel value="4">
                      <Menu branchId={params.id} />
                    </TabPanel>
                    <TabPanel value="5">
                      <Bookings branchId={params.id} />
                    </TabPanel>
                    <TabPanel value="6">
                      <Offer branchId={params.id} />
                    </TabPanel>
                    <TabPanel value="7">
                      <Ratings branchId={params.id}/>
                    </TabPanel>
                    <TabPanel value="8">
                      <BankDetails restaurantDetail={restaurantDetail} />
                    </TabPanel>
                    <TabPanel value="9">
                      <PaymentInterval
                        branchId={params.id}
                        restaurantDetail={restaurantDetail}
                        getRestaurantData={getRestaurantData}
                      />
                    </TabPanel>
                    <TabPanel value="10">Device</TabPanel>
                    <TabPanel value="11">
                      <Gst
                        branchId={params.id}
                        restaurantDetail={restaurantDetail}
                        getRestaurantData={getRestaurantData}
                      />
                    </TabPanel>
                    {/* <TabPanel value="11" >
                  <Credential restaurantDetail={restaurantDetail} />
                </TabPanel> */}
                  </TabContext>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {editOpen && (
            <EditBranch
              open={editOpen}
              handleEditClose={handleEditClose}
              branchDetails={restaurantDetail}
              branchId={params.id}
              getRestaurantData={getRestaurantData}
            />
          )}
          <Index.Modal
            open={Open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Index.Box
                sx={Index.Constant.MODAL_STYLE}
                className="add-user-modal-inner-main"
              >
                <Index.Typography
                  id="modal-modal-title"
                  className="modal-title"
                  variant="h6"
                  component="h2"
                >
                  Give Reason For Reject
                </Index.Typography>
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Reason
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      name="Reason"
                      // defaultValue={props.row? props.row.type : ""}
                      {...register("Reason", {
                        required: "Reason is required",
                      })}
                      error={Boolean(errors.Reason)}
                      helperText={errors.Reason?.message}
                    />
                  </Index.Box>
                </Index.Box>

                <Index.Box className="modal-user-btn-flex">
                  <Index.Box className="discard-btn-main border-btn-main">
                    <Index.Button
                      className="discard-user-btn border-btn"
                      onClick={() => setOpen(false)}
                    >
                      Discard
                    </Index.Button>
                  </Index.Box>
                  <Index.Box className="save-btn-main border-btn-main">
                    <Index.Button
                      className="save-user-btn border-btn"
                      type="submit"
                    >
                      <img
                        src={Index.Svg.save}
                        className="user-save-icon"
                      ></img>
                      Save
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          </Index.Modal>
        </Index.Box>
      )}
    </>
  );
};

export default BranchDetail;
