import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { Controller, useForm } from "react-hook-form";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const AboutUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [editorData, setEditorData] = useState("");
  const [editorError, setEditorError] = useState("");
  const [aboutData, setAboutData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesUrl, setSelectedFilesUrl] = useState([]);
  const [prevImages, setPrevImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleFileSelect = async (event) => {
    // const files = event.target.files;
    // setSelectedFiles([...selectedFiles, ...files]);

    const files = event.target.files[0];
    const formdata = new FormData();
    formdata.append("image", files);

    setImageLoading(true);
    await DataService.post(
      `${Api.User.imageUpload}?encrypt=true`,
      formdata
    ).then((res) => {
      if (res.data.status === 200 || res.data.status === 200) {
        setSelectedFiles([...selectedFiles, files]);
        setSelectedFilesUrl([...selectedFilesUrl, res.data.data.image]);
        setImageLoading(false);
      }
    });
  };

  //console.log(selectedFilesUrl, selectedFiles, aboutData, "selectedFilesUrl");
  console.log(prevImages, "prevImages");
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);

    if (data == "") {
      setEditorError("Details is required");
    } else if (data != "") {
      setEditorError("");
    }
  };

  const onSubmit = async (data) => {
    if (editorData === "") {
      setEditorError("Details is required");
    } else {
      setLoading(true);
      const urlencoded = new URLSearchParams();
      urlencoded.append("title", data.title);
      urlencoded.append("description", editorData);
      urlencoded.append(
        "image",
        selectedFilesUrl.length > 0
          ? JSON.stringify(selectedFilesUrl)
          : JSON.stringify(prevImages)
      );
      aboutData._id && urlencoded.append("id", aboutData._id);

      await DataService.post(Api.Admin.addEditAboutus, urlencoded)
        .then((res) => {
          if (res.data.status === 201 || res.data.status === 200) {
            Index.toast.success("Updated successfully");
            getAboutUs();
            setSelectedFilesUrl([]);
            setSelectedFiles([]);
            setLoading(false);
          }
        })
        .catch((e) => {
          Index.toast.error(
            e.response.data.message ? e.response.data.message : e.message
          );
          setLoading(false);
        });
    }
  };

  const getAboutUs = async () => {
    await DataService.get(Api.Common.getAboutUs)
      .then((res) => {
        if (res.data.data.length > 0) {
          setAboutData(res.data.data[0]);
          setValue("title", res.data.data[0].title);
          setEditorData(res.data.data[0].description);
          setPrevImages(res.data.data[0].image);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
      });
  };
  useEffect(() => {
    getAboutUs();
  }, []);

  // console.log(selectedFiles, "uves45");
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Index.Box className="add-user-modal-inner-main about-us-main">
        <Index.Typography
          id="modal-modal-title"
          className="modal-title"
          variant="h6"
          component="h2"
        >
          About us
        </Index.Typography>
        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Title
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="title"
              {...register("title", {
                required: "Title is required",
              })}
              error={Boolean(errors.title)}
              helperText={errors.title?.message}
            />
          </Index.Box>
        </Index.Box>

        {/* <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Details
          </Index.FormHelperText>
          <CKEditor
            editor={ClassicEditor}
            data={editorData}
            onChange={handleEditorChange}
            
          />
        </Index.Box> */}

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Details
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.CKEditor
              editor={Index.ClassicEditor}
              data={editorData}
              onChange={handleEditorChange}
              //    name="description"
              //   {...register("description", {
              //     required: "Description is required",
              //   })}
            />

            <Index.FormHelperText error>{editorError}</Index.FormHelperText>
          </Index.Box>

          {/* {errors.description && <span>This field is required</span>} */}
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Images
          </Index.FormHelperText>
          <Index.Box sx={{ display: "flex" }}>
            {aboutData.image?.map((row, index) => (
              <Index.Box sx={{ margin: "10px" }}>
                <img
                  height={"140px"}
                  width={"140px"}
                  key={index}
                  src={Index.Constant.IMAGE_URL + row}
                />
              </Index.Box>
            ))}
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Select Images
          </Index.FormHelperText>

          <Index.Button
            variant="outlined"
            fullWidth
            component="label"
            className="file-upload-btn"
          >
            {imageLoading ? "Loading....." : "Select Images"}
            <input
              hidden
              accept="files/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={(e) => {
                handleFileSelect(e);
              }}
            />
          </Index.Button>
        </Index.Box>

        <Index.Box sx={{ display: "flex" }}>
          {selectedFiles.length > 0 &&
            selectedFiles.map((file, index) => (
              <Index.Box sx={{ margin: "10px" }}>
                <img
                  height={"140px"}
                  width={"140px"}
                  key={index}
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                />
              </Index.Box>
            ))}
        </Index.Box>

        <Index.Box className="modal-user-btn-flex">
          <Index.Box className="save-btn-main border-btn-main">
            <Index.LoadingButton
              className="save-user-btn border-btn"
              type="submit"
              loadingPosition="start"
              loading={loading}
              startIcon={<Index.SaveIcon />}
            >
              Save
            </Index.LoadingButton>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </form>
  );
};

export default AboutUs;
