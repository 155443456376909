import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { makeStyles } from "@mui/styles";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "TextValidator",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const GstConfiguration = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [gstData, setGstData] = useState({});
  const [gstDataForEdit, setGstDataForEdit] = useState({
    gstNumber: "",
    sgst: "",
    cgst: "",
    convenienceFee: "",
    GstOnConvenienceFee: "",
    transactionFee:""
  });
  const [gstNumberError, setGstNumberError] = useState("");
  const [sgstError, setSgstError] = useState("");
  const [cgstError, setCgstError] = useState("");
  const [convenienceFeeError, setConvenienceFeeError] = useState("");
  const [gstOnConvenienceFeeError, setGstOnConvenienceFeeError] = useState("");
  const [transactionFeeError, setTransactionFeeError] = useState("");

  const getGstDetails = () => {
    DataService.get(Api.Common.getAllAdminGSTConfiguration).then((res) => {
      const data = res.data.data;
      setGstData(data);
      const tempArr = {
        gstNumber: data?.gst_no,
        sgst: data?.sgst,
        cgst: data.cgst,
        convenienceFee: data?.convenience_fee,
        GstOnConvenienceFee: data?.gst_on_convenience,
        transactionFee:data?.transaction_fee
      };
      setGstDataForEdit(tempArr);
    });
  };
  useEffect(() => {
    getGstDetails();
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setGstDataForEdit({ ...gstDataForEdit, [name]: value });
  };

  const ValidationInput = () => {
    if (gstDataForEdit.gstNumber === "") {
      setGstNumberError("Please enter gst number");
    } else {
      setGstNumberError("");
    }

    if (gstDataForEdit.sgst === "") {
      setSgstError("Please enter sgst amount");
    } else {
      setSgstError("");
    }

    if (gstDataForEdit.cgst === "") {
      setCgstError("Please enter cgst amount");
    } else {
      setCgstError("");
    }

    if (gstDataForEdit?.convenienceFee === "") {
      setConvenienceFeeError("Please enter convenience fee");
    } else {
      setConvenienceFeeError("");
    }

    if (gstDataForEdit?.GstOnConvenienceFee === "") {
      setGstOnConvenienceFeeError("Please enter gst on convenience fee");
    } else {
      setGstOnConvenienceFeeError("");
    }
    if (gstDataForEdit?.transactionFee === "") {
      setTransactionFeeError("Please enter transaction fee");
    } else {
      setTransactionFeeError("");
    }
  };

  const handleEditGstDetails = () => {
    ValidationInput();

    if (Object.values(gstDataForEdit).every((e) => e !== "")) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("id", gstData?._id);
      urlencoded.append("gst_no", gstDataForEdit.gstNumber);
      urlencoded.append("sgst", gstDataForEdit.sgst);
      urlencoded.append("cgst", gstDataForEdit.cgst);
      urlencoded.append("convenience_fee", gstDataForEdit.convenienceFee);
      urlencoded.append("gst_on_convenience", gstDataForEdit.GstOnConvenienceFee);
      urlencoded.append("transaction_fee", gstDataForEdit.transactionFee);

      DataService.post(Api.Admin.addEditAdminGSTConfiguration, urlencoded)
        .then((res) => {
          Index.toast.success(res.data.message);
          setOpen(false);
          getGstDetails();
        })
        .catch((e) => {
          Index.toast.error(e.response.data.message || e.message);
        });
    }
  };

  return (
    <Index.Box className="dashboard-content booking-configuration-main">
      <Index.Box className="booking-border-main">
        <Index.Box className="booking-configuration-titles">
          <Index.Typography
            className="common-para booking-text-main"
            component="p"
            variant="p"
          >
            GST Configuration
          </Index.Typography>
          {/* <Index.Typography
              className="common-para booking-text-para"
              component="p"
              variant="p"
            >
              Section to config basic product information
            </Index.Typography> */}
        </Index.Box>
        <Index.Box className="border-btn-main booking-edit-btn-main">
          <Index.Button
            className="border-btn booking-edit-btn"
            onClick={() => setOpen(true)}
          >
            <img src={Index.Svg.edit} className="booking-ediit-icon" />
            Edit
          </Index.Button>
        </Index.Box>
        <Index.Box className="admin-booking-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    GST Number
                  </Index.FormHelperText>
                  <Index.TextField
                    disabled
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={gstData?.gst_no}
                  />
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Sgst
                  </Index.FormHelperText>
                  <Index.TextField
                    disabled
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={gstData?.sgst}
                  />
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Cgst
                  </Index.FormHelperText>
                  <Index.TextField
                    disabled
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={gstData?.cgst}
                  />
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Convenience fee %
                  </Index.FormHelperText>
                  <Index.TextField
                    disabled
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={gstData?.convenience_fee}
                  />
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Gst on convenience fee %
                  </Index.FormHelperText>
                  <Index.TextField
                    disabled
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={gstData?.gst_on_convenience}
                  />
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Transaction fee
                  </Index.FormHelperText>
                  <Index.TextField
                    disabled
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    value={gstData?.transaction_fee}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main gst-config-modal"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Edit GST Configuration
          </Index.Typography>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              GST Number
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className={classes.input}
                name="gstNumber"
                value={gstDataForEdit?.gstNumber}
                onChange={(e) => handleInput(e)}
              />
            </Index.Box>

            <Index.FormHelperText>
              <Index.Typography className="error-msg" variant="p">
                {gstNumberError}
              </Index.Typography>
            </Index.FormHelperText>
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Sgst
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className={classes.input}
                name="sgst"
                type="number"
                value={gstDataForEdit?.sgst}
                onChange={(e) => handleInput(e)}
              />
            </Index.Box>

            <Index.FormHelperText>
              <Index.Typography className="error-msg" variant="p">
                {sgstError}
              </Index.Typography>
            </Index.FormHelperText>
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              cgst
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className={classes.input}
                name="cgst"
                type="number"
                value={gstDataForEdit?.cgst}
                onChange={(e) => handleInput(e)}
              />
            </Index.Box>

            <Index.FormHelperText>
              <Index.Typography className="error-msg" variant="p">
                {cgstError}
              </Index.Typography>
            </Index.FormHelperText>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Convenience fee
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className={classes.input}
                name="convenienceFee"
                type="number"
                value={gstDataForEdit?.convenienceFee}
                onChange={(e) => handleInput(e)}
              />
            </Index.Box>

            <Index.FormHelperText>
              <Index.Typography className="error-msg" variant="p">
                {convenienceFeeError}
              </Index.Typography>
            </Index.FormHelperText>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Gst on convenience fee
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className={classes.input}
                name="GstOnConvenienceFee"
                type="number"
                value={gstDataForEdit?.GstOnConvenienceFee}
                onChange={(e) => handleInput(e)}
              />
            </Index.Box>

            <Index.FormHelperText>
              <Index.Typography className="error-msg" variant="p">
                {gstOnConvenienceFeeError}
              </Index.Typography>
            </Index.FormHelperText>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Transaction fee
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className={classes.input}
                name="transactionFee"
                type="number"
                value={gstDataForEdit?.transactionFee}
                onChange={(e) => handleInput(e)}
              />
            </Index.Box>

            <Index.FormHelperText>
              <Index.Typography className="error-msg" variant="p">
                {transactionFeeError}
              </Index.Typography>
            </Index.FormHelperText>
          </Index.Box>

          <Index.Box className="center-button">
            <Index.Button
              variant="contained"
              onClick={() => handleEditGstDetails()}
            >
              Submit
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </Index.Box>
  );
};

export default GstConfiguration;
