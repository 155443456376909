import React,{useState,useEffect} from "react";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import { downloadPdfRepport, downloadReport } from "../../../../../common/functions";

const MoneyView = () => {
  const [moneyViewData, setMoneyViewData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedType, setSelectedType] = useState("commission");
  const [startDate,setStartDate] = useState("")
  const [endDate,setEndDate] = useState("")

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const typeOptions = [
    {
      key: "commission",
      name: "Commission",
    },
    {
      key: "penalty",
      name: "Penalty",
    },
    {
      key: "refund",
      name: "Refund",
    },
  ];

  const getAllMoneyViewList = async (type,startDate,endDate) => {
    setLoading(true);
    const data = {
      type,
      startDate,
      endDate
    }
    await DataService.post(Api.Admin.moneyViewReport, data)
      .then((response) => {
        if (response.data.status === 200) {
          setMoneyViewData(response.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        setMoneyViewData([])
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadReport = () => {
    const data = moneyViewData.map((item) => {
      return {   
        "Branch Name": item?.branchName,
        Amount: item?.amount.toFixed(2),
 
      };
    });
    downloadReport(data,"Money view report");
  };

  const handleExportPDF = () => {
    const tableHeaders = [
      "Branch Name",
      "Amount",
    ];
    const tableData = moneyViewData.map((row) => [
      row.branchName,
      row.amount.toFixed(2),
    ]);
    downloadPdfRepport(tableHeaders,tableData,"Money view report")
  };

  useEffect(() => {
    getAllMoneyViewList(selectedType,startDate,endDate);
  }, []);

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="view-btn-main border-btn-main filter-btn export-transaction-btn">
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!moneyViewData.length}
                        onClick={() => {
                          handleDownloadReport();
                        }}
                    >
                      Export
                    </Index.Button>
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!moneyViewData.length}
                        onClick={() => {
                          handleExportPDF();
                        }}
                    >
                      Export PDF
                    </Index.Button>
                  </Index.Box>

                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Money view Reports
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group table-head-date-main">
                           
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group table-head-date-main">
                          <Index.Box className="branch-name">
                              <Index.Typography>Type:</Index.Typography>
                              <Index.Select
                                value={selectedType}
                                displayEmpty
                                onChange={(e) =>
                                  setSelectedType(e.target.value)
                                }
                                className="form-control"
                              >
                                <Index.MenuItem value="" disabled>
                                  Select Type
                                </Index.MenuItem>
                                {typeOptions?.map((row) => {
                                  return (
                                    <Index.MenuItem value={row?.key}>
                                      {row?.name}
                                    </Index.MenuItem>
                                  );
                                })}
                              </Index.Select>
                            </Index.Box>
                            <Index.Box className="from-to-date-table">
                              <Index.Box className="from-date-label fromto-date-label-same">
                                <Index.Typography>From :</Index.Typography>
                                <Index.TextField
                                  type="date"
                                  value={startDate}
                                  onChange={(e) => {
                                    setStartDate(
                                      Index.moment(e.target.value).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                    setEndDate("");
                                  }}
                                />
                              </Index.Box>
                              <Index.Box className="to-date-label fromto-date-label-same">
                                <Index.Typography>To :</Index.Typography>
                                <Index.TextField
                                  type="date"
                                  value={endDate}
                                  onChange={(e) => {
                                    setEndDate(
                                      Index.moment(e.target.value).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                  }}
                                  inputProps={{
                                    min: startDate,
                                  }}
                                />
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="view-btn-main border-btn-main filter-btn">
                              <Index.Button
                                className="view-order-btn border-btn"
                                onClick={() =>
                                  getAllMoneyViewList(
                                    selectedType,
                                    startDate,
                                    endDate,
                                  )
                                }
                              >
                                Filter
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                    </Index.Box>
                    <Index.Box className="page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Branch Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Amount
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {moneyViewData.length > 0
                              ? moneyViewData &&
                                paginate(
                                  moneyViewData,
                                  rowsPerPage,
                                  page + 1
                                ).map((row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.branchName || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.amount.toFixed(2) || "-"}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              : "No Data Found"}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={moneyViewData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default MoneyView;
