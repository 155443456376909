import usericon from "./images/png/user.png";
import logo from "./images/png/logo.png";
import edit from "./images/png/edit.png";
import loginbg from "./images/png/login-bg.png";
import userlist1 from "./images/png/userlist1.png";
import bergurmenu from "./images/png/bergur-menu.png";
import close from "./images/png/close.png";
import veg from "./images/png/veg.png";
import location from "./images/png/location.png";
import pizza from "./images/png/pizza.png";
import detailpizza from "./images/png/details-pizza.png";
import detailburger from "./images/png/details-burger.png";
import detailchina from "./images/png/details-china.png";
import detailnoodles from "./images/png/details-noodles.png";
import burger from "./images/png/burger.png";
import china from "./images/png/china.png";
import noodles from "./images/png/noodles.png";
import mealord from "./images/png/mealOrd.png";
import mealordlogo from "./images/png/mealordlogo.png";
import shopingcart from "./images/png/shopping-cart.png";

import vegicon from "./images/png/veg-icon.png";
import nonveg from "./images/png/non-veg.png";
import vegan from "./images/png/vegan.png";
import singleLogo from "./images/png/mealOrd-single-logo.png";

const Png = {
  vegicon,
  nonveg,
  vegan,
  shopingcart,
  location,
  usericon,
  logo,
  edit,
  loginbg,
  userlist1,
  bergurmenu,
  close,
  veg,
  pizza,
  burger,
  china,
  noodles,
  detailpizza,
  detailburger,
  detailchina,
  detailnoodles,
  mealord,
  mealordlogo,
  singleLogo,
};

export default Png;
