import Index from "../component/Index";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

export const displayValue = (value) => {
  return value ? value : "-";
};

export const displayNumberValueWithToFixed = (value) => {
  if (value === undefined || isNaN(value)) {
    return "-";
  } else if (value == 0) {
    return "0";
  } else {
    return value.toFixed(2);
  }
};

export const displayNumberValue = (value) => {
  if (value === undefined || isNaN(value)) {
    return "-";
  } else if (value == 0) {
    return "0";
  } else {
    return value;
  }
};

export const displayDateValue = (value) => {
  return Index.moment(value).format("DD-MM-YYYY");
};
export const displayDateWithTime = (value) => {
  return Index.moment(value).format("DD-MM-YYYY h:mm A");
};

export const successToast = (message) => {
  return Index.toast.success(message, {
    toastId: "customId",
  });
};

export const errorToast = (error) => {
  return Index.toast.error(
    error.response.data.message ? error.response.data.message : error.message,
    {
      toastId: "customId",
    }
  );
};
export const downloadReport = (formdata, fileName) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(formdata);
  // delete ws["H1"];
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export const downloadPdfRepport = (tableHeaders, tableData, fileName) => {
  const doc = new jsPDF();
  autoTable(doc, {
    head: [tableHeaders],
    body: tableData,
  });

  doc.save(`${fileName}.pdf`);
};
