import React from "react";
import Index from "../../../../../../component/Index";

const Documents = ({ documents }) => {
  const displayImage = (image) => {
    if (image === "" || image === undefined) {
      Index.toast.error("Image not found");
    } else {
      window.open(Index.Constant.IMAGE_URL + image);
    }
  };
  console.log(documents);
  return (
    <>
      <Index.Box className="branch-btn-flex">
        <Index.Button
          className="branch-btns"
          onClick={() => displayImage(documents?.aadhar_card)}
        >
          Adhar card{" "}
        </Index.Button>
        <Index.Button
          className="branch-btns"
          onClick={() => displayImage(documents?.pan_card)}
        >
          Pan Card
        </Index.Button>
        <Index.Button
          className="branch-btns"
          onClick={() => displayImage(documents?.fssai_certi)}
        >
          Fssai certificate
        </Index.Button>
        <Index.Button
          className="branch-btns"
          onClick={() => displayImage(documents.gst_certi)}
        >
          GST certificate
        </Index.Button>
      </Index.Box>
    </>
  );
};

export default Documents;
