import React, { useState } from "react";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  
  return (
    <p className="read-more-text">
      {isReadMore ? text.slice(0, 180) : text}
      {text.length > 180 && (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...Read more" : " Show less"}
        </span>
      )}
    </p>
  );
};

export default ReadMore;
