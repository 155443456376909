import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import AddSelection from "./AddSelection";
import DeleteModal from "../../../../../component/common/DeleteModal";

const Selecttion = () => {
  const [open, setOpen] = useState(false);
  const [selectionData, setSelectionData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectionRow, setSelectionRow] = useState("");
  const [foodTypeData, setFoodTypeData] = useState({});
  const [loading, setLoading] = useState(false);

  const getAllSelectionList = async () => {
    setLoading(true);
    await DataService.get(Api.Common.getAllSelectionList)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          setSelectionData(response.data.data);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllSelectionList();
    getAllFoodType();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecocrd = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", selectionRow?._id);
    await DataService.post(Api.Admin.deleteSelection, urlencoded)
      .then((res) => {
        if (res.data.status === 200) {
          handleDeleteClose();
          getAllSelectionList();
          setSelectionRow("");
          Index.toast.success("Selection deleted successfully");
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  const getAllFoodType = async () => {
    await DataService.get(Api.Common.getAllFoodType).then((response) => {
      if (response.data.status === 200) {
        setFoodTypeData(response.data.data);
      }
    });
  };

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="flex-all admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Selections
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="view-btn-main border-btn-main">
                      <Index.Button
                        className="view-order-btn border-btn"
                        onClick={() => {
                          handleOpen();
                          setSelectionRow("");
                        }}
                      >
                        Add
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="page-table-main food-item-table food-selection-table">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 500 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              NO
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Selection on
                            </Index.TableCell>
                            {/* 
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            align="center"
                          >
                            Items
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                //className="table-th"
                              >
                                Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Food Type
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableCell> */}

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Selection type
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {selectionData.map((row, index) => (
                            <Index.TableRow
                              key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {index + 1}
                              </Index.TableCell>

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.selection_on}
                              </Index.TableCell>
                              {/* 
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              {row.item?.map((item, index) => {
                                return (
                                  <>
                                    <Index.TableRow>
                                      <Index.TableCell
                                        // component="td"
                                        // variant="td"
                                        // scope="row"
                                        // className="table-td"
                                      >
                                        {item.name}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        // component="td"
                                        // variant="td"
                                        // scope="row"
                                        // className="table-td"
                                      >
                                        {item?.food_type?.type}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </>
                                );
                              })}
                            </Index.TableCell> */}

                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.selection_type ? "Multiple" : "Single"}
                              </Index.TableCell>

                              <Index.TableCell>
                                <Index.Button
                                  className="table-btn"
                                  onClick={() => {
                                    handleOpen();
                                    setSelectionRow(row);
                                  }}
                                >
                                  <img
                                    src={Index.Svg.blueedit}
                                    alt="edit-icon"
                                  ></img>
                                </Index.Button>
                                <Index.Button
                                  className="table-btn"
                                  onClick={() => {
                                    handleDeleteOpen();
                                    setSelectionRow(row);
                                  }}
                                >
                                  <img
                                    src={Index.Svg.trash}
                                    alt="delete-icon"
                                  ></img>
                                </Index.Button>
                              </Index.TableCell>
                            </Index.TableRow>
                          ))}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>

                    {open && (
                      <AddSelection
                        open={open}
                        handleClose={handleClose}
                        getAllSelection={getAllSelectionList}
                        foodTypeData={foodTypeData}
                        row={selectionRow}
                        style={Index.Constant.MODAL_STYLE}
                      />
                    )}
                    {deleteOpen && (
                      <DeleteModal
                        deleteOpen={deleteOpen}
                        handleDeleteClose={handleDeleteClose}
                        hanldeDeleteReocrd={handleDeleteRecocrd}
                      />
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default Selecttion;
