import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { Controller, useForm } from "react-hook-form";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
const SocialMedia = () => {

    const [linksData, setLinksData] =  useState([])

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const onSubmit = async(data) => {

    const urlencoded = new URLSearchParams();
    urlencoded.append("facebook_link", data.facebook);
    urlencoded.append("instagram_link", data.instagram);
    urlencoded.append("linkedIn_link", data.linkedin);
    urlencoded.append("twitter_link", data.twitter);
    urlencoded.append("youtube_link", data.youtube);
    urlencoded.append("link", data.discord);
    urlencoded.append("telegram_link", data.telegram);
    urlencoded.append("id", linksData._id);

    await DataService.post(Api.Admin.addEditSocial, urlencoded)
      .then((res) => {
        if (res.data.status === 201 || res.data.status === 200) {
          Index.toast.success("Updated successfully");
          getAllLinks();
        }
      })
      .catch((e) => {
        Index.toast.error(e.response.data.message);
      });
  };

  const getAllLinks = async () => {
    await DataService.get(Api.Common.getAllSocialLinks).then((res) => {
      if (res.data.data) {
        setLinksData(res.data.data[0]);

        setValue("facebook", res.data.data[0].facebook_link);
        setValue("instagram", res.data.data[0].instagram_link);
        setValue("linkedin", res.data.data[0].linkedIn_link);
        setValue("twitter", res.data.data[0].twitter_link);
        setValue("youtube", res.data.data[0].youtube_link);
        setValue("discord", res.data.data[0].link);
        setValue("telegram", res.data.data[0].telegram_link); 
      }
    });
  };
  useEffect(() => {
    getAllLinks();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Index.Box className="add-user-modal-inner-main about-us-main">
        <Index.Typography
          id="modal-modal-title"
          className="modal-title"
          variant="h6"
          component="h2"
        >
          Social Link
        </Index.Typography>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Facebook
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="facebook"
              {...register("facebook", {
                required: "Facebook is required",
                pattern: {
                  value:
                    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                  message: "Please enter a valid link",
                },
              })}
              error={Boolean(errors.facebook)}
              helperText={errors.facebook?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Instagram
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="instagram"
              {...register("instagram", {
                required: "Instagram is required",
                pattern: {
                  value:
                    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                  message: "Please enter a valid link",
                },
              })}
              error={Boolean(errors.instagram)}
              helperText={errors.instagram?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Twitter
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="twitter"
              {...register("twitter", {
                required: "Twitter is required",
                pattern: {
                  value:
                    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                  message: "Please enter a valid link",
                },
              })}
              error={Boolean(errors.twitter)}
              helperText={errors.twitter?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Linkedin
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="linkedin"
              {...register("linkedin", {
                required: "Linkedin is required",
                pattern: {
                  value:
                    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                  message: "Please enter a valid link",
                },
              })}
              error={Boolean(errors.linkedin)}
              helperText={errors.linkedin?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Youtube
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="youtube"
              {...register("youtube", {
                required: "Youtube is required",
                pattern: {
                  value:
                    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                  message: "Please enter a valid link",
                },
              })}
              error={Boolean(errors.youtube)}
              helperText={errors.youtube?.message}
            />
          </Index.Box>
        </Index.Box>

       

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Discord
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="discord"
              {...register("discord", {
                required: "Discord is required",
                pattern: {
                  value:
                    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                  message: "Please enter a valid link",
                },
              })}
              error={Boolean(errors.discord)}
              helperText={errors.discord?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Telegram
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="telegram"
              {...register("telegram", {
                required: "Telegram is required",
                pattern: {
                  value:
                    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                  message: "Please enter a valid link",
                },
              })}
              error={Boolean(errors.telegram)}
              helperText={errors.telegram?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="modal-user-btn-flex">
          <Index.Box className="save-btn-main border-btn-main">
            <Index.Button className="save-user-btn border-btn" type="submit">
              <img src={Index.Svg.save} className="user-save-icon"></img>Save
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </form>
  );
};

export default SocialMedia;
