import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Index from '../../../../component/Index';
import { toast } from 'react-toastify';
import { Api } from '../../../../config/Api';
import DataService from '../../../../config/DataService';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import ChangePassword from './ChangePassword';
import { useEffect } from 'react';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EditProfile() {
  const [tabValue, setTabValue] = useState(0);
  const [image, setImage] = useState(null);
  const [adminDetails,setAdminDetails] = useState(JSON.parse(localStorage.getItem("admin-details")));
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
    setValue
  } = useForm();

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleImg = (e) => {
    setImage(e.target.files[0]);
  };

  console.log(image, 'image');

  const IOSSwitch = Index.styled((props) => (
    <Index.Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 34,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 3,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark' ? '#2ECA45' : '#114A65',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 14,
      height: 14,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));


  useEffect(()=>{
    // let adminDetails = JSON.parse(localStorage.getItem("admin-details"))
    // setAdminDetails(adminDetails);

    setValue('name', adminDetails.name)
    setValue('email', adminDetails.email)
    setValue('title', adminDetails.title)
    setValue('language', adminDetails.language)

  },[])
  
  const onSubmit = async (data) => {

    const urlencoded = new FormData();
    urlencoded.append('name', data.name);
    urlencoded.append('email', data.email);
    urlencoded.append('title', data.title);
    urlencoded.append('language', data.language);
    urlencoded.append('image', image ? image : adminDetails.profile_image );


    await DataService.patch(`${Api.Admin.updateProfile}/${adminDetails._id}`, urlencoded).then((res) => {
      if (res.data.status === 200 || res.data.status === 201 || res.data.status === 202) {
        toast.success('Profile updated successfully');
        navigate('/login');
      }
    });
  };

  return (
    <Index.Box className="dashboard-content add-user-containt">
      <Index.Typography
        className="admin-page-title"
        component="h2"
        variant="h2"
      >
        Account Settings
      </Index.Typography>

      <Index.Box className="tabs-main-box">
        <Index.Box sx={{ width: '100%' }}>
          <Index.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Index.Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="admin-tabs-main"
            >
              <Index.Tab
                label="Edit Profile"
                {...a11yProps(0)}
                className="admin-tab"
              />
              <Index.Tab
                label="Change Password"
                {...a11yProps(1)}
                className="admin-tab"
              />
            </Index.Tabs>
          </Index.Box>
          <TabPanel value={tabValue} index={0} className="admin-tabpanel">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Index.Box className="tabpanel-main">
                <Index.Box className="page-border">
                  <Index.Typography
                    className="common-para edit-highlight-text"
                    component="p"
                    variant="p"
                  >
                    General
                  </Index.Typography>
                  <Index.Typography
                    className="common-para edit-para-text"
                    component="p"
                    variant="p"
                  >
                    Basic info, like your name and address that will displayed
                    in public
                  </Index.Typography>
                  <Index.Box className="edit-profile-flex">
                    <Index.Box className="file-upload-btn-main">
                      <img
                        src={
                          image
                            ? window.URL.createObjectURL(image)
                            : Index.Constant.IMAGE_URL + adminDetails?.profile_image
                        }
                        className="upload-edit-profile-img"
                        alt="upload img"
                      ></img>
                      <Index.Button
                        variant="contained"
                        component="label"
                        className="file-upload-btn"
                       
                      >
                        <img
                          src={Index.Svg.edit}
                          className="upload-icon-img"
                          alt="upload img"
                        ></img>
                        <input
                          hidden
                          accept="files/*"
                          type="file"
                          
                        //   {...register('files', {
                        //   required: "Please select Image",
                        // })}

                        onChange={(e) => {
                          handleImg(e)
                        }}
                        />
                      </Index.Button>
                      <Index.FormHelperText error id="accountId-error">
                          {errors.files?.message
                            ? errors.files?.message
                            : ''}
                        </Index.FormHelperText>
                    </Index.Box>
                    <Index.Box className="switch-main">
                      <Index.FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                        label="Public Profile"
                        className="switch-lable"
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="add-user-data-main">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        className="display-row"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: 'span 12',
                            sm: 'span 6',
                            md: 'span 6',
                            lg: 'span 6',
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Name
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                sx={{
                                  '& .MuiFormHelperText-root': {
                                    ml: 0,
                                    mt: '5px',
                                  },
                                }}
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                name="name"
                                {...register('name', {
                                  required: 'Name is required',
                                })}
                                error={Boolean(errors.name)}
                                helperText={errors.name?.message}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: 'span 12',
                            sm: 'span 6',
                            md: 'span 6',
                            lg: 'span 6',
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Email
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                sx={{
                                  '& .MuiFormHelperText-root': {
                                    ml: 0,
                                    mt: '5px',
                                  },
                                }}
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                name="email"
                                {...register('email', {
                                  required: 'Email is required',
                                })}
                                error={Boolean(errors.email)}
                                helperText={errors.email?.message}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: 'span 12',
                            sm: 'span 6',
                            md: 'span 6',
                            lg: 'span 6',
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Title
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                sx={{
                                  '& .MuiFormHelperText-root': {
                                    ml: 0,
                                    mt: '5px',
                                  },
                                }}
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                name="title"
                                {...register('title', {
                                  required: 'Title is required',
                                })}
                                error={Boolean(errors.title)}
                                helperText={errors.title?.message}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: 'span 12',
                            sm: 'span 6',
                            md: 'span 6',
                            lg: 'span 6',
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Language
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                sx={{
                                  '& .MuiFormHelperText-root': {
                                    ml: 0,
                                    mt: '5px',
                                  },
                                }}
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                name="language"
                                {...register('language', {
                                  required: 'Language is required',
                                })}
                                error={Boolean(errors.language)}
                                helperText={errors.language?.message}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        {/* <Index.Box
                          gridColumn={{
                            xs: 'span 12',
                            sm: 'span 12',
                            md: 'span 12',
                            lg: 'span 12',
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="user-btn-flex">
                            <Index.Box className="discard-btn-main border-btn-main">
                              <Index.Button className="discard-user-btn border-btn">
                                Discard
                              </Index.Button>
                            </Index.Box>
                            <Index.Box className="save-btn-main border-btn-main">
                              <Index.Button
                                className="save-user-btn border-btn"
                                type="submit"
                              >
                                <img
                                  src={Index.Svg.save}
                                  className="user-save-icon"
                                ></img>
                                Save
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          </TabPanel>
          <TabPanel value={tabValue} index={1} className="admin-tabpanel">
            <ChangePassword />
          </TabPanel>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
