import React, { useEffect, useState } from 'react';
import Index from '../../../../../component/Index';
import { useForm, Controller } from 'react-hook-form';
import DataService from '../../../../../config/DataService';
import { Api } from '../../../../../config/Api';

const AddTeam = (props) => {

    const [images , setImages] = useState(null)
    const [imageName , setImageName] = useState('')
    const [imageError , setImageError] = useState('');


      const {
        register,
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        formState: { errors },
      } = useForm();

      const handleImageChange = (e) => {
        setImageName(e.target.files[0].name)
        setImages(e.target.files[0])
      }

    const onSubmit = async(data)=>{
        
      const formData = new FormData();
      formData.append("name", data.name)
      formData.append("image", images ? images : imageName);
      formData.append("position", data.position)
      formData.append("bio", data.bio)
      formData.append("facebook_link", data.facebook)
      formData.append("instagram_link", data.instagram)
      formData.append("twitter_link", data.twitter)
      props.row && formData.append("id", props.row?._id)
      await  DataService.post(Api.Admin.addEditTeam, formData).then((res)=>{

        if(res.data.status === 200 || res.data.status === 201)
        {
          if(props.row)
          {
            Index.toast.success("Team updated successfully")
          }
          else
          {
            Index.toast.success("Team adedd successfully")
        
          }
          props.handleClose();
          props.getAllTeam();
          reset();
          setImages(null)
          setImageName("")
          
        }
      }).catch((e)=>{
        Index.toast.error(e.response.data.message)
      })
    }  


    useEffect(()=>{
      if(props.row)
      {
        setValue("name", props.row.name)
        setValue("position", props.row.position)
        setValue("bio", props.row.bio)
        setValue("facebook", props.row.facebook_link)
        setValue("instagram", props.row.instagram_link)
        setValue("twitter", props.row.twitter_link)
        setImageName(props.row ? props?.row?.image : "Select File");
      }
    },[])
  return (
    <Index.Modal
    open={props.open}
    onClose={props.handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="modal"
  >
    <form onSubmit={handleSubmit(onSubmit)}>
      <Index.Box sx={Index.Constant.MODAL_STYLE} className="add-food-item-modal-inner-main">
        <Index.Typography
          id="modal-modal-title"
          className="modal-title"
          variant="h6"
          component="h2"
        >
         {props.row ? 'Update' : 'Add'} Team
        </Index.Typography>


        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Select Image
          </Index.FormHelperText>
          <Index.Button
            variant="outlined"
            fullWidth
            // startIcon={<Index.AddIcon/>}
            component="label"
            className="file-upload-btn"
            // onChange={(e) => handleImageChange(e)}
          >
            {imageName ? imageName :  'Select File'} 
            <input
                    hidden
                    accept="files/*"
                    id="contained-button-file"
                    // multiple
                    type="file"
                    onChange={(e) => {
                      handleImageChange(e)
                    }}
                  />
          </Index.Button>
    
          <Index.FormHelperText error id="accountId-error">
              {imageName == 'Select File' ? imageError : ""}
            </Index.FormHelperText>
       

        </Index.Box>


        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Name
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="name"
              {...register('name', {
                required: 'Name is required',
              })}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Position / Experties
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="position"
              {...register('position', {
                required: 'Position is required',
              })}
              error={Boolean(errors.position)}
              helperText={errors.position?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Bio
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="bio"
              {...register('bio', {
                required: 'Bio is required',
              })}
              error={Boolean(errors.bio)}
              helperText={errors.bio?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Facebook Link
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="facebook"
              {...register('facebook',{
                pattern: {
                  value:/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                  message: 'Please enter a valid link',
              },
              })}
              error={Boolean(errors.facebook)}
              helperText={errors.facebook?.message}
            />
          </Index.Box>
        </Index.Box>

       
        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Instagram Link
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              defaultValue={props?.row?.item_name ? props?.row?.item_name : ""}
              name="instagram"
              {...register('instagram',{
                pattern: {
                  value:/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                  message: 'Please enter a valid link',
              },
              })} 
              error={Boolean(errors.instagram)}
              helperText={errors.instagram?.message}
            />
          </Index.Box>
        </Index.Box>
       

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Twitter Link
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              defaultValue={props?.row?.item_name ? props?.row?.item_name : ""}
              name="twitter"
              {...register('twitter',{
                pattern: {
                  value:/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                  message: 'Please enter a valid link',
              },
              })} 
              error={Boolean(errors.twitter)}
              helperText={errors.twitter?.message}

            />
          </Index.Box>
        </Index.Box>

   

        <Index.Box className="modal-user-btn-flex">
          <Index.Box className="discard-btn-main border-btn-main">
            <Index.Button
              className="discard-user-btn border-btn"
              onClick={props.handleClose}
            >
              Discard
            </Index.Button>
          </Index.Box>
          <Index.Box className="save-btn-main border-btn-main">
            <Index.Button className="save-user-btn border-btn" type="submit">
              <img src={Index.Svg.save} className="user-save-icon"></img>Save
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </form>
  </Index.Modal>
  )
}

export default AddTeam