import React, { useEffect, useState } from 'react';
import Index from '../../../../component/Index'
import { useForm, Controller } from 'react-hook-form';
import DataService from '../../../../config/DataService';
import { Api } from '../../../../config/Api';

const EditRestaurant = (props) => {

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
      } = useForm();

      const [loading, setLoading] = useState(false)
      const onSubmit = async(data) =>{

        setLoading(true)
        const urlencoded = new URLSearchParams();
        urlencoded.append("restaurant_name", data.restaurantName)
        urlencoded.append("email", data.email)
        urlencoded.append("owner_name", data.ownerName)
        urlencoded.append("mobile_number", data.mobileNo)
        urlencoded.append("mobile_number1", data.additionalMobileNo1 || 0)
        urlencoded.append("mobile_number2", data.additionalMobileNo2 || 0)
        props.row && urlencoded.append("id", props.row?._id)

        await DataService.post(Api.Admin.editRestaurant, urlencoded).then(
          (response) => {
            if (response.data.status === 200 || response.data.status === 201) {
              setLoading(false)
             Index.toast.success("Restaurant updated successfully")
              props.getAllRestaurantList();
              props.handleClose();
              reset();
            }
          }
        ).catch((e)=>{
          Index.toast.error(e.response.data.message ? e.response.data.message : e.message );
          setLoading(false)
        });
      }

      useEffect(()=>{
        if(props.row)
        {
          setValue("restaurantName", props.row?.restaurant_name)
          setValue("email", props.row?.email)
          setValue("ownerName", props.row?.owner_name)
          setValue("mobileNo", props.row?.mobile_number)
          setValue("additionalMobileNo1", props.row?.mobile_number1 || "")
          setValue("additionalMobileNo2", props.row?.mobile_number2 || "")
        }
      },[props.row])
  return (
      <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box sx={Index.Constant.MODAL_STYLE} className="add-food-item-modal-inner-main">
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Edit Restaurant Details
          </Index.Typography>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Restaurant Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="restaurantName"
                {...register('restaurantName', {
                  validate:(value)=>Index.validateWithStringOnly(value,"Restaurant name",50)
                })}
                error={Boolean(errors.restaurantName)}
                helperText={errors.restaurantName?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Email
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="email"
                {...register('email', {
                  required: "Restaurant email is required",
                  pattern: {
                    value:
                      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    message: "Please enter a valid email",
                  },
                })}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Owner Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="ownerName"
                {...register('ownerName', {
                  validate:(value)=>Index.validateWithStringOnly(value,"Owner name",50)
                })}
                error={Boolean(errors.ownerName)}
                helperText={errors.ownerName?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Mobile Number
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="mobileNo"
                {...register('mobileNo', {
                  required: 'Mobile Number is required',
                  pattern:{
                    value:/^([+]\d{2})?\d{10}$/,
                    message:"Please enter a valid mobile number"
                  }
                })}
                error={Boolean(errors.mobileNo)}
                helperText={errors.mobileNo?.message}
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
             Additional Mobile Number (Optional)
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="additionalMobileNo1"
                {...register('additionalMobileNo1',{
                    pattern:{
                        value:/^([+]\d{2})?\d{10}$/,
                        message:"Please enter a valid mobile number"
                      }
                })}
                error={Boolean(errors.additionalMobileNo1)}
                helperText={errors.additionalMobileNo1?.message}
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
             Additional Mobile Number (Optional)
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="additionalMobileNo2"
                {...register('additionalMobileNo2',{
                    pattern:{
                        value:/^([+]\d{2})?\d{10}$/,
                        message:"Please enter a valid mobile number"
                      }
                })}
                error={Boolean(errors.additionalMobileNo2)}
                helperText={errors.additionalMobileNo2?.message}
              />
            </Index.Box>
          </Index.Box>
        
        <Index.SubmitDiscardButton loading={loading} handleClose={props.handleClose} />
        </Index.Box>
      </form>
    </Index.Modal>
  )
}

export default EditRestaurant