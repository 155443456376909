import axios from "axios";
// var baseUrl = 'http://35.177.56.74:3014/api/';
// var baseUrl ='https://demo.mealord.in/api/';
var baseUrl = "https://api.mealord.in/api/";
// var baseUrl ='http://localhost:3014/api/';

const DataService = axios.create({
  baseURL: baseUrl,
});

DataService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.auth = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default DataService;
