import React, { useEffect, useState } from "react";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import { makeStyles } from "@mui/styles";
import Index from "../../../../../component/Index";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "TextValidator",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});


const BookingConfiguration = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [number, setNumber] = useState([]);
  const [bookingData, setBookingData] = useState({});
  const [booking, setBooking] = useState({
    maxDays: "",
    // maxSets: "",
    minServingTime: "",
    maxServingTime: "",
    minPreparationTime: "",
    maxPreparationTime: "",
    minBufferTime: "",
    maxBufferTime: "",
  });
  const [maxDaysError, setMaxDaysError] = useState("");
  const [minservingTimeError, setMinServingTimeError] = useState("");
  const [maxservingTimeError, setMaxServingTimeError] = useState("");
  const [minpreparationTimeError, setMinPreparationTimeError] = useState("");
  const [maxpreparationTimeError, setMaxPreparationTimeError] = useState("");
  const [minbuffertimeError, setMinBufferTimeError] = useState("");
  const [maxbuffertimeError, setMaxBufferTimeError] = useState("");

  const handleInput = (e) => {
    const { name, value } = e.target;
    setBooking({ ...booking, [name]: value });
  };

  const ValidationInput = () => {
    if (booking.maxDays == "") {
      setMaxDaysError("Please Enter Max Days Booking");
    } else {
      setMaxDaysError("");
    }

    if (booking.minServingTime == "") {
      setMinServingTimeError("Select Min Serving Time");
    } else {
      setMinServingTimeError("");
    }

    if (booking.maxServingTime == "") {
      setMaxServingTimeError("Select Max Serving Time");
    } else {
      setMaxServingTimeError("");
    }

    if (booking.minPreparationTime == "") {
      setMinPreparationTimeError("Select Min Preparation Time");
    } else {
      setMinPreparationTimeError("");
    }

    if (booking.maxPreparationTime == "") {
      setMaxPreparationTimeError("Select Max Preparation Time");
    } else {
      setMaxPreparationTimeError("");
    }

    if (booking.minBufferTime == "") {
      setMinBufferTimeError("Select Min Buffer Time");
    } else {
      setMinBufferTimeError("");
    }

    if (booking.maxBufferTime == "") {
      setMaxBufferTimeError("Select Max Buffer Time");
    } else {
      setMaxBufferTimeError("");
    }
  };

  const handleSubmit = async () => {
    ValidationInput();

    if (
      booking.maxDays !== "" &&
      booking.maxServingTime !== "" &&
      booking.maxServingTime !== "" &&
      booking.minPreparationTime !== "" &&
      booking.maxPreparationTime !== "" &&
      booking.minBufferTime !== "" &&
      booking.maxBufferTime !== ""
    ) {
      const urlencoded = new URLSearchParams();
      urlencoded.append("BookingMaxDays", booking.maxDays);
      urlencoded.append("ServingMinTime", booking.minServingTime);
      urlencoded.append("ServingMaxTime", booking.maxServingTime);
      urlencoded.append("PreparationMinTime", booking.minPreparationTime);
      urlencoded.append("PreparationMaxTime", booking.maxPreparationTime);
      urlencoded.append("BufferMinTime", booking.minBufferTime);
      urlencoded.append("BufferMaxTime", booking.maxBufferTime);

      await DataService.post(Api.Admin.addEditGeneralSetting, urlencoded)
        .then((response) => {
          if (response.data.status == 201 || response.data.status == 200) {
            setOpen(false);
            Index.toast.success(response.data.message);
            getBookingData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getBookingData = async () => {
    await DataService.get(Api.Admin.getGeneralSetting)
      .then((response) => {
        setBookingData(response.data.data);
        const tempArr = {
          maxDays: response.data.data?.booking_max_days,
          minServingTime: response.data.data?.serving_min_time,
          maxServingTime: response.data.data?.serving_max_time,
          minPreparationTime: response.data?.data.preparation_min_time,
          maxPreparationTime: response.data?.data.preparation_max_time,
          minBufferTime: response.data.data?.buffer_min_time,
          maxBufferTime: response.data.data?.buffer_max_time,
        };
        setBooking(tempArr);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getBookingData();
  }, []);

  useEffect(() => {
    setNumber(
      [...Array(100)]
        .map((_, i) => {
          return (i + 1) % 5 == 0 ? i + 1 : "";
        })
        .filter((a) => a !== "")
    );
  }, []);

  return (
    <>
      <Index.Box className="dashboard-content booking-configuration-main">
        <Index.Box className="booking-border-main">
          <Index.Box className="booking-configuration-titles">
            <Index.Typography
              className="common-para booking-text-main"
              component="p"
              variant="p"
            >
              Basic Information
            </Index.Typography>
            <Index.Typography
              className="common-para booking-text-para"
              component="p"
              variant="p"
            >
              Section to config basic product information
            </Index.Typography>
          </Index.Box>
          <Index.Box className="border-btn-main booking-edit-btn-main">
            <Index.Button className="border-btn booking-edit-btn"  onClick={() => setOpen(true)}>
              <img
                src={Index.Svg.edit}
                className="booking-ediit-icon"
               
              />
              Edit
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-booking-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Max Days Bookings
                    </Index.FormHelperText>
                    <Index.TextField
                      disabled
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      value={bookingData?.booking_max_days}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Serving Minimum Time
                    </Index.FormHelperText>
                    <Index.TextField
                      disabled
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      value={bookingData?.serving_min_time}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Serving Maximum Time
                    </Index.FormHelperText>
                    <Index.TextField
                      disabled
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      value={bookingData?.serving_max_time}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Preparation Minimum Time
                    </Index.FormHelperText>
                    <Index.TextField
                      disabled
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      value={bookingData?.preparation_min_time}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Preparation Maximum Time
                    </Index.FormHelperText>
                    <Index.TextField
                      disabled
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      value={bookingData?.preparation_max_time}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Buffering Minimum Time
                    </Index.FormHelperText>
                    <Index.TextField
                      disabled
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      value={bookingData?.buffer_min_time}
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Buffering Maximum Time
                    </Index.FormHelperText>
                    <Index.TextField
                      disabled
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      value={bookingData?.buffer_max_time}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

      

        <Index.Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal"
        >
          <Index.Box sx={Index.Constant.MODAL_STYLE} className="add-user-modal-inner-main">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >
              Edit Booking Configuration
            </Index.Typography>

            <Index.Box className="input-box modal-input-box">
              <Index.FormHelperText className="form-lable">
                Max Days Bookings
              </Index.FormHelperText>
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className={classes.input}
                  name="maxDays"
                  type="number"
                  value={booking?.maxDays}
                  onChange={(e) => handleInput(e)}
                />
              </Index.Box>

              <Index.FormHelperText>
                <Index.Typography className="error-msg" variant="p">
                  {maxDaysError}
                </Index.Typography>
              </Index.FormHelperText>
            </Index.Box>

            <Index.Box className="input-box modal-input-box">
              <Index.FormHelperText className="form-lable">
                General Serving Time
              </Index.FormHelperText>

              <Index.Box className="multiple-dropdown">
                <Index.FormControl sx={{ m: 1, minWidth: 150 }}>
                  <Index.InputLabel>Min</Index.InputLabel>
                  <Index.Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Min"
                    name="minServingTime"
                    onChange={(e) => handleInput(e)}
                    value={booking?.minServingTime}
                  >
                    {number.map((_) => {
                      return <Index.MenuItem value={_}>{_}</Index.MenuItem>;
                    })}
                    <Index.MenuItem value={"1"}>{"7"}</Index.MenuItem>
                  </Index.Select>
                  <Index.FormHelperText>
                    <Index.Typography className="error-msg" variant="p">
                      {minservingTimeError}
                    </Index.Typography>
                  </Index.FormHelperText>
                </Index.FormControl>

                <Index.FormControl sx={{ m: 1, minWidth: 150 }}>
                  <Index.InputLabel>Max</Index.InputLabel>
                  <Index.Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    label="Max"
                    name="maxServingTime"
                    onChange={(e) => handleInput(e)}
                    value={booking?.maxServingTime}
                  >
                    {number.map((_) => {
                      return <Index.MenuItem value={_}>{_}</Index.MenuItem>;
                    })}
                  </Index.Select>
                  <Index.FormHelperText>
                    <Index.Typography className="error-msg" variant="p">
                      {maxservingTimeError}
                    </Index.Typography>
                  </Index.FormHelperText>
                </Index.FormControl>
              </Index.Box>
            </Index.Box>

            <Index.Box className="input-box modal-input-box">
              <Index.FormHelperText className="form-lable">
                Preparing Time
              </Index.FormHelperText>

              <Index.Box className="multiple-dropdown">
                <Index.FormControl sx={{ m: 1, minWidth: 150 }}>
                  <Index.InputLabel>Min</Index.InputLabel>
                  <Index.Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    label="Min"
                    name="minPreparationTime"
                    onChange={(e) => handleInput(e)}
                    value={booking?.minPreparationTime}
                  >
                    {number.map((_) => {
                      return <Index.MenuItem value={_}>{_}</Index.MenuItem>;
                    })}
                  </Index.Select>
                  <Index.FormHelperText>
                    <Index.Typography className="error-msg" variant="p">
                      {minpreparationTimeError}
                    </Index.Typography>
                  </Index.FormHelperText>
                </Index.FormControl>

                <Index.FormControl sx={{ m: 1, minWidth: 150 }}>
                  <Index.InputLabel>Max</Index.InputLabel>
                  <Index.Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    label="Max"
                    name="maxPreparationTime"
                    onChange={(e) => handleInput(e)}
                    value={booking?.maxPreparationTime}
                  >
                    {number.map((_) => {
                      return <Index.MenuItem value={_}>{_}</Index.MenuItem>;
                    })}
                  </Index.Select>

                  <Index.FormHelperText>
                    <Index.Typography className="error-msg" variant="p">
                      {maxpreparationTimeError}
                    </Index.Typography>
                  </Index.FormHelperText>
                </Index.FormControl>
              </Index.Box>
            </Index.Box>

            <Index.Box className="input-box modal-input-box">
              <Index.FormHelperText className="form-lable">
                Buffering Time
              </Index.FormHelperText>

              <Index.Box className="multiple-dropdown">
                <Index.FormControl sx={{ m: 1, minWidth: 150 }}>
                  <Index.InputLabel>Min</Index.InputLabel>
                  <Index.Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Min"
                    name="minBufferTime"
                    onChange={(e) => handleInput(e)}
                    value={booking?.minBufferTime}
                  >
                    {number.map((_) => {
                      return <Index.MenuItem value={_}>{_}</Index.MenuItem>;
                    })}
                  </Index.Select>

                  <Index.FormHelperText>
                    <Index.Typography className="error-msg" variant="p">
                      {minbuffertimeError}
                    </Index.Typography>
                  </Index.FormHelperText>
                </Index.FormControl>

                <Index.FormControl sx={{ m: 1, minWidth: 150 }}>
                  <Index.InputLabel>Max</Index.InputLabel>
                  <Index.Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    label="Max"
                    name="maxBufferTime"
                    onChange={(e) => handleInput(e)}
                    value={booking?.maxBufferTime}
                  >
                    {number.map((_) => {
                      return <Index.MenuItem value={_}>{_}</Index.MenuItem>;
                    })}
                  </Index.Select>

                  <Index.FormHelperText>
                    <Index.Typography className="error-msg" variant="p">
                      {maxbuffertimeError}
                    </Index.Typography>
                  </Index.FormHelperText>
                </Index.FormControl>
              </Index.Box>
            </Index.Box>

            <Index.Box className="center-button">
              <Index.Button variant="contained" onClick={handleSubmit}>
                Submit
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Modal>

      </Index.Box>
    </>
  );
};

export default BookingConfiguration;
