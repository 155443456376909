export const validateWithNoWhiteSpace = (value, field) => {
  if (!value) {
    return `${field} is required`;
  } else if (/^\s/.test(value)) {
    return `${field} cannot start with spaces`;
  }
};

export const validateWithStringOnly = (value, field, charLength) => {
  if (!value) {
    return `${field} is required`;
  } else if (/^\s/.test(value)) {
    return `${field} cannot start with spaces`;
  } else if (!/^[a-zA-Z\s]+$/.test(value)) {
    return "Special characters and numbers are not allowed";
  } else if (value.length > charLength) {
    return `${field} can not contain more than ${charLength} characters`;
  }
};

export const validateWithLimitedCharacters = (value, field, charLength) => {
  if (!value) {
    return `${field} is required`;
  } else if (/^\s/.test(value)) {
    return `${field} cannot start with spaces`;
  } else if (value.length > charLength) {
    return `${field} can not contain more than ${charLength} characters`;
  }
};
