import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { useForm, Controller } from "react-hook-form";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import { toast } from "react-toastify";

const AddFoodItem = (props) => {
  const [foodTypeData, setFoodTypeData] = useState([]);
  const [cuisinesData, setCuisinesData] = useState([]);
  const [categoryData, setCateGoryData] = useState([]);
  const [qtyData, setQtyData] = useState([]);
  // const [images, setImages] = useState(null);
  const [imageName, setImageName] = useState("");
  const [loading, setLoading] = useState(false);
  // const [imageError , setImageError] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    let arr = [];
    props?.row?.cuisine?.map((item) => {
      return arr.push(item.cuisine_id._id);
    });

    if (props?.row) {
      setValue("foodType", props.row ? props?.row?.food_type?._id : "");
      setValue("cuisines", props.row ? arr : "");
      setValue("category", props.row ? props?.row?.category?._id : "");
      setValue("ingredients", props.row ? props?.row?.ingredients : "");
      //setValue("spice_level" , props.row ? props?.row?.spice_level : "")
      setValue("quantity", props.row ? props?.row?.quantity?._id : "");
      setImageName(props.row ? props?.row?.image : "Select File");
      setValue("image", props.row?.image || "")
      if (props.row.spice_level === true) {
        setValue("spice_level", "true");
      } else {
        setValue("spice_level", "false");
      }
    }
  }, [props.row]);

  const getAllCuisine = async () => {
    await DataService.get(Api.Common.getAllCuisine).then((response) => {
      if (response.data.status === 200) {
        setCuisinesData(response.data.data);
      }
    });
  };

  const getAllFoodType = async () => {
    await DataService.get(Api.Common.getAllFoodType).then((response) => {
      if (response.data.status === 200) {
        setFoodTypeData(response.data.data);
      }
    });
  };

  const getAllCategory = async () => {
    await DataService.get(Api.Common.getAllCategoryList).then((response) => {
      if (response.data.status === 200) {
        setCateGoryData(response.data.data);
      }
    });
  };

  const getAllQtyUnits = async () => {
    await DataService.get(Api.Common.getAllQtyUnits).then((response) => {
      if (response.data.status === 200) {
        console.log(response.data.data, "300");
        setQtyData(response.data.data);
      }
    });
  };

  useState(() => {
    getAllCuisine();
    getAllCategory();
    getAllFoodType();
    getAllQtyUnits();
  }, []);

  const handleImageChange = (e) => {
    setImageName(e.target.files[0].name);
    setValue("image",e.target.files[0],{shouldValidate:true});
  };


  const onSubmit = async (data) => {
    // if( !props?.row && imageName !== 'Select File'){

    setLoading(true);
    const cusine = [];

    data.cuisines.map((item) =>
      cusine.push({
        cuisine_id: item,
      })
    );

    let formdata = new FormData();
    formdata.append("item_name", data.itemName);
    formdata.append("cuisine", JSON.stringify(cusine));
    formdata.append("category", data.category);
    formdata.append("ingredients", data.ingredients);
    formdata.append("food_type", data.foodType);
    formdata.append("spice_level", data.spice_level);
    formdata.append("quantity", data.quantity);
    formdata.append("image", data.image);
    formdata.append("quantity_arr", JSON.stringify([{}]));

    if (props.row) {
      formdata.append("id", props.row._id);
    }

    await DataService.post(Api.Admin.addEditFoodItem, formdata)
      .then((response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          setLoading(false);
          if (props.row) {
            toast.success("Food item updated successfully");
          } else {
            toast.success("Food item addedd successfully");
          }
          props.getAllFoodItem();
          props.handleClose();
          reset();
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });

    // }else{
    //   setImageError('Please select Image')
    // }
  };

  return (
    <Index.Modal
      open={props.open}
      // onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-food-item-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            {props?.row ? "Update" : "Add"} Food Item
          </Index.Typography>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Item Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                defaultValue={
                  props?.row?.item_name ? props?.row?.item_name : ""
                }
                name="itemName"
                {...register("itemName", {
                  required: "Item Name is required",
                })}
                error={Boolean(errors.itemName)}
                helperText={errors.itemName?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Cuisines
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.Box className="dropdown-box">
                <Index.FormControl className="form-control">
                  <Controller
                    name="cuisines"
                    control={control}
                    {...register("cuisines", {
                      required: "Please select cuisines",
                    })}
                    render={({ field: { onChange, value } }) => (
                      <Index.Select
                        sx={{
                          "& .MuiFormHelperText-root": {
                            ml: 0,
                            mt: "5px",
                          },
                        }}
                        multiple
                        value={value ? value : []}
                        labelId="cuisines"
                        error={Boolean(errors.cuisines)}
                        className="dropdown-select"
                        id="cuisines"
                        onChange={onChange}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {cuisinesData?.map((data) => (
                          <Index.MenuItem value={data?._id}>
                            {data?.cuisine}
                          </Index.MenuItem>
                        ))}
                      </Index.Select>
                    )}
                  />
                  {/* <span><img src={Svg.greydown} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                </Index.FormControl>
                <Index.FormHelperText error id="accountId-error">
                  {errors?.cuisines?.message ? errors?.cuisines?.message : ""}
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Food Type
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.Box className="dropdown-box">
                <Index.FormControl className="form-control">
                  <Controller
                    name="foodType"
                    control={control}
                    {...register("foodType", {
                      required: "Please select foodType",
                    })}
                    render={({ field: { onChange, value } }) => (
                      <Index.Select
                        sx={{
                          "& .MuiFormHelperText-root": {
                            ml: 0,
                            mt: "5px",
                          },
                        }}
                        labelId="foodType"
                        error={Boolean(errors.foodType)}
                        className="dropdown-select"
                        id="foodType"
                        value={value}
                        onChange={onChange}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {foodTypeData?.map((data) => (
                          <Index.MenuItem value={data?._id}>
                            {data?.type}
                          </Index.MenuItem>
                        ))}
                      </Index.Select>
                    )}
                  />
                  {/* <span><img src={Svg.greydown} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                </Index.FormControl>
                <Index.FormHelperText error id="accountId-error">
                  {errors.foodType?.message ? errors.foodType?.message : ""}
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Category
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.Box className="dropdown-box">
                <Index.FormControl className="form-control">
                  <Controller
                    name="category"
                    control={control}
                    {...register("category", {
                      required: "Please select category",
                    })}
                    render={({ field: { onChange, value } }) => (
                      <Index.Select
                        sx={{
                          "& .MuiFormHelperText-root": {
                            ml: 0,
                            mt: "5px",
                          },
                        }}
                        labelId="category"
                        error={Boolean(errors.category)}
                        className="dropdown-select"
                        id="category"
                        value={value}
                        onChange={onChange}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {categoryData?.map((data) => (
                          <Index.MenuItem value={data?._id}>
                            {data?.category_name}
                          </Index.MenuItem>
                        ))}
                      </Index.Select>
                    )}
                  />
                  {/* <span><img src={Svg.greydown} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                </Index.FormControl>
                <Index.FormHelperText error id="accountId-error">
                  {errors.category?.message ? errors.category?.message : ""}
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Description
            </Index.FormHelperText>

            <Index.Box className="form-group">
              <Index.Box className="dropdown-box">
                <Index.FormControl className="form-control">
                  <Controller
                    name="ingredients"
                    control={control}
                    {...register("ingredients", {
                      required: "Description is required",
                    })}
                    render={({ field: { onChange, value } }) => (
                      <Index.TextareaAutosize
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      name="ingredients"
                      value={watch("ingredients")}            
                      style={{
                        height: "120px",
                        width: "430px",
                        padding:"7px"
                      }}
                      error={Boolean(errors.ingredients)}
                      helperText={errors.ingredients?.message}
                      onChange={onChange}
                    />
                    )}
                  />
                </Index.FormControl>
                <Index.FormHelperText error id="accountId-error">
                  {errors.ingredients?.message ? errors.ingredients?.message : ""}
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="food-item-input-flex">
            <Index.Box className="input-box add-user-input radio-main">
              <Index.FormHelperText className="form-lable">
                Spice Level
              </Index.FormHelperText>
              <Index.FormControl className="form-control">
                <Controller
                  name="spice_level"
                  control={control}
                  {...register("spice_level", {
                    required: "Spice level type is required",
                  })}
                  render={({ field: { onChange, value } }) => (
                    <Index.RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      className="radiogroup"
                      labelId="priceLimit"
                      value={value}
                      onChange={onChange}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <Index.FormControlLabel
                        value="true"
                        control={<Index.Radio className="radio" />}
                        label="Yes"
                      />
                      <Index.FormControlLabel
                        value="false"
                        control={<Index.Radio className="radio" />}
                        label="No"
                      />
                    </Index.RadioGroup>
                  )}
                />
              </Index.FormControl>

              <Index.FormHelperText error id="accountId-error">
                {errors?.spice_level?.message
                  ? errors?.spice_level?.message
                  : ""}
              </Index.FormHelperText>
            </Index.Box>

            <Index.Box className="input-box modal-input-box qty-food-item">
              <Index.FormHelperText className="form-lable">
                Quantity
              </Index.FormHelperText>
              <Index.Box className="form-group">
                <Index.Box className="dropdown-box">
                  <Index.FormControl className="form-control">
                    <Controller
                      name="quantity"
                      control={control}
                      {...register("quantity", {
                        required: "Please select quantity",
                      })}
                      render={({ field: { onChange, value } }) => (
                        <Index.Select
                          sx={{
                            "& .MuiFormHelperText-root": {
                              ml: 0,
                              mt: "5px",
                            },
                          }}
                          labelId="quantity"
                          error={Boolean(errors.quantity)}
                          className="dropdown-select"
                          id="quantity"
                          value={value}
                          onChange={onChange}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {qtyData?.map((data) => (
                            <Index.MenuItem value={data?._id}>
                              {data?.name}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      )}
                    />
                    {/* <span><img src={Svg.greydown} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                  </Index.FormControl>
                  <Index.FormHelperText error id="accountId-error">
                    {errors.category?.message ? errors.category?.message : ""}
                  </Index.FormHelperText>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Select Image
            </Index.FormHelperText>
            <Index.Button
              variant="outlined"
              fullWidth
              // startIcon={<Index.AddIcon/>}
              component="label"
              className="file-upload-btn"
              // onChange={(e) => handleImageChange(e)}
            >
              {imageName ? imageName : "Select File"}
              <input
                hidden
                accept="files/*"
                id="contained-button-file"
                // multiple
                type="file"
                ref={register("image",{
                  required:"Food image is required" 
                })}
                onChange={(e) => {
                  handleImageChange(e);
                }}
              />
            </Index.Button>

            <Index.FormHelperText error>
              {errors.image?.message}
            </Index.FormHelperText>

            {/* <Index.FormHelperText error id="accountId-error">
                {imageName === 'Select File' ? imageError : ""}
              </Index.FormHelperText> */}
          </Index.Box>

          <Index.SubmitDiscardButton
            handleClose={props.handleClose}
            loading={loading}
          />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddFoodItem;
