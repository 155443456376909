import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { TablePagination } from "@mui/material";

const QrOrderList = () => {
  const [orderList, setOrderList] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [branchId, setBranchId] = useState("");
  const [branches, setBranches] = useState([]);
  const [date, setDate] = useState(null);

  const getAllQrOrderList = async () => {
    setLoading(true);
    await DataService.get(Api.Admin.QrlistOrder)
      .then((response) => {
        if (response.data.status === 200) {
          setOrderList(response.data.data);
          setSearchedData(response.data.data);
          setFilteredData(response.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  const getAllBranches = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("type", "branch");
    DataService.post(Api.Admin.getResturantDetail, urlencoded)
      .then((response) => {
        if (response.data.status === 200) {
          setBranches(response.data.data);
        }
      })
      .catch((e) => {});
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getAllQrOrderList();
    getAllBranches();
  }, []);

  // Get date and branch wise filter

  useEffect(() => {
    let filterdata = [];
    if (date && branchId) {
      filterdata = orderList.filter(
        (item) =>
          item.branch_id._id === branchId &&
          Index.moment(item?.createdAt).format("YYYY-MM-DD") === date
      );
    } else if (branchId) {
      filterdata = orderList.filter((item) => item.branch_id._id === branchId);
    } else if (date) {
      filterdata = orderList.filter(
        (item) => Index.moment(item?.createdAt).format("YYYY-MM-DD") === date
      );
    } else {
      setSearchedData(orderList);
      setFilteredData(orderList);
    }

    setSearchedData(filterdata);
    setFilteredData(filterdata);
  }, [date, branchId]);

  const handleClear = () => {
    setBranchId("");
    setDate(null);
    getAllQrOrderList();
  };

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="flex-all admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        QR Orders List
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="user-search-main">
                      <Index.Box className="user-search-box">
                        <Index.Box className="form-group table-head-date-main">
                          <Index.Box className="branch-name">
                            <Index.Typography>Branch :</Index.Typography>
                            <Index.Select
                              value={branchId}
                              displayEmpty
                              onChange={(e) => setBranchId(e.target.value)}
                              className="form-control"
                            >
                              <Index.MenuItem value="" disabled>
                                Select branch
                              </Index.MenuItem>
                              {branches &&
                                branches?.map((row) => {
                                  return (
                                    <Index.MenuItem value={row?._id}>
                                      {row?.branch_name}
                                    </Index.MenuItem>
                                  );
                                })}
                            </Index.Select>
                          </Index.Box>
                          <Index.Box className="from-to-date-table">
                            <Index.Box className="from-date-label fromto-date-label-same">
                              <Index.Typography>Date :</Index.Typography>
                              <Index.TextField
                                type="date"
                                value={date}
                                onChange={(e) => {
                                  setDate(
                                    Index.moment(e.target.value).format(
                                      "YYYY-MM-DD"
                                    )
                                  );
                                }}
                              />
                            </Index.Box>

                            <Index.Button onClick={handleClear}>
                              Clear
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="page-table-main food-item-table">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table aria-label="simple table" className="table">
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Date
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Branch
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Table No
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              No of Meal
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {searchedData.length > 0
                            ? (rowsPerPage > 0
                                ? searchedData.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                  )
                                : searchedData
                              ).map((row, index) => {
                                return (
                                  <>
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.moment(row?.createdAt).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.branch_id?.branch_name
                                          ? row?.branch_id?.branch_name
                                          : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.table_number
                                          ? row?.table_number
                                          : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.food_items
                                          ? row?.food_items.length
                                          : "-"}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </>
                                );
                              })
                            : "No Data Found"}

                          <TablePagination
                            rowsPerPageOptions={[
                              5,
                              10,
                              15,
                              { label: "All", value: 1 },
                            ]}
                            colSpan={8}
                            count={searchedData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: {
                                "aria-label": "rows per page",
                              },
                              native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            // ActionsComponent={TablePaginationActions}
                          />
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default QrOrderList;
