import React from 'react'
import Index from '../Index'

const DeleteModal = (props) => {
  return (
    <Index.Modal
    open={props.deleteOpen}
    onClose={props.handleDeleteClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="modal-delete"
  >
    <Index.Box sx={Index.Constant.MODAL_STYLE} className="delete-modal-inner-main">
      <Index.Box className="modal-circle-main">
        <img src={Index.Svg.closecircle} className="user-circle-img" />
      </Index.Box>
      <Index.Typography
        className="delete-modal-title"
        component="h2"
        variant="h2"
      >
        Are you sure?
      </Index.Typography>
      <Index.Typography
        className="delete-modal-para common-para"
        component="p"
        variant="p"
      >
        Do you really want to delete these records? This process cannot be
        undone.
      </Index.Typography>
      <Index.Box className="delete-modal-btn-flex">
        <Index.Button
          className="modal-cancel-btn modal-btn"
          onClick={props.handleDeleteClose}
        >
          Cancel
        </Index.Button>
        <Index.Button
          className="modal-delete-btn modal-btn"
          onClick={props.hanldeDeleteReocrd}
        >
          Delete
        </Index.Button>
      </Index.Box>
    </Index.Box>
  </Index.Modal>
  )
}

export default DeleteModal