import React,{useState, useEffect} from "react";
import Index from "../../../../../component/Index";
import { Controller, useForm } from "react-hook-form";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const PrivacyPolicy = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const [editorData, setEditorData] = useState("");
  const [editorError, setEditorError] = useState("");
  const [policyData, setPolicyData] = useState([]);

 

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);

    if (data == "") {
      setEditorError("Description is required");
    } else if (data != "") {
      setEditorError("");
    }
  };


  const onSubmit = async (data) => {
    if (editorData === "") {
      setEditorError("Description is required");
    } else {
      const urlencoded = new URLSearchParams();
      urlencoded.append("title", data.title);
      urlencoded.append("description", editorData);
      policyData._id && urlencoded.append("id", policyData._id);

      await DataService.post(Api.Admin.addEditPrivacyPolicy, urlencoded)
        .then((res) => {
          if (res.data.status === 201 || res.data.status === 200) {
            Index.toast.success("Updated successfully");
            getPrivacyPolicy();
            reset();
          }
        })
        .catch((e) => {
          Index.toast.error(e.response.data.message);
        });
    }
  };


  const getPrivacyPolicy = async () => {
    await DataService.get(Api.Common.getAllPrivacyPolicy).then((res) => {
      if (res.data.data.length > 0) {
        setPolicyData(res.data.data[0]);
        setValue("title", res.data.data[0].title);
        setEditorData(res.data.data[0].description);
      }
    });
  };
  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Index.Box className="add-user-modal-inner-main about-us-main">
        <Index.Typography
          id="modal-modal-title"
          className="modal-title"
          variant="h6"
          component="h2"
        >
          Privacy Policy
        </Index.Typography>
        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Title
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="title"
              {...register("title", {
                required: "Title is required",
              })}
              error={Boolean(errors.title)}
              helperText={errors.title?.message}
            />
          </Index.Box>
        </Index.Box>

        {/* <Index.Box className="input-box modal-input-box">
              <Index.FormHelperText className="form-lable">
                Details
              </Index.FormHelperText>
              <CKEditor
                editor={ClassicEditor}
                data={editorData}
                onChange={handleEditorChange}
                
              />
            </Index.Box> */}

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Description
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.CKEditor
              editor={Index.ClassicEditor}
              data={editorData}
              onChange={handleEditorChange}
              //    name="description"
              //   {...register("description", {
              //     required: "Description is required",
              //   })}
            />

            <Index.FormHelperText error>{editorError}</Index.FormHelperText>
          </Index.Box>

          {/* {errors.description && <span>This field is required</span>} */}
        </Index.Box>

        <Index.Box className="modal-user-btn-flex">
          <Index.Box className="save-btn-main border-btn-main">
            <Index.Button className="save-user-btn border-btn" type="submit">
              <img src={Index.Svg.save} className="user-save-icon"></img>Submit
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </form>
  );
};

export default PrivacyPolicy;
