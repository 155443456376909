import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import AddSpiceLevel from "./AddSpiceLevel";
import DeleteModal from "../../../../../component/common/DeleteModal";

const SpiceLevel = () => {
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [spiceLevelData, setSpiceLevelData] = useState([]);
  const [spiceLevelRow, setSpiceLevelRow] = useState({});
  const [loading, setLoading] = useState(false);

  const getAllSpiceLevel = async () => {
    setLoading(true);
    await DataService.get(Api.Common.getAllSpiceLevel)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          setSpiceLevelData(response.data.data);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hanldeDeleteReocrd = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", spiceLevelRow?._id);
    await DataService.post(Api.Admin.deleteSpiceLevel, urlencoded)
      .then((res) => {
        if (res.data.status === 200) {
          handleDeleteClose();
          setSpiceLevelRow("");
          getAllSpiceLevel();
          Index.toast.success("Spice level deleted successfully");
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
      });
  };

  useEffect(() => {
    getAllSpiceLevel();
  }, []);
  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="flex-all admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Spice Level
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="view-btn-main border-btn-main">
                      <Index.Button
                        className="view-order-btn border-btn"
                        onClick={() => {
                          handleOpen();
                          setSpiceLevelRow("");
                        }}
                      >
                        Add
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="page-table-main food-item-table">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 500 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              NO
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Spice Level
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Image
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {spiceLevelData.length > 0 ? (
                            <>
                              {spiceLevelData.map((row, index) => (
                                <Index.TableRow
                                  key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    {index + 1}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row.name}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    <img
                                      src={Index.Constant.IMAGE_URL + row.image}
                                      height="80px"
                                      width="120px"
                                      alt="spice-level-img"
                                    />
                                  </Index.TableCell>

                                  <Index.TableCell>
                                    <Index.Button
                                      className="table-btn"
                                      onClick={() => {
                                        handleOpen();
                                        setSpiceLevelRow(row);
                                      }}
                                    >
                                      <img
                                        src={Index.Svg.blueedit}
                                        alt="edit-icon"
                                      ></img>
                                    </Index.Button>
                                    <Index.Button
                                      className="table-btn"
                                      onClick={() => {
                                        handleDeleteOpen();
                                        setSpiceLevelRow(row);
                                      }}
                                    >
                                      <img
                                        src={Index.Svg.trash}
                                        alt="delete-icon"
                                      ></img>
                                    </Index.Button>
                                  </Index.TableCell>
                                </Index.TableRow>
                              ))}
                            </>
                          ) : (
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                No Data Found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>

                    {open && (
                      <AddSpiceLevel
                        open={open}
                        handleClose={handleClose}
                        getAllSpiceLevel={getAllSpiceLevel}
                        row={spiceLevelRow}
                        style={Index.Constant.MODAL_STYLE}
                      />
                    )}

                    {deleteOpen && (
                      <DeleteModal
                        deleteOpen={deleteOpen}
                        handleDeleteClose={handleDeleteClose}
                        hanldeDeleteReocrd={hanldeDeleteReocrd}
                      />
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default SpiceLevel;
