import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import CurrentOrders from "./orders/CurrentOrders";
import PastOrders from "./orders/PastOrders";

const ViewSingleUserDetails = () => {
  const [value, setValue] = useState("1");
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const userId = Index.useParams().id;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSingleUserDetails = async () => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("user_id", userId);

    await DataService.post(Api.Admin.getSingleUserDetails, urlencoded)
      .then((res) => {
        setUserDetails(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSingleUserDetails();
  }, []);

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box>
          <Index.Box sx={{ flexGrow: 1 }}>
            {/* <Index.Box>
                <Index.Typography variant="h5">User Details</Index.Typography>
             </Index.Box> */}
            <Index.Grid container spacing={2} className="user-detail-grid-main">
              <Index.Grid xs={4}>
                <Index.Box className="user-detail-data">
                  <Index.Typography component="h6">Name :</Index.Typography>
                  <Index.Typography component="p">
                    {userDetails?.userDetails?.name || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>
              <Index.Grid xs={4}>
                <Index.Box className="user-detail-data">
                  <Index.Typography component="h6">Phone :</Index.Typography>
                  <Index.Typography component="p">
                    {userDetails?.userDetails?.mobile_number || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>
              <Index.Grid xs={4}>
                <Index.Box className="user-detail-data">
                  <Index.Typography component="h6">Email :</Index.Typography>
                  <Index.Typography component="p">
                    {userDetails?.userDetails?.email || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>
              <Index.Grid xs={4}>
                <Index.Box className="user-detail-data">
                  <Index.Typography component="h6">
                    Total Orders :
                  </Index.Typography>
                  <Index.Typography component="p">
                  {Index.displayNumberValue(userDetails?.orderDetails?.ordersCount)}
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>
              <Index.Grid xs={4}>
                <Index.Box className="user-detail-data">
                  <Index.Typography component="h6">Ratings :</Index.Typography>
                  <Index.Typography component="p">-</Index.Typography>
                </Index.Box>
              </Index.Grid>
              <Index.Grid xs={4}>
                <Index.Box className="user-detail-data">
                  <Index.Typography component="h6">
                    Wallet Amount :
                  </Index.Typography>
                  <Index.Typography component="p">
                    {userDetails?.walletAmount == 0
                      ? "0"
                      : userDetails?.walletAmount || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>
              <Index.Grid xs={4}>
                <Index.Box className="user-detail-data">
                  <Index.Typography component="h6">
                    Date of Sign Up :
                  </Index.Typography>
                  <Index.Typography component="p">
                    {Index.moment(userDetails.userDetails?.createdAt).format(
                      "L"
                    )}
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>
              <Index.Grid xs={4}>
                <Index.Box className="user-detail-data">
                  <Index.Typography component="h6">
                    Average orders :
                  </Index.Typography>
                  <Index.Typography component="p">
                    {Index.displayNumberValue(
                      userDetails?.orderDetails?.avaerageOrdersPerMonth
                    )}
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>

              <Index.Grid xs={4}>
                <Index.Box className="user-detail-data">
                  <Index.Typography component="h6">
                    Total Orders :
                  </Index.Typography>
                  <Index.Typography component="p">
                    {Index.displayNumberValue(userDetails?.orderDetails?.ordersCount)}
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>

              <Index.Grid xs={4}>
                <Index.Box className="user-detail-data long-text-user">
                  <Index.Typography component="h6">
                    User active from the date :
                  </Index.Typography>
                  <Index.Typography component="p">23-09-2034</Index.Typography>
                </Index.Box>
              </Index.Grid>
              <Index.Grid xs={4}>
                <Index.Box className="user-detail-data">
                  <Index.Typography component="h6">
                    Last order placed :
                  </Index.Typography>
                  <Index.Typography component="p">
                    {Index.moment(
                      userDetails?.orderDetails?.lastOrder?.createdAt
                    ).format("DD-MM-YYYY") || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>

              <Index.Grid xs={4}>
                <Index.Box className="user-detail-data long-text-user">
                  <Index.Typography component="h6">
                    Average no of people booking :
                  </Index.Typography>
                  <Index.Typography component="p">
                   {Index.displayNumberValue(
                      userDetails?.orderDetails?.averageNoOfpeopleBooking
                    )}
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>
              {/* <Index.Grid xs={4}>
                <Index.Box className="user-detail-data long-text-user">
                  <Index.Typography component="h6">
                    Top 3 cuisines/categories of users :
                  </Index.Typography>
                  <Index.Typography component="p">-</Index.Typography>
                </Index.Box>
              </Index.Grid>

              <Index.Grid xs={6}>
                <Index.Box className="user-detail-data long-text-user">
                  <Index.Typography component="h6">
                    Number of tickets generated by the users :
                  </Index.Typography>
                  <Index.Typography component="p">-</Index.Typography>
                </Index.Box>
              </Index.Grid> */}

              <Index.Grid xs={6}>
                <Index.Box className="user-detail-data long-text-user">
                  <Index.Typography component="h6">
                    Total amount of orders the user placed :
                  </Index.Typography>
                  <Index.Typography component="p">
                    {userDetails?.orderDetails?.totalAmountOfOrdersUserPlaced ||
                      "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
          <Index.Box></Index.Box>
          <Index.Box className="main-card-section">
            <Index.Box className="user-container-mobile">
              <Index.Box className="main-tab mini-tabbar">
                <Index.Box sx={{ width: "100%" }} className="tab-content">
                  <TabContext value={value}>
                    <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        className="tab-ul"
                      >
                        <Tab
                          label="Current orders"
                          value="1"
                          className="tab-li"
                        />
                        <Tab label="Past orders" value="2" className="tab-li" />
                        <Tab label="Reports" value="3" className="tab-li" />
                      </TabList>
                    </Index.Box>

                    <TabPanel value="1">
                      <CurrentOrders
                        currentOrders={userDetails?.currentOrders}
                      />
                    </TabPanel>

                    <TabPanel value="2">
                      <PastOrders pastOrders={userDetails?.pastOrders} />
                    </TabPanel>

                    {/* <TabPanel value="3">Reports</TabPanel> */}
                  </TabContext>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default ViewSingleUserDetails;
