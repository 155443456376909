import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const User = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const [editorData, setEditorData] = useState("");
  const [editorError, setEditorError] = useState("");
  const [userData, setUserData] = useState([]);
  const [imageName, setImageName] = useState("");
  const [images, setImages] = useState(null);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);

    if (data == "") {
      setEditorError("Details is required");
    } else if (data != "") {
      setEditorError("");
    }
  };
  const handleImageChange = (e) => {
    console.log(e,"images");
    setImageName(e.target.files[0].name);
    setImages(e.target.files[0]);
  };

  const getUserData = async () => {
    await DataService.get(Api.Common.getCmsUserData).then((res) => {
      console.log(res.data.data ,"uves56");
      setValue("title", res.data.data.title);
      setValue("playstore", res.data.data.play_store_link);
      setValue("appstore", res.data.data.apple_store_link);
      setUserData(res.data.data);
      setEditorData(res.data.data.description);
      setImageName(res.data.data.image ? res.data.data.image : "Select File");
    });
  };
  const onSubmit = async (data) => {
    const formdata = new FormData();
    formdata.append("title", data.title);
    formdata.append("description", editorData);
    formdata.append("play_store_link", data.playstore);
    formdata.append("apple_store_link", data.appstore);
    formdata.append("image", images ? images : imageName);

    userData._id && formdata.append("id", userData._id);

    await DataService.post(Api.Admin.addEditCmsUser, formdata)
      .then((res) => {
        if (res.data.status === 201 || res.data.status === 200) {
          Index.toast.success("Updated successfully");
          getUserData();
        }
      })
      .catch((e) => {
        Index.toast.error(e.response.data.message);
      });
  };


  useEffect(() => {
    getUserData();
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Index.Box className="add-user-modal-inner-main about-us-main">
        <Index.Typography
          id="modal-modal-title"
          className="modal-title"
          variant="h6"
          component="h2"
        >
          User App
        </Index.Typography>
        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Title
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="title"
              {...register("title", {
                required: "Title is required",
              })}
              error={Boolean(errors.title)}
              helperText={errors.title?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Details
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.CKEditor
              editor={Index.ClassicEditor}
              data={editorData}
              onChange={handleEditorChange}
            />

            <Index.FormHelperText error>
              {editorError && editorError}
            </Index.FormHelperText>
          </Index.Box>
        </Index.Box>
        
        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Play Store Link
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="playstore"
              {...register("playstore", {
                required: "Playstore link is required",
                pattern: {
                  value:
                    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                  message: "Please enter a valid link",
                },
              })}
              error={Boolean(errors.playstore)}
              helperText={errors.playstore?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Apple Store Link
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="appstore"
              {...register("appstore", {
                required: "Appstore link is required",
                pattern: {
                  value:
                    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                  message: "Please enter a valid link",
                },
              })}
              error={Boolean(errors.appstore)}
              helperText={errors.appstore?.message}
            />
          </Index.Box>
        </Index.Box>
        
        <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Select Image
            </Index.FormHelperText>

            <Index.Button
              variant="outlined"
              fullWidth
              component="label"
              className="file-upload-btn"
            >
              {imageName != "" ? imageName : "Select File"}
              <input
                hidden
                accept="files/*"
                id="contained-button-file"
                // multiple
                type="file"
                onChange={(e) => {
                  handleImageChange(e);
                }}
              />
            </Index.Button>
          </Index.Box>

        <Index.Box className="modal-user-btn-flex">
          <Index.Box className="save-btn-main border-btn-main">
            <Index.Button className="save-user-btn border-btn" type="submit">
              <img src={Index.Svg.save} className="user-save-icon"></img>Submit
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </form>
  );
};

export default User;
