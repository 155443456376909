import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import png from "../../../../assets/png";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { CircularProgress } from "@mui/material";

export const CheckOut = () => {
  const [productDetail, setProductDetail] = useState(
    JSON.parse(localStorage.getItem("addFoodItem"))
  );
  console.log(productDetail, "productDetail");
  // const [tax, setTax] = useState(0);
  // const [qty, setQty] = useState(productDetail?.qty);
  const [tableNo, setTableNo] = useState("");
  const [tableNoError, setTableNoError] = useState("");
  const [productTotalPrice, setProductTotalPrice] = useState("");
  const [cookingInstruction, setCookingInstruction] = useState("");
  const [persons, setPersons] = useState("");
  const [personError, setPersonError] = useState("");
  const [loading, setLoading] = useState(false);

  const [productTotalPriceAfterGst, setProductTotalPriceAfterGst] =
    useState("");
  const [gstTotal, setGstTotal] = useState({
    sgst: 0,
    cgst: 0,
  });
  const navigate = Index.useNavigate();

  //getting gst details from Restaurent page while navigating from checkout
  const location = Index.useLocation();
  const gstDetails = location?.state;

  const removeQty = (qty, index) => {
    let newArr = [...productDetail];

    if (newArr.length === 1 && newArr[index].qty === 1) {
      localStorage.removeItem("addFoodItem");
      navigate(`/restaurent/${gstDetails?.branch_id}`);
    } else {
      if (newArr[index].qty === 1) {
        newArr.splice(index, 1);
      } else {
        let topingsTotal = 0;

        for (let i = 0; i < newArr[index].selection_item_id.length; i++) {
          for (
            let j = 0;
            j < newArr[index].selection_item_id[i].selection.length;
            j++
          ) {
            topingsTotal +=
              newArr[index].selection_item_id[i].selection[j].price;
          }
        }

        newArr[index].qty -= 1;
        newArr[index].totalPrice =
          (newArr[index].price + topingsTotal) * newArr[index].qty;
      }
      setProductDetail(newArr);
      localStorage.setItem("addFoodItem", JSON.stringify(newArr));
    }

    //   setProductDetail((prevValue) => {
    //       let newArr = [...prevValue];
    //       newArr[index]["qty"] === 1 ? newArr[index]["qty"] = 1:newArr[index]["qty"] -=1 ;
    //       newArr[index]["totalPrice"] = newArr[index]["price"] * newArr[index]["qty"] ;
    //       return newArr;
    //  });
  };

  const addQty = (qty, index) => {
    setProductDetail((prevValue) => {
      let newArr = [...prevValue];

      let topingsTotal = 0;

      for (let i = 0; i < newArr[index].selection_item_id.length; i++) {
        for (
          let j = 0;
          j < newArr[index].selection_item_id[i].selection.length;
          j++
        ) {
          topingsTotal += newArr[index].selection_item_id[i].selection[j].price;
        }
      }

      newArr[index]["qty"] += 1;
      newArr[index]["totalPrice"] =
        (newArr[index]["price"] + topingsTotal) * newArr[index]["qty"];
      localStorage.setItem("addFoodItem", JSON.stringify(newArr));
      return newArr;
    });
  };

  const hanldeTableNo = (e) => {
    setTableNo(e.target.value.replace(/[^0-9]/g, ""));

    if (e.target.value === "") {
      setTableNoError("Please enter table number");
    } else if (e.target.value.replace(/[^0-9]/g, "") !== "") {
      setTableNoError("");
    }
  };

  const hanldePersons = (e) => {
    const regex = /^[0-9\b]+$/;
    setPersons(e.target.value);

    if (e.target.value === "") {
      setPersonError("Please enter no of dinners");
    } else if (!regex.test(e.target.value)) {
      return setPersonError("Please enter only number");
    } else if (e.target.value !== "") {
      setPersonError("");
    }
  };

  //navigating to dashboard if product details length is zero
  // useEffect(() => {
  //   if (productDetail.length < 0) {
  //     localStorage.removeItem("addFoodItem");
  //     navigate(`/restaurent/${gstDetails?.branch_id}`);
  //   }
  // }, [productDetail]);

  useEffect(() => {
    //calculating all productdetails price total
    let grandTotal = 0;

    for (let i = 0; i < productDetail.length; i++) {
      grandTotal += productDetail[i]["totalPrice"];
    }
    setProductTotalPrice(grandTotal);
  }, [productDetail]);

  useEffect(() => {
    //calculating product total price after gst

    let sgst_total = gstDetails?.sgst
      ? (productTotalPrice * gstDetails?.sgst) / 100
      : 0;
    let cgst_total = gstDetails?.cgst
      ? (productTotalPrice * gstDetails?.cgst) / 100
      : 0;

    const productTotalPriceAfterGSt = gstDetails?.is_gst
      ? productTotalPrice + sgst_total + cgst_total
      : productTotalPrice;

    setProductTotalPriceAfterGst(productTotalPriceAfterGSt);

    //setting gst amount
    setGstTotal({
      sgst: sgst_total,
      cgst: cgst_total,
    });
  }, [productTotalPrice]);

  const handleCheckOut = async () => {
    const regex = /^[0-9\b]+$/;

    if (tableNo === "") {
      return setTableNoError("Please enter table number");
    }
    // } else if (!regex.test(tableNo)) {
    //   return setTableNoError("Please enter only number");
    // }
    // else if(persons === "")
    // {
    //   return setPersonError("Please enter persons")
    // }
    // else if (!regex.test(tableNo)) {
    //   return setPersonError("Please enter only number");
    // }

    if (!loading) {
      setLoading(true);

      const meals = productDetail?.map((row, index) => {
        return {
          selected_item_id: row?.selected_food_id,
          food_size: row?.food_size,
          price: row?.price,
          quantity: row?.qty,
          food_size_value: row.food_size_value,
          instruction: row.foodCookingInstruction,
          selection_item_id: row?.selection_item_id.map((selection) => {
            return {
              selection_item_id: selection.selection_item_id,
              selection_on: selection.selection_on,
              selection: selection.selection,
            };
          }),

          // selection_item_id: row?.selection_item_id.map((selection)=>{
          //   return {
          //     selection_item_id:selection.selection_item_id,
          //     selection:selection.selection.map((e)=>{
          //       return {
          //         ...e,
          //         price:e.price*row.qty
          //       }
          //     })
          //   }
          // })
        };
      });

      const urlencoded = new URLSearchParams();
      urlencoded.append("branch_id", gstDetails?.branch_id);
      urlencoded.append("order_type", "WalkIn");
      // urlencoded.append("persons", persons);
      urlencoded.append("table_number", tableNo);
      urlencoded.append("instruction", cookingInstruction);
      urlencoded.append(
        "total_amount",
        parseFloat(productTotalPriceAfterGst).toFixed(2)
      );
      urlencoded.append("food_items", JSON.stringify(meals));

      if (gstDetails?.is_gst) {
        urlencoded.append(
          "sgst_amount",
          gstTotal.sgst ? parseFloat(gstTotal.sgst).toFixed(2) : 0
        );
        urlencoded.append(
          "cgst_amount",
          gstTotal.cgst ? parseFloat(gstTotal.cgst).toFixed(2) : 0
        );
      }
      // urlencoded.append("date", Index.moment().format("YYYY-MM-DD"));
      // urlencoded.append("time",moment().format('HH:00'));
      // urlencoded.append(
      //   "time",
      //   Index.moment(productDetail[0]?.walkinTime, "hh:mm A").format("HH:mm")
      // );

      await DataService.post(Api.Restaurant.placeOderByQrCode, urlencoded)
        .then((res) => {
          if (res.data.status === 200 || res.data.status === 201) {
            Index.toast.success("Order placed successfully");
            // setLoading(false);
            setTimeout(() => {
              localStorage.removeItem("addFoodItem");
              navigate(`/restaurent/${productDetail[0]?.branchId}`);
            }, 500);
          }
        })
        .catch((e) => {
          Index.toast.error(e.response.data.message);
          setLoading(false);
        });
    }
  };

  const handleInstruction = (e) => {
    setCookingInstruction(e.target.value);
  };

  const addMore = () => {
    navigate(`/restaurent/${productDetail[0]?.branchId}`);
  };

  console.log(productDetail[0]?.branchName,"branchName")
  return (
    <div>
      <Index.Box className="main-checkout">
        <Index.Box className="">
          <Index.Box className="head">
            <Index.Box className="user-container">
              <Index.Box className="checkout-head">
                <Index.Button
                  className="goback-btn"
                  onClick={() => navigate(-1)}
                >
                  <Index.ArrowBackIosNewIcon />
                  <Index.Typography>Go Back</Index.Typography>
                </Index.Button>
                <Index.Typography component="h1" variant="h1">
                  {productDetail[0]?.branchName}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="main-checkout-card">
            <Index.Box className="user-container">
              <Index.Box className="main-details main-details-2">
                <Index.Box className="main-left-detail">
                  <Index.Box className="order-detail">
                    <Index.Typography
                      className="ur-order"
                      variant="p"
                      component="p"
                    >
                      Your Order
                    </Index.Typography>
                    {productDetail.length &&
                      productDetail?.map((item, index) => {
                        console.log(item, "item");
                        return (
                          <Index.Box className="order-card">
                            {/* <Index.Box className="veg-icon">
                              <img src={png.veg} className="veg-icon-img" />
                            </Index.Box> */}
                            <Index.Box className="check-card-details">
                              <Index.Box className="head-check-card">
                                <Index.Box className="item-detail-check">
                                  <Index.Box className='pro-name-price'>
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="name-check"
                                  >
                                    {item?.productName}
                                  </Index.Typography>
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="final-price-check"
                                  >
                                    &#8377; {item?.totalPrice}
                                  </Index.Typography>
                                  </Index.Box>
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="amount-check"
                                  >
                                    &#8377;{item?.price} (
                                    {item?.kilogram === 0 ? "" : item?.kilogram}
                                    {` ${item?.food_size}`})
                                  </Index.Typography>
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="item-check"
                                  ></Index.Typography>
                                  <Index.Box>
                                    {item?.selection_item_id.map((toppings) => {
                                      if (toppings.selection.length === 0) {
                                        return null;
                                      }
                                      return (
                                        <Index.Box
                                          key={toppings.selection_item_id}
                                          className="Your-order-maindiv"
                                        >
                                          <Index.Typography className="order-detail-des-head">
                                            {toppings?.selection_on + ":" + " "}
                                          </Index.Typography>
                                          <Index.Box className="Your-order-innerdiv">
                                            {toppings.selection.map(
                                              (selection, selectionIndex) => (
                                                <Index.Typography>
                                                  {`${selection?.name}${toppings?.selection?.length -
                                                    1 ===
                                                  selectionIndex
                                                    ? " "
                                                    : ","} `}
                                                  {/* {selection.name} */}
                                                   
                                                </Index.Typography>
                                              )
                                            )}
                                          </Index.Box>
                                        </Index.Box>
                                      );
                                    })}
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="card-bottom-check">
                                <Index.Box className="slice-more slice-more-check">
                                  <Index.Link href="#">{/* Edit */}</Index.Link>
                                  <Index.Box className="inc-dec-btn inc-dec-btn-check">
                                    <Index.Button
                                      className="dec-btn dec-btn-check"
                                      onClick={() =>
                                        removeQty(item?.qty, index)
                                      }
                                    >
                                      <Index.RemoveIcon />
                                    </Index.Button>
                                    <Index.Typography>
                                      {item?.qty}
                                    </Index.Typography>
                                    <Index.Button
                                      className="inc-btn"
                                      onClick={() => addQty(item?.qty, index)}
                                    >
                                      <Index.AddIcon />
                                    </Index.Button>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        );
                      })}
                  </Index.Box>
                  <Index.Box
                    className="more-item-btn"
                    onClick={() => addMore()}
                  >
                    <Index.Button>Add More items </Index.Button>
                    <Index.KeyboardArrowRightIcon />
                  </Index.Box>
                  <Index.Box className="cook-ins">
                    <Index.Box className="jain-cooking cook-ins-sub">
                      {/* <Index.Typography className="" variant="p" component="p">
                        Add Table Number
                      </Index.Typography> */}
                      <Index.TextareaAutosize
                        value={tableNo}
                        aria-label="minimum height"
                        placeholder="Add Table Number"
                        onChange={(e) => {
                          hanldeTableNo(e);
                        }}
                      />

                      <Index.FormHelperText error>
                        {" "}
                        {tableNoError && tableNoError}{" "}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>

                  {/* <Index.Box className="cook-ins">
                    <Index.Box className="jain-cooking cook-ins-sub">
                      <Index.Typography className="" variant="p" component="p">
                        No of Dinners
                      </Index.Typography>
                      <Index.TextareaAutosize
                        aria-label="minimum height"
                        placeholder="No of Dinners"
                        onChange={(e) => hanldePersons(e)}
                      />
                       <Index.FormHelperText error>
                        {" "}
                        {personError && personError}{" "}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box> */}

                  <Index.Box className="cook-ins">
                    <Index.Box className="jain-cooking cook-ins-sub">
                      {/* <Index.Typography className="" variant="p" component="p">
                        Add Cooking Instruction
                      </Index.Typography> */}
                      <Index.TextareaAutosize
                        aria-label="minimum height"
                        placeholder="Add Cooking Instruction"
                        onChange={(e) => handleInstruction(e)}
                      />
                      <Index.Box
                        className="jain-btn loader-set"
                        onClick={() => handleCheckOut()}
                      >
                        <Index.Button disabled={loading}>
                          {loading ? <CircularProgress /> : "Check out"}
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="main-right-detail">
                  <Index.Box className="amount-pay-check">
                    <Index.Box className="amount-pay-head">
                      <Index.Typography variant="h6" component="h6">
                        Bill Summary
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="amount-pay-detail">
                      <Index.Box className="final-amount">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="check-text-total"
                        >
                          Item total
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="check-total"
                        >
                          ₹{parseFloat(productTotalPrice).toFixed(2)}
                        </Index.Typography>
                      </Index.Box>

                      {gstDetails?.is_gst && (
                        <>
                          <Index.Box className="final-amount gov-text">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="check-text-total"
                            >
                              SGST({gstDetails?.sgst})%
                            </Index.Typography>
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="check-total"
                            >
                              ₹{parseFloat(gstTotal?.sgst).toFixed(2)}
                            </Index.Typography>
                          </Index.Box>

                          <Index.Box className="final-amount gov-text">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="check-text-total"
                            >
                              CGST({gstDetails?.cgst})%
                            </Index.Typography>
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="check-total"
                            >
                              ₹{parseFloat(gstTotal?.cgst).toFixed(2)}
                            </Index.Typography>
                          </Index.Box>
                        </>
                      )}
                    </Index.Box>
                    <Index.Box className="amount-pay-total">
                      <Index.Typography component="p">
                        Grand total
                      </Index.Typography>
                      <Index.Typography component="p">
                        ₹{parseFloat(productTotalPriceAfterGst).toFixed(2)}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    className="jain-btn loader-set jain-cooking mobile-checkout"
                    onClick={() => handleCheckOut()}
                  >
                    <Index.Button disabled={loading}>
                      {loading ? <CircularProgress /> : "Check out"}
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};
