import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Index from "../../../../component/Index";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";

import { handleMouseDown } from "../../../../component/common/CommonFunctions";

const AddSubAdmin = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const onSubmit = async (data) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("name", data.name);
    urlencoded.append("email", data.email);
    urlencoded.append("password", data.password);
    props.row && urlencoded.append("id", props.row?._id);
    await DataService.post(Api.Admin.createSubAdmin, urlencoded).then(
      (response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          if (props.row) {
            Index.toast.success("Sub Admin updated successfully");
          } else {
            Index.toast.success("Sub admin created successfully");
          }
          props.getAllSubAdmin();
          props.handleClose();
          reset();
          setLoading(false);
        }
      }
    ).catch((e)=>{
      Index.toast.error(e.response.data.message ? e.response.data.message : e.message );
      setLoading(false)
    });
  };
  useEffect(() => {
    if (props.row) {
      setValue("name", props.row.name);
      setValue("email", props.row.email);
    }
  }, []);
  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            {props?.row ? "Edit" : "Add"} Sub Admin
          </Index.Typography>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="name"
                {...register("name", {
                  required: "Name is required",
                })}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Email
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="email"
                {...register("email", {
                  required: "Email  is required",
                })}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Password
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.OutlinedInput
                className="form-control-eye"
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="password"
                {...register("password", {
                  required: "Password is required",
                })}
                error={Boolean(errors.password)}
                helperText={errors.password?.message}
                endAdornment={
                  <Index.InputAdornment position="end">
                    <Index.IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDown}
                      edge="end"
                    >
                      {showPassword ? (
                        <Index.Visibility />
                      ) : (
                        <Index.VisibilityOff />
                      )}
                    </Index.IconButton>
                  </Index.InputAdornment>
                }
              />
              <Index.FormHelperText error id="accountId-error">
                {errors.password?.message ? errors.password?.message : ""}
              </Index.FormHelperText>
            </Index.Box>
          </Index.Box>
          <Index.SubmitDiscardButton
            loading={loading}
            handleClose={props.handleClose}
          />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddSubAdmin;
