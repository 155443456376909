import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import { useForm } from "react-hook-form";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const AddQtyUnits = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const [addItem, setAddItem] = useState([]);
  const [loading, setLoading] = useState(false);

  const addRow = () => {
    setAddItem((prevValue) => [
      ...prevValue,
      {
        id: Math.random(),
        quantity: "",
      },
    ]);
  };

  const deleteRow = (row) => {
    const rows = [...addItem];
    setAddItem(rows.filter((data) => data.id !== row.id));
  };

  const handleChangeRow = (e, i) => {
    const { name, value } = e.target;

    setAddItem((prevValue) => {
      let newArr = [...prevValue];
      newArr[i][name] = value;
      return newArr;
    });
  };

  const onSubmit = async (data) => {
    // const items= [];

    // addItem.map((row,index)=>{
    //   items.push(row["item"])
    // })

    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("name", data.name);
    urlencoded.append("type", JSON.stringify(addItem));
    urlencoded.append("checked", data.checked);

    if (props?.row) {
      urlencoded.append("id", props.row._id);
    }

    await DataService.post(Api.Admin.addEditQty, urlencoded)
      .then((response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          setLoading(false);
          if (props.row) {
            Index.toast.success("Quantity updated successfully");
          } else {
            Index.toast.success("Quantity addedd successfully");
          }
          props.getAllQtyUnits();
          props.handleClose();
        }
      })
      .catch((e) => {
        Index.toast.error(e.response.data.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (props?.row) {
      setValue("name", props.row ? props.row.name : "");
      setValue("checked", props.row ? props.row.checked : "");

      setAddItem(
        props?.row?.type.map((data, index) => {
          return {
            id: Math.random(),
            quantity: data.quantity,
          };
        })
      );
    } else {
      setAddItem([
        {
          id: Math.random(),
          quantity: "",
        },
      ]);
    }
  }, [props]);

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className={`${addItem.length > 4 ? 'add-food-item-modal-inner-main' : 'add-user-modal-inner-main' }`}
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            {props?.row ? "Edit" : "Add"} Qty Units
          </Index.Typography>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="name"
                {...register("name", {
                  required: "name is required",
                })}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Item
            </Index.FormHelperText>
            {addItem?.map((row, index) => {
              return (
                <Index.Box className="form-group set-qty-units">
                  <Index.TextField
                    id="fullWidth"
                    className="form-control"
                    name="quantity"
                    value={row.quantity}
                    onChange={(e) => handleChangeRow(e, index)}
                  />

                  <Index.Box className="dropdown-box-set-input">
                    {index === 0 ? (
                      <Index.AddCircleOutlineIcon
                        className="add-qty"
                        onClick={() => {
                          addRow();
                        }}
                      />
                    ) : (
                      <Index.RemoveCircleOutlineIcon
                        className="minus-qty"
                        onClick={() => {
                          deleteRow(row);
                        }}
                      />
                    )}
                  </Index.Box>
                </Index.Box>
              );
            })}
          </Index.Box>

          <Index.Box className="input-box add-user-input radio-main">
            <Index.FormControlLabel
              {...register("checked", {})}
              name="checked"
              error={Boolean(errors.checked)}
              helperText={errors.checked?.message}
              control={<Index.Checkbox />}
              label="Shall allow to enter quantity?"
              checked={watch("checked") ? true : false}
            />
          </Index.Box>

          <Index.SubmitDiscardButton
            handleClose={props.handleClose}
            loading={loading}
          />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddQtyUnits;
