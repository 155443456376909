import React, { useEffect } from "react";
import Index from "../../../../../component/Index";
import { useForm } from "react-hook-form";
import { Api } from "../../../../../config/Api";
import DataService from "../../../../../config/DataService";
const AddCmsOffer = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("title", data.title);
    urlencoded.append("description", data.description);
    urlencoded.append("offer", data.offer);
    props.row && urlencoded.append("id", props.row?._id);

    await DataService.post(Api.Admin.addEditAdminOffer, urlencoded)
      .then((res) => {
        if (res.data.status === 201 || res.data.status === 200) {
          if (props.row) {
            Index.toast.success("Offer updated successfully");
          } else {
            Index.toast.success("Offer created successfully");
          }
          props.getAllOffers();
          props.handleClose();
          reset();
        }
      })
      .catch((e) => {
        Index.toast.error(e.response.data.message);
      });
  };

  useEffect(()=>{

    if(props.row)
    {
      setValue("title", props.row?.title)
      setValue("description", props.row?.description)
      setValue("offer", props.row?.offer)
    }
  },[])

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box sx={Index.Constant.MODAL_STYLE} className="add-user-modal-inner-main">
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Offers
          </Index.Typography>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Titile
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="title"
                {...register("title", {
                  required: "Title is required",
                })}
                error={Boolean(errors.title)}
                helperText={errors.title?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Short Description
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="description"
                {...register("description", {
                  required: "Description is required",
                })}
                error={Boolean(errors.description)}
                helperText={errors.description?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Offer
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="offer"
                {...register("offer", {
                  required: "Offer is required",
                  pattern:{
                    value:/^[0-9]+$/,
                    message:"Offer should be number only"
                  }
                })}
                error={Boolean(errors.offer)}
                helperText={errors.offer?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="modal-user-btn-flex">
          <Index.Box className="discard-btn-main border-btn-main">
              <Index.Button
                className="discard-user-btn border-btn"
                onClick={props.handleClose}
              >
                Discard
              </Index.Button>
            </Index.Box>
            <Index.Box className="save-btn-main border-btn-main">
              <Index.Button className="save-user-btn border-btn" type="submit">
                <img src={Index.Svg.save} className="user-save-icon"></img>
                Submit
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddCmsOffer;
