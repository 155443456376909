import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import { useForm, Controller } from "react-hook-form";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import { toast } from "react-toastify";

const AddSelection = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [addItem, setAddItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const addRow = () => {
    setAddItem((prevValue) => [
      ...prevValue,
      {
        id: Math.random(),
        // dbId:"",
        name: "",
        food_type: "",
      },
    ]);
  };

  const deleteRow = (row) => {
    const rows = [...addItem];
    let delete_row = rows.filter((data) => {
      return data.id !== row.id;
    });
    setAddItem(delete_row);
  };

  const handleChangeRow = (e, i) => {
    const { name, value } = e.target;
    setAddItem((prevValue) => {
      let newArr = [...prevValue];
      newArr[i][name] = value;
      return newArr;
    });
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("selection_on", data.name);
    urlencoded.append("item", JSON.stringify(addItem));
    urlencoded.append(
      "selection_type",
      data.active === "multiple" ? true : false
    );
    urlencoded.append("checked", data.checked ? "true" : "false");

    if (props.row) {
      urlencoded.append("id", props.row._id);
    }

    await DataService.post(Api.Admin.addEditSelection, urlencoded)
      .then((response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          setLoading(false);
          if (props.row) {
            toast.success("Selection updated successfully");
          } else {
            toast.success("Selection addedd successfully");
          }
          props.getAllSelection();
          props.handleClose();
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    setValue("name", props.row ? props.row.selection_on : "");

    if (props.row) {
      if (props.row.selection_type === true) {
        setValue("active", "multiple");
      } else {
        setValue("active", "single");
      }
    }

    setValue("checked", props.row ? props.row.checked : "");

    if (props?.row?.item) {
      setAddItem(
        props?.row?.item.map((data) => {
          return {
            id: Math.random(),
            // dbId:data.food_type._id,
            name: data.name,
            food_type: data.food_type._id,
          };
        })
      );
    } else {
      setAddItem([
        {
          id: Math.random(),
          // dbId:"",
          name: "",
          food_type: "",
        },
      ]);
    }
  }, [props]);

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box sx={props.style} className="add-food-item-modal-inner-main">
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Add/Edit Selection
          </Index.Typography>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Selection on
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="name"
                {...register("name", {
                  required: "Selection  is required",
                })}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Item
            </Index.FormHelperText>
            {addItem?.map((row, index) => {
              return (
                <Index.Box className="form-group ">
                  <Index.Box className="dropdown-box selection-item-grid set-add-btn ">
                    <Index.Box className="form-group  form-group-box-set">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        placeholder="Enter Name"
                        className="form-control"
                        name="name"
                        value={row.name}
                        onChange={(e) => handleChangeRow(e, index)}
                        //   {...register("name", {
                        //     required: "Name  is required",
                        //   })}
                        //   error={Boolean(errors.name)}
                        //   helperText={errors.name?.message}
                      />
                    </Index.Box>
                    <Index.FormControl className="form-control dropdown-box-set-input">
                      <Controller
                        name="food_type"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Index.Select
                            sx={{
                              "& .MuiFormHelperText-root": {
                                ml: 0,
                                mt: "5px",
                              },
                            }}
                            className="dropdown-select"
                            value={row?.food_type}
                            name="food_type"
                            onChange={(e) => handleChangeRow(e, index)}
                          >
                            {props?.foodTypeData?.map((data, i, array) => {
                              return (
                                <Index.MenuItem value={data._id}>
                                  {data.type}
                                </Index.MenuItem>
                              );
                            })}
                          </Index.Select>
                        )}
                      />
                    </Index.FormControl>
                    <Index.Box className="dropdown-box-set-input">
                      {index === 0 ? (
                        <Index.AddCircleOutlineIcon
                          className="add-qty"
                          onClick={() => {
                            addRow();
                          }}
                        />
                      ) : (
                        <Index.RemoveCircleOutlineIcon
                          className="minus-qty"
                          onClick={() => {
                            deleteRow(row);
                          }}
                        />
                      )}
                    </Index.Box>
                    <Index.FormHelperText error id="accountId-error">
                      {errors.foodType?.message ? errors.foodType?.message : ""}
                    </Index.FormHelperText>
                  </Index.Box>
                </Index.Box>
              );
            })}
          </Index.Box>

          <Index.Box className="input-box add-user-input radio-main">
            <Index.FormHelperText className="form-lable">
              Selection Type
            </Index.FormHelperText>
            <Index.FormControl className="form-control">
              <Controller
                name="active"
                control={control}
                {...register("active", {
                  required: "Selection type is required",
                })}
                render={({ field: { onChange, value } }) => (
                  <Index.RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    className="radiogroup"
                    labelId="priceLimit"
                    value={value}
                    onChange={onChange}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <Index.FormControlLabel
                      value="single"
                      control={<Index.Radio className="radio" />}
                      label="single"
                    />
                    <Index.FormControlLabel
                      value="multiple"
                      control={<Index.Radio className="radio" />}
                      label="Multiple"
                    />
                  </Index.RadioGroup>
                )}
              />
            </Index.FormControl>

            <Index.FormHelperText error id="accountId-error">
              {errors?.active?.message ? errors?.active?.message : ""}
            </Index.FormHelperText>
          </Index.Box>

          <Index.Box className="input-box add-user-input radio-main">
            <Index.FormControlLabel
              {...register("checked", {})}
              name="checked"
              error={Boolean(errors.checked)}
              helperText={errors.checked?.message}
              control={<Index.Checkbox />}
              label="Can have free selection"
              checked={watch("checked") ? true : false}
              // onChange={onChange}
            />
          </Index.Box>

          <Index.SubmitDiscardButton
            handleClose={props.handleClose}
            loading={loading}
          />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddSelection;
