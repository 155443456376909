import React, { useState, useEffect } from "react";
import Index from "../../../../../../component/Index";
import { Api } from "../../../../../../config/Api";
import DataService from "../../../../../../config/DataService";

const Menu = (props) => {
  const [menuData, setMenuData] = useState([]);

  const getAllMenu = async () => {
    await DataService.get(
      `${Api.Admin.foodItemsByBranchId}/${props.branchId}`
    ).then((res) => {
      if (res.data.data) {
        setMenuData(res.data.data);
      }
    });
  };

  useEffect(() => {
    getAllMenu();
  }, []);
  return (
    <div>
      <Index.Box className="menu-crd-row">
        <Index.Box className="menu-card-main">
          {menuData?.length > 0
            ? menuData?.map((row, index) => {
                return (
                  <Index.Box className="menu-card-content-box">
                    <Index.Box className="menu-card-img">
                      <Index.Box className="veg-icon-box red-veg">
                        <Index.Box className="veg-dots"></Index.Box>
                      </Index.Box>
                      <img
                        src={
                          Index.Constant.IMAGE_URL +
                          row?.selected_item_id?.image
                        }
                        className="menu-img"
                      />
                    </Index.Box>
                    <Index.Box className="menu-card-content">
                      <Index.Typography className="menu-card-title">
                        {row?.selected_item_id?.item_name}
                      </Index.Typography>
                      <Index.Typography className="menu-card-para">
                        {row?.selected_item_id?.ingredients.length > 150
                          ? row?.selected_item_id?.ingredients.slice(0, 150) +
                            "..."
                          : row?.selected_item_id?.ingredients}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                );
              })
            : "No Data Found"}
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default Menu;
