import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import png from "../../../../assets/png";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Png from "../../../../assets/png";
import ClearIcon from '@mui/icons-material/Clear';

export const Restaurent = () => {
  const [cartHasData, setCartHasData] = React.useState(false);
  const [value, setValue] = React.useState("All");
  const [branchData, setBranchData] = useState([]);
  const [foodData, setFoodData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue,setSearchValue] = useState("")

  // const [brachTime, setBranchTime] = useState([]);
  // const [timeSlot, setTimeSlot] = useState([]);
  // const [walkinTime, setWalkinTime] = useState("");
  const params = Index.useParams();
  const [addToCartCount, setAddToCartCount] = useState([]);

  useEffect(() => {
    const cartItem = JSON.parse(localStorage.getItem("addFoodItem"));

    if (cartItem) {
      if (cartItem.some((item) => item.branchId === params.branchId)) {
        setAddToCartCount(cartItem);
      } else {
        localStorage.removeItem("addFoodItem");
      }
    }
  }, [params.branchId]);

  const handleChange = async (event, newValue) => {
    // let time = await getWalkInTime();
    // setWalkinTime(time);
    setValue(newValue);
    console.log("Food : ", foodData);
    newValue === "All"
      ? setFilteredData(foodData)
      : newValue === "Popular"
      ? setFilteredData(foodData?.filter((e) => e?.is_famouse === true))
      : setFilteredData(
          foodData?.filter(
            (e) => e?.selected_item_id?.category?.category_name === newValue
          )
        );
  };

  const navigate = Index.useNavigate();

  const getAllRestaurantData = async () => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("branchId", params.branchId);

    await DataService.post(Api.User.branchDetailsByQrCode, urlencoded)
      .then((res) => {
        if (res.data.status === 200) {
          setBranchData(res.data?.data?.BranchDetails);
          setFoodData(res.data?.data?.SelectedFoodItem);
          setFilteredData(res.data?.data?.SelectedFoodItem);
          setLoading(false);
          // setBranchTime(res.data.data.timingData);
          // getTimeSlots(res.data.data.timingData);
        }
      })
      .catch(() => setLoading(false));
  };

  // const getTimeSlots = (btime) => {
  //   /* Time slots function */
  //   btime[0].timings?.map(val => {
  //     var days = val?.days;
  //     var morning_start_time = val?.morning_start_time;
  //     var morning_end_time = val?.morning_end_time;
  //     var evening_start_time = val?.evening_start_time;
  //     var evening_end_time = val?.evening_end_time;
  //     var serving_time = val?.serving_time.slice(0, 2);
  //     days.map((dayitem) => {
  //       var morningStartTime = moment(morning_start_time, "hh:mm A");
  //       var morningEndTime = moment(morning_end_time, "hh:mm A");
  //       var totalTimeSlot = [];
  //       while (morningStartTime <= morningEndTime) {
  //         totalTimeSlot.push(new moment(morningStartTime).format("hh:mm A"));
  //         morningStartTime.add(serving_time, "minutes");
  //       }

  //       var eveningStartTime = moment(evening_start_time, "hh:mm A");
  //       var eveningEndTime = moment(evening_end_time, "hh:mm A");
  //       while (eveningStartTime <= eveningEndTime) {
  //         totalTimeSlot.push(new moment(eveningStartTime).format("hh:mm A"));
  //         eveningStartTime.add(serving_time, "minutes");
  //       }
  //       var temp = { day: dayitem, finalTimeSlot: totalTimeSlot };
  //       timeSlot.push(temp);
  //     });
  //   });
  // };

  // const getWalkInTime = async () => {
  //   /* Find time for walkin order */
  //   let temp = [];
  //   //console.log("Time is : ", timeSlot);
  //   timeSlot?.map((item) => {
  //     if (item?.day === moment().format("dddd").toLowerCase()) {
  //       item?.finalTimeSlot?.filter((val) => {
  //         if (
  //           moment(val, "HH:mm A").format("HH:mm") <= moment().format("HH:mm")
  //         ) {
  //           temp.push(val);
  //         }
  //       });
  //     }
  //   });
  //   return temp[temp.length - 1];
  // };

  useEffect(() => {
    getAllCategory();
  }, [foodData]);

  useEffect(() => {
    getAllRestaurantData();
    // localStorage.clear()
    const cartData = localStorage.getItem("addFoodItem");

    if (cartData) {
      setCartHasData(true);
    } else {
      setCartHasData(false);
    }
  }, [params.branchId]);

  const handleNavigate = async (item) => {
    // let time = await getWalkInTime();
    navigate(`/product-detail/${item._id}`, {
      state: {
        item: item,
        branchName: branchData?.branch_name,
        branchId: params?.branchId,
        // walkinTime: walkinTime ? walkinTime : time,
      },
    });
  };

  const getAllCategory = () => {
    if (foodData?.length) {
      DataService.get(Api.Common.getAllCategory).then((res) => {
        let categories = res.data.data.filter((item) => {
          return foodData?.some(
            (item2) => item2?.selected_item_id?.category?._id === item?._id
          );
        });
        categories.sort(function (a, b) {
          return a?.category_name.localeCompare(b?.category_name);
        });
        setCategoryData(categories);
      });
    }
  };

  console.log(categoryData,"categoryData")
  const handleFilterData = (value) => {
    setValue("All");
    if (value === "") {
      setFilteredData(foodData);
    } else {
      const result = foodData?.filter((item) => {
        return (
          item?.alias?.toLowerCase().includes(value.toLowerCase()) ||
          item?.selected_item_id?.category?.category_name
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      });
      setFilteredData(result);
    }
  };

  console.log(branchData?.branch_name ,"branchName")
  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box>
          <Index.Box className="main-user-section">
            <Index.Box className="user-container">
              <Index.Box className="user-content res-user-content">
                <Index.Box className="veg-text">
                  <img
                    src={
                      Index.Constant.IMAGE_URL+ branchData?.food_type_id?.image
                    }
                    className="veg-icon"
                    alt="veg-icon"
                  />
                  {/* {branchData?.restaurant_id?.restaurant_name} */}
                </Index.Box>
                <Index.Box className="location-text">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="user-head"
                  >
                    {branchData?.branch_name}
                  </Index.Typography>
                  <Index.Box className="location-area">
                    <Index.Box className="location-km">
                      {/* <img src={png.location} className="location" />5 km */}
                    </Index.Box>
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="user-para"
                    >
                      {branchData?.location}
                      {/* ,{" "} */}
                      {/* {branchData?.landmark} */}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="food-item-search-sec">
                  <Index.Box className="user-search-main">
                    <Index.Box className="user-search-box">
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder="Search by item name or category"
                          value={searchValue}
                          onChange={(e) => {setSearchValue(e.target.value) ;handleFilterData(e.target.value)}}
                        />

                        <img
                          src={Index.Svg.search}
                          className="search-grey-img"
                          alt="search grey img"
                        ></img>
                        <Index.HighlightOffIcon onClick={() => {setSearchValue("") ;handleFilterData("")}}/>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="restaurant-cart-button-container">
            <Index.Box className="restaurant-cart-button">
              <Index.Button
                disabled={!cartHasData}
                onClick={() =>
                  navigate("/checkout", {
                    state: {
                      is_gst: branchData?.is_gst,
                      sgst: branchData?.sgst,
                      cgst: branchData?.cgst,
                      branch_id: branchData?._id,
                    },
                  })
                }
              >
                <img
                  src={Index.Png.shopingcart}
                  className="shoping-cart"
                  alt="shopping-cart"
                />
                <span className="cart-amount">
                  {addToCartCount?.length ? addToCartCount?.length : 0}
                </span>
              </Index.Button>
            </Index.Box>
          </Index.Box>

          <Index.Box className="main-card-section qr-order-tab-top">
            <Index.Box className="user-container-mobile">
              <Index.Box className="main-tab">
                <Index.Box sx={{ width: "100%" }} className="tab-content">
                  <TabContext value={value}>
                    <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        className="tab-ul res-tab-ul"
                      >
                        <Tab label={"All"} value={"All"} className="tab-li" />
                        <Tab
                          label={"Popular"}
                          value={"Popular"}
                          className="tab-li"
                        />
                        {categoryData?.map((data, index) => {
                          console.log(data,"dtaa")
                          return (
                            <Tab
                              label={data?.category_name}
                              value={data?.category_name}
                              className="tab-li"
                            />
                          );
                        })}
                      </TabList>
                    </Index.Box>
                    <TabPanel value={value} className="tab-text-content res-tab-con">
                      <Index.Typography variant="p" component='p' className="pro-txt-tab">(Food images are only for promotional purpose)</Index.Typography>
                      <Index.Box className="main-box">
                        <Index.Box className="all-cards">
                          {filteredData.length > 0
                            ? filteredData?.map((item, index) => {
                                return (
                                  <Index.Box
                                    className="same-card card-one"
                                    onClick={() => handleNavigate(item)}
                                  >
                                    <Index.Box className="card-top">
                                      <img
                                        src={
                                          Index.Constant.IMAGE_URL +
                                          item?.selected_item_id?.image
                                        }
                                        className="pizza"
                                        alt="cart-img"
                                      />
                                      {/* <img src={Png.vegicon} className="card-icon-veg"/> */}
                                    </Index.Box>
                                    <Index.Box className="card-bottom">
                                      <Index.Typography
                                        className="pizza-name"
                                        variant="p"
                                        component="p"
                                      >
                                        {item?.alias}
                                      </Index.Typography>

                                      <Index.Typography
                                        className="price"
                                        variant="p"
                                        component="p"
                                      >
                                        &#8377; {item?.quantity?.sort((a,b)=>a?.price-b?.price)[0]?.price}
                                      </Index.Typography>

                                      <Index.Typography
                                        className="pizza-details"
                                        variant="p"
                                        component="p"
                                      >
                                        {item?.selected_item_id?.ingredients}
                                      </Index.Typography>
                                      {/* <Index.Box className="rating-like">
                                <Index.Box className="rating">
                                  <Index.Stack
                                    spacing={1}
                                    className="stack-rat"
                                  >
                                    <Index.Rating
                                      name="half-rating"
                                      defaultValue={4.5}
                                      precision={0.5}
                                    />
                                    <Index.Box className="rating-users">
                                      142
                                    </Index.Box>
                                  </Index.Stack>
                                </Index.Box>
                                <Index.Box className="likes">
                                  <Index.Box className="rating-users">
                                    Like
                                  </Index.Box>
                                  <Index.Checkbox
                                    defaultChecked
                                    icon={<Index.FavoriteBorder />}
                                    checkedIcon={<Index.Favorite />}
                                  />
                                </Index.Box>
                              </Index.Box> */}
                                    </Index.Box>
                                  </Index.Box>
                                );
                              })
                            : "No food item found"}
                        </Index.Box>
                      </Index.Box>
                    </TabPanel>
                  </TabContext>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};
