import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
const BranchList = (props) => {
  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setFilterData(props.branchList);
  }, [props.branchList]);

  //pagination section

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //#end pagination

  //Seasrch section

  const handleFilterData = (e) => {
    if (e.target.value === "") {
      setFilterData(props.branchList);
    } else {
      const result = props.branchList.filter((item) => {
        return (
          item?.branch_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          item?.branch_email
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          item?.branch_mobile_number
            ?.toString()
            .toLowerCase()
            .includes(e.target.value?.toLowerCase())
        );
      });

      setFilterData(result);
      setPage(0);
    }
  };


  const handleChangeSelectAllBranches = (e) => {
    const { checked } = e.target;

    if (checked) {
      props.setBranches(() => {
        return filterData.map((row) => row._id);
      });
    } else {
      props.setBranches([]);
    }
  };

  //#end serach section
  return (
    <Index.Box className="admin-dashboard-list-row">
      <Index.Box sx={{ width: 1 }} className="grid-main">
        <Index.Box
          display="grid"
          className="display-row"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column"
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="admin-dash-box">
                <Index.Box className="flex-all admin-dashboard-table-row">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h3"
                      variant="h3"
                    >
                      Branch List
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="view-btn-main border-btn-main">
                    <Index.Box className="user-search-main">
                      <Index.Box className="user-search-box">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder="Search branch"
                            onChange={(e) => handleFilterData(e)}
                          />

                          <img
                            src={Index.Svg.search}
                            className="search-grey-img"
                            alt="search grey img"
                          ></img>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="page-table-main food-item-table">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table
                      sx={{ minWidth: 500 }}
                      aria-label="simple table"
                      className="table"
                    >
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            <Index.FormControlLabel
                              control={<Index.Checkbox />}
                              onChange={(e) => {
                                handleChangeSelectAllBranches(e);
                              }}
                              checked={
                                // props.branches.length === filterData.length &&
                                filterData.every((row) => {
                                  return props.branches.some(
                                    (row2) => row2 === row._id
                                  );
                                })
                              } //default check while edit and filtering data
                            />
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Branch Name
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Email
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Mobile number
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {filterData.length > 0 ? (
                          filterData &&
                          paginate(filterData, rowsPerPage, page + 1).map(
                            (row, index) => (
                              <Index.TableRow
                                key={row._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Index.FormControlLabel
                                    value={row._id}
                                    control={<Index.Checkbox />}
                                    onChange={(e) => {
                                      props.handleChangeBranches(e);
                                    }}
                                    checked={props.branches.some(
                                      (e) => e == row._id
                                    )}
                                  />
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row?.branch_name || "-"}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row?.branch_email || "-"}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row?.branch_mobile_number || "-"}
                                </Index.TableCell>
                              </Index.TableRow>
                            )
                          )
                        ) : (
                          <Index.TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              No Branch Found
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filterData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Index.Box>
    </Index.Box>
  );
};

export default BranchList;
