import React, { useState } from "react";
import Index from "../../../../component/Index";
import { useForm, Controller } from "react-hook-form";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import EmojiPicker from "emoji-picker-react";

const AddMassNotifications = (props) => {
  const [loading, setLoading] = useState(false);
  const [showTitleEmoji, setShowTitleEmoji] = useState(false);
  const [showDescriptionEmoji, setShowDescriptionEmoji] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm();

  const handleEmojiToggle = () => {
    setShowTitleEmoji(!showTitleEmoji);
  };

  const handleDescEmojiToggle = () => {
    setShowDescriptionEmoji(!showDescriptionEmoji);
  };
  const onSubmit = async (data) => {
    setLoading(true);

    let urlencoded = new URLSearchParams();
    urlencoded.append("user_type", data.user);
    urlencoded.append("notification_type", data.type);
    if (watch("type") === "SMS") {
      urlencoded.append("templateId", data.title);
    } else {
      urlencoded.append("title", data.title);
    }
    urlencoded.append("text", data.description);

    await DataService.post(Api.Admin.sendMastNotification, urlencoded)
      .then((res) => {
        setLoading(false);
        Index.toast.success(res?.data?.message);
        props.getAllMassNotifications();
        props.handleClose();
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });

    // }else{
    //   setImageError('Please select Image')
    // }
  };

  const handleTitleEmojiClick = (emoji) => {
    const input = document.getElementById('title'); 
    const cursorPosition = input.selectionStart; 
    const currentValue = watch('title') || ''; 

    const newValue =
      currentValue.substring(0, cursorPosition) +
      emoji +
      currentValue.substring(cursorPosition); 

    setValue('title', newValue); 
  };

  const handleDescEmojiClick = (emoji) => {
    const input = document.getElementById('description'); 
    const cursorPosition = input.selectionStart; 
    const currentValue = watch('description') || ''; 

    const newValue =
      currentValue.substring(0, cursorPosition) +
      emoji +
      currentValue.substring(cursorPosition); 

    setValue('description', newValue); 
  };
  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-food-item-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Add Mass Notification
          </Index.Typography>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Select User
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.Box className="dropdown-box">
                <Index.FormControl className="form-control">
                  <Controller
                    name="user"
                    control={control}
                    {...register("user", {
                      required: "Please select user",
                    })}
                    render={({ field: { onChange, value } }) => (
                      <Index.Select
                        sx={{
                          "& .MuiFormHelperText-root": {
                            ml: 0,
                            mt: "5px",
                          },
                        }}
                        value={value}
                        error={Boolean(errors.user)}
                        className="dropdown-select"
                        onChange={onChange}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <Index.MenuItem value={"User"}>User</Index.MenuItem>

                        <Index.MenuItem value={"Branch"}>
                          Restaurant
                        </Index.MenuItem>
                      </Index.Select>
                    )}
                  />
                </Index.FormControl>
                <Index.FormHelperText error id="accountId-error">
                  {errors?.user?.message ? errors?.user?.message : ""}
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Select Type
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Controller
                name="type"
                control={control}
                {...register("type", {
                  required: "Please select notification type",
                })}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Index.FormControlLabel
                      control={
                        <Index.Checkbox
                          value="SMS"
                          onChange={(e) => onChange(e.target.value)}
                          checked={watch("type") === "SMS"}
                        />
                      }
                      label="SMS"
                    />
                    <Index.FormControlLabel
                      control={
                        <Index.Checkbox
                          value="EMAIL"
                          onChange={(e) => onChange(e.target.value)}
                          checked={watch("type") === "EMAIL"}
                        />
                      }
                      label="Email"
                    />
                    <Index.FormControlLabel
                      control={
                        <Index.Checkbox
                          value="PUSH"
                          onChange={(e) => onChange(e.target.value)}
                          checked={watch("type") === "PUSH"}
                        />
                      }
                      label="Push Notification"
                    />
                  </>
                )}
              />
            </Index.Box>
            <Index.FormHelperText error id="accountId-error">
              {errors?.type?.message ? errors?.type?.message : ""}
            </Index.FormHelperText>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              {watch("type") === "SMS" ? "Template Id" : "Title"}
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.OutlinedInput
                fullWidth
                id="title"
                className="form-control"
                type={watch("type") === "SMS" ? "number" : "text"}
                name="title"
                {...register("title", {
                  validate: (value) => {
                    if (watch("type") === "SMS") {
                      if (!value) {
                        return "Template id is required";
                      } else if (value.length < 19) {
                        return "Template id must be 19 digits";
                      } else {
                        return true;
                      }
                    }
                    if (watch("type") !== "SMS" && !value) {
                      return "Title is required";
                    } else {
                      return true;
                    }
                  },
                })}
                error={Boolean(errors.title)}
                endAdornment={
                  watch("type") === "SMS" ? null : (
                    <Index.InputAdornment position="end">
                      <Index.IconButton
                        aria-label="toggle password visibility"
                        onClick={handleEmojiToggle}
                        edge="end"
                      >
                        <Index.EmojiEmotionsIcon />
                      </Index.IconButton>
                    </Index.InputAdornment>
                  )
                }
              />
              <Index.FormHelperText error>
                {errors.title?.message ? errors.title?.message : ""}
              </Index.FormHelperText>
              {showTitleEmoji && (
                <EmojiPicker
                  native
                  groupVisibility={{
                    recently_used: false,
                  }}
                  onEmojiClick={(e) => {
                    handleTitleEmojiClick(e.emoji)
                  }}
                />
              )}
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Description
            </Index.FormHelperText>

            <Index.IconButton
              aria-label="toggle password visibility"
              onClick={handleDescEmojiToggle}
              edge="end"
            >
              <Index.EmojiEmotionsIcon />
            </Index.IconButton>

            <Index.Box className="form-group">
              <Index.Box className="dropdown-box">
                <Index.FormControl className="form-control">
                  <Controller
                    name="description"
                    control={control}
                    {...register("description", {
                      required: "Description is required",
                    })}
                    render={({ field: { onChange, value } }) => (
                      <Index.TextareaAutosize
                        value={watch("description")}
                        fullWidth
                        id="description"
                        className="form-control"
                        name="ingredients"
                        style={{
                          height: "120px",
                          width: "430px",
                          padding: "7px",
                        }}
                        error={Boolean(errors.description)}
                        helperText={errors.description?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                  {console.log(watch("description"),"description")}
                </Index.FormControl>
                {showDescriptionEmoji && (
                  <EmojiPicker
                    native
                    groupVisibility={{
                      recently_used: false,
                    }}
                    onEmojiClick={(e) => {
                      handleDescEmojiClick(e.emoji)
                    }}
                  />
                )}

                <Index.FormHelperText error id="accountId-error">
                  {errors.description?.message
                    ? errors.description?.message
                    : ""}
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.SubmitDiscardButton
            loading={loading}
            handleClose={props.handleClose}
          />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddMassNotifications;
