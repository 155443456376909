import React, { useState, useEffect } from "react";
import Index from "../../../component/Index";
import { Api } from "../../../config/Api";
import DataService from "../../../config/DataService";

const SubscriberList = () => {
  const [subscriberData, setSubscriberData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [requestFormRow, setRequestFormRow] = useState({});
  const [loading, setLoading] = useState(false);

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterData = (e) => {
    if (e.target.value === "") {
      setFilterData(subscriberData);
    } else {
      const result = subscriberData.filter((item) => {
        return (
          item?.subscription_id.name_of_plan
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          item?.login_id?.name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          item?.login_id?.user_code
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          item?.start_date
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          item?.end_date?.toLowerCase().includes(e.target.value?.toLowerCase())
        );
      });
      setFilterData(result);
      setPage(0);
    }
  };

  const getAllSubscriberList = async () => {
    setLoading(true);
    await DataService.get(Api.Admin.subscriberList)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          setLoading(false);
          setSubscriberData(res.data.data);
          setFilterData(res.data.data);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllSubscriberList();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateStatus = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", requestFormRow?._id);
    urlencoded.append("status", action === "Accept" ? 1 : 2);

    await DataService.post(Api.Admin.approveRejectSubsctiberPlan, urlencoded)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          if (action === "Accept") {
            Index.toast.success("Reuqest Accepted successfully");
          } else {
            Index.toast.success("Reuqest Rejected successfully");
          }
          setOpen(false);
          setRequestFormRow("");
          setAction("");
          getAllSubscriberList();
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
      });
  };
  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="flex-all admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Subscriber List
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="view-btn-main border-btn-main">
                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Search subscriber"
                              onChange={(e) => handleFilterData(e)}
                            />

                            <img
                              src={Index.Svg.search}
                              className="search-grey-img"
                              alt="search grey img"
                            ></img>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      {/* <Index.Button
                    className="view-order-btn border-btn"
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    Add User
                  </Index.Button> */}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="page-table-main suscriber-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 500 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Name of plan
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Branch Name
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Branch Code
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Start Date
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              End Date
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Expire status
                            </Index.TableCell>


                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Status
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filterData.length > 0
                            ? filterData &&
                              paginate(filterData, rowsPerPage, page + 1).map(
                                (row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.subscription_id?.name_of_plan
                                        ? row?.subscription_id?.name_of_plan
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.created_by?.name
                                        ? row?.created_by?.name
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.created_by?.user_code
                                        ? row?.created_by?.user_code
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.start_date
                                        ? Index.moment(row?.start_date).format(
                                            "L"
                                          )
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.end_date
                                        ? Index.moment(row?.end_date).format(
                                            "L"
                                          )
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.expired_status
                                        ? "Expired"
                                        : "Ongoing" }
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.status === 1
                                        ? "Approved"
                                        : row.status === 2
                                        ? "Rejected"
                                        : "Pending"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td  accpt-rjct-btn-main"
                                    >
                                      {row?.status === 0 ? (
                                        <>
                                          <Index.Button
                                            variant="contained"
                                            className="accpt-rjct-btn succes-btn"
                                            size="small"
                                            onClick={() => {
                                              handleOpen();
                                              setAction("Accept");
                                              setRequestFormRow(row);
                                            }}
                                          >
                                            Accept
                                          </Index.Button>

                                          {/* <Index.Button
                                          variant="contained"
                                          className="accpt-rjct-btn reject-btn"
                                          color="error"
                                          size="small"
                                          onClick={() => {
                                            handleOpen();
                                            setAction("Reject");
                                            setRequestFormRow(row);
                                          }}
                                          style={{ marginLeft: "5px" }}
                                        >
                                          Reject
                                        </Index.Button> */}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                )
                              )
                            : "No Data Found"}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filterData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Index.Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete"
          >
            <Index.Box
              sx={Index.Constant.MODAL_STYLE}
              className="delete-modal-inner-main"
            >
              <Index.Box className="modal-circle-main">
                <img
                  src={
                    action === "Accept"
                      ? Index.Svg.checkmark
                      : Index.Svg.closecircle
                  }
                  className="user-circle-img"
                />
              </Index.Box>
              <Index.Typography
                className="delete-modal-title"
                component="h2"
                variant="h2"
              >
                Are you sure?
              </Index.Typography>
              <Index.Typography
                className="delete-modal-para common-para"
                component="p"
                variant="p"
              >
                {action === "Accept"
                  ? "Do you really want to Accept these record ? "
                  : "Do you really want to Reject these record ?"}
              </Index.Typography>
              <Index.Box className="delete-modal-btn-flex">
                <Index.Button
                  className="modal-cancel-btn modal-btn"
                  onClick={handleClose}
                >
                  Cancel
                </Index.Button>
                <Index.Button
                  className={
                    action === "Accept"
                      ? "modal-success-btn modal-btn"
                      : "modal-delete-btn modal-btn"
                  }
                  onClick={handleUpdateStatus}
                >
                  {action}
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
        </Index.Box>
      )}
    </>
  );
};

export default SubscriberList;
