import React , {useState} from "react";
import Index from "../../../../component/Index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

function createData(name, address, city, number, stutus, action) {
  return { name, address, city, number, stutus, action };
}

const rows = [
  createData(
    <Index.Box className="userlist-data">
      <img src={Index.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={Index.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={Index.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={Index.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),
  createData(
    <Index.Box className="userlist-data">
      <img src={Index.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={Index.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={Index.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={Index.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),

  createData(
    <Index.Box className="userlist-data">
      <img src={Index.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={Index.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={Index.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={Index.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),

  createData(
    <Index.Box className="userlist-data">
      <img src={Index.Png.userlist1} className="userlist-img"></img> GASTON
    </Index.Box>,
    "12974 KEEBLER PRAIRIE, SOUTH BRENDON, ID, CL",
    "NEW KAELACHESTER",
    "623-880-0509 X6880",
    "STATUS",
    <Index.Box className="userdata-btn-flex">
      <Index.Button>
        <img src={Index.Svg.blueedit}></img>
      </Index.Button>
      <Index.Button>
        <img src={Index.Svg.trash}></img>
      </Index.Button>
      <Index.Button>
        <img src={Index.Svg.yelloweye}></img>
      </Index.Button>
    </Index.Box>
  ),
];

export default function UserList() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);



  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            User List Page
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search user"
                  />

                  <img
                    src={Index.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="flex-all user-list-inner-flex">
              <Index.Box className="export-btn-main border-btn-main">
                <Index.Button
                  className="export-btn border-btn"
                  onClick={handleOpenDelete}
                >
                  <img
                    src={Index.Svg.down}
                    className="down-icon"
                    alt="download icon"
                  />
                  Export
                </Index.Button>
              </Index.Box>
              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={handleOpen}
                >
                  <img
                    src={Index.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add User
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="userlist-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              NAME
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ADDRESS
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              CITY
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              NUMBER
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              STATUS
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ACTION
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {rows.map((row) => (
                            <Index.TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row.name}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.address}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.city}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.number}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.stutus}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.action}
                              </Index.TableCell>
                            </Index.TableRow>
                          ))}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="pagination-main">
          {/* <Index.Pagination count={10} shape="rounded" /> */}
          <Index.Pagination
            count={3}
            variant="outlined"
            shape="rounded"
            className="pagination"
          />
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main">
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Add User Modal
          </Index.Typography>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              First Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                placeholder=""
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Last Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                placeholder=""
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Email<span className="span-text common-para">(Optional)</span>
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                placeholder=""
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Date
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                placeholder=""
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="modal-user-btn-flex">
            <Index.Box className="discard-btn-main border-btn-main">
              <Index.Button className="discard-user-btn border-btn">
                Discard
              </Index.Button>
            </Index.Box>
            <Index.Box className="save-btn-main border-btn-main">
              <Index.Button className="save-user-btn border-btn">
                <img src={Index.Svg.save} className="user-save-icon"></img>Save
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete"
      >
        <Index.Box sx={style} className="delete-modal-inner-main">
          <Index.Box className="modal-circle-main">
            <img src={Index.Svg.closecircle} className="user-circle-img" />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            Do you really want to delete these records? This process cannot be
            undone.
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button className="modal-cancel-btn modal-btn">
              Cancel
            </Index.Button>
            <Index.Button className="modal-delete-btn modal-btn">
              Delete
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
}
