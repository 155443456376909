import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index.js";
import { Api } from "../../../../config/Api.js";
import DataService from "../../../../config/DataService.js";

const RestaurantDetail = () => {
  const params = Index.useParams();
  const navigate = Index.useNavigate();
  const [restaurantDetail, setRestaurantDetail] = useState({});
  const [branchDetail, setBranchDetail] = useState([]);
  const [loading, setLoading] = useState(false);

  let role = localStorage.getItem("role-id");

  const getRestaurantData = async () => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", params.id);
    await DataService.post(Api.Admin.getResturantDetail, urlencoded)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          setRestaurantDetail(response.data.data);
          setBranchDetail(response.data.branch);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getRestaurantData();
  }, [params]);

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box className="admin-reastuarant-detail">
            <Index.Box className="reastuarant-Image-box">
              <img
                src="https://images.pexels.com/photos/1579739/pexels-photo-1579739.jpeg?auto=compress&cs=tinysrgb&w=600"
                width="100%"
              />
            </Index.Box>
            <Index.Box className="reastuarant-detail-box">
              <Index.Box className="title">
                <Index.Typography variant="h6">
                  {restaurantDetail?.restaurant_name}
                </Index.Typography>
              </Index.Box>
              <br />
              <Index.Box className="form-group-input">
                <Index.Typography>Owner Name</Index.Typography>
                <Index.TextField
                  size="small"
                  id="standard-basic"
                  variant="standard"
                  disabled
                  value={restaurantDetail?.owner_name}
                />
              </Index.Box>

              <Index.Box className="form-group-input">
                <Index.Typography>Mobile Number</Index.Typography>
                <Index.TextField
                  size="small"
                  variant="standard"
                  disabled
                  id="fullWidth"
                  value={restaurantDetail?.mobile_number}
                />
              </Index.Box>

              <Index.Box className="form-group-input">
                <Index.Typography>Email</Index.Typography>
                <Index.TextField
                  size="small"
                  variant="standard"
                  disabled
                  id="fullWidth"
                  value={restaurantDetail?.email}
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="reastuaran-Branch">
            <Index.Typography variant="h6" sx={{ padding: "10px" }}>
              Branch List
            </Index.Typography>
            <Index.Box className="admin-reastuarant-branch-detail">
              {branchDetail?.length > 0
                ? branchDetail.map((row, i) => {
                    return (
                      <>
                        <Index.Box
                          className="reastuarant-branch-card"
                          onClick={() =>
                            role === "0" &&
                            navigate(`/restaurant/branchDetails/${row?._id}`)
                          }
                        >
                          <Index.Box className="flex-restuarant-branch">
                            <Index.Box className="admin-sub-title-main">
                              <Index.Typography
                                className="admin-branch-title"
                                component="p"
                                variant="p"
                              >
                                {row?.branch_name}
                              </Index.Typography>
                            </Index.Box>

                            {row?.status == 1 ? (
                              <Index.Typography> Approved</Index.Typography>
                            ) : row?.status == 2 ? (
                              <Index.Typography> Rejected</Index.Typography>
                            ) : (
                              <Index.Typography> Pending</Index.Typography>
                            )}
                          </Index.Box>
                          {/* <Index.Typography>Branch Name</Index.Typography> */}
                          <img
                            src={
                              Index.Constant.IMAGE_URL +
                              row.documents.front_rest_image
                            }
                            width="100%"
                            height={"200px"}
                          />
                          <Index.Box className="form-group-input">
                            <Index.Typography variant="p">
                              Branch code
                            </Index.Typography>
                            <Index.TextField
                              size="small"
                              variant="standard"
                              disabled
                              id="fullWidth"
                              value={row?.branch_code}
                            />
                          </Index.Box>
                          <Index.Box className="form-group-input">
                            <Index.Typography variant="p">
                              Location
                            </Index.Typography>
                            <Index.TextField
                              size="small"
                              variant="standard"
                              disabled
                              id="fullWidth"
                              value={row?.location}
                            />
                          </Index.Box>

                          {role === "1" && (
                              <Index.Box className="view-btn-main border-btn-main">
                                <Index.Button
                                  className="view-Approve-btn border-btn"
                                  onClick={() => {
                                    window.open(
                                      `https://restaurant.mealord.in/admin-login/${row?._id}`
                                    );
                                  }}
                                >
                                  Login
                                </Index.Button>
                              </Index.Box>
                            )}
                            
                        </Index.Box>
                      </>
                    );
                  })
                : ""}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default RestaurantDetail;
