import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { useForm, Controller } from "react-hook-form";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const AddCategory = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("category_name", data.categoryName);
    // urlencoded.append("food_type", data.foodType);
    // urlencoded.append("cuisine", data.cuisine);
    urlencoded.append("active", data.active);
    if (props.row) {
      urlencoded.append("id", props.row._id);
    }

    await DataService.post(Api.Admin.addEditCategory, urlencoded)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          if (props.row) {
            Index.toast.success("Category Updated successfully");
          } else {
            Index.toast.success("Category Added successfully");
          }
          props.getAllCategory();
          props.handleClose();
          reset();
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log("rendered");
    setValue("categoryName", props.row ? props.row?.category_name : "");
    //setValue('cuisine', props.row ? props.row?.cuisine?._id : '');
    //setValue('foodType', props.row ? props.row?.food_type?._id : '');
    setValue("active", props.row ? props.row?.active : "");
  }, [props.row]);

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box sx={props.style} className="add-user-modal-inner-main">
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Add/Edit Category
          </Index.Typography>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Category name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="categoryName"
                {...register("categoryName", {
                  required: "Category name is required",
                })}
                error={Boolean(errors.categoryName)}
                helperText={errors.categoryName?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box add-user-input radio-main">
            <Index.FormHelperText className="form-lable">
              Active
            </Index.FormHelperText>
            <Index.FormControl className="form-control">
              <Controller
                name="active"
                control={control}
                {...register("active", {
                  required: "Please select Active",
                })}
                render={({ field: { onChange, value } }) => (
                  <Index.RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="radiogroup"
                    defaultValue=""
                    labelId="priceLimit"
                    id="priceLimit"
                    value={value}
                    onChange={onChange}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <Index.FormControlLabel
                      value="true"
                      control={<Index.Radio className="radio" />}
                      label="yes"
                    />
                    <Index.FormControlLabel
                      value="false"
                      control={<Index.Radio className="radio" />}
                      label="No"
                    />
                  </Index.RadioGroup>
                )}
              />
            </Index.FormControl>

            <Index.FormHelperText error id="accountId-error">
              {String(errors["active"]?.message ?? "")}
            </Index.FormHelperText>
          </Index.Box>

          <Index.SubmitDiscardButton
            handleClose={props.handleClose}
            loading={loading}
          />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddCategory;
