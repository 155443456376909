import React from "react";
import DataService from "../../../config/DataService";
import { useForm } from "react-hook-form";
import { Api } from "../../../config/Api";
import Index from "../../../component/Index";
const ForgotPassword = () => {
  let navigate = Index.useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", data.email);

    await DataService.post(Api.Admin.forgotPassword, urlencoded).then((res) => {
      if (res.data.status === 201) {
        Index.toast.success(res.data.message);
        navigate("/otp-verify");
      }
    }).catch((e)=>{
      Index.toast.error(e.response.data.message ? e.response.data.message : e.message );
    });
  };
  return (
    <>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <Index.Box className="login-left-bg"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Forgot Password
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                ></Index.Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Index.Box className="input-box">
                    <Index.FormHelperText className="form-lable">
                      Email
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        sx={{
                          "& .MuiFormHelperText-root": { ml: 0, mt: "5px" },
                        }}
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        name="email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                            message: "Please enter a valid email",
                          },
                        })}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="btn-main-primary otp-btn-main">
                    <Index.Button className="btn-primary otp-btn" type="submit">
                      Send otp
                    </Index.Button>
                  </Index.Box>
                </form>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ForgotPassword;
