import React from "react";
import { useState, useEffect } from "react";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import Index from "../../../../component/Index";
import DownloadIcon from "@mui/icons-material/Download";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ViewSingleTransactionDetail from "./ViewSingleTransactionDetail";

const MealordView = () => {
  const [transactionList, setTransactionList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [row, setRow] = useState({});

  const [startDate, setStartDate] = useState(
    Index.moment().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(Index.moment().format("YYYY-MM-DD"));
  const [branchId, setBranchId] = useState("");
  const [branches, setBranches] = useState([]);

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggleOpen = () => {
    setOpen(!open);
  };

  const getAllTransactionList = (startDate, endDate, branchId) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("startDate", startDate);
    urlencoded.append("endDate", endDate);
    branchId && urlencoded.append("branchId", branchId);

    DataService.post(Api.Admin.transactionList, urlencoded)
      .then((response) => {
        if (response.data.status === 200) {
          setTransactionList(response?.data?.data);
          setFilterData(response?.data?.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        // Index.toast.error(
        //   e.response.data.message ? e.response.data.message : e.message
        // );
        setLoading(false);
      });
  };

  const getAllBranches = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("type", "branch");
    DataService.post(Api.Admin.getResturantDetail, urlencoded)
      .then((response) => {
        if (response.data.status === 200) {
          setBranches(response.data.data);
        }
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getAllBranches();
    getAllTransactionList(startDate, endDate, branchId);
  }, []);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const changedNameData = apiData?.map((row) => {
      return {
        "Order Id": row?.order_id?.branch_order || "-",
        "Unique Id":
          row?.order_type === "additional"
            ? row?.additional_order_id?._id
            : row?.order_id?._id || "-",
        Date: Index.moment(row?.order_id?.date).format("DD-MM-YYYY") || "-",
        Time: row?.order_id?.time || "-",
        "Order type": row?.order_id?.order_type || "-",
        "Branch name": row?.branch_id?.branch_name || "-",
        "Booking status":
          row?.order_type === "additional"
            ? row?.additional_order_id?._id?.order_status
            : row?.order_id?.booking_status || "-",
        "Payment Type": row?.payment_type || "-",
        Amount: displayAmount(row?.actual_amount),
        "Promo applied": displayAmount(row?.discount_amount),
        "Order Total after Promo": displayAmount(row?.after_offer),
        GST: displayAmount(row?.total_gst),
        "Convenience Fee": displayAmount(row?.convenience_fees),
        "GST (18%) on convenience fee": displayAmount(
          row?.gst_on_convenience_fees
        ),
        "Customer final Order": displayAmount(row?.customer_final_order),
        "Wallet Amount": displayAmount(row?.wallet_amount),
        "CCAVENUE Transaction fee": displayAmount(
          row?.transaction_fee_of_CCAvenue
        ),
        "Amount received by mealord": displayAmount(
          row?.amount_received_by_mealord
        ),
        "Amount applicable": displayAmount(row?.deduction_for_restaurant),
        "Mealord Promo Added back": displayAmount(row?.mealord_promo),
        "GST Applied (deducted)": displayAmount(row?.restaurant_gst),
        "Commmision deducted": displayAmount(row?.commission_of_mealord),
        "18% GST on Commission": displayAmount(row?.gst_on_commission),
        "TDS (1% of order)": displayAmount(row?.tds),
        "Amount receivable before transaction fee": displayAmount(
          row?.amount_before_transaction_fee
        ),
        "Transaction Fee": displayAmount(row?.transaction_fees),
        "Refund amount": displayAmount(row?.refund_amount),
        Penalty: displayAmount(row?.penalty_amount),
        "Amount received by Restaurant": displayAmount(
          row?.amount_received_by_restaurant
        ),
        "Mealord net revenue": displayAmount(row?.mealord_final_amount),
      };
    });

    const ws = XLSX.utils.json_to_sheet(changedNameData);
    // delete ws["H1"];
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const displayAmount = (value) => {
    if (value === undefined || isNaN(value)) {
      return "-";
    } else if (value == 0) {
      return "0";
    } else {
      return value.toFixed(2);
    }
  };

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="view-btn-main border-btn-main filter-btn export-transaction-btn">
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<DownloadIcon />}
                      disabled={!filterData.length}
                      onClick={() =>
                        exportToCSV(filterData, "Transaction report")
                      }
                    >
                      Export
                    </Index.Button>
                  </Index.Box>

                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Transaction History
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group table-head-date-main">
                            <Index.Box className="branch-name">
                              <Index.Typography>Branch :</Index.Typography>
                              <Index.Select
                                value={branchId}
                                displayEmpty
                                onChange={(e) => setBranchId(e.target.value)}
                                className="form-control"
                              >
                                <Index.MenuItem value="" disabled>
                                  Select branch
                                </Index.MenuItem>
                                {branches &&
                                  branches?.map((row) => {
                                    return (
                                      <Index.MenuItem value={row?._id}>
                                        {row?.branch_name}
                                      </Index.MenuItem>
                                    );
                                  })}
                              </Index.Select>
                            </Index.Box>
                            <Index.Box className="from-to-date-table">
                              <Index.Box className="from-date-label fromto-date-label-same">
                                <Index.Typography>From :</Index.Typography>
                                <Index.TextField
                                  type="date"
                                  value={startDate}
                                  onChange={(e) => {
                                    setStartDate(
                                      Index.moment(e.target.value).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                    setEndDate("");
                                  }}
                                />
                              </Index.Box>
                              <Index.Box className="to-date-label fromto-date-label-same">
                                <Index.Typography>To :</Index.Typography>
                                <Index.TextField
                                  type="date"
                                  value={endDate}
                                  onChange={(e) => {
                                    setEndDate(
                                      Index.moment(e.target.value).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                  }}
                                  inputProps={{
                                    min: startDate,
                                  }}
                                />
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="view-btn-main border-btn-main filter-btn">
                              <Index.Button
                                className="view-order-btn border-btn"
                                onClick={() =>
                                  getAllTransactionList(
                                    startDate,
                                    endDate,
                                    branchId
                                  )
                                }
                              >
                                Filter
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main transaction-history-table">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Order Id
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Unique Id
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Date
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Time
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Order Type
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Branch Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Status
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Payment type
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Customer Order Amount
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Promo applied
                              </Index.TableCell>

                              <Index.Tooltip
                                title="Order Total after Promo"
                                placement="top-start"
                              >
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Order Total
                                </Index.TableCell>
                              </Index.Tooltip>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                GST
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Convenience Fee
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                GST on convenience fee
                                <Index.Tooltip
                                  title="(18%) on convenience fee"
                                  placement="top-start"
                                  arrow
                                >
                                  <Index.InfoIcon className="info-icon" />
                                </Index.Tooltip>
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Customer final Order
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Amount used by wallet
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                CCAVENUE Transaction fee
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Amount received by mealord
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Amount applicable
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Mealord Promo
                                <Index.Tooltip
                                  title="Mealord Promo Added back"
                                  placement="top-start"
                                  arrow
                                >
                                  <Index.InfoIcon className="info-icon" />
                                </Index.Tooltip>
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                GST Applied
                                <Index.Tooltip
                                  title="GST Applied (deducted)"
                                  placement="top-start"
                                  arrow
                                >
                                  <Index.InfoIcon className="info-icon" />
                                </Index.Tooltip>
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Commmision deducted
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                GST on Commission
                                <Index.Tooltip
                                  title="18% GST on Commission"
                                  placement="top-start"
                                  arrow
                                >
                                  <Index.InfoIcon className="info-icon" />
                                </Index.Tooltip>
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                TDS
                                <Index.Tooltip
                                  title="TDS (1% of order)"
                                  placement="top-start"
                                  arrow
                                >
                                  <Index.InfoIcon className="info-icon" />
                                </Index.Tooltip>
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Amount receivable
                                <Index.Tooltip
                                  title="Amount receivable before transaction fee"
                                  placement="top-start"
                                  arrow
                                >
                                  <Index.InfoIcon className="info-icon" />
                                </Index.Tooltip>
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Transaction Fee
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Refund amount
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Penalty
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Amount received by Restaurant
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Mealord net revenue
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData.length > 0 ? (
                              filterData &&
                              paginate(filterData, rowsPerPage, page + 1).map(
                                (row, index) => (
                                  <Index.TableRow
                                    key={row?._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Button
                                        className="table-btn"
                                        onClick={() => {
                                          setRow(row);
                                          handleToggleOpen();
                                        }}
                                      >
                                        <img
                                          src={Index.Svg.yelloweye}
                                          alt="view-icon"
                                        ></img>
                                      </Index.Button>
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.order_id?.branch_order || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.order_type === "additional"
                                        ? row?.additional_order_id?._id
                                        : row?.order_id?._id || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {Index.moment(row?.order_id?.date).format(
                                        "DD-MM-YYYY"
                                      ) || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.order_id?.time || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.order_id?.order_type || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.branch_id?.branch_name || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.order_type === "additional"
                                        ? row?.additional_order_id
                                            ?.order_status
                                        : row?.order_id?.booking_status || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.payment_type || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(row?.actual_amount)}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {displayAmount(row?.discount_amount)}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(row?.after_offer)}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(row?.total_gst)}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(row?.convenience_fees)}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(
                                        row?.gst_on_convenience_fees
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(row?.customer_final_order)}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(row?.wallet_amount)}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(
                                        row?.transaction_fee_of_CCAvenue
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(
                                        row?.amount_received_by_mealord
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(
                                        row?.deduction_for_restaurant
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(row?.mealord_promo)}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(row?.restaurant_gst)}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(
                                        row?.commission_of_mealord
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(row?.gst_on_commission)}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(row?.tds)}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(
                                        row?.amount_before_transaction_fee
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(row?.transaction_fees)}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(row?.refund_amount)}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.penalty_amount > 0 ? ( - displayAmount(row?.penalty_amount) ): 0 }
                                   
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(
                                        row?.amount_received_by_restaurant-row?.penalty_amount
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {displayAmount(row?.mealord_final_amount)}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                )
                              )
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell align="center" colSpan={11}>
                                  No record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filterData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Index.Box>

          {open && (
            <ViewSingleTransactionDetail
              open={open}
              handleToggleOpen={handleToggleOpen}
              row={row}
              displayAmount={displayAmount}
              forType="mealord"
            />
          )}
        </Index.Box>
      )}
    </>
  );
};

export default MealordView;
