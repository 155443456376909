import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const EditBranch = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [images, setImages] = useState({
    frontRestaurantImage: "",
    adharCard: "",
    panCard: "",
    fssiCertificate: "",
    gstCertificate: "",
  });

  const [loading, setLoading] = useState({
    frontRestaurantImage: false,
    adharCard: false,
    panCard: false,
    fssiCertificate: false,
    gstCertificate: false,
  });

  const [imageError, setImageError] = useState({
    frontRestaurantImage: "",
    adharCard: "",
    panCard: "",
    fssiCertificate: "",
    gstCertificate: "",
  });

  const [imageSuccess, setImageSuccess] = useState({
    frontRestaurantImage: "",
    adharCard: "",
    panCard: "",
    fssiCertificate: "",
    gstCertificate: "",
  });

  const [submitLoading, setSubmitLoading] = useState(false)
  // const handleImageChange = (e)=>{
  //   const { name }= e.target;
  //   setImagesName((prevValue) => ({ ...prevValue , [name]: e.target.files[0].name}));
  //   setImagesFile((prevValue) => ({ ...prevValue , [name]: e.target.files[0]}));
  // }

  const handleImageChange = async (e) => {
    const { name } = e.target;

    const file = e.target.files[0];

    const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "image/bmp", "application/pdf"];

    const allowedSize = 10 * 1024 * 1024; // 10 MB

    if (!allowedTypes.includes(file.type)) {
      setImageError({
        [name]: "Please upload a JPEG, JPG, PNG, BMP, or PDF file.",
      });
      return;
    }

    if (file.size > allowedSize) {
      setImageError({
        [name]: "Please upload a file that is smaller than 10 MB.",
      });
      return;
    }

    setLoading({
      [name]: true,
    });

    const formdata = new FormData();
    formdata.append("image",file);

    await DataService.post(
      `${Api.User.imageUpload}?encrypt=${
        name === "frontRestaurantImage" ? "true" : "false"
      }`,
      formdata
    ).then((res) => {
      if (res.status === 200 || res.status === 201) {
        setImages((prevValue) => ({
          ...prevValue,
          [name]: res.data.data.image,
        }));

        setLoading({
          [name]: false,
        });

        setImageError({
          [name]: "",
        });

        setImageSuccess({
          ...imageSuccess,
          [name]: "Uploaded successfully",
        });
      }
    }).catch((e)=>{
      Index.toast.error(e.response.data.message)
    });
  };

  const onSubmit = async (data) => {
    
    setSubmitLoading(true)
    let documents = {
      pan_card: images.panCard,
      aadhar_card: images.adharCard,
      fssai_certi: images.fssiCertificate,
      front_rest_image: images.frontRestaurantImage,
      gst_certi: images.gstCertificate,
      created_by: props.branchDetails?.documents?.created_by || 0,
      is_deleted: false,
    };

    await DataService.post(Api.Admin.editBranch, {
      location: data.location,
      landmark: data.landmark,
      branch_name: data.branchName,
      branch_mobile_number: data.mobileNo,
      account_name: data.accountName,
      account_no: data.accountNumber,
      ifsc_code: data.ifscCode,
      documents: documents,
      id: props.branchId,
    })
      .then((response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          setSubmitLoading(false)
          Index.toast.success("Branch updated successfully");
          props.handleEditClose();
          props.getRestaurantData();
          reset();
        }
      })
      .catch((e)=>{
        Index.toast.error(e.response.data.message ? e.response.data.message : e.message );
        setSubmitLoading(false)
      });
  };

  useEffect(() => {
    if (props.branchDetails) {
      setValue("location", props.branchDetails?.location);
      setValue("landmark", props.branchDetails?.landmark);
      setValue("branchName", props.branchDetails?.branch_name);
      setValue("mobileNo", props.branchDetails?.branch_mobile_number);
      setValue("accountName", props.branchDetails?.account_name);
      setValue("accountNumber", props.branchDetails?.account_no);
      setValue("ifscCode", props.branchDetails?.ifsc_code);

      setImages({
        ...images,
        frontRestaurantImage: props.branchDetails?.documents?.front_rest_image
          ? props.branchDetails?.documents?.front_rest_image
          : "",
        adharCard: props.branchDetails?.documents?.aadhar_card
          ? props.branchDetails?.documents?.aadhar_card
          : "",
        panCard: props.branchDetails?.documents?.pan_card
          ? props.branchDetails?.documents?.pan_card
          : "",
        fssiCertificate: props.branchDetails?.documents?.fssai_certi
          ? props.branchDetails?.documents?.fssai_certi
          : "",
        gstCertificate: props.branchDetails?.documents?.gst_certi
          ? props.branchDetails?.documents?.gst_certi
          : "",
      });
    }
  }, [props.branchDetails]);


  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleEditClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-food-item-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Edit Branch Details
          </Index.Typography>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Location
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="location"
                {...register("location", {
                  required: "Location is required",
                })}
                error={Boolean(errors.location)}
                helperText={errors.location?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Landmark
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="landmark"
                {...register("landmark", {
                  required: "Landmark is required",
                })}
                error={Boolean(errors.landmark)}
                helperText={errors.landmark?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Branch Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="branchName"
                {...register("branchName", {
                  required: "Branch Name is required",
                })}
                error={Boolean(errors.branchName)}
                helperText={errors.branchName?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Mobile Number
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="mobileNo"
                {...register("mobileNo", {
                  required: "Mobile Number is required",
                  pattern: {
                    value: /^([+]\d{2})?\d{10}$/,
                    message: "Please enter a valid mobile number",
                  },
                })}
                error={Boolean(errors.mobileNo)}
                helperText={errors.mobileNo?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box sx={{ margin: "10px 0px" }}>
            <Index.Typography variant="h6">Bank Details</Index.Typography>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Account Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="accountName"
                {...register("accountName", {
                  required: "Account Name is required",
                })}
                error={Boolean(errors.accountName)}
                helperText={errors.accountName?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Account Number
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="accountNumber"
                {...register("accountNumber", {
                  required: "Account Number is required",
                })}
                error={Boolean(errors.accountNumber)}
                helperText={errors.accountNumber?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              IFSC Code
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="ifscCode"
                {...register("ifscCode", {
                  required: "IFSC Code is required",
                })}
                error={Boolean(errors.ifscCode)}
                helperText={errors.ifscCode?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box sx={{ margin: "10px 0px" }}>
            <Index.Typography variant="h6">Documents</Index.Typography>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Front Restaurant Image
            </Index.FormHelperText>
            <Index.Button
              variant="outlined"
              fullWidth
              component="label"
              className="file-upload-btn"
            >
              {loading.frontRestaurantImage === true
                ? "Loading...."
                : imageSuccess.frontRestaurantImage
                ? imageSuccess.frontRestaurantImage
                : "Select File"}

              <input
                hidden
                accept=".png, .jpg, .jpeg, .pdf"
                max="10485760"
                id="contained-button-file"
                type="file"
                name="frontRestaurantImage"
                onChange={(e) => {
                  handleImageChange(e);
                }}
              />
            </Index.Button>

            <Index.FormHelperText error id="accountId-error">
              {imageError.frontRestaurantImage &&
                imageError.frontRestaurantImage}
            </Index.FormHelperText>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Aadhar Card
            </Index.FormHelperText>
            <Index.Button
              variant="outlined"
              fullWidth
              component="label"
              className="file-upload-btn"
            >
             {loading.adharCard === true
                ? "Loading...."
                : imageSuccess.adharCard
                ? imageSuccess.adharCard
                : "Select File"}

              <input
                hidden
                accept=".png, .jpg, .jpeg, .pdf"
                max="10485760"
                id="contained-button-file"
                type="file"
                name="adharCard"
                onChange={(e) => {
                  handleImageChange(e);
                }}
              />
            </Index.Button>

            <Index.FormHelperText error id="accountId-error">
              {imageError.adharCard && imageError.adharCard}
            </Index.FormHelperText>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Pan Card
            </Index.FormHelperText>
            <Index.Button
              variant="outlined"
              fullWidth
              component="label"
              className="file-upload-btn"
            >
               {loading.panCard === true
                ? "Loading...."
                : imageSuccess.panCard
                ? imageSuccess.panCard
                : "Select File"}

              <input
                hidden
                accept=".png, .jpg, .jpeg, .pdf"
                max="10485760"
                id="contained-button-file"
                type="file"
                name="panCard"
                onChange={(e) => {
                  handleImageChange(e);
                }}
              />
            </Index.Button>

            <Index.FormHelperText error id="accountId-error">
              {imageError.panCard && imageError.panCard}
            </Index.FormHelperText>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              FSSI Certificate
            </Index.FormHelperText>
            <Index.Button
              variant="outlined"
              fullWidth
              component="label"
              className="file-upload-btn"
            >
              {loading.fssiCertificate === true
                ? "Loading...."
                : imageSuccess?.fssiCertificate
                ? imageSuccess?.fssiCertificate
                : "Select File"}

              <input
                hidden
                accept=".png, .jpg, .jpeg, .pdf"
                max="10485760"
                id="contained-button-file"
                type="file"
                name="fssiCertificate"
                onChange={(e) => {
                  handleImageChange(e);
                }}
              />
            </Index.Button>

            <Index.FormHelperText error id="accountId-error">
              {imageError.fssiCertificate && imageError.fssiCertificate}
            </Index.FormHelperText>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              GST Certificate
            </Index.FormHelperText>
            <Index.Button
              variant="outlined"
              fullWidth
              component="label"
              className="file-upload-btn"
            >
              {loading.gstCertificate === true
                ? "Loading...."
                : imageSuccess.gstCertificate
                ? imageSuccess.gstCertificate
                : "Select File"}

              <input
                hidden
                accept=".png, .jpg, .jpeg, .pdf"
                max="10485760"
                id="contained-button-file"
                type="file"
                name="gstCertificate"
                onChange={(e) => {
                  handleImageChange(e);
                }}
              />
            </Index.Button>

            <Index.FormHelperText error id="accountId-error">
              {imageError.gstCertificate && imageError.gstCertificate}
            </Index.FormHelperText>
          </Index.Box>

          <Index.SubmitDiscardButton loading={submitLoading} handleClose={props.handleEditClose} />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default EditBranch;
