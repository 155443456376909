import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { useForm } from "react-hook-form";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const AddFaqs = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [editorData, setEditorData] = useState("");
  const [editorError, setEditorError] = useState("");

  const onSubmit = async (data) => {
    if (editorData === "") {
      setEditorError("Answer is requried");
    } else {
      const urlencoded = new URLSearchParams();
      urlencoded.append("question", data.question);
      urlencoded.append("answer", editorData);
      props.row && urlencoded.append("id", props.row?._id);

      await DataService.post(Api.Admin.addEditFaqs, urlencoded)
        .then((res) => {
          if (res.data.status === 201 || res.data.status === 200) {
            if (props.row) {
              Index.toast.success("Faqs updated successfully");
            } else {
              Index.toast.success("Faqs created successfully");
            }
            props.getAllFaqs();
            props.handleClose();
            reset();
          }
        })
        .catch((e) => {
          Index.toast.error(e.response.data.message);
        });
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);

    if (data === "") {
      setEditorError("Answer is required");
    } else if (data !== "") {
      setEditorError("");
    }
  };

  console.log(editorData, "edit");

  useEffect(() => {
    if (props.row) {
      setValue("question", props.row?.question);
      setEditorData(props.row?.answer);
    }
  }, []);

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-food-item-modal-inner-main  faqs-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            FAQs
          </Index.Typography>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Question
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="question"
                {...register("question", {
                  required: "Question is required",
                })}
                error={Boolean(errors.question)}
                helperText={errors.question?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Answer
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.CKEditor
                editor={Index.ClassicEditor}
                data={editorData}
                onChange={handleEditorChange}
              />
            </Index.Box>

            <Index.FormHelperText error>
              {editorError && editorError}
            </Index.FormHelperText>
          </Index.Box>

          <Index.Box className="modal-user-btn-flex">
            <Index.Box className="discard-btn-main border-btn-main">
              <Index.Button
                className="discard-user-btn border-btn"
                onClick={props.handleClose}
              >
                Discard
              </Index.Button>
            </Index.Box>
            <Index.Box className="save-btn-main border-btn-main">
              <Index.Button className="save-user-btn border-btn" type="submit">
                <img src={Index.Svg.save} className="user-save-icon"></img>Save
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddFaqs;
