import React, { useContext, useEffect, useState } from "react";
import Index from "../Index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { styled, alpha } from "@mui/material/styles";
import { MyContext } from "../../container/admin/pages/notification/MyContextProvider";

export default function Header(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [adminDetails, setAdminDetails] = React.useState(
    JSON.parse(localStorage.getItem("admin-details"))
  );
  const [notificationCount, setNotificationCount] = useState(0);

  // firebase get notification using usecontext
  const { data } = useContext(MyContext);
  useEffect(() => {
    const storedNotifications =
      JSON.parse(localStorage.getItem("notifications")) || [];

    setNotificationCount(storedNotifications?.length);
    if (data && Object.keys(data).length !== 0) {
      const updatedNotifications = [...storedNotifications, data];
      localStorage.setItem(
        "notifications",
        JSON.stringify(updatedNotifications)
      );
      const storedNotificationsCount = JSON.parse(
        localStorage.getItem("notifications")
      );
      setNotificationCount(storedNotificationsCount?.length);
    }
  }, [data]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("admin-details");
    localStorage.removeItem("role-id");
    navigate("/login");
    Index.successToast("Logged out successfully");
    setAnchorEl(null);
  };

  const handleProfileNavigate = () => {
    navigate("/edit-profile");
    setAnchorEl(null);
  };
  const StyledMenu = styled((props) => (
    <Index.Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 3,
      marginTop: theme.spacing(1),
      minWidth: 258,
      paddingTop: 30,
      paddingBottom: 0,
      paddingLeft: 23,
      marginTop: 38,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow: "0px 3px 6px #00000029",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  const handleNotification = () => {
    navigate("notifications");
    localStorage.removeItem("notifications");
    setNotificationCount(0);
  };

  return (
    <>
      <Index.Box
        className={`admin-header-main ${
          props.open ? "active" : "admin-header-deactive"
        }`}
      >
        <Index.Box className="admin-header-left">
          <Index.Box className="admin-header-logo-main">
            <img
              src={Index.Png.mealord}
              className="admin-sidebar-logo"
              alt="logo"
            />
            <Index.Button
              className="admin-bergur-button"
              onClick={() => props.setOpen(!props.open)}
            >
              <img
                src={Index.Svg.bergurmenu}
                className="bergurmenu-icon"
                alt="dashboard logo"
              ></img>
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-header-right">
            <Index.Box
              className="admin-header-icon-box"
              onClick={() => setNotificationCount(0)}
            >
              <Index.Badge
                badgeContent={notificationCount}
                // showZero
                color="primary"
              >
                <img
                  src={Index.Svg.notification}
                  className="admin-header-icon notification_icon"
                  alt="dashboard bell icon"
                  onClick={() => handleNotification()}
                ></img>
              </Index.Badge>
            </Index.Box>
            {/* <Index.Box className="admin-header-icon-box">
              <img
                src={Index.Svg.settings}
                className="admin-header-icon"
                alt="dashboard setting icon"
              ></img>
            </Index.Box> */}
            <Index.Box className="admin-header-drop-main">
              <Index.Button
                className="drop-header-btn"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Index.Box className="flex-drop-main">
                  <img
                    src={Index.Constant.IMAGE_URL + adminDetails?.profile_image}
                    className="admin-header-profile-icon"
                    alt="dashboard profile icon"
                  ></img>
                  <Index.Box className="title-admin-drop">
                    <Index.Typography
                      variant="h5"
                      component="h5"
                      className="admin-header-drop"
                    >
                      Admin
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Button>
            </Index.Box>
            <Index.Menu
              className="drop-header-menu"
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Index.MenuItem
                onClick={handleProfileNavigate}
                className="drop-header-menuitem"
              >
                {" "}
                <img src={Index.Svg.profilegrey} className="drop-header" />{" "}
                Profile
              </Index.MenuItem>
              {/* <Index.MenuItem onClick={handleClose} className='drop-header-menuitem'> <img src={Index.Svg.settings} className="drop-header" /> Account Settings</Index.MenuItem> */}
              <Index.MenuItem
                onClick={handleClose}
                className="drop-header-menuitem"
              >
                {" "}
                <img src={Index.Svg.edit} className="drop-header" /> Log Out
              </Index.MenuItem>
            </Index.Menu>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
