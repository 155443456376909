import React, { useState, useEffect } from "react";
import Index from "../../../component/Index";
import { useForm } from "react-hook-form";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";

const AddSubscriptionManagement = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.row) {
      setValue("planName", props.row.name_of_plan);
      setValue("description", props.row.description_of_plan);
      setValue("freeFor1Month", props.row.trial_activation_for_month);
      setValue("qrScanner", props.row.qr_scanner);
      setValue("placeOrder", props.row.place_order);
      setValue("onlineForWalkin", props.row.order_for_walkin);
      setValue("inventoryManagement", props.row.inventry_management);
      setValue("paymentManagement", props.row.payment_management);
      setValue("menuManagement", props.row.menu_updation);
      setValue("tableManagement", props.row.table_management);
      setValue("reportAndAnalytics", props.row.report_analytics);
      setValue("customerSupport", props.row.customer_support);
      setValue("amount", props.row.amount_of_plan);
      setValue("durationOfPlan", props.row.duration_plan_days);
      setValue("foodPromotion", props.row.food_promotion);
    }
  }, [props.row]);

  const onSubmit = async (data) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("name_of_plan", data.planName);
    urlencoded.append("description_of_plan", data.description);
    urlencoded.append("trial_activation_for_month", data.freeFor1Month);
    urlencoded.append("qr_scanner", data.qrScanner);
    urlencoded.append("place_order", data.placeOrder);
    urlencoded.append("order_for_walkin", data.onlineForWalkin);
    urlencoded.append("inventry_management", data.inventoryManagement);
    urlencoded.append("payment_management", data.paymentManagement);
    urlencoded.append("menu_updation", data.menuManagement);
    urlencoded.append("table_management", data.tableManagement);
    urlencoded.append("report_analytics", data.reportAndAnalytics);
    urlencoded.append("customer_support", data.customerSupport);
    urlencoded.append("food_promotion", data.foodPromotion);
    urlencoded.append("amount_of_plan", data.amount);
    urlencoded.append("duration_plan_days", data.durationOfPlan);

    if (props.row) {
      urlencoded.append("id", props.row._id);
    }

    await DataService.post(Api.Admin.addEditSubscriptionManagement, urlencoded)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          setLoading(false);
          if (props.row) {
            Index.toast.success("Subscription updated successfully");
          } else {
            Index.toast.success("Subscription added successfully");
          }

          props.handleClose();
          props.getAllSubscription();
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };
  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main add-subscriptin-inner"
        >
          <Index.Box className=" food-item-table">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                sx={{ minWidth: 500 }}
                aria-label="simple table"
                className="table"
              >
                <Index.TableBody>
                  <Index.TableRow>
                    <Index.TableCell>Name of the plan</Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            name="planName"
                            {...register("planName", {
                              required: "Plan name is required",
                            })}
                            error={Boolean(errors.planName)}
                            helperText={errors.planName?.message}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>

                  <Index.TableRow>
                    <Index.TableCell>Description of the plan</Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            name="description"
                            {...register("description", {
                              required: "Description is required",
                            })}
                            error={Boolean(errors.description)}
                            helperText={errors.description?.message}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>

                  <Index.TableRow>
                    <Index.TableCell>
                      Free trial activation for 2 month
                    </Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.Checkbox
                            name="freeFor1Month"
                            {...register("freeFor1Month")}
                            checked={watch("freeFor1Month") ? true : false}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>

                  <Index.TableRow>
                    <Index.TableCell>Qr scanner</Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.Checkbox
                            name="qrScanner"
                            {...register("qrScanner")}
                            checked={watch("qrScanner") ? true : false}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>

                  <Index.TableRow>
                    <Index.TableCell>
                      Captain Place Order Module
                    </Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.Checkbox
                            name="placeOrder"
                            {...register("placeOrder")}
                            checked={watch("placeOrder") ? true : false}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>

                  <Index.TableRow>
                    <Index.TableCell>
                      Online order management for walkin
                    </Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.Checkbox
                            name="onlineForWalkin"
                            {...register("onlineForWalkin")}
                            checked={watch("onlineForWalkin") ? true : false}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>

                  <Index.TableRow>
                    <Index.TableCell>Inventry management</Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.Checkbox
                            name="inventoryManagement"
                            {...register("inventoryManagement")}
                            checked={
                              watch("inventoryManagement") ? true : false
                            }
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>

                  <Index.TableRow>
                    <Index.TableCell>
                      Compehensive payment management module
                    </Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.Checkbox
                            name="paymentManagement"
                            {...register("paymentManagement")}
                            checked={watch("paymentManagement") ? true : false}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>

                  <Index.TableRow>
                    <Index.TableCell>Menu updation/ management</Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.Checkbox
                            name="menuManagement"
                            {...register("menuManagement")}
                            checked={watch("menuManagement") ? true : false}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>

                  <Index.TableRow>
                    <Index.TableCell>Table management</Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.Checkbox
                            name="tableManagement"
                            {...register("tableManagement")}
                            checked={watch("tableManagement") ? true : false}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>

                  <Index.TableRow>
                    <Index.TableCell>Report and analytics</Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.Checkbox
                            name="reportAndAnalytics"
                            {...register("reportAndAnalytics")}
                            checked={watch("reportAndAnalytics") ? true : false}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>

                  <Index.TableRow>
                    <Index.TableCell>Customer support</Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.Checkbox
                            name="customerSupport"
                            {...register("customerSupport")}
                            checked={watch("customerSupport") ? true : false}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>

                  <Index.TableRow>
                    <Index.TableCell>Food promotion</Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.Checkbox
                            name="foodPromotion"
                            {...register("foodPromotion")}
                            checked={watch("foodPromotion") ? true : false}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>

                  <Index.TableRow>
                    <Index.TableCell>Amount of the plan</Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            name="amount"
                            {...register("amount", {
                              required: "Amount of the plan is required",
                              pattern: {
                                value: /^\d*$/,
                                message: "Please enter only number",
                              },
                            })}
                            error={Boolean(errors.amount)}
                            helperText={errors.amount?.message}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>

                  <Index.TableRow>
                    <Index.TableCell>Duration of plan in days</Index.TableCell>

                    <Index.TableCell>
                      <Index.Box className="input-box subscription-modal-input-box">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            name="durationOfPlan"
                            {...register("durationOfPlan", {
                              required: "Duration of the plan is required",
                              pattern: {
                                value: /^\d*$/,
                                message: "Please enter only number",
                              },
                            })}
                            error={Boolean(errors.durationOfPlan)}
                            helperText={errors.durationOfPlan?.message}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>

          <Index.SubmitDiscardButton
            loading={loading}
            handleClose={props.handleClose}
          />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddSubscriptionManagement;
