import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Index from "../../../../../component/Index";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AddCalanederEvent from "./AddCalanederEvent";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
const localizer = momentLocalizer(Index.moment);

const CalenderSetting = () => {
  const [open, setOpen] = useState(false);
  const [eventdata, setEventData] = useState([]);
  const [editOrDeleteModal, setEditOrDeleteModal] = useState(false);
  const [eventRow, setEventRow] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    // borderRadius:"5px",
    p: 4,
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleEditOrDeleteOpen = () => {
    setEditOrDeleteModal(true);
  };

  const handleEditOrDeleteClose = () => {
    setEditOrDeleteModal(false);
  };

  const hanldeDeleteReocrd = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", eventRow?.id);
    await DataService.post(Api.Admin.deleteCalenderSetting, urlencoded).then(
      (res) => {
        if (res.data.status === 200) {
          handleDeleteClose();
          setEventRow("");
          getAllCalenderSetting();
          Index.toast.success("Evenet deleted successfully");
        }
      }
    ).catch((e)=>{
      Index.toast.error(e.response.data.message ? e.response.data.message : e.message );
    });
  };

  const getAllCalenderSetting = async () => {
    setLoading(true);
    await DataService.get(Api.Admin.getAllCalenderSetting)
      .then((res) => {
        if (res.data.status === 200) {
          setLoading(false);
          let list1 = res.data.data.map((item) => {
            return {
              title: item?.event_name,
              start: new Date(item?.start_date),
              end: new Date(item?.end_date),
              id: item?._id,
            };
          });
          setEventData(list1);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllCalenderSetting();
  }, []);

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box>
          <Index.Button
            variant="contained"
            className="add-event-btn"
            startIcon={<Index.AddIcon />}
            onClick={() => {
              handleOpen();
              setEventRow("");
            }}
          >
            Add Event
          </Index.Button>
          <Calendar
            localizer={localizer}
            events={eventdata}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={(e) => {
              setEventRow(e);
              handleEditOrDeleteOpen();
            }}
            style={{ height: 500, marginTop: "20px" }}
          />

          {open && (
            <AddCalanederEvent
              open={open}
              handleClose={handleClose}
              getAllCalenderSetting={getAllCalenderSetting}
              row={eventRow}
            />
          )}
          {deleteOpen && (
            <Index.DeleteModal
              deleteOpen={deleteOpen}
              handleDeleteClose={handleDeleteClose}
              hanldeDeleteReocrd={hanldeDeleteReocrd}
            />
          )}

          <Index.Modal
            open={editOrDeleteModal}
            onClose={handleEditOrDeleteClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Index.Box sx={style} className="calender-edit-delete-modal">
              <Index.Box
                className="close-btn-sec"
                onClick={handleEditOrDeleteClose}
              >
                <Index.CloseIcon />
              </Index.Box>
              <Index.Typography
                className="modal-title"
                id="modal-modal-title"
                variant="h6"
              >
                Are you sure want to change the event?
              </Index.Typography>

              <Index.Box className="edit-delete-btn-sec">
                <Index.Button
                  // color="primary"
                  className="edit-btn"
                  variant="contained"
                  onClick={() => {
                    handleEditOrDeleteClose();
                    handleOpen();
                  }}
                >
                  Edit
                </Index.Button>
                <Index.Button
                  color="error"
                  className="delete-btn"
                  variant="contained"
                  onClick={() => {
                    handleEditOrDeleteClose();
                    handleDeleteOpen();
                  }}
                >
                  Delete
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
        </Index.Box>
      )}
    </>
  );
};

export default CalenderSetting;
