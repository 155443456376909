import React, { useEffect } from "react";
import Index from "../../../../../../component/Index";
import { useForm } from "react-hook-form";
import DataService from "../../../../../../config/DataService";
import { Api } from "../../../../../../config/Api";

const Gst = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("is_gst", data.is_gst);
    urlencoded.append("gst_no", data.gstNumber);
    urlencoded.append("sgst", data.sgst);
    urlencoded.append("cgst", data.cgst);
    urlencoded.append("id", props.branchId);

    DataService.put(Api.Admin.addAndUpdateGSt, urlencoded)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          Index.toast.success(res.data.message);

          let resData = res.data.data;

          setValue("is_gst", resData?.is_gst ? resData?.is_gst : "");
          setValue("gstNumber", resData?.gst_no ? resData?.gst_no : "");
          setValue("sgst", resData?.sgst ? resData?.sgst : "");
          setValue("cgst", resData?.cgst ? resData?.cgst : "");

          props.getRestaurantData();
        }
      })
      .catch((e) => {
        Index.toast.error(e.response.data.message);
      });
  };

  useEffect(() => {
    setValue(
      "is_gst",
      props.restaurantDetail.is_gst ? props.restaurantDetail.is_gst : ""
    );
    setValue(
      "gstNumber",
      props.restaurantDetail.gst_no ? props.restaurantDetail.gst_no : ""
    );
    setValue(
      "sgst",
      props.restaurantDetail.sgst ? props.restaurantDetail.sgst : ""
    );
    setValue(
      "cgst",
      props.restaurantDetail.cgst ? props.restaurantDetail.cgst : ""
    );
  }, [props.restaurantDetail]);

  return (
    <>
      <Index.Box>
        <form onSubmit={handleSubmit(onSubmit)} className="gst-main-form">
          <Index.Box className="gst-row">
            <Index.Typography>IS_GST:</Index.Typography>
            <Index.Box className="input-gst">
              <Index.Checkbox
                name="is_gst"
                {...register("is_gst")}
                checked={watch("is_gst") ? true : false}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="gst-row">
            <Index.Typography>Gst number:</Index.Typography>
            <Index.Box className="input-gst">
              <Index.TextField
                name="gstNumber"
                {...register("gstNumber", {
                  required: "Gst number is required",
                })}
                helperText={errors.gstNumber?.message}
                error={Boolean(errors.gstNumber)}
                disabled={watch("is_gst") ? false : true}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="gst-row">
            <Index.Typography>SGST:</Index.Typography>
            <Index.Box className="input-gst">
              <Index.TextField
                name="sgst"
                {...register("sgst", {
                  required: "Sgst number is required",
                  pattern: {
                    value: /^[-+]?[0-9]+[.]?[0-9]*([eE][-+]?[0-9]+)?$/,
                    message: "Please enter only number",
                  },
                })}
                helperText={errors.sgst?.message}
                error={Boolean(errors.sgst)}
                disabled={watch("is_gst") ? false : true}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="gst-row">
            <Index.Typography>CGST:</Index.Typography>
            <Index.Box className="input-gst">
              <Index.TextField
                name="cgst"
                {...register("cgst", {
                  required: "Cgst number is required",
                  pattern: {
                    value: /^[-+]?[0-9]+[.]?[0-9]*([eE][-+]?[0-9]+)?$/,
                    message: "Please enter only number",
                  },
                })}
                helperText={errors.cgst?.message}
                error={Boolean(errors.cgst)}
                disabled={watch("is_gst") ? false : true}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="gst-row-btn">
            <Index.Button type="submit" variant="contained">
              Save
            </Index.Button>
          </Index.Box>
        </form>
      </Index.Box>
    </>
  );
};

export default Gst;
