import React from "react";
import MealordView from "./MealordView";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Index from "../../../../component/Index";
import RestaurantView from "./RestaurantView";
import { useEffect } from "react";
import PaymentReportSummary from "./paymentReportSummary/paymentReportSummary";

const TransactionHistory = () => {
  const [value, setValue] = React.useState(localStorage.getItem("transactionTabId")||"1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("transactionTabId",newValue)
  };

  // useEffect(()=>{
  //   return ()=>{
  //     localStorage.setItem("transactionTabId","1")
  //   } 
  // },[])
  return (
    <Index.Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Mealord View" value="1" />
            <Tab label="Restaurant View" value="2" />
            <Tab label="Report" value="3" />
          </TabList>
        </Index.Box>
        <TabPanel value="1" style={{ padding: "0px" }}>
          <MealordView />
        </TabPanel>
        <TabPanel value="2" style={{ padding: "0px" }}>
          <RestaurantView />
        </TabPanel>
         <TabPanel value="3" style={{ padding: "0px" }}>
          <PaymentReportSummary />
        </TabPanel> 
      </TabContext>
    </Index.Box>
  );
};

export default TransactionHistory;
