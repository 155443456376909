import React,{useEffect, useState} from 'react'
import Index from '../../../../../../component/Index';
import { Api } from '../../../../../../config/Api';
import DataService from '../../../../../../config/DataService';

const QrCode = (props) => {

  const [qrCodeImage, setQrCodeImage] = useState("");

  const GenerateQR = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("branchId", props?.branchId);

    await DataService.post(Api.Admin.generateQrCode, urlencoded)
      .then((response) => {
        console.log(response, 78);
        setQrCodeImage(response?.data?.data?.qr_url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(()=>{
  //   GenerateQR();
  // },[props.branchId])
  
  console.log(props.qrCode,67)
  return (
    <Index.Box className="branch-qr-code">
    <Index.Box>
      {qrCodeImage || props.qrCode ? (
        <>
        <Index.Box>
          <a href={props?.qrCodeLink} target='_blank'>{props?.qrCodeLink}</a>
        </Index.Box>
        <Index.Box className="reastuarant-Image-box">
          <img
            // src="https://images.pexels.com/photos/1579739/pexels-photo-1579739.jpeg?auto=compress&cs=tinysrgb&w=600"
            width="300px"
            height="300px"
            src={props.qrCode ? props.qrCode : qrCodeImage}
          />
        </Index.Box>
        </>
      ) : (
        <Index.Button onClick={GenerateQR} variant='contained' className='genrate-qr-code-btn'>
          Genrate QR Code
        </Index.Button>
      )}
    </Index.Box>
  </Index.Box>
  )
}

export default QrCode