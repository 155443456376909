import React, { useEffect, useState } from "react";
import Index from "../../../component/Index";
import { Api } from "../../../config/Api";
import DataService from "../../../config/DataService";
import AddSubscriptionManagement from "./AddSubscriptionManagement";

const SubscriptionManagement = () => {
  const [open, setOpen] = useState(false);
  const [subscriptionData, setSubcriptionData] = useState([]);
  const [subscriptionRow, setSubscriptionRow] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const hanldeDeleteReocrd = async () => {
    await DataService.get(
      `${Api.Admin.deleteSubscription}/${subscriptionRow?._id}`
    ).then((res) => {
      if (res.data.status === 200 || res.data.status === 201) {
        handleDeleteClose();
        setSubscriptionRow("");
        getAllSubscription();
        Index.toast.success("Subscription deleted successfully");
      }
    }).catch((e)=>{
      Index.toast.error(e.response.data.message ? e.response.data.message : e.message );
    });
  };

  
  const getAllSubscription = async () => {
    setLoading(true);
    await DataService.get(Api.Common.getAllSubscription).then((res) => {
      if (res.data.status === 200) {
        setSubcriptionData(res.data.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getAllSubscription();
  }, []);

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Subscription Management
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="view-btn-main border-btn-main">
                        <Index.Button
                          className="view-order-btn border-btn"
                          onClick={() => {
                            handleOpen();
                            setSubscriptionRow("");
                          }}
                        >
                          Add Plan
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main food-item-table subscription-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 1500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableBody>
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              ></Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td subscription-main-heading"
                                    >
                                      {row.name_of_plan}
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>

                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                Description of the plan
                              </Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.description_of_plan}
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>

                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                Free trial activation for 2 month
                              </Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Checkbox
                                        disabled
                                        checked={row.trial_activation_for_month}
                                      />
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>

                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                Qr scanner
                              </Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Checkbox
                                        disabled
                                        checked={row.qr_scanner}
                                      />
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>

                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                Captain Place Order
                              </Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Checkbox
                                        disabled
                                        checked={row?.place_order}
                                      />
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                Online order management for walkin
                              </Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Checkbox
                                        disabled
                                        checked={row.order_for_walkin}
                                      />
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>

                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                Inventry management
                              </Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Checkbox
                                        disabled
                                        checked={row.inventry_management}
                                      />
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>

                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                Compehensive payment management module
                              </Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Checkbox
                                        disabled
                                        checked={row.payment_management}
                                      />
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>

                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                Menu updation/ management
                              </Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Checkbox
                                        disabled
                                        checked={row.menu_updation}
                                      />
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>

                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                Table management
                              </Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Checkbox
                                        disabled
                                        checked={row.table_management}
                                      />
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>

                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                Report and analytics
                              </Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Checkbox
                                        disabled
                                        checked={row.report_analytics}
                                      />
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                Customer support
                              </Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Checkbox
                                        disabled
                                        checked={row.customer_support}
                                      />
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>

                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                Food promotion
                              </Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Checkbox
                                        disabled
                                        checked={row.food_promotion}
                                      />
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>

                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                Amount of the plan
                              </Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.amount_of_plan}
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                Duration of plan in days
                              </Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.duration_plan_days}
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              ></Index.TableCell>

                              {subscriptionData.map((row) => {
                                return (
                                  <>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Box>
                                        <Index.Button
                                          variant="contained"
                                          className="subcription-edit-plan"
                                          onClick={() => {
                                            setSubscriptionRow(row);
                                            handleOpen();
                                          }}
                                        >
                                          Update
                                        </Index.Button>
                                        ,
                                        <Index.Button
                                          variant="contained"
                                          className="subcription-delete-plan"
                                          onClick={() => {
                                            setSubscriptionRow(row);
                                            handleDeleteOpen();
                                          }}
                                        >
                                          Delete
                                        </Index.Button>
                                      </Index.Box>
                                    </Index.TableCell>
                                  </>
                                );
                              })}
                            </Index.TableRow>
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>

                      {open && (
                        <AddSubscriptionManagement
                          open={open}
                          handleClose={handleClose}
                          getAllSubscription={getAllSubscription}
                          row={subscriptionRow}
                        />
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {deleteOpen && (
        <Index.DeleteModal
          deleteOpen={deleteOpen}
          handleDeleteClose={handleDeleteClose}
          hanldeDeleteReocrd={hanldeDeleteReocrd}
        />
      )}
        </Index.Box>
      )}
    </>
  );
};

export default SubscriptionManagement;
