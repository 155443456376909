import React, { useState } from "react";
import Index from "../../../../../../component/Index";
import { useEffect } from "react";


const PastOrders = (props) => {
  const [orderList, setOrderList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterData, setFilterData] = useState([]);

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterData = (e) => {
    if (e.target.value === "") {
      setFilterData(orderList);
    } else {
      const result = orderList.filter((item) => {
        return item.branch_id.branch_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      });
      setFilterData(result);
      setPage(0);
    }
  };

  useEffect(() => {
    setOrderList(props.pastOrders);
    setFilterData(props.pastOrders);
  }, [props.pastOrders]);
  return (
    <Index.Box className="admin-dashboard-list-row">
      <Index.Box sx={{ width: 1 }} className="grid-main">
        <Index.Box
          display="grid"
          className="display-row"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column"
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="admin-dash-box">
                <Index.Box className="flex-all admin-dashboard-table-row">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h3"
                      variant="h3"
                    >
                      Past orders
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="user-search-main">
                    <Index.Box className="user-search-box">
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder="Search order"
                          onChange={(e) => handleFilterData(e)}
                        />

                        <img
                          src={Index.Svg.search}
                          className="search-grey-img"
                          alt="search grey img"
                        ></img>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="page-table-main food-item-table table-order-list-res">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table
                      sx={{ minWidth: 500 }}
                      aria-label="simple table"
                      className="table"
                    >
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Order Number
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Branch Name
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Date
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Order Type
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Time
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            align="center"
                          >
                            Persons
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            FoodItems
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Amount
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Status
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {filterData?.length > 0
                          ? filterData &&
                            paginate(filterData, rowsPerPage, page + 1).map(
                              (row, index) => (
                                <Index.TableRow
                                  key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {Index.displayValue(row?.order_number)}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {Index.displayValue(row?.branch_id?.branch_name)}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {Index.displayDateValue(row?.date)}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {Index.displayValue(row?.order_type)}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {Index.displayValue(row?.time)}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    {Index.displayNumberValue(row?.persons)}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {Index.displayNumberValue(
                                      row?.food_items?.length
                                    )}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {Index.displayNumberValueWithToFixed(
                                      row?.total_amount
                                    )}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                     {Index.displayValue(row?.booking_status)}
                                  </Index.TableCell>
                                </Index.TableRow>
                              )
                            )
                          : "No Data Found"}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filterData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Index.Box>
    </Index.Box>
  );
};

export default PastOrders;
