import React from 'react'
import Index from '../../../../../../component/Index'

const BankDetails = ({restaurantDetail}) => {

    console.log(restaurantDetail,"uv56")
  return (
    <div>
         <Index.Box className="page-table-main food-item-table">
            <Index.TableContainer component={Index.Paper} className='table-container'>
              <Index.Table sx={{ minWidth: 500 }} aria-label="simple table" className='table'>
                <Index.TableHead className='table-head'>
                  <Index.TableRow className='table-row'>
                    <Index.TableCell component='th' variant='th' className='table-th'>Account Name</Index.TableCell>
                    <Index.TableCell  component='th' variant='th' className='table-th'>Account No</Index.TableCell>
                    <Index.TableCell  component='th' variant='th' className='table-th'>IFSC Code</Index.TableCell>
                    
                  </Index.TableRow>
                </Index.TableHead>
                

                <Index.TableBody className="table-body">
                <Index.TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                          >
                            {restaurantDetail.account_name}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                          >
                            {restaurantDetail?.account_no}
                          </Index.TableCell>

                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td"
                          >
                            {restaurantDetail?.ifsc_code}
                          </Index.TableCell>

                        
                        </Index.TableRow>     
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>

           
          </Index.Box>
    </div>
  )
}

export default BankDetails