import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import {
  downloadPdfRepport,
  downloadReport,
} from "../../../../../common/functions";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const UserActiveness = () => {
  const [ordersData, setOrdersData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedType, setSelectedType] = useState("with-offer");

  const slotOptions = [
    {
      key: "with-offer",
      name: "With offer",
    },
    {
      key: "without-offer",
      name: "Without offer",
    },
  ];

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadReport = () => {
    const data = ordersData.map((row) => {
      let data = {
        "User Name": Index.displayValue(row?.userName),
        "Total orders": Index.displayNumberValue(row?.totalOrders),
        "Total order amount": Index.displayNumberValueWithToFixed(
          row?.totalOrderAmount
        ),
        "Average order amount": Index.displayNumberValueWithToFixed(
          row?.averageOrderAmount
        ),
      };
      if (
        row?.hasOwnProperty("offersMadeByAdmin") &&
        row?.hasOwnProperty("offersMadeByBranch")
      ) {
        data["Offer made by admin"] = Index.displayNumberValue(
          row?.offersMadeByAdmin
        );
        data["Offer made by branch"] = Index.displayNumberValue(
          row?.offersMadeByBranch
        );
      }
      return data;
    });
    downloadReport(data, "User activeness report");
  };

  const handleExportPDF = () => {
    const tableHeaders = [
      "User Name",
      "Total orders",
      "Total order amount",
      "Average order amount",
    ];
    if (selectedType === "with-offer") {
      tableHeaders.push("Offer made by admin", "Offer made by branch");
    }
    const tableData = ordersData.map((row) => [
      Index.displayValue(row?.userName),
      Index.displayNumberValue(row?.totalOrders),
      Index.displayNumberValueWithToFixed(row?.totalOrderAmount),
      Index.displayNumberValueWithToFixed(row?.averageOrderAmount),
      // This two line is only added when their headers are paresent
      Index.displayNumberValue(row?.offersMadeByAdmin),
      Index.displayNumberValue(row?.offersMadeByBranch),
    ]);
    downloadPdfRepport(tableHeaders, tableData, "User activeness report");
  };

  const getAllOrderList = async () => {
    setLoading(true);
    await DataService.post(Api.Admin.userActivenessReport, {
      type: selectedType,
    })
      .then((response) => {
        if (response.data.status === 200) {
          setOrdersData(response.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllOrderList();
  }, [selectedType]);
  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="view-btn-main border-btn-main filter-btn export-transaction-btn">
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!ordersData.length}
                      onClick={() => {
                        handleDownloadReport();
                      }}
                    >
                      Export
                    </Index.Button>
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!ordersData.length}
                      onClick={() => {
                        handleExportPDF();
                      }}
                    >
                      Export PDF
                    </Index.Button>
                  </Index.Box>

                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          User activeness report
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group table-head-date-main">
                            <Index.Box className="branch-name">
                              <Index.Typography>Type:</Index.Typography>
                              <Index.Select
                                value={selectedType}
                                displayEmpty
                                onChange={(e) =>
                                  setSelectedType(e.target.value)
                                }
                                className="form-control"
                              >
                                {slotOptions?.map((row) => {
                                  return (
                                    <Index.MenuItem value={row?.key}>
                                      {row?.name}
                                    </Index.MenuItem>
                                  );
                                })}
                              </Index.Select>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                User Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Total orders
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Total order amount
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Average order amount
                              </Index.TableCell>
                              {selectedType === "with-offer" && (
                                <>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    Admin offfer
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    Branch offer
                                  </Index.TableCell>
                                </>
                              )}
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {ordersData.length > 0
                              ? ordersData &&
                                paginate(ordersData, rowsPerPage, page + 1).map(
                                  (row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayValue(row?.userName)}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayNumberValue(
                                          row?.totalOrders
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayNumberValueWithToFixed(
                                          row?.totalOrderAmount
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayNumberValueWithToFixed(
                                          row?.averageOrderAmount
                                        )}
                                      </Index.TableCell>

                                      {selectedType === "with-offer" && (
                                        <>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                          >
                                            {Index.displayNumberValue(
                                              row?.offersMadeByAdmin
                                            )}
                                          </Index.TableCell>

                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                          >
                                            {Index.displayNumberValue(
                                              row?.offersMadeByBranch
                                            )}
                                          </Index.TableCell>
                                        </>
                                      )}
                                    </Index.TableRow>
                                  )
                                )
                              : "No Data Found"}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={ordersData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default UserActiveness;
