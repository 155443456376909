import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { useForm } from "react-hook-form";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const EditCuisines = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("cuisine", data.cuisine);
    //urlencoded.append('food_type', data.foodType);
    if (props.row) {
      urlencoded.append("id", props.row?._id);
    }

    await DataService.post(Api.Admin.addEditCuisine, urlencoded)
      .then((response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          setLoading(false);
          if (props.row) {
            Index.toast.success("Cuisine Updated successfully");
          } else {
            Index.toast.success("Cuisine Added successfully");
          }
          props.getAllCuisine();
          props.handleClose();
          reset();
        }
      })
      .catch((e) => {
        Index.toast.error(e.response.data.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    setValue("cuisine", props.row ? props?.row?.cuisine : "");
    //setValue('foodType', props.row ? props.row?.food_type?._id : '');
  }, [props.row]);

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            {props?.row ? "Edit" : "Add"} Cuisine
          </Index.Typography>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Cuisine
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="cuisine"
                {...register("cuisine", {
                  required: "Cuisine is required",
                })}
                error={Boolean(errors.cuisine)}
                helperText={errors.cuisine?.message}
              />
            </Index.Box>
          </Index.Box>
          {/* <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Food Type
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.Box className="dropdown-box">
                <Index.FormControl className="form-control">
                  <Controller
                    name="foodType"
                    control={control}
                    defaultValue={props?.row ? props?.row?.cuisine : ''}
                    {...register('foodType', {
                      required: 'Please select foodType',
                    })}
                    render={({ field: { onChange, value } }) => (
                      <Index.Select
                        sx={{
                          '& .MuiFormHelperText-root': {
                            ml: 0,
                            mt: '5px',
                          },
                        }}
                        defaultValue={props?.row ? props?.row?.cuisine : ''}
                        labelId="foodType"
                        error={Boolean(errors.foodType)}
                        className="dropdown-select"
                        id="foodType"
                        value={value}
                        onChange={onChange}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        {props.foodTypeData?.map((data) => (
                          <Index.MenuItem value={data._id}>
                            {data.type}
                          </Index.MenuItem>
                        ))}
                      </Index.Select>
                    )}
                  />
                </Index.FormControl>
                <Index.FormHelperText error id="accountId-error">
                  {errors.foodType?.message ? errors.foodType?.message : ''}
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
          </Index.Box> */}

          <Index.SubmitDiscardButton
            loading={loading}
            handleClose={props.handleClose}
          />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default EditCuisines;
