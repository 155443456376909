import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import { useForm } from "react-hook-form";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";

const AddCampaign = (props) => {
  const [editorData, setEditorData] = useState("");
  const [editorError, setEditorError] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const formdata = new URLSearchParams();
    if (props.row) {
      formdata.append("id", props.row._id);
    }
    formdata.append("campaign_name", data.name);
    formdata.append("campaign_time_period", data.time);
    formdata.append("campaign_price", data.amount);
    formdata.append("campaign_description", editorData);

    await DataService.post(Api.Admin.addCampaign, formdata)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          if (props.row) {
            Index.toast.success("Campaign updated successfully");
          } else {
            Index.toast.success("Campaign added successfully");
          }

          props.handleClose();
          reset();
          props.getAllCampaign();
          setLoading(false);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);

    if (data == "") {
      setEditorError("Details is required");
    } else if (data != "") {
      setEditorError("");
    }
  };

  useEffect(() => {
    setEditorData(
      props.row.campaign_description ? props.row.campaign_description : ""
    );
    setValue(
      "time",
      props.row.campaign_time_period ? props.row.campaign_time_period : ""
    );
    setValue("name", props.row.campaign_name ? props.row.campaign_name : "");
    setValue(
      "amount",
      props.row.campaign_price ? props.row.campaign_price : ""
    );
  }, []);

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            {props.row ? "Update Campaign" : "Add Campaign"}
          </Index.Typography>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="name"
                {...register("name", {
                  required: "Name is required",
                })}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Description
            </Index.FormHelperText>

            <Index.Box className="form-group">
              <Index.CKEditor
                editor={Index.ClassicEditor}
                data={editorData}
                onChange={handleEditorChange}
              />

              <Index.FormHelperText error>{editorError}</Index.FormHelperText>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Time Period
            </Index.FormHelperText>

            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="time"
                {...register("time", {
                  required: "time  is required",
                })}
                error={Boolean(errors.time)}
                helperText={errors.time?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Amount
            </Index.FormHelperText>

            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="amount"
                {...register("amount", {
                  required: "Amount is required",
                })}
                error={Boolean(errors.amount)}
                helperText={errors.amount?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.SubmitDiscardButton
            loading={loading}
            handleClose={props.handleClose}
          />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddCampaign;
