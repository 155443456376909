import React, { useState } from "react";
import { Api } from "../../../config/Api";
import DataService from "../../../config/DataService";
import { useForm } from "react-hook-form";
import Index from "../../../component/Index";

export default function ResetPassword() {
  let navigate = Index.useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const onSubmit = async (data) => {
    if (data.password != data.confirmPassword) {
      Index.toast.error("Password and confirm password should be same");
    } else {
      const userData = JSON.parse(localStorage.getItem("admin-details"));

      const urlencoded = new URLSearchParams();
      urlencoded.append("email", userData?.email);
      urlencoded.append("newPassword", data.password);

      await DataService.post(Api.Admin.resetPassword, urlencoded).then(
        (res) => {
          if (res.data.status === 201) {
            Index.toast.success(`${res.data.message}`);
            navigate("/login");
          }
        }
      ).catch((e)=>{
        Index.toast.error(e.response.data.message ? e.response.data.message : e.message );
      });
    }
  };
  return (
    <>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <Index.Box className="login-left-bg"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Reset Password
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                </Index.Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Index.Box className="input-box">
                    <Index.FormHelperText className="form-lable">
                      Password
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.OutlinedInput
                        className="form-control-eye"
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        {...register("password", {
                          required: "Password is required",
                        })}
                        error={Boolean(errors.password)}
                        helperText={errors.password?.message}
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Index.Visibility />
                              ) : (
                                <Index.VisibilityOff />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                      <Index.FormHelperText error id="accountId-error">
                        {errors.password?.message
                          ? errors.password?.message
                          : ""}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box">
                    <Index.FormHelperText className="form-lable">
                      Confirm Password
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.OutlinedInput
                        className="form-control-eye"
                        id="outlined-adornment-password"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        {...register("confirmPassword", {
                          required: "Confirm Password is required",
                        })}
                        error={Boolean(errors.confirmPassword)}
                        helperText={errors.confirmPassword?.message}
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Index.Visibility />
                              ) : (
                                <Index.VisibilityOff />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                      <Index.FormHelperText error id="accountId-error">
                        {errors.confirmPassword?.message
                          ? errors.confirmPassword?.message
                          : ""}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="btn-main-primary otp-btn-main">
                    <Index.Button className="btn-primary otp-btn" type="submit">
                      Reset
                    </Index.Button>
                  </Index.Box>
                </form>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
