import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { TablePagination } from "@mui/material";
import EditRestaurant from "./EditRestaurant";

const RestaurantMangement = () => {
  const navigate = Index.useNavigate();
  const [RestaurantList, setRestaurantList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterData, setFilterData] = useState([]);
  const [open, setOpen] = useState(false);
  const [restaurantRow, setRestaurantRow] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  let role = localStorage.getItem("role-id");

  const getAllRestaurantList = async () => {
    setLoading(true);
    await DataService.get(Api.Common.getRestaurantList)
      .then((response) => {
        if (response.data.status === 200) {
          setRestaurantList(response.data.data);
          setFilterData(response.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getAllRestaurantList();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleFilterData = (e) => {
    if (e.target.value === "") {
      setFilterData(RestaurantList);
    } else {
      const result = RestaurantList.filter((item) => {
        return (
          item?.restaurant_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          item?.owner_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          item?.email?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
          item?.mobile_number
            ?.toString()
            .toLowerCase()
            .includes(e.target.value?.toLowerCase())
        );
      });

      setFilterData(result);
      setPage(0);
    }
  };

  const hanldeDeleteReocrd = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", restaurantRow?._id);
    await DataService.post(Api.Admin.deleteRestaurant, urlencoded).then(
      (res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          handleDeleteClose();
          setRestaurantRow("");
          getAllRestaurantList();
          Index.toast.success("Restaurant deleted successfully");
        }
      }
    );
  };

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="flex-all admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Restaurant Management
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="view-btn-main border-btn-main">
                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Search resturant"
                              onChange={(e) => handleFilterData(e)}
                            />

                            <img
                              src={Index.Svg.search}
                              className="search-grey-img"
                              alt="search grey img"
                            ></img>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="page-table-main res-managment food-item-table">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 500 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Date
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Owner
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Restaurant
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Email
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Phone
                            </Index.TableCell>

                            {/* <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th th-lo-lu"
                        >
                          Verified
                        </Index.TableCell> */}
                         
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>
                          
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filterData.length > 0 ? (
                            (rowsPerPage > 0
                              ? filterData.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                              : filterData
                            ).map((row, index) => {
                              let createdDate = Index.moment(
                                row?.createdAt
                              ).format("L");
                              let date = createdDate.split("T")[0];

                              return (
                                <>
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {date}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.owner_name ? row?.owner_name : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.restaurant_name
                                        ? row?.restaurant_name
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.email ? row?.email : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.mobile_number
                                        ? row?.mobile_number
                                        : "-"}
                                    </Index.TableCell>
                                    {/* <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                 {
                                  
                                    row.is_verified === 1 ? <Index.VerifiedIcon color="success"/> : <Index.CancelIcon color="error"/> 
                                 
                                 }
                                </Index.TableCell> */}

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Button
                                        className="table-btn"
                                        onClick={() => {
                                          navigate(
                                            `/restaurant/details/${row?._id}`
                                          );
                                        }}
                                      >
                                        <img
                                          src={Index.Svg.yelloweye}
                                          alt="view-icon"
                                        ></img>
                                      </Index.Button>
                                      {role === "0" ? (
                                        <>
                                          <Index.Button
                                            className="table-btn"
                                            onClick={() => {
                                              handleOpen();
                                              setRestaurantRow(row);
                                            }}
                                          >
                                            <img
                                              src={Index.Svg.blueedit}
                                              alt="edit-icon"
                                            ></img>
                                          </Index.Button>
                                          <Index.Button
                                            className="table-btn"
                                            onClick={() => {
                                              handleDeleteOpen();
                                              setRestaurantRow(row);
                                            }}
                                          >
                                            <img
                                              src={Index.Svg.trash}
                                              alt="delete-icon"
                                            ></img>
                                          </Index.Button>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </>
                              );
                            })
                          ) : (
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                No Data Found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}

                          <Index.TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TablePagination
                              rowsPerPageOptions={[
                                5,
                                10,
                                15,
                                { label: "All", value: filterData.length },
                              ]}
                              colSpan={8}
                              count={filterData.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              // ActionsComponent={TablePaginationActions}
                            />
                          </Index.TableRow>
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>

                    {open && (
                      <EditRestaurant
                        open={open}
                        handleClose={handleClose}
                        row={restaurantRow}
                        getAllRestaurantList={getAllRestaurantList}
                      />
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Modal
            open={deleteOpen}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete"
          >
            <Index.Box
              sx={Index.Constant.MODAL_STYLE}
              className="delete-modal-inner-main"
            >
              <Index.Box className="modal-circle-main">
                <img
                  src={Index.Svg.closecircle}
                  className="user-circle-img"
                  alt="close-icon"
                />
              </Index.Box>
              <Index.Typography
                className="delete-modal-title"
                component="h2"
                variant="h2"
              >
                Are you sure?
              </Index.Typography>
              <Index.Typography
                className="delete-modal-para common-para"
                component="p"
                variant="p"
              >
                Do you really want to delete these records? This process cannot
                be undone.
              </Index.Typography>
              <Index.Box className="delete-modal-btn-flex">
                <Index.Button
                  className="modal-cancel-btn modal-btn"
                  onClick={handleDeleteClose}
                >
                  Cancel
                </Index.Button>
                <Index.Button
                  className="modal-delete-btn modal-btn"
                  onClick={hanldeDeleteReocrd}
                >
                  Delete
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
        </Index.Box>
      )}
    </>
  );
};

export default RestaurantMangement;
