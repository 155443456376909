import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Index from "../../../../component/Index";
import { Api } from "../../../../config/Api";
import DataService from "../../../../config/DataService";
import { toast } from "react-toastify";

const AddUser = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [loading,setLoading] = useState(false)

  const onSubmit = async (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("name", data.name);
    urlencoded.append("email", data.email);
    urlencoded.append("mobile_number", data.mobile);
    props.row && urlencoded.append("id", props?.row?._id);

    setLoading(true)
    await DataService.post(Api.Admin.createAdminUser, urlencoded).then(
      (response) => {
        if (response.data.success === 200 || response.data.success === 201) {
          if (props.row) {
            toast.success("User updated successfully");
          } else {
            toast.success("User addedd successfully");
          }
          setLoading(false)
          props.getAllUserList();
          props.handleClose();
          reset();
        }
      }
    ).catch((e)=>{
      Index.toast.error(e.response.data.message);
      setLoading(false)
    });
  };

  useEffect(() => {
    if (props.row) {
      setValue("name", props.row.name);
      setValue("email", props.row.email);
      setValue("mobile", props.row.mobile_number);
    }
  }, []);

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            {props?.row ? "Edit" : "Add"} User
          </Index.Typography>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="name"
                {...register("name", {
                  required: "Name is required",
                })}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Email
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Mobile No
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="mobile"
                {...register("mobile", {
                  required: "Mobile number is required",
                  pattern: {
                    value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                    message: "Please enter a valid mobile number",
                  },
                })}
                error={Boolean(errors.mobile)}
                helperText={errors.mobile?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.SubmitDiscardButton loading={loading} handleClose={props.handleClose}  />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddUser;
