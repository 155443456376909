import React, { useState } from "react";
import Index from "../../../../../component/Index";
import { useForm } from "react-hook-form";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const AddTableConfiguration = (props) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("no_of_dinners", data.NoOfDinners);
    if (props.row) {
      urlencoded.append("id", props.row._id);
    }

    await DataService.post(Api.Admin.addEditTable, urlencoded).then(
      (response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          setLoading(true);
          Index.toast.success(response.data.message);
          props.getTableData();
          props.handleClose();
          reset();
        }
      }
    );
  };

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Add / Edit Table Configuration
          </Index.Typography>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              No of Dinners
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="NoOfDinners"
                defaultValue={props.row ? props?.row?.no_of_dinners : ""}
                {...register("NoOfDinners", {
                  required: "No Of Dinners  is required",
                })}
                error={Boolean(errors.NoOfDinners)}
                helperText={errors.NoOfDinners?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.SubmitDiscardButton
            handleClose={props.handleClose}
            loading={loading}
          />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddTableConfiguration;
