import React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import AddUser from "../container/admin/pages/adduser/AddUser";
import Dashboard from "../container/admin/pages/dashboard/Dashboard";
import Login from "../container/admin/auth/Login";
import EditProfile from "../container/admin/pages/editprofile/EditProfile";
import UserList from "../container/admin/pages/userlist/UserList";
import ForgotPassword from "../container/admin/auth/ForgotPassword";
import ResetPassword from "../container/admin/auth/ResetPassword";
import OtpVerify from "../container/admin/auth/OtpVerify";
// import AddUser from '../container/admin/pages/adduser/AddUser';
import "react-toastify/dist/ReactToastify.css";
import CampaignManagement from "../container/admin/pages/campaignManagement/CampaignManagement";

import Home from "../container/admin/pages/home/Home";
import FoodType from "../container/admin/pages/foodItemManagement/foodType/FoodType";
import QtyUnits from "../container/admin/pages/foodItemManagement/qty/QtyUnits";
import NeglectWords from "../container/admin/pages/foodItemManagement/neglectWords/NeglectWords";
import Cuisines from "../container/admin/pages/foodItemManagement/cuisines/Cuisines";
import BookingConfiguration from "../container/admin/pages/generalConfiguration/booking/BookingConfiguration";
import TableConfiguration from "../container/admin/pages/generalConfiguration/table/TableConfiguration";
import CancelationPolicy from "../container/admin/pages/generalConfiguration/policy/CancelationPolicy";
import Category from "../container/admin/pages/foodItemManagement/category/Category";
import RestaurantManagement from "../container/admin/pages/restaurantManagement/RestaurantManagement";
import SpiceLevel from "../container/admin/pages/foodItemManagement/spiceLevel/SpiceLevel";
import RequestForm from "../container/admin/pages/requestFromManagement/RequestForm";
import Selecttion from "../container/admin/pages/foodItemManagement/selection/Selecttion";
import FoodItem from "../container/admin/pages/foodItemManagement/foodItem/FoodItem";
import RestaurantDetail from "../container/admin/pages/restaurantManagement/RestaurantDetail";
import BranchDetail from "../container/admin/pages/restaurantManagement/branch/BranchDetail";
import { Restaurent } from "../container/user/pages/restaurent/Restaurent";
import { ProductDetail } from "../container/user/pages/productDetail/ProductDetail";
import { CheckOut } from "../container/user/pages/checkOut/CheckOut";
import QrOrderList from "../container/admin/pages/qrCodeOrders/QrCodeOrders";
import OrderWalkinList from "../container/admin/pages/orderList/OrderWalkinList";
import OrderTableList from "../container/admin/pages/orderList/OrderTableList";
import EndUserList from "../container/admin/pages/userlist/EndUserList";
import SuggestionManagement from "../container/admin/pages/suggestionManagement/SuggestionManagement";
import OfferManagement from "../container/admin/pages/offerManagement/OfferManagement";
import Cms from "../container/admin/pages/cms/Cms";
import Restaurant from "../container/admin/pages/cms/Restaurant/Restaurant";
import AddOffer from "../container/admin/pages/offerManagement/AddOffer";
import AboutUs from "../container/admin/pages/cms/aboutUs/AboutUs";
import Offer from "../container/admin/pages/cms/offer/Offer";
import Features from "../container/admin/pages/cms/features/Features";
import User from "../container/admin/pages/cms/user/User";
import FAQs from "../container/admin/pages/cms/faqs/FAQs";
import Team from "../container/admin/pages/cms/team/Team";
import Promo from "../container/admin/pages/cms/promo/Promo";
import SubscriptionManagement from "../container/admin/subscriptionManagement/SubscriptionManagement";
import SubscriberList from "../container/admin/subscriptionManagement/SubscriberList";
import SocialMedia from "../container/admin/pages/cms/socialMedia/SocialMedia";
import PrivacyPolicy from "../container/admin/pages/cms/privacyPolicy/PrivacyPolicy";
import TermsAndCondition from "../container/admin/pages/cms/termsAndCondition/TermsAndCondition";
import ContactUs from "../container/admin/pages/cms/contactUs/ContactUs";
import UserQueries from "../container/admin/pages/userQueries/UserQueries";
import Subadmin from "../container/admin/pages/subadmin/Subadmin";
import PrivateRoutes from "./PrivateRoutes";
import CampaignSubscriberList from "../container/admin/pages/campaignManagement/SubscriberList";
import UserInquiries from "../container/admin/pages/userInquiries/userInquiries";
import FeaturedRestaurant from "../container/admin/pages/cms/featuredRestaurant/featuredRestaurant";
import CalenderSetting from "../container/admin/pages/generalConfiguration/calender/CalenderSetting";
import UserWalletList from "../container/admin/pages/userlist/userWallet/UserWalletList";
import Notification from "../container/admin/pages/cms/notification/Notification";
import GstConfiguration from "../container/admin/pages/generalConfiguration/gst/GstConfiguration";
import LocationConfiguration from "../container/admin/pages/generalConfiguration/location/LocationConfiguration";
import PaneltyList from "../container/admin/pages/paneltyList/PaneltyList";
import MassNotifications from "../container/admin/pages/massNotifications/MassNotifications";
import TransactionHistory from "../container/admin/pages/transactionHistory/TransactionHistory";
import DeleteAccountRequest from "../container/admin/pages/deleteAccountRequest/DeleteAccountRequest";
import ViewSingleUserDetails from "../container/admin/pages/userlist/viewSingleUser/ViewSingleUserDetails";
import SingleBranchPaymentSummary from "../container/admin/pages/transactionHistory/paymentReportSummary/SingleBranchPaymentSummary";
import Notifications from "../container/admin/pages/notification/Notifications";
import DashboardReport from "../container/admin/pages/dashboard/DashboardReport";
import Bookings from "../container/admin/pages/reportsAndAnalysis/bookings/Bookings";
import SlotWiseOrders from "../container/admin/pages/reportsAndAnalysis/orders/SlotWiseOrders";
import MoneyView from "../container/admin/pages/reportsAndAnalysis/moenyView/MoneyView";
import CityWiseBranchPromotion from "../container/admin/pages/reportsAndAnalysis/cityWiseBranchPromotion/CityWiseBranchPromotion";
import BranchPromotion from "../container/admin/pages/reportsAndAnalysis/branchPromotion/BranchPromotion";
import FeaturedVsNonFeatured from "../container/admin/pages/reportsAndAnalysis/featuredVsNonFeatured/FeaturedVsNonFeatured";
import PerformingRestaurant from "../container/admin/pages/reportsAndAnalysis/performingRestaurant/PerformingRestaurant";
import OrderRating from "../container/admin/pages/reportsAndAnalysis/orderRating/OrderRating";
import UserActiveness from "../container/admin/pages/reportsAndAnalysis/userActiveness/UserActiveness";
import RegionWiseOrderReport from "../container/admin/pages/reportsAndAnalysis/regionWiseOrder/RegionWiseOrderReport";
import CuisineAndCategoryReport from "../container/admin/pages/reportsAndAnalysis/cuisineAndCategory/CuisineAndCategoryReport";
import AverageOrderPerDay from "../container/admin/pages/reportsAndAnalysis/avgOrderPerDay/AverageOrderPerDay";
const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="otp-verify" element={<OtpVerify />} />
        <Route path="restaurent/:branchId" element={<Restaurent />} />
        <Route path="product-detail/:productId" element={<ProductDetail />} />
        <Route path="checkout" element={<CheckOut />} />

        <Route element={<PrivateRoutes />}> 
          <Route path="/" element={<Dashboard />}>
            {/* <Route path="dashboard" element={<Home />} /> */}
            <Route path="" element={<Home />} />
            <Route path="dashboard-reports" element={<DashboardReport />} />
            <Route path="add-user" element={<AddUser />} />
            <Route path="edit-profile" element={<EditProfile />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="table-demo" element={<UserList />} />
            <Route path="user-list" element={<EndUserList />} />
            <Route path="single-user-details/:id" element={<ViewSingleUserDetails />} />
            <Route path="user-wallet" element={<UserWalletList/>} />
            <Route path="qr-order-list" element={<QrOrderList />} />
            <Route path="/food-item-management">
              <Route path="food-type" element={<FoodType />} />
              <Route path="qty-units" element={<QtyUnits />} />
              <Route path="cuisines" element={<Cuisines />} />
              <Route path="neglect-words" element={<NeglectWords />} />
              <Route path="food-item" element={<FoodItem />} />
              <Route path="category" element={<Category />} />
              <Route path="spice-level" element={<SpiceLevel />} />
              <Route path="selection" element={<Selecttion />} />
            </Route>

            <Route path="/report-analysis">
              <Route path="bookings" element={<Bookings/>} />
              <Route path="slot-wise-orders" element={<SlotWiseOrders/>} />
              <Route path="money-view" element={<MoneyView/>} />
              <Route path="city-wise-branch-promotion" element={<CityWiseBranchPromotion/>} />
              <Route path="branch-promotion" element={<BranchPromotion/>} />
              <Route path="featured-vs-non-featured" element={<FeaturedVsNonFeatured/>} />
              <Route path="restaurant-performance" element={<PerformingRestaurant/>} />
              <Route path="orders-rating" element={<OrderRating/>} />
              <Route path="user-activeness" element={<UserActiveness/>} />
              <Route path="region-orders" element={<RegionWiseOrderReport/>} />
              <Route path="cuisine-category" element={<CuisineAndCategoryReport/>} />
              <Route path="average-orders" element={<AverageOrderPerDay/>} />
            </Route>

            <Route path="/reuqest-form-management" element={<RequestForm />} />
            <Route path="/general">
              <Route path="booking" element={<BookingConfiguration />} />
              <Route path="table" element={<TableConfiguration />} />
              <Route path="calender-setting" element={<CalenderSetting/>} />
              <Route path="gst" element={<GstConfiguration/>} />
              <Route path="location-update" element={<LocationConfiguration/>} />
              <Route
                path="policy-cancelation"
                element={<CancelationPolicy />}
              />
            </Route>
            <Route path="/restaurant">
              <Route path="" element={<RestaurantManagement />} />
              <Route path="details/:id" element={<RestaurantDetail />} />
              <Route path="branchDetails/:id" element={<BranchDetail />} />
            </Route>
            <Route path="order-walkin-list" element={<OrderWalkinList />} />
            <Route path="order-table-list" element={<OrderTableList />} />
            <Route
              path="suggestion-management"
              element={<SuggestionManagement />}
            />
            <Route path="offer-management" element={<OfferManagement />} />
            <Route path="add-offer" element={<AddOffer />} />

            <Route path="/content-management">
              <Route path="" element={<Cms />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="add-user" element={<AddUser />} />
              <Route path="restaurant" element={<Restaurant />} />
              <Route path="user" element={<User/>} />
              <Route path="offers" element={<Offer />} />
              <Route path="features" element={<Features />} />
              <Route path="faqs" element={<FAQs />} />
              <Route path="team" element={<Team />} />
              <Route path="promo" element={<Promo />} />
              <Route path="social-links" element={<SocialMedia />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="featured-restaurant" element={<FeaturedRestaurant/>} />
              <Route path="notification" element={<Notification/>} />
              <Route
                path="terms-and-condition"
                element={<TermsAndCondition />}
              />
              <Route path="contact-us" element={<ContactUs />} />
            </Route>
            <Route
              path="subscription-management"
              element={<SubscriptionManagement />}
            />
            <Route path="subscriber-list" element={<SubscriberList />} />
            <Route path="user-queries" element={<UserQueries />} />
            <Route path="user-inquiries" element={<UserInquiries/>} />
            <Route path="sub-admin" element={<Subadmin />} />
            <Route path="panelty-list" element={<PaneltyList />} />
            <Route path="mass-notifications" element={<MassNotifications />} />
            <Route path="transaction-history" element={<TransactionHistory />} />
            <Route path="/payment-report/:branchId/:startDate/:endDate" element={<SingleBranchPaymentSummary/>} />
            <Route path="delete-account-request" element={<DeleteAccountRequest/>} />
            <Route path="/campaign-management" >
              <Route path="" element={<CampaignManagement />} />
              <Route path="subscriber-list" element={<CampaignSubscriberList />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
