import React from 'react';
import Index from '../../../../component/Index';
import { toast } from 'react-toastify';
import { Api } from '../../../../config/Api';
import DataService from '../../../../config/DataService';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

export default function AddUser() {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const [age, setAge] = React.useState('');

  const handleChangedropdown = (event) => {
    setAge(event.target.value);
  };

  const onSubmit = async (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append('1', data.userName);
    urlencoded.append('2', data.code);
    urlencoded.append('3', data.bulkDiscountPrice);
    urlencoded.append('4', data.taxRate);
    urlencoded.append('5', data.SKU);
    urlencoded.append('6', data.priceLimit);
    urlencoded.append('7', data.description);

    await DataService.post(Api.Admin.resetPassword, urlencoded).then((res) => {
      if (res.data.status === 201) {
        toast.success(`${res.data.message}`);
        navigate('/login');
      }
    });
  };

  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Typography
          className="admin-page-title"
          component="h2"
          variant="h2"
        >
          Add User
        </Index.Typography>
        <Index.Box className="page-border">
          <Index.Typography
            className="common-para add-basic-text"
            component="p"
            variant="p"
          >
            Basic Information
          </Index.Typography>
          <Index.Typography
            className="common-para add-section-text"
            component="p"
            variant="p"
          >
            Section to config basic product information
          </Index.Typography>
          <Index.Box className="add-user-data-main">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 6',
                    md: 'span 6',
                    lg: 'span 6',
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      User Name
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        sx={{
                          '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                        }}
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        name="userName"
                        {...register('userName', {
                          required: 'User Name is required',
                        })}
                        error={Boolean(errors.userName)}
                        helperText={errors.userName?.message}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 6',
                    md: 'span 6',
                    lg: 'span 6',
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Code
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        sx={{
                          '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                        }}
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        name="code"
                        {...register('code', {
                          required: 'Code is required',
                        })}
                        error={Boolean(errors.code)}
                        helperText={errors.code?.message}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 6',
                    md: 'span 6',
                    lg: 'span 6',
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Bulk Discount Price
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        sx={{
                          '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                        }}
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        name="bulkDiscountPrice"
                        {...register('bulkDiscountPrice', {
                          required: 'Bulk Discount Price is required',
                        })}
                        error={Boolean(errors.bulkDiscountPrice)}
                        helperText={errors.bulkDiscountPrice?.message}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 6',
                    md: 'span 6',
                    lg: 'span 6',
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Tax Rate(%)
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        sx={{
                          '& .MuiFormHelperText-root': { ml: 0, mt: '5px' },
                        }}
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        name="taxRate"
                        {...register('taxRate', {
                          required: 'Tax Rate(%) is required',
                        })}
                        error={Boolean(errors.taxRate)}
                        helperText={errors.taxRate?.message}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 6',
                    md: 'span 6',
                    lg: 'span 6',
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      SKU
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.Box className="dropdown-box">
                        <Index.FormControl className="form-control">
                          <Controller
                            name="SKU"
                            control={control}
                            defaultValue=""
                            {...register('SKU', {
                                  required: 'Please select SKU',
                                })}
                            render={({ field: { onChange, value } }) => (
                              <Index.Select
                                sx={{
                                  '& .MuiFormHelperText-root': {
                                    ml: 0,
                                    mt: '5px',
                                  },
                                }}
                                defaultValue="1"
                                labelId="SKU"
                                error={Boolean(errors.SKU)}
                                className="dropdown-select"
                                id="SKU"
                                value={value}
                                onChange={onChange}
                                inputProps={{ 'aria-label': 'Without label' }}
                               
                              >
                                <Index.MenuItem
                                  value={'10'}
                                  className="menuitem"
                                >
                                  10
                                </Index.MenuItem>
                                <Index.MenuItem
                                  value={'20'}
                                  className="menuitem"
                                >
                                  20
                                </Index.MenuItem>
                                <Index.MenuItem
                                  value={'30'}
                                  className="menuitem"
                                >
                                  30
                                </Index.MenuItem>
                                <Index.MenuItem
                                  value={'40'}
                                  className="menuitem"
                                >
                                  40
                                </Index.MenuItem>
                              </Index.Select>
                            )}
                          />
                          {/* <span><img src={Svg.greydown} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                        </Index.FormControl>
                        <Index.FormHelperText error id="accountId-error">
                          {errors.SKU?.message ? errors.SKU?.message : ''}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 6',
                    md: 'span 6',
                    lg: 'span 6',
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input radio-main">
                    <Index.FormHelperText className="form-lable">
                      Price Limit
                    </Index.FormHelperText>
                    <Index.FormControl className="form-control">
                      <Controller
                        name="priceLimit"
                        control={control}
                        defaultValue=""
                            {...register('priceLimit', {
                              required: 'Please select Price Limit',
                            })}
                        render={({ field: { onChange, value } }) => (
                          <Index.RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            className="radiogroup"
                            defaultValue=""
                            labelId="priceLimit"
                            id="priceLimit"
                            value={value}
                            onChange={onChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                        //     {...register('priceLimit', {
                        //       required: 'Please select Price Limit',
                        //     })}
                          >
                            <Index.FormControlLabel
                              value="$200k"
                              control={<Index.Radio className="radio" />}
                              label="$200k"
                            />
                            <Index.FormControlLabel
                              value="$100k"
                              control={<Index.Radio className="radio" />}
                              label="$100k"
                            />
                            <Index.FormControlLabel
                              value="$300k"
                              control={<Index.Radio className="radio" />}
                              label="$300k"
                            />
                          </Index.RadioGroup>
                        )}
                      />
                    </Index.FormControl>
                    
                    <Index.FormHelperText error id="accountId-error">
                    {String(errors['priceLimit']?.message ?? "")}
                    </Index.FormHelperText>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 12',
                    md: 'span 12',
                    lg: 'span 12',
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Description
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      {/* <Index.TextareaAutosize
                      aria-label="minimum height"
                      // minRows={3}
                      placeholder=""
                      className="form-control-textarea"
                    /> */}
                      <Index.TextareaAutosize
                        aria-label="minimum height"
                        // minRows={3}
                        placeholder=""
                        error={Boolean(errors.description)}
                        className="form-control-textarea"
                        name="description"
                        
                        type="text"
                        {...register(`description`, {
                          required: 'Please enter Description',
                        })}
                      />
                      <Index.FormHelperText error id="accountId-error">
                        {errors.description?.message
                          ? errors.description?.message
                          : ''}
                      </Index.FormHelperText>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: 'span 12',
                    sm: 'span 12',
                    md: 'span 12',
                    lg: 'span 12',
                  }}
                  className="grid-column"
                >
                  <Index.Box className="user-btn-flex">
                    <Index.Box className="discard-btn-main border-btn-main">
                      <Index.Button className="discard-user-btn border-btn">
                        Discard
                      </Index.Button>
                    </Index.Box>
                    <Index.Box className="save-btn-main border-btn-main">
                      <Index.Button
                        className="save-user-btn border-btn"
                        type="submit"
                      >
                        <img
                          src={Index.Svg.save}
                          className="user-save-icon"
                        ></img>
                        Save
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </form>
  );
}
