const USER = 'user';
const ADMIN = 'admin';
const COMMON = 'common'
const RESTAURANT = 'restaurant'
export const Api = {
  Admin: {
    login: `${ADMIN}/admin-login`,
    addCampaign: `${ADMIN}/add-edit-campaign`,
    deleteCampaign: `${ADMIN}/delete-campaign`,
    forgotPassword: `${ADMIN}/forgot-password`,
    resetPassword: `${ADMIN}/reset-password`,
    verifyOtp: `${ADMIN}/verify-otp`,
    addEditGeneralSetting : `${ADMIN}/add-edit-general-setting`,
    getGeneralSetting : `${ADMIN}/get-general-setting`,
    addEditFoodType:`${ADMIN}/add-edit-food-type`,
    deleteFoodType:`${ADMIN}/delete-food-type`,
    addEditCuisine:`${ADMIN}/add-edit-cuisine`,
    deleteCuisine:`${ADMIN}/delete-cuisine`,
    deleteQtyUnit:`${ADMIN}/delete-quantity`,  
    addEditQty:`${ADMIN}/add-edit-quantity`,
    addEditNeglectWords:`${ADMIN}/add-edit-neglect`,
    deleteNeglectWord:`${ADMIN}/delete-neglect`,
    addEditTable: `${ADMIN}/add-edit-table`,
    deleteTable: `${ADMIN}/delete-table` ,
    addEditCancellation : `${ADMIN}/add-edit-cancellation`,
    deleteCancellation : `${ADMIN}/delete-cancellation`,
    getCancellation: `${ADMIN}/get-cancellation`,
    addEditCategory: `${ADMIN}/add-edit-category`,
    deleteCategory: `${ADMIN}/delete-category`,
    getRestaurantList: `${ADMIN}/get-restaurant-list`,
    deleteSpiceLevel: `${ADMIN}/delete-spicy-level`,
    addEditSpicyLevel: `${ADMIN}/add-edit-spicy-level`,
    getAllRequestFrom : `${ADMIN}/get-restaurant-food-item`,
    updateRequestFrom : `${ADMIN}/update-request-by-admin`, 
    addEditSelection : `${ADMIN}/add-edit-selection`,
    deleteSelection : `${ADMIN}/delete-selection`, 
    addEditFoodItem: `${ADMIN}/add-edit-food-item`,
    deleteFoodItem: `${ADMIN}/delete-food-item`,
    getResturantDetail: `${ADMIN}/get-branch-list`,
    branchApprove: `${ADMIN}/approve-branch`,
    branchReject : `${ADMIN}/reject-branch`,
    generateQrCode : `${ADMIN}/generate-qr-code-branch`,
    QrlistOrder: `${ADMIN}/list-qr-code-order`,
    getAllWalkinOrderList : `${ADMIN}/get-order-walk-in`,
    getAllTableOrderList : `${ADMIN}/get-order-table`, 
    getAllUserList:`${ADMIN}/get-user-list`,
    createAdminUser:`${ADMIN}/user-create`,
    updateProfile:`${ADMIN}/edit-profile`,
    adminChangePassword : `${ADMIN}/change-password`,
    addEditSubscriptionManagement:`${ADMIN}/add-edit-subscription`,
    deleteSubscription:`${ADMIN}/delete-subscription`,
    subscriberList:`${ADMIN}/subscriber-list`,
    addEditAdminOffer:`${ADMIN}/add-edit-offer`,
    getAllAdminOffer:`${ADMIN}/getall-offer`,
    deleteAdminOffer:`${ADMIN}/delete-offer`,
    addEditFaqs:`${ADMIN}/add-edit-faqs`,
    deleteFaqs:`${ADMIN}/delete-faqs`,
    addEditAboutus:`${ADMIN}/about-us`,
    addEditTeam:`${ADMIN}/add-edit-team`,
    deleteTeam:`${ADMIN}/delete-team`,
    addEditPromo:`${ADMIN}/add-edit-promo`,
    deletePromo:`${ADMIN}/delete-promo`,
    addEditSocial:`${ADMIN}/add-edit-social`,
    addEditAdminFeature:`${ADMIN}/add-edit-adminfeature`,
    deleteAdminFeature:`${ADMIN}/delete-adminfeature`,
    addEditPrivacyPolicy:`${ADMIN}/add-edit-privacypolicy`,
    addEditTermsAndCondition:`${ADMIN}/add-edit-termsandcondition`,
    addEditContactus:`${ADMIN}/add-edit-contactus`,
    getAllUserQueries:`${ADMIN}/getAll-usercontactus`,
    orderByBranchId:`${ADMIN}/qr-order-by-branch`,
    foodItemsByBranchId:`${ADMIN}/get-food-item`,
    getRestaurantListing: `${ADMIN}/get-list-restaurant-app`,
    editRestaurantData: `${ADMIN}/add-edit-restaurant-app-details`,
    approveRejectSubsctiberPlan : `${ADMIN}/approve-subscriber-plan`,
    createSubAdmin:`${ADMIN}/create-sub-admin`,
    getAllSubAdmin:`${ADMIN}/list-sub-admin`,
    deleteSubAdmin:`${ADMIN}/delete-sub-admin`,
    editRestaurant:`${ADMIN}/update-user-restaurant`,
    editBranch:`${ADMIN}/update-branch`,
    deleteRestaurant:`${ADMIN}/delete-user-restaurant`,
    getAllCampaign: `${ADMIN}/get-list-campaign`,
    campaignSubcriberList: `${ADMIN}/campaign-subscriber-list`,
    addEditCmsUser:`${ADMIN}/add-edit-userApp`,
    addEditFeaturedRestaurant:`${ADMIN}/add-edit-featuredRestaurant`,
    addAndUpdateGSt:`${ADMIN}/update-gst-details-by-admin`,
    getAllCalenderSetting:`${ADMIN}/getCalenderSetting`,
    addEditCalenderSetting:`${ADMIN}/addAndEditCalenderSetting`,
    deleteCalenderSetting:`${ADMIN}/deleteCalenderSetting`,
    approveRejectCampagin:`${ADMIN}/approve-campaign`,
    addEditOffer:`${ADMIN}/add-edit-offer`,
    getAllOffer:`${ADMIN}/list-admin-offer`,
    deleteOffer:`${ADMIN}/delete-offers`,
    offerDetailsByBranch:`${ADMIN}/list-offer-branch`,
    getUserWalletById:`${ADMIN}/get-wallet-list-of-user`,
    creditAndDebitUserWallet:`${ADMIN}/add-wallet-amount`,
    getAllNotificationText:`${ADMIN}/list-notification-text`,
    addEditNotificationText: `${ADMIN}/add-edit-notification-text`,
    addEditAdminGSTConfiguration:`${ADMIN}/add-edit-gst-configuration`,
    locationUpdate: `${ADMIN}/update-location`,
    getPaneltyList : `${ADMIN}/get-penalty-history`,
    getAllMassNotificationList:`${ADMIN}/get-mass-notification`,
    sendMastNotification:`${ADMIN}/send-mass-notification`,
    multipleDeleteFoodItems : `${ADMIN}/delete-foods`,
    transactionList:`${ADMIN}/get-payment-data`,
    getAllDeleteAccountRequest : `${ADMIN}/get-terminate-data`,
    deleteUser:`${ADMIN}/delete-user-by-admin`,
    getSingleUserDetails : `${ADMIN}/get-single-user-details`,
    genereteRestaurantReport:`${ADMIN}/generate-restaurant-report`,
    updateBranchPaymentInterval:`${ADMIN}/update-payment-interval`,
    iciciPaymentReport:`${ADMIN}/get-payment-of-icici`,
    getPaymentSummaryReport:`${ADMIN}/get-summary-report`,
    updatePaymentSummaryReport:`${ADMIN}/update-summary-report`,
    getBranchRating:`${ADMIN}/get-review-of-branch`,
    deleteRating:`${ADMIN}/delete-review-of-user`,
    getBranchCred:`${ADMIN}/credential-of-branch`,
    dashBaordApi:`${ADMIN}/today-order-list-admin`,
    getNotificationList:`${ADMIN}/get-notification-list`,
    disableBranch:`${ADMIN}/disable-branch`,
    getDashboardReport:`${ADMIN}/admin-dashboard-reports`,
    orderReports:`${ADMIN}/order-reports`,
    slotWiseOrderReport:`${ADMIN}/slot-wise-order-report`,
    moneyViewReport:`${ADMIN}/money-view-report`,
    cityWiseBranchPromotionReport:`${ADMIN}/city-wise-branch-promotion`,
    branchPromotion:`${ADMIN}/branch-promotion`,
    featuredVsNonFeaturedRestaurantReport:`${ADMIN}/featured-vs-nonFeatured-restaurant`,
    performanceReport:`${ADMIN}/restaurant-performance`,
    orderRatingReport:`${ADMIN}/order-rating-report`,
    userActivenessReport:`${ADMIN}/user-activeness-report`,
    regionWiseOrderReport:`${ADMIN}/region-wise-report`,
    cuisineAndCategoryReport:`${ADMIN}/cuisine-category-report`,
    avgOrderPerDay:`${ADMIN}/avg-order-per-day`
  },
  Common:{
    getAllFoodType: `${COMMON}/get-all-food-type`,
    getAllCuisine: `${COMMON}/get-all-cuisine`,
    getFoodType: `${COMMON}/get-food-type`,
    getAllQtyUnits: `${COMMON}/get-all-quantity`,
    getAllNeglectWords: `${COMMON}/get-all-neglect`,
    getNoofDinnerList: `${COMMON}/get-all-table`,
    getAllCategory: `${COMMON}/get-all-category`,
    getAllSelectionList: `${COMMON}/get-all-selection`,
    getAllCategoryList: `${COMMON}/get-all-category`,
    getAllFoodItemList: `${COMMON}/get-all-food-item`,
    getRestaurantList: `${COMMON}/get-restaurant-list`,
    getBranchDetail : `${COMMON}/get-branch-detail`,
    getAllSpiceLevel: `${COMMON}/get-all-spicy-level`,
    getAllSubscription:`${COMMON}/get-all-subscription`,
    getAboutUs:`${COMMON}/about-us`,
    getAllFaqs:`${COMMON}/getAll-faqs`,
    getAllTeam:`${COMMON}/getAll-team`,
    getAllSocialLinks:`${COMMON}/getAll-social`,
    getAllContactus:`${COMMON}/getAll-contactus`,
    getAllTermsAndCondition:`${COMMON}/getAll-termsandcondition`,
    getAllPrivacyPolicy:`${COMMON}/getAll-privacypolicy`,
    getAllAdminFeature:`${COMMON}/getAll-adminfeature`,
    getAllCancellation: `${COMMON}/get-all-cancellation`,
    getAllPromo:`${COMMON}/getAll-promo`,
    getCmsUserData:`${COMMON}/list-userApp`,
    getAllFeaturedRestaurant:`${COMMON}/list-featured-restaurant`,
    getAllCityOrBranchesByCity:`${COMMON}/get-city-list`,
    getAllAdminGSTConfiguration: `${COMMON}/get-gst-configuration`,
    getLocationUpdate: `${COMMON}/get-location`,
    getAllBranches:`${COMMON}/get-all-branches`
  },
  User:{
    branchDetailsByQrCode: `${USER}/branch-details-by-qr-code`,
    imageUpload:`${USER}/image-upload`
  },

  Restaurant:{
    placeOderByQrCode: `${USER}/place-order-by-qr-code`,
    branchDetailsByQrCode: `${USER}/branch-details-by-qr-code`,
    getRestaurantSuggestion :`${RESTAURANT}/get-suggestion`,   
  }   
}
 

