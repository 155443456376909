import React, { useState } from "react";
import Index from "../../../../../component/Index";
import { useForm } from "react-hook-form";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "TextValidator",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const AddCancelationPolicy = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("percentage", data.amount);
    if (props.row) {
      urlencoded.append("id", props.row._id);
    }

    await DataService.post(Api.Admin.addEditCancellation, urlencoded)
      .then((response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          setLoading(false);
          toast.success(response.data.message);
          props.getCancelationData();
          props.handleClose();
          reset();
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Add / Edit Cancelation Policy
          </Index.Typography>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Amount
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className={classes.input}
                name="amount"
                type="number"
                defaultValue={props.row ? props?.row?.percentage : ""}
                {...register("amount", {
                  required: "Amount  is required",
                })}
                error={Boolean(errors.amount)}
                helperText={errors.amount?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.SubmitDiscardButton
            handleClose={props.handleClose}
            loading={loading}
          />



          
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddCancelationPolicy;
