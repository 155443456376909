import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import AddNeglectWords from "./AddNeglectWords";
import DeleteModal from "../../../../../component/common/DeleteModal";

const NeglectWords = () => {
  const [neglectWordsData, setNeglectWordsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [neglectWordRow, setNeglectWordRow] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const getAllNeglectWords = async () => {
    setLoading(true);
    await DataService.get(Api.Common.getAllNeglectWords)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          setNeglectWordsData(response.data.data);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  const hanldeDeleteReocrd = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", neglectWordRow?._id);
    await DataService.post(Api.Admin.deleteNeglectWord, urlencoded)
      .then((res) => {
        if (res.data.status === 200) {
          handleDeleteClose();
          setNeglectWordRow("");
          getAllNeglectWords();
          Index.toast.success("neglect word deleted successfully");
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
      });
  };

  useEffect(() => {
    getAllNeglectWords();
  }, []);

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="flex-all admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Neglect Words
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="view-btn-main border-btn-main">
                      <Index.Button
                        className="view-order-btn border-btn"
                        onClick={() => {
                          handleOpen();
                          setNeglectWordRow("");
                        }}
                      >
                        Add
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="page-table-main food-item-table">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 500 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              NO
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              TYPE
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>

                        <Index.TableBody className="table-body">
                          {neglectWordsData.length > 0 ? (
                            neglectWordsData.map((row, index) => (
                              <Index.TableRow
                                key={row._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  {index + 1}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row?.neglect_word}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row.protein}

                                  <Index.Button
                                    className="table-btn"
                                    onClick={() => {
                                      handleOpen();
                                      setNeglectWordRow(row);
                                    }}
                                  >
                                    <img
                                      src={Index.Svg.blueedit}
                                      alt="edit-icon"
                                    ></img>
                                  </Index.Button>
                                  <Index.Button
                                    className="table-btn"
                                    onClick={() => {
                                      handleDeleteOpen();
                                      setNeglectWordRow(row);
                                    }}
                                  >
                                    <img
                                      src={Index.Svg.trash}
                                      alt="delete-icon"
                                    ></img>
                                  </Index.Button>
                                </Index.TableCell>
                              </Index.TableRow>
                            ))
                          ) : (
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              {" "}
                              No Data Found{" "}
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>

                    {open && (
                      <AddNeglectWords
                        open={open}
                        handleClose={handleClose}
                        getAllNeglectWords={getAllNeglectWords}
                        row={neglectWordRow}
                      />
                    )}

                    {deleteOpen && (
                      <DeleteModal
                        deleteOpen={deleteOpen}
                        handleDeleteClose={handleDeleteClose}
                        hanldeDeleteReocrd={hanldeDeleteReocrd}
                      />
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default NeglectWords;
