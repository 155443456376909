import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import {
  downloadPdfRepport,
  downloadReport,
} from "../../../../../common/functions";

const RegionWiseOrderReport = () => {
  const [orderData, setOrderData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAllOrderDetails = async () => {
    setLoading(true);

    await DataService.get(Api.Admin.regionWiseOrderReport)
      .then((response) => {
        if (response.data.status === 200) {
          setOrderData(response.data.data);
          setFilterData(response.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        setOrderData([]);
        setLoading(false);
      });
  };

  const handleDownloadReport = () => {
    const data = filterData.map((item) => {
      return {
        "Pincode": Index.displayValue(item?.pincode),
        "Total orders": Index.displayValue(item?.totalOrders),
      };
    });
    downloadReport(data, "Region order report");
  };

  const handleExportPDF = () => {
    const tableHeaders = [
      "Pincode",
      "Total orders",
    ];
    const tableData = filterData.map((item) => [
      Index.displayValue(item?.pincode),
      Index.displayValue(item?.totalOrders),
    ]);
    downloadPdfRepport(tableHeaders, tableData, "Region order report");
  };

  const handleFilterData = (e) => {
    if (e.target.value === "") {
      setFilterData(orderData);
    } else {
      const result = orderData.filter((item) => {
        return (
          item?.pincode?.toString().toLowerCase().includes(e.target.value.trim()?.toLowerCase())
        );
      });
      setFilterData(result);
      setPage(0);
    }
  };

  useEffect(() => {
    getAllOrderDetails();
  }, []);
  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="view-btn-main border-btn-main filter-btn export-transaction-btn">
                    <Index.Box className="user-search-box">
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder="Search by pincode"
                          onChange={(e) => handleFilterData(e)}
                        />

                        <img
                          src={Index.Svg.search}
                          className="search-grey-img"
                          alt="search grey img"
                        ></img>
                      </Index.Box>
                    </Index.Box>
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!filterData.length}
                        onClick={() => {
                          handleDownloadReport();
                        }}
                    >
                      Export
                    </Index.Button>
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!filterData.length}
                        onClick={() => {
                          handleExportPDF();
                        }}
                    >
                      Export PDF
                    </Index.Button>
                  </Index.Box>

                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Region orders
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group table-head-date-main"></Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Pincode
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Total orders
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData.length > 0
                              ? filterData &&
                                paginate(filterData, rowsPerPage, page + 1).map(
                                  (row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayValue(row?.pincode)}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayValue(row?.totalOrders)}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  )
                                )
                              : "No Data Found"}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filterData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default RegionWiseOrderReport;
