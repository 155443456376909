import React from "react";
import jwtDecode from "jwt-decode";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
  // Check token expiry
  const isValidToken = (accessToken) => {
    if (!accessToken) return false;

    const decodedToken = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    
    if (decodedToken.exp > currentTime) {
      return true;
    } else {
      localStorage.removeItem("token");
      return false;
    }
  };

  const token = localStorage.getItem("token");

  return isValidToken(token) ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
