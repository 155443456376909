import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import svg from "../../../../assets/svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReadMore from "./ReadMore";
import Png from "../../../../assets/png";

export const ProductDetail = () => {
  //#region states

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [product, setProduct] = useState(location.state?.item);
  const [totalPrice, setTotalPrice] = useState("");
  const [variantDetails, setVariantDetails] = useState(
    product?.quantity.sort((a, b) => a.price - b.price)[0]
  );
  const [branchId, setBranchId] = useState(location.state?.branchId);
  const [branchName, setBranchName] = useState(location.state?.branchName);
  const [walkinTime, setWalkInTime] = useState(location.state?.walkinTime);
  const [foodCookingInstruction, setFoodCookingInstruction] = useState("");
  const [localstorageArray, setLocalstorageArray] = useState([]);
  const [qty, setQty] = useState(1);

  const [selectionItems, setSelectionItems] = useState(
    location.state?.item?.selectionItems
  );

  console.log(product?.quantity, "productQTy");
  const [selectionItemsValueArray, setSelectionItemsValueArray] = useState([]);
  //#endregion

  console.log(selectionItems, "selectionItems");

  useEffect(() => {
    let selected = selectionItems?.map((res) => {
      // console.log(res,"selectionItemRes")
      return {
        selection_item_id: res?._id,
        selection_on: res?.selected_toppings_id?.selection_on,
        selection: [],
        selection_type: res?.selection_type,
        free_selection: res?.free_selection,
        free_selection_count: res?.free_selection_count,
        // errorMsg: null,
      };
    });

    setSelectionItemsValueArray(selected);
  }, [selectionItems]);

  // console.log(selected, "selected");
  const handleChangeSelectionItems = (
    event,
    mainSelectionId,
    mainSelectionIndex
  ) => {
    console.log(event, mainSelectionId, mainSelectionIndex, "uves89");

    const mainSelection = selectionItems?.find(
      (item) => item?._id === mainSelectionId
    );
    const data = mainSelection?.selections.find(
      (item) => item?._id === event.target.value
    );
    const newArray = [...selectionItemsValueArray];

    if (event.target.checked) {
      const obj = {
        id: data?._id,
        name: data?.name,
        price: data?.price,
      };

      if (newArray[mainSelectionIndex].selection_type === "Single") {
        newArray[mainSelectionIndex]?.selection.splice(0);
        newArray[mainSelectionIndex]?.selection.push(obj);
      } else {
        newArray[mainSelectionIndex]?.selection.push(obj);
      }
    } else {
      let newValue = newArray[mainSelectionIndex]?.selection.filter(
        (row) => row.id !== data?._id
      );
      newArray[mainSelectionIndex].selection = newValue;
    }

    // newArray[mainSelectionIndex] = {
    //   mainSelectionId: mainSelectionId,
    //   selection: [
    //     {
    //       id: data?._id,
    //       name: data?.name,
    //       price: data?.price,
    //     },
    //   ],
    // };

    setSelectionItemsValueArray(newArray);
  };

  console.log(selectionItemsValueArray, "selectionItemsValue");

  let selectedToppings = selectionItemsValueArray.map((response, index) => {
    let selectionData = response.selection?.map((toppings, toppingIndex) => {
      if (response.free_selection) {
        if (toppingIndex < Number(response?.free_selection_count)) {
          return {
            _id: toppings?.id,
            price: 0,
            name: toppings?.name,
          };
        } else {
          return {
            ...toppings,
          };
        }
      } else {
        return {
          ...toppings,
        };
      }
    });

    return {
      selection_item_id: response?.selection_item_id,
      selection_on: response?.selection_on,
      selection_type: response?.selection_type,
      free_selection: response?.free_selection,
      free_selection_count: response?.free_selection_count,
      selection: selectionData,
    };
  });

  useEffect(() => {
    const addtocartItem = localStorage.getItem("addFoodItem");
    if (addtocartItem) {
      setLocalstorageArray(JSON.parse(addtocartItem));
    } else {
      setLocalstorageArray([]);
    }
  }, []);

  const removeQty = () => {
    qty !== 1 ? setQty(qty - 1) : setQty(qty);
  };

  const addQty = () => {
    setQty(qty + 1);
  };

  useEffect(() => {
    let topingsTotal = 0;
    for (let i = 0; i < selectedToppings.length; i++) {
      if (
        !(
          selectedToppings[i]?.selection_type === "Single" &&
          selectedToppings[i]?.free_selection === true
        )
      ) {
        for (let j = 0; j < selectedToppings[i]?.selection?.length; j++) {
          topingsTotal += selectedToppings[i]?.selection[j]?.price;
        }
      }
    }

    setTotalPrice((variantDetails?.price + topingsTotal) * qty);
  }, [variantDetails, qty, selectionItemsValueArray]);

  console.log(variantDetails, "variantDetails");
  const addToCart = (e) => {
    const addtocart = {
      branchId: branchId,
      branchName: branchName,
      selected_food_id: params?.productId,
      foodCookingInstruction: foodCookingInstruction,
      productName: product?.alias,
      price: variantDetails?.price,
      food_size: variantDetails?.food_size,
      kilogram: variantDetails?.kilogram,
      food_size_value: variantDetails?.kilogram || 0,
      totalPrice: totalPrice,
      qty: qty,
      walkinTime: walkinTime,
      selection_item_id: selectedToppings.filter(
        (row) => row?.selection?.length > 0
      ),
    };

    if (localstorageArray?.length === 0 || localstorageArray === null) {
      localstorageArray.push(addtocart);
    } else if (
      localstorageArray?.some(
        (item) => item.selected_food_id === addtocart.selected_food_id
      )
    ) {
      for (var i = 0; i < localstorageArray?.length; i++) {
        if (
          localstorageArray[i].selected_food_id == addtocart.selected_food_id
        ) {
          localstorageArray?.splice(i, 1);
          localstorageArray?.push(addtocart);
          break;
        }
      }
    } else {
      localstorageArray?.push(addtocart);
    }

    localStorage.setItem("addFoodItem", JSON.stringify(localstorageArray));
    navigate(-1);
  };

  const handleInstruction = (e) => {
    setFoodCookingInstruction(e.target.value);
  };

  console.log(location.state?.item, "productDetails");
  return (
    <div>
      <Index.Box className="product-detail-section">
        <Index.Box className="user-container">
          <Index.Box className="checkout-head">
            <Index.Button className="goback-btn" onClick={() => navigate(-1)}>
              <Index.ArrowBackIosNewIcon />
              <Index.Typography>Go Back</Index.Typography>
            </Index.Button>
          </Index.Box>
          <Index.Box className="main-details">
            <Index.Box className="left-detail">
              <img
                src={
                  Index.Constant.IMAGE_URL +
                  `${product?.selected_item_id?.image}`
                }
                className="details-pizza"
              />
              {/* <Index.Checkbox
                className="like-product"
                defaultChecked
                icon={<Index.FavoriteBorder />}
                checkedIcon={<Index.Favorite />}
              /> */}
              <Index.Box className="veg-non-img">
                <img
                  src={
                    Index.Constant.IMAGE_URL +
                    product?.selected_item_id?.food_type?.image
                  }
                  className="veg-icon-one-pro"
                  alt="veg-icon"
                />
              </Index.Box>
            </Index.Box>
            <Index.Box className="right-detail">
              <Index.Box className="top-details">
                <Index.Box
                  className="details-pizza-name"
                  variant="p"
                  component="p"
                >
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="head-p"
                  >
                    {product?.alias}
                  </Index.Typography>
                  {/* <Index.Box variant="p" component="p">
                    (Simply Cheesy)
                  </Index.Box> */}
                  {/* <Index.Typography
                    className="details-pizza-price"
                    variant="p"
                    component="p"
                  >
                    &#8377; {variantDetails?.price}
                  </Index.Typography> */}
                </Index.Box>
                {/* <Index.Box className="review">
                  <Index.Box className="review-top">
                    4.9
                    <img src={svg.review} className="details-pizza" />
                  </Index.Box>
                  <Index.Box className="review-bottom">
                    <Index.Typography
                      className="review-text"
                      variant="p"
                      component="p"
                    >
                      25 Reviews
                    </Index.Typography>
                  </Index.Box>
                </Index.Box> */}
              </Index.Box>
              <Index.Box className="variation-part description-part ">
                {/* <Index.Typography
                  className="variation-head"
                  variant="p"
                  component="p"
                >
                  Description
                </Index.Typography> */}
                <Index.Typography
                  className="description-para"
                  variant="p"
                  component="p"
                >
                  <ReadMore>{product?.selected_item_id?.ingredients}</ReadMore>
                </Index.Typography>
              </Index.Box>
              <Index.Box className="pro-detail-middle">
                <Index.Box className="variation-part">
                  <Index.Typography
                    className="variation-head"
                    variant="p"
                    component="p"
                  >
                    Variation
                  </Index.Typography>
                  <Index.Box className="all-types">
                    {product?.quantity.map((quantityItem, index) => {
                      return (
                        <Index.Box className="same-slice-check">
                          <Index.Typography
                            className="slice-check-text"
                            variant="p"
                            component="p"
                          >
                            {quantityItem?.kilogram === 0
                              ? ""
                              : quantityItem?.kilogram}
                            {` ${quantityItem?.food_size}`}
                          </Index.Typography>
                          <Index.Box className="slice-price-check">
                            <Index.Typography
                              className=""
                              variant="p"
                              component="p"
                            >
                              &#8377; {quantityItem?.price}
                            </Index.Typography>
                            <Index.Checkbox
                              className="price-select-slice"
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 28 },
                              }}
                              onChange={(e) => {
                                setVariantDetails(quantityItem);
                              }}
                              checked={
                                variantDetails?._id === quantityItem?._id
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      );
                    })}
                  </Index.Box>
                </Index.Box>

                {selectionItems?.map((row, mainIndex, arr2) => {
                  return (
                    <Index.Box className="variation-part slice-part">
                      {row?.selection_type === "Single" &&
                      row?.free_selection === true ? (
                        <>
                          <Index.Typography
                            className="variation-head"
                            variant="p"
                            component="p"
                          >
                            {` ${row?.selected_toppings_id?.selection_on} (${
                              row?.selection_type === "Single"
                                ? "Single selection"
                                : "Multiple selection"
                            }${
                              row?.free_selection
                                ? `, ${row?.free_selection_count}
                                 Free)`
                                : ""
                            } `}
                          </Index.Typography>
                          <Index.Box className="slice-checkboxes">
                            <Index.RadioGroup
                              onChange={(e) =>
                                handleChangeSelectionItems(
                                  e,
                                  row?._id,
                                  mainIndex
                                )
                              }
                              // value={selectionItemsValue[selectionItemIndex]?.["selection"]}
                              className="topping-selection-radiorow single-select"
                            >
                              {row?.selections?.map(
                                (selectionValue, selectionIndex) => {
                                  console.log(
                                    selectionValue,
                                    "selectionValue",
                                    row
                                  );
                                  return (
                                    <Index.Box className="same-slice-check">
                                      <Index.Typography
                                        className="slice-check-text"
                                        variant="p"
                                        component="p"
                                      >
                                        {selectionValue?.name}
                                      </Index.Typography>
                                      <Index.Box className="toppings-price-div">
                                        <Index.Box className="slice-price-check">
                                          <Index.FormControlLabel
                                            className="price-select-slice"
                                            sx={{
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 28,
                                              },
                                            }}
                                            control={<Index.Radio />}
                                            value={selectionValue?._id}
                                          />
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  );
                                }
                              )}
                            </Index.RadioGroup>
                          </Index.Box>
                        </>
                      ) : row?.selection_type === "Multiple" &&
                        row?.free_selection === true ? (
                        <>
                          <Index.Typography
                            className="variation-head"
                            variant="p"
                            component="p"
                          >
                            {` ${row?.selected_toppings_id?.selection_on} (${
                              row?.selection_type === "Single"
                                ? "Single selection"
                                : "Multiple selection"
                            }${
                              row?.free_selection
                                ? `, ${row?.free_selection_count}
                                 Free)`
                                : ""
                            } `}
                          </Index.Typography>
                          <Index.Box className="slice-checkboxes">
                            {row?.selections?.map(
                              (selectionValue, selectionIndex, arr) => {
                                return (
                                  <Index.Box className="same-slice-check">
                                    <Index.Typography
                                      className="slice-check-text"
                                      variant="p"
                                      component="p"
                                    >
                                      {selectionValue?.name}
                                    </Index.Typography>
                                    <Index.Box className="slice-price-check">
                                      {selectionItemsValueArray[mainIndex]
                                        ?.selection.length >=
                                      row?.free_selection_count ? (
                                        !selectionItemsValueArray[
                                          mainIndex
                                        ]?.selection
                                          .map((res) => res.id)
                                          ?.slice(
                                            0,
                                            Number(row.free_selection_count)
                                          )
                                          .includes(selectionValue?._id) ? (
                                          <Index.Typography
                                            className=""
                                            variant="p"
                                            component="p"
                                          >
                                            ₹{selectionValue?.price}
                                          </Index.Typography>
                                        ) : (
                                          <Index.Typography
                                            className=""
                                            variant="p"
                                            component="p"
                                          >
                                            Free
                                          </Index.Typography>
                                        )
                                      ) : (
                                        ""
                                      )}
                                      <Index.Checkbox
                                        className="price-select-slice"
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 28,
                                          },
                                        }}
                                        value={selectionValue?._id}
                                        onChange={(e) =>
                                          handleChangeSelectionItems(
                                            e,
                                            row?._id,
                                            mainIndex
                                          )
                                        }
                                      />
                                    </Index.Box>
                                  </Index.Box>
                                );
                              }
                            )}
                          </Index.Box>
                        </>
                      ) : row?.selection_type == "Multiple" &&
                        row?.free_selection === false ? (
                        <>
                          <Index.Typography
                            className="variation-head"
                            variant="p"
                            component="p"
                          >
                            {` ${row?.selected_toppings_id?.selection_on} (${
                              row?.selection_type === "Single"
                                ? "Single selection"
                                : "Multiple selection"
                            }${
                              row?.free_selection
                                ? `, ${row?.free_selection_count}
                                 Free)`
                                : ")"
                            } `}
                          </Index.Typography>
                          <Index.Box className="slice-checkboxes">
                            {row?.selections?.map((selectionValue) => {
                              return (
                                <Index.Box className="same-slice-check">
                                  <Index.Typography
                                    className="slice-check-text"
                                    variant="p"
                                    component="p"
                                  >
                                    {selectionValue?.name}
                                  </Index.Typography>
                                  <Index.Box className="slice-price-check">
                                    <Index.Typography
                                      className=""
                                      variant="p"
                                      component="p"
                                    >
                                      ₹{selectionValue?.price}
                                    </Index.Typography>
                                    <Index.Checkbox
                                      className="price-select-slice"
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 28 },
                                      }}
                                      value={selectionValue?._id}
                                      onChange={(e) =>
                                        handleChangeSelectionItems(
                                          e,
                                          row?._id,
                                          mainIndex
                                        )
                                      }
                                    />
                                  </Index.Box>
                                </Index.Box>
                              );
                            })}
                          </Index.Box>
                        </>
                      ) : row?.selection_type === "Single" &&
                        row?.free_selection === false ? (
                        <>
                          <Index.Typography
                            className="variation-head"
                            variant="p"
                            component="p"
                          >
                            {` ${row?.selected_toppings_id?.selection_on} (${
                              row?.selection_type === "Single"
                                ? "Single selection"
                                : "Multiple selection"
                            }${
                              row?.free_selection
                                ? `, ${row?.free_selection_count}
                                 Free)`
                                : ")"
                            } `}
                          </Index.Typography>
                          <Index.Box className="slice-checkboxes">
                            <Index.RadioGroup
                              onChange={(e) =>
                                handleChangeSelectionItems(
                                  e,
                                  row?._id,
                                  mainIndex
                                )
                              }
                              // value={selectionItemsValue[selectionItemIndex]?.["selection"]}
                              className="topping-selection-radiorow single-select"
                            >
                              {row?.selections?.map(
                                (selectionValue, selectionIndex) => {
                                  console.log(
                                    selectionValue,
                                    "selectionValue",
                                    row
                                  );
                                  return (
                                    <Index.Box className="same-slice-check">
                                      <Index.Typography
                                        className="slice-check-text"
                                        variant="p"
                                        component="p"
                                      >
                                        {selectionValue?.name}
                                      </Index.Typography>
                                      <Index.Box className="toppings-price-div">
                                        <Index.Typography
                                          className=""
                                          variant="p"
                                          component="p"
                                        >
                                          ₹{selectionValue?.price}
                                        </Index.Typography>
                                        <Index.Box className="slice-price-check">
                                          <Index.FormControlLabel
                                            className="price-select-slice"
                                            sx={{
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 28,
                                              },
                                            }}
                                            control={<Index.Radio />}
                                            value={selectionValue?._id}
                                          />
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  );
                                }
                              )}
                            </Index.RadioGroup>
                          </Index.Box>
                        </>
                      ) : (
                        ""
                      )}
                    </Index.Box>
                  );
                })}
                <Index.Box>
                  <Index.Box
                    className={
                      selectionItems?.length === 0
                        ? "pro-detail-blank-box box-no-220"
                        : selectionItems?.length === 1
                        ? "pro-detail-blank-box box-no-70"
                        : "pro-detail-blank-box box-none-0"
                    }
                  ></Index.Box>
                  <Index.Box className="variation-part slice-part slice-part4">
                    {/* <Index.Typography className='variation-head' variant='p' component='p'>
                                    Choice Of 7 Jain Preparation
                                </Index.Typography> 
                                <Index.Box className='slice-checkboxes jain-pizza'>
                                    <Index.Box className="same-slice-check">
                                        <Index.Typography className='slice-check-text' variant='p' component='p'>
                                            Pure Jain
                                        </Index.Typography>
                                        <Index.Box className="slice-price-check">
                                            <Index.Checkbox
                                                className='price-select-slice'
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                            />
                                        </Index.Box>
                                    </Index.Box> 

                                </Index.Box> */}

                    <Index.Box className="jain-cooking">
                      {/* <Index.Typography className="" variant="p" component="p">
                    Add Cooking Instructions
                  </Index.Typography> */}
                      <Index.TextareaAutosize
                        aria-label="minimum height"
                        placeholder=" Add Cooking Instructions.."
                        onChange={(e) => handleInstruction(e)}
                      />
                      {/* <Index.Box className="jain-btn">
                                        <Index.Button>
                                            Add
                                        </Index.Button>
                                    </Index.Box> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="add-item part">
                <Index.Box className="upper-add-item">
                  <Index.Typography
                    className="add-item-head"
                    variant="p"
                    component="p"
                  >
                    Quantity
                  </Index.Typography>
                  <Index.Box className="inc-dec-btn">
                    <Index.Button className="dec-btn" onClick={removeQty}>
                      <Index.RemoveIcon />
                    </Index.Button>
                    <Index.Typography>{qty}</Index.Typography>
                    <Index.Button className="inc-btn" onClick={addQty}>
                      <Index.AddIcon />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.Link style={{ textDecoration: "none" }}>
                  <Index.Box className="bottom-add-item" onClick={addToCart}>
                    <Index.AddIcon />
                    <Index.Typography variant="p" component="p">
                      ADD TO CART &#8377;{totalPrice}
                    </Index.Typography>
                  </Index.Box>
                </Index.Link>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};
