import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import Checkbox from "@mui/material/Checkbox";
import { downloadReport,downloadPdfRepport } from "../../../../../common/functions";

const Bookings = () => {
  const [bookingsData, setBookingsData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [bookingsFilter, setBookingsFilter] = useState([]);

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAllBookingList = async () => {
    setLoading(true);
    await DataService.post(Api.Admin.orderReports, {
      orderFilter: bookingsFilter,
    })
      .then((response) => {
        if (response.data.status === 200) {
          setBookingsData(response.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  const handleChangeBookingFilter = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setBookingsFilter([...bookingsFilter, value]);
    } else {
      const newArr = [...bookingsFilter].filter((item) => item !== value);

      setBookingsFilter(newArr);
    }
  };
  useEffect(() => {
    getAllBookingList();
  }, [bookingsFilter]);

  const handleDownloadReport = () => {
    const data = bookingsData.map((item) => {
      return {
        "Order Id": item?._id,
        "User Name": item?.created_by?.name,
        "Branch Name": item?.branch_id?.branch_name,
        Date: Index.moment(item.date).format("DD/MM/YYYY"),
        Time: item?.time,
        Persons: item?.persons,
        FoodItems: item?.food_items.length,
        Amount: item?.total_amount.toFixed(2),
        Status: item?.booking_status,
      };
    });
    downloadReport(data, "Booking report");
  };

  const handleExportPDF = () => {
    const tableHeaders = [
      "Order Id",
      "User Name",
      "Branch Name",
      "Date",
      "Time",
      "Amount",
      "Status"
    ];
    const tableData = bookingsData.map((row) => [
      row._id,
      row?.created_by?.name,
      row?.branch_id?.branch_name,
      Index.moment(row.date).format("DD/MM/YYYY"),
      row?.time,
      row?.total_amount.toFixed(2),
      row?.booking_status
    ]);
    downloadPdfRepport(tableHeaders,tableData,"Booking report")
  };

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="view-btn-main border-btn-main filter-btn export-transaction-btn">
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!bookingsData.length}
                      onClick={() => {
                        handleDownloadReport();
                      }}
                    >
                      Export CSV
                    </Index.Button>
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!bookingsData.length}
                      onClick={() => {
                        handleExportPDF();
                      }}
                    >
                      Export PDF
                    </Index.Button>
                  </Index.Box>

                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Bookings Reports
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="view-btn-main border-btn-main">
                        <Index.FormControlLabel
                          control={
                            <Checkbox
                              value="cancelled"
                              onChange={handleChangeBookingFilter}
                              checked={bookingsFilter?.some(
                                (e) => e === "cancelled"
                              )}
                            />
                          }
                          label="Cancel"
                        />
                        <Index.FormControlLabel
                          control={
                            <Checkbox
                              value="completed"
                              onChange={handleChangeBookingFilter}
                              checked={bookingsFilter?.some(
                                (e) => e === "completed"
                              )}
                            />
                          }
                          label="Completed"
                        />
                        <Index.FormControlLabel
                          control={
                            <Checkbox
                              value="rejected"
                              onChange={handleChangeBookingFilter}
                              checked={bookingsFilter?.some(
                                (e) => e === "rejected"
                              )}
                            />
                          }
                          label="Rejected"
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main food-item-table table-order-list-res">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Order Id
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                User Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Branch Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Date
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Time
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Persons
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                FoodItems
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Amount
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Status
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {bookingsData.length > 0
                              ? bookingsData &&
                                paginate(
                                  bookingsData,
                                  rowsPerPage,
                                  page + 1
                                ).map((row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {row._id || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.created_by?.name || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.branch_id?.branch_name || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row.date
                                        ? Index.moment(row.date).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {/* {row?.time ? moment(row?.time).format('HH:mm:ss a') : '-'} */}
                                      {row?.time ? row?.time : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.persons || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.food_items.length || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.total_amount.toFixed(2) || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.booking_status || "-"}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              : "No Data Found"}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={bookingsData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default Bookings;
