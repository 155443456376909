import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import EditNotification from "./EditNotification";

const Notification = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [notificationRow, setNotificationRow] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterData = (e) => {
    if (e.target.value === "") {
      setFilterData(notificationData);
    } else {
      const result = notificationData.filter((item) => {
        return (
          item?.title?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
          item?.text?.toLowerCase().includes(e.target.value?.toLowerCase())
        );
      });
      setFilterData(result);
      setPage(0);
    }
  };

  const getAllNotification = async () => {
    setLoading(true);
    await DataService.get(Api.Admin.getAllNotificationText)
      .then((res) => {
        setNotificationData(res.data.data);
        setFilterData(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        Index.toast.error(e.response.data.message || e.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllNotification();
  }, []);

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Notification
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="view-btn-main border-btn-main">
                        <Index.Box className="user-search-main">
                          <Index.Box className="user-search-box">
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Search notification"
                                onChange={(e) => handleFilterData(e)}
                              />

                              <img
                                src={Index.Svg.search}
                                className="search-grey-img"
                                alt="search grey img"
                              ></img>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        {/* <Index.Button
                      className="view-order-btn border-btn"
                      onClick={() => {
                        handleOpen();
                        setTeamRow('')
                      }}
                    >
                      Add Team
                    </Index.Button> */}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main food-item-table">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Title
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Text
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData.length > 0
                              ? filterData &&
                                paginate(filterData, rowsPerPage, page + 1).map(
                                  (row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.title ? row?.title : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row.text ? row.text : "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        <Index.Button
                                          className="table-btn"
                                          onClick={() => {
                                            handleOpen();
                                            setNotificationRow(row);
                                          }}
                                        >
                                          <img src={Index.Svg.blueedit}></img>
                                        </Index.Button>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  )
                                )
                              : "No Data Found"}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {open && (
              <EditNotification
                open={open}
                handleClose={handleClose}
                getAllNotification={getAllNotification}
                row={notificationRow}
              />
            )}

            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filterData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default Notification;
