import React from "react";
import Index from "../Index";

const SubmitDiscardButton = (props) => {
  return (
    <Index.Box className="modal-user-btn-flex">
      <Index.Box className="discard-btn-main border-btn-main">
        <Index.Button
          className="discard-user-btn border-btn"
          onClick={props.handleClose}
        >
          Discard
        </Index.Button>
      </Index.Box>
      <Index.Box className="save-btn-main border-btn-main">
        <Index.LoadingButton
          className="save-user-btn border-btn"
          type="submit"
          loadingPosition="start"
          loading={props.loading}
          startIcon={<Index.SaveIcon />}
        >
          Save
        </Index.LoadingButton>
      </Index.Box>
    </Index.Box>
  );
};

export default SubmitDiscardButton;
