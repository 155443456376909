import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { useForm, Controller } from "react-hook-form";
import { FileUploader } from "react-drag-drop-files";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const AddPromo = (props) => {
  const [imageError, setImageError] = useState("");
  const [file, setFile] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const fileTypes = ["JPG", "PNG", "GIF"];

  const handleChange = (file) => {
    if (file) {
      setImageError("");
    }
    setFile(file);
  };

  const onSubmit = async (data) => {
    if (file === null) {
      setImageError("Please select image");
    } else {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("image", file);

      await DataService.post(Api.Admin.addEditPromo, formData)
        .then((res) => {
          if (res.data.status === 200 || res.data.status === 201) {
            Index.toast.success("Promo adedd successfully");
            props.handleClose();
            props.getAllPromo();
            reset();
          }
        })
        .catch((e) => {
          Index.toast.error(e.response.data.message);
        });
    }
  };
  //console.log(file, "file");
  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Add Promo
          </Index.Typography>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="name"
                {...register("name", {
                  required: "Name is required",
                })}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box modal-main">
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
            />

            <p>{file ? `File name: ${file.name}` : "no files uploaded yet"}</p>

            <Index.FormHelperText error>
              {imageError && imageError}
            </Index.FormHelperText>
          </Index.Box>

          <Index.Box className="modal-user-btn-flex">
            <Index.Box className="discard-btn-main border-btn-main">
              <Index.Button
                className="discard-user-btn border-btn"
                onClick={props.handleClose}
              >
                Discard
              </Index.Button>
            </Index.Box>
            <Index.Box className="save-btn-main border-btn-main">
              <Index.Button className="save-user-btn border-btn" type="submit">
                <img src={Index.Svg.save} className="user-save-icon"></img>Save
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddPromo;
