import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import { Controller, useForm } from "react-hook-form";
import { Api } from "../../../../../config/Api";
import DataService from "../../../../../config/DataService";

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const [contactData, setContactData] = useState([]);

  const onSubmit = async (data) => {
   
      const urlencoded = new URLSearchParams();
      urlencoded.append("name", data.name);
      urlencoded.append("email", data.email);
      urlencoded.append("address", data.address);
      urlencoded.append("mobile_number", data.mobile);
      contactData._id && urlencoded.append("id", contactData._id);

      await DataService.post(Api.Admin.addEditContactus, urlencoded)
        .then((res) => {
          if (res.data.status === 201 || res.data.status === 200) {
            Index.toast.success("Updated successfully");
            getContactUs();
            reset();
          }
        })
        .catch((e) => {
          Index.toast.error(e.response.data.message);
        });
 
  };

  const getContactUs = async () => {
    await DataService.get(Api.Common.getAllContactus).then((res) => {
      if (res.data.data.length > 0) {
        setContactData(res.data.data[0]);
        setValue("name", res.data.data[0].name);
        setValue("email", res.data.data[0].email);
        setValue("address", res.data.data[0].address);
        setValue("mobile", res.data.data[0].mobile_number);
        
      }
    });
  };
  useEffect(() => {
    getContactUs();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Index.Box className="add-user-modal-inner-main about-us-main">
        <Index.Typography
          id="modal-modal-title"
          className="modal-title"
          variant="h6"
          component="h2"
        >
          Contact Us
        </Index.Typography>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Title
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="name"
              {...register("name", {
                required: "Name is required",
              })}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Email
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email',
                },
              })}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Address
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="address"
              {...register("address", {
                required: "Address is required",
              })}
              error={Boolean(errors.address)}
              helperText={errors.address?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Mobile number
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="mobile"
              {...register("mobile", {
                required: "Mobile is required",
                pattern:{
                    value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                    message:'Please enter a valid mobile number'
                }
              })}
              error={Boolean(errors.mobile)}
              helperText={errors.mobile?.message}
            />
          </Index.Box>
        </Index.Box>
        
        <Index.Box className="modal-user-btn-flex">
          <Index.Box className="save-btn-main border-btn-main">
            <Index.Button className="save-user-btn border-btn" type="submit">
              <img src={Index.Svg.save} className="user-save-icon"></img>Submit
            </Index.Button>
          </Index.Box>
        </Index.Box>


      </Index.Box>
    </form>
  );
};

export default ContactUs;
