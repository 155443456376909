export const Constant = {
    // IMAGE_URL : 'http://35.177.56.74:3014/images/',
    IMAGE_URL : 'https://mealord-restaurant-docs.s3.ap-south-1.amazonaws.com/',
    // IMAGE_URL : 'https://mealord-demo-document.s3.ap-southeast-2.amazonaws.com/',
    MODAL_STYLE:{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
    }
} 
    