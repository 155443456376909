import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Index from "../Index";

export default function Sidebar(props) {
  const location = useLocation();
  const [opneGeneral, setOpenGeneral] = useState(false);
  const [openRestaurant, setOpenRestaurant] = useState(false);
  let role = localStorage.getItem("role-id");
  const [foodItemManagementOpen, setFoodItemManagementOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const params = Index.useParams();
  // const [requestFormManagementOpen, setRequestFormManagementOpen] = useState(false);

  const handleFoodItemManagementOpen = () => {
    setFoodItemManagementOpen(!foodItemManagementOpen);
  };
  const handleRestaurantOpen = () => {
    setOpenRestaurant(!openRestaurant);
  };
  const handleReportOpen = () => {
    setReportOpen(!reportOpen);
  };

  return (
    <>
      <Index.Box className={`admin-sidebar-main ${props.open ? "active" : ""}`}>
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Box className="admin-sidebar-logo-main">
            <img
              src={Index.Png.mealordlogo}
              className="admin-sidebar-logo"
              alt="logo"
            />
            <Index.Button onClick={() => props.setOpen(!props.open)}>
              <img src={Index.Png.close} className="close-icon" alt="logo" />
            </Index.Button>
          </Index.Box>

          <Index.Box className="admin-sidebar-list-main">
            <Index.List className="admin-sidebar-list">
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.ReactLink
                  to="/"
                  className={
                    location.pathname === "/dashboard"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link"
                  }
                >
                  <Index.Box className="flex-icon-center">
                    <img
                      src={Index.Svg.dashboard}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Dashboard
                  </Index.Box>
                </Index.ReactLink>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.ReactLink
                  to="/dashboard-reports"
                  className={
                    location.pathname === "/dashboard-reports"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link"
                  }
                >
                  <Index.Box className="flex-icon-center">
                    <img
                      src={Index.Svg.dashboard}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Dashboard Reports
                  </Index.Box>
                </Index.ReactLink>
              </Index.ListItem>

              <Index.ListItem
                className="admin-sidebar-listitem admin-submenu-listitem-main"
                // onClick={handleFoodItemManagementOpen}
              >
                <Index.Link className="admin-sidebar-link">
                  <Index.Box className="flex-icon-center">
                    <img
                      src={Index.Svg.foodItem}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    <span onClick={handleFoodItemManagementOpen}>
                      {" "}
                      Food Item Management{" "}
                    </span>
                  </Index.Box>
                  {foodItemManagementOpen ? (
                    <Index.ExpandLess
                      className="expandmore-icon"
                      onClick={handleFoodItemManagementOpen}
                    />
                  ) : (
                    <Index.ExpandMore
                      className="expandless-icon"
                      onClick={handleFoodItemManagementOpen}
                    />
                  )}
                  <Index.Box className="submenu-main">
                    <Index.Collapse
                      in={foodItemManagementOpen}
                      timeout="auto"
                      unmountOnExit
                      className="submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/food-item-management/food-type"}
                            className={
                              location.pathname ===
                              "/food-item-management/food-type"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Food Type
                          </Index.ReactLink>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            className={
                              location.pathname ===
                              "/food-item-management/qty-units"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                            to={"/food-item-management/qty-units"}
                          >
                            Qty Units
                          </Index.ReactLink>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/food-item-management/cuisines"}
                            className={
                              location.pathname ===
                              "/food-item-management/cuisines"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Cuisines
                          </Index.ReactLink>
                        </Index.ListItem>

                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/food-item-management/category"}
                            className={
                              location.pathname ===
                              "/food-item-management/category"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Category
                          </Index.ReactLink>
                        </Index.ListItem>

                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/food-item-management/spice-level"}
                            className={
                              location.pathname ===
                              "/food-item-management/spice-level"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Spice Level
                          </Index.ReactLink>
                        </Index.ListItem>

                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to="/food-item-management/neglect-words"
                            className={
                              location.pathname ===
                              "/food-item-management/neglect-words"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Neglect Words
                          </Index.ReactLink>
                        </Index.ListItem>

                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            className={
                              location.pathname ===
                              "/food-item-management/food-item"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                            to="/food-item-management/food-item"
                          >
                            Food Item
                          </Index.ReactLink>
                        </Index.ListItem>

                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to="/food-item-management/selection"
                            className={
                              location.pathname ===
                              "/food-item-management/selection"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Food Selection
                          </Index.ReactLink>
                        </Index.ListItem>
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem
                className="admin-sidebar-listitem admin-submenu-listitem-main"
                // onClick={handleFoodItemManagementOpen}
              >
                <Index.Link className="admin-sidebar-link">
                  <Index.Box className="flex-icon-center">
                    <img
                      src={Index.Svg.foodItem}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    <span onClick={handleReportOpen}>
                      {" "}
                      Report and analysis{" "}
                    </span>
                  </Index.Box>
                  {foodItemManagementOpen ? (
                    <Index.ExpandLess
                      className="expandmore-icon"
                      onClick={handleReportOpen}
                    />
                  ) : (
                    <Index.ExpandMore
                      className="expandless-icon"
                      onClick={handleReportOpen}
                    />
                  )}
                  <Index.Box className="submenu-main">
                    <Index.Collapse
                      in={reportOpen}
                      timeout="auto"
                      unmountOnExit
                      className="submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/report-analysis/bookings"}
                            className={
                              location.pathname ===
                              "/report-analysis/bookings"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Bookings
                          </Index.ReactLink>
                        </Index.ListItem>

                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/report-analysis/slot-wise-orders"}
                            className={
                              location.pathname ===
                              "/report-analysis/slot-wise-orders"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Slot wise orders
                          </Index.ReactLink>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/report-analysis/money-view"}
                            className={
                              location.pathname ===
                              "/report-analysis/money-view"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Money view 
                          </Index.ReactLink>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/report-analysis/city-wise-branch-promotion"}
                            className={
                              location.pathname ===
                              "/report-analysis/city-wise-branch-promotion"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            City Promotion 
                          </Index.ReactLink>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/report-analysis/branch-promotion"}
                            className={
                              location.pathname ===
                              "/report-analysis/branch-promotion"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Branch Promotion 
                          </Index.ReactLink>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/report-analysis/featured-vs-non-featured"}
                            className={
                              location.pathname ===
                              "/report-analysis/featured-vs-non-featured"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Featured Restaurant
                          </Index.ReactLink>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/report-analysis/restaurant-performance"}
                            className={
                              location.pathname ===
                              "/report-analysis/restaurant-performance"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Restaurant Performance
                          </Index.ReactLink>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/report-analysis/orders-rating"}
                            className={
                              location.pathname ===
                              "/report-analysis/orders-rating"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Rating order
                          </Index.ReactLink>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/report-analysis/user-activeness"}
                            className={
                              location.pathname ===
                              "/report-analysis/user-activeness"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            User activeness 
                          </Index.ReactLink>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/report-analysis/region-orders"}
                            className={
                              location.pathname ===
                              "/report-analysis/region-orders"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Region orders
                          </Index.ReactLink>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/report-analysis/cuisine-category"}
                            className={
                              location.pathname ===
                              "/report-analysis/cuisine-category"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Cusine and Category
                          </Index.ReactLink>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.ReactLink
                            to={"/report-analysis/average-orders"}
                            className={
                              location.pathname ===
                              "/report-analysis/average-orders"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            Average orders
                          </Index.ReactLink>
                        </Index.ListItem>
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Link>
              </Index.ListItem>

              {role === "0" && (
                <>
                  <Index.ListItem
                    className="admin-sidebar-listitem admin-submenu-listitem-main"
                    // onClick={() => setOpenGeneral(!opneGeneral)}
                  >
                    <Index.ReactLink className="admin-sidebar-link">
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.changepassword}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        <span onClick={() => setOpenGeneral(!opneGeneral)}>
                          {" "}
                          General Configuration{" "}
                        </span>
                      </Index.Box>
                      {opneGeneral ? (
                        <Index.ExpandLess
                          className="expandmore-icon"
                          onClick={() => setOpenGeneral(!opneGeneral)}
                        />
                      ) : (
                        <Index.ExpandMore
                          className="expandless-icon"
                          onClick={() => setOpenGeneral(!opneGeneral)}
                        />
                      )}
                      <Index.Box className="submenu-main">
                        <Index.Collapse
                          in={opneGeneral}
                          timeout="auto"
                          unmountOnExit
                          className="submenu-collapse"
                        >
                          <Index.List
                            component="div"
                            disablePadding
                            className="admin-sidebar-submenulist"
                          >
                            <Index.ListItem className="admin-sidebar-listitem">
                              <Index.ReactLink
                                to="/general/calender-setting"
                                className={
                                  location.pathname ===
                                  "/general/calender-setting"
                                    ? "admin-sidebar-link active"
                                    : "admin-sidebar-link"
                                }
                              >
                                Calendar Setting
                              </Index.ReactLink>
                            </Index.ListItem>
                            <Index.ListItem className="admin-sidebar-listitem">
                              <Index.ReactLink
                                to="/general/location-update"
                                className={
                                  location.pathname ===
                                  "/general/location-update"
                                    ? "admin-sidebar-link active"
                                    : "admin-sidebar-link"
                                }
                              >
                                Location Updates
                              </Index.ReactLink>
                            </Index.ListItem>
                            <Index.ListItem className="admin-sidebar-listitem">
                              <Index.ReactLink
                                to="/general/booking"
                                className={
                                  location.pathname === "/general/booking"
                                    ? "admin-sidebar-link active"
                                    : "admin-sidebar-link"
                                }
                              >
                                Booking Configuration
                              </Index.ReactLink>
                            </Index.ListItem>
                            <Index.ListItem className="admin-sidebar-listitem">
                              <Index.ReactLink
                                to="/general/gst"
                                className={
                                  location.pathname === "/general/gst"
                                    ? "admin-sidebar-link active"
                                    : "admin-sidebar-link"
                                }
                              >
                                GST Configuration
                              </Index.ReactLink>
                            </Index.ListItem>
                            <Index.ListItem className="admin-sidebar-listitem">
                              <Index.ReactLink
                                to="/general/table"
                                className={
                                  location.pathname === "/general/table"
                                    ? "admin-sidebar-link active"
                                    : "admin-sidebar-link"
                                }
                              >
                                Table Configuration
                              </Index.ReactLink>
                            </Index.ListItem>

                            <Index.ListItem className="admin-sidebar-listitem">
                              <Index.ReactLink
                                to="/general/policy-cancelation"
                                className={
                                  location.pathname ===
                                  "/general/policy-cancelation"
                                    ? "admin-sidebar-link active"
                                    : "admin-sidebar-link"
                                }
                              >
                                Cancellation Policy
                              </Index.ReactLink>
                            </Index.ListItem>
                          </Index.List>
                        </Index.Collapse>
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/reuqest-form-management"
                      className={
                        location.pathname === "/reuqest-form-management"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.userlist}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Request Form Management
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>
                </>
              )}

              <Index.ListItem
                className="admin-sidebar-listitem admin-submenu-listitem-main"
                onClick={() => handleRestaurantOpen(!openRestaurant)}
              >
                <Index.ReactLink
                  className={
                    location.pathname === "/restaurant"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link"
                  }
                  to={"/restaurant"}
                >
                  <Index.Box className="flex-icon-center">
                    <img
                      src={Index.Svg.changepassword}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Restaurant Management
                  </Index.Box>
                  <Index.Box className="submenu-main">
                    <Index.Collapse
                      in={openRestaurant}
                      timeout="auto"
                      unmountOnExit
                      className="submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      ></Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.ReactLink>
              </Index.ListItem>

              {role === "0" && (
                <>
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/subscription-management"
                      className={
                        location.pathname === "/subscription-management"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.subscription}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Subscription Management
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/user-queries"
                      className={
                        location.pathname === "/user-queries"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.userQuery}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Users Query List
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  {/* <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/user-inquiries"
                      className={
                        location.pathname === "/user-inquiries"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.userQuery}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Users Inquiry List
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem> */}

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/subscriber-list"
                      className={
                        location.pathname === "/subscriber-list"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.subscriberList}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Subscriber List
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>
                </>
              )}

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.ReactLink
                  to="/user-list"
                  className={
                    location.pathname === "/user-list" ||
                    location.pathname === `/single-user-details/${params.id}`
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link"
                  }
                >
                  <Index.Box className="flex-icon-center">
                    <img
                      src={Index.Svg.userList2}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    User List
                  </Index.Box>
                </Index.ReactLink>
              </Index.ListItem>

              {role === "0" && (
                <>
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/qr-order-list"
                      className={
                        location.pathname === "/qr-order-list"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.qrCode}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        QR orders
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/order-walkin-list"
                      className={
                        location.pathname === "/order-walkin-list"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.orderTable}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Walkin orders
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/order-table-list"
                      className={
                        location.pathname === "/order-table-list"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.orderTable}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Table orders
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/suggestion-management"
                      className={
                        location.pathname === "/suggestion-management"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.suggestionManagement}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Suggestion Management
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/offer-management"
                      className={
                        location.pathname === "/offer-management" ||
                        location.pathname === "/add-offer"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.OfferManagement}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Offer Management
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/content-management"
                      className={
                        location.pathname === "/content-management"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.contentManagement}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Content Management
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/sub-admin"
                      className={
                        location.pathname === "/sub-admin"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.contentManagement}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Sub Admin
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/campaign-management"
                      className={
                        location.pathname === "/campaign-management"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.contentManagement}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Campaign Management
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/campaign-management/subscriber-list"
                      className={
                        location.pathname ===
                        "/campaign-management/subscriber-list"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.contentManagement}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Campaign Subscriber List
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/panelty-list"
                      className={
                        location.pathname === "/panelty-list"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.contentManagement}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Panelty List
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/mass-notifications"
                      className={
                        location.pathname === "/mass-notifications"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.contentManagement}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Mass Notifications
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/transaction-history"
                      className={
                        location.pathname === "/transaction-history"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.contentManagement}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Transaction History
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>

                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.ReactLink
                      to="/delete-account-request"
                      className={
                        location.pathname === "/delete-account-request"
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <Index.Box className="flex-icon-center">
                        <img
                          src={Index.Svg.contentManagement}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Delete Account Request
                      </Index.Box>
                    </Index.ReactLink>
                  </Index.ListItem>
                </>
              )}
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
