import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDN-BSA2eKeFFHs0fG1vB7B4wP2bAgvKXY",
  authDomain: "mealord-7d8ef.firebaseapp.com",
  projectId: "mealord-7d8ef",
  storageBucket: "mealord-7d8ef.appspot.com",
  messagingSenderId: "734167187467",
  appId: "1:734167187467:web:cebb8865e67f1e18c7470e",
  measurementId: "G-6XTNCXL93K",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
