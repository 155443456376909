import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";

import AddCampaign from "./AddCampaign";
const CampaignManagement = () => {
  const [editedData, setEditedData] = useState("");
  const [campaignManagementData, setCampaignManagementData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const getAllCampaign = async () => {
    setLoading(true);
    await DataService.get(Api.Admin.getAllCampaign)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          setCampaignManagementData(res.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllCampaign();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const onDeleteCampaign = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", editedData?._id);
    await DataService.post(Api.Admin.deleteCampaign, urlencoded)
      .then((res) => {
        handleDeleteClose();
        setEditedData("");
        getAllCampaign();
        Index.toast.success("Campaign deleted successfully");
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Campaign Management
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="view-btn-main border-btn-main">
                        <Index.Button
                          className="view-order-btn border-btn"
                          onClick={() => {
                            handleOpen();
                            // setFoodTypeRow("");
                            setEditedData("");
                          }}
                        >
                          Add
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main food-item-table">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                NO
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Description
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Time Period
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Amount
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {campaignManagementData.map((row, index) => (
                              <Index.TableRow
                                key={row._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="table-td"
                                >
                                  {index + 1}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row.campaign_name}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {Index.parse(row?.campaign_description)}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row.campaign_time_period} days
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row.campaign_price}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Index.Button
                                    className="table-btn"
                                    onClick={() => {
                                      handleOpen();
                                      setEditedData(row);
                                    }}
                                  >
                                    <img src={Index.Svg.blueedit}></img>
                                  </Index.Button>
                                  <Index.Button
                                    className="table-btn"
                                    onClick={() => {
                                      handleDeleteOpen();
                                      setEditedData(row);
                                    }}
                                  >
                                    <img src={Index.Svg.trash}></img>
                                  </Index.Button>
                                </Index.TableCell>
                              </Index.TableRow>
                            ))}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>

                      {open && (
                        <AddCampaign
                          open={open}
                          handleClose={handleClose}
                          getAllCampaign={getAllCampaign}
                          row={editedData}
                        />
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Modal
            open={deleteOpen}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete"
          >
            <Index.Box
              sx={Index.Constant.MODAL_STYLE}
              className="delete-modal-inner-main"
            >
              <Index.Box className="modal-circle-main">
                <img src={Index.Svg.closecircle} className="user-circle-img" />
              </Index.Box>
              <Index.Typography
                className="delete-modal-title"
                component="h2"
                variant="h2"
              >
                Are you sure?
              </Index.Typography>
              <Index.Typography
                className="delete-modal-para common-para"
                component="p"
                variant="p"
              >
                Do you really want to delete these records? This process cannot
                be undone.
              </Index.Typography>
              <Index.Box className="delete-modal-btn-flex">
                <Index.Button
                  className="modal-cancel-btn modal-btn"
                  onClick={handleDeleteClose}
                >
                  Cancel
                </Index.Button>
                <Index.Button
                  className="modal-delete-btn modal-btn"
                  onClick={onDeleteCampaign}
                >
                  Delete
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
        </Index.Box>
      )}
    </>
  );
};

export default CampaignManagement;
