import {
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Link,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  Collapse,
  Stack,
  Pagination,
  TablePagination,
  FormLabel,
  InputLabel,
  Badge ,
  Tooltip
} from "@mui/material";
import Grid from "@mui/material/Grid";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Svg from "../assets/svg";
import Png from "../assets/png";
import Sidebar from "./defaulLayout/Sidebar";
import Header from "./defaulLayout/Header";
import PaidLable from "./common/PaidLable";
import FailedLable from "./common/FailedLable";
import PendingLable from "./common/PendingLable";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link as ReactLink } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import Rating from "@mui/material/Rating";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import { Constant } from "../common/Constant";
import { toast } from "react-toastify";
import moment from "moment";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from "html-react-parser";
import DeleteModal from "./common/DeleteModal";
import CancelIcon from "@mui/icons-material/Cancel";
import VerifiedIcon from "@mui/icons-material/Verified";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "./common/Loader/Loader";
import SubmitDiscardButton from "./common/SubmitDiscardButton";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MailIcon from "@mui/icons-material/Mail";
import DownloadIcon from "@mui/icons-material/Download";
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from "@mui/icons-material/Info";
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import {
  displayNumberValue,
  displayNumberValueWithToFixed,
  displayValue,
  displayDateValue,
  errorToast,
  successToast,
  displayDateWithTime
} from "../common/functions";

import {
  validateWithNoWhiteSpace,
  validateWithLimitedCharacters,
  validateWithStringOnly,
} from "../common/validations";
export default {
  FavoriteBorder,
  KeyboardArrowRightIcon,
  ArrowBackIosNewIcon,
  RemoveIcon,
  Favorite,
  Rating,
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  Visibility,
  Link,
  List,
  ListItem,
  Svg,
  Png,
  Grid,
  Sidebar,
  Header,
  Badge ,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  PaidLable,
  FailedLable,
  PendingLable,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  ExpandLess,
  ExpandMore,
  Collapse,
  Stack,
  Pagination,
  ReactLink,
  AddCircleOutlineIcon,
  RemoveCircleOutlineIcon,
  AddIcon,
  TablePagination,
  FormLabel,
  DeleteIcon,
  ReplyIcon,
  Constant,
  toast,
  moment,
  useParams,
  useNavigate,
  useLocation,
  makeStyles,
  CKEditor,
  ClassicEditor,
  parse,
  DeleteModal,
  CancelIcon,
  VerifiedIcon,
  CloseIcon,
  Loader,
  SubmitDiscardButton,
  LoadingButton,
  SaveIcon,
  InputLabel,
  HighlightOffIcon,
  displayValue,
  displayNumberValueWithToFixed,
  displayNumberValue,
  displayDateValue,
  MailIcon,
  DownloadIcon,
  successToast,
  errorToast,
  validateWithNoWhiteSpace,
  validateWithLimitedCharacters,
  validateWithStringOnly,
  displayDateWithTime,
  DoneIcon,
  Tooltip,
  ArrowBackIcon,
  InfoIcon,
  EmojiEmotionsIcon,
  ArrowUpwardIcon,
  ArrowDownwardIcon
};
