import React, { useState } from "react";
import Index from "../../../../component/Index";

const ViewSingleTransactionDetail = ({
  open,
  row,
  handleToggleOpen,
  displayAmount,
  forType
}) => {
  console.log(row, "row");
  return (
    <Index.Modal
      open={open}
      onClose={handleToggleOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <Index.Box
        sx={Index.Constant.MODAL_STYLE}
        className="add-food-item-modal-inner-main"
      >
        <Index.Box className="view-transaction-details-heading">
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Order Details
          </Index.Typography>
          <Index.CloseIcon onClick={handleToggleOpen} />
        </Index.Box>

        <Index.Box className="view-transaction-same-box">
          <Index.Typography>Order ID : </Index.Typography>
          <Index.Typography>
            {row?.order_id?.branch_order || "-"}
          </Index.Typography>
        </Index.Box>

        <Index.Box className="view-transaction-same-box">
          <Index.Typography>User Name : </Index.Typography>
          <Index.Typography>{row?.created_by?.name || "-"}</Index.Typography>
        </Index.Box>

        <Index.Box className="view-transaction-same-box">
          <Index.Typography>Booking Date : </Index.Typography>
          <Index.Typography>
            {Index.moment(row?.createdAt).format("L") || "-"}
          </Index.Typography>
        </Index.Box>

        <Index.Box className="view-transaction-same-box">
          <Index.Typography>Booking Time : </Index.Typography>
          <Index.Typography>
            {Index.moment(row?.createdAt).format("hh:mm a") || "-"}
          </Index.Typography>
        </Index.Box>

        <Index.Box className="view-transaction-same-box">
          <Index.Typography>Payment Date : </Index.Typography>
          <Index.Typography>
            {Index.moment(row?.date).format("LL") || "-"}
          </Index.Typography>
        </Index.Box>

        <Index.Box className="view-transaction-same-box">
          <Index.Typography>Promo Code : </Index.Typography>
          <Index.Typography>{row?.promoCode || "-"}</Index.Typography>
        </Index.Box>

        <Index.Box className="view-transaction-same-box">
          <Index.Typography>Amount Received : </Index.Typography>
          <Index.Typography>
            {displayAmount(row?.amount_received_by_mealord)}
          </Index.Typography>
        </Index.Box>

        <Index.Box className="view-transaction-same-box">
          <Index.Typography>Transaction Id : </Index.Typography>
          <Index.Typography>{row?.payment_id || "-"}</Index.Typography>
        </Index.Box>

        <Index.Box className="view-transaction-same-box">
          <Index.Typography>Payment Status : </Index.Typography>
          <Index.Typography>
            {row?.order_id?.order_status || "-"}
          </Index.Typography>
        </Index.Box>

        <Index.Box className="view-transaction-same-box">
          <Index.Typography>Final Status Of Booking : </Index.Typography>
          <Index.Typography>
            {row?.order_type === "additional"
              ? row?.additional_order_id?.order_status
              : row?.order_id?.booking_status || "-"}
          </Index.Typography>
        </Index.Box>

        <Index.Box className="view-transaction-same-box">
          <Index.Typography>Commission : </Index.Typography>
          <Index.Typography>
            {displayAmount(row?.commission_of_mealord)}
          </Index.Typography>
        </Index.Box>

        <Index.Box className="view-transaction-same-box">
          <Index.Typography>
            Final payment after deducting Commission :{" "}
          </Index.Typography>
          <Index.Typography>
            {displayAmount(row?.amount_received_by_restaurant)}
          </Index.Typography>
        </Index.Box>

        <Index.Box className="view-transaction-same-box">
          <Index.Typography>Refund Amount : </Index.Typography>
          <Index.Typography>
            {displayAmount(forType==="restaurant" ? row?.refund_amount_restaurant : row?.refund_amount)}
          </Index.Typography>
        </Index.Box>

        {row?.penalty_status ? (
          <Index.Box className="view-transaction-same-box">
            <Index.Typography>Penalty Amount : </Index.Typography>
            <Index.Typography>{row?.penalty_amount > 0 ? (-Index.displayNumberValueWithToFixed(
                                      row?.penalty_amount
                                    )) : 0}</Index.Typography>
          </Index.Box>
        ) : (
          ""
        )}
      </Index.Box>
    </Index.Modal>
  );
};

export default ViewSingleTransactionDetail;
