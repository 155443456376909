import React, { useState } from "react";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { useEffect } from "react";
import Index from "../../../../component/Index";

const DashboardReport = () => {
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [revenueFilter, setRevenueFilter] = useState("all");
  const [orderFilter, setOrderFilter] = useState("all");

  const getDashboardReport = () => {
    setLoading(true);
    const data = {
      revenueFilter,
      orderFilter
    };
    DataService.post(Api.Admin.getDashboardReport, data).then((res) => {
      setReportData(res.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getDashboardReport();
  }, [revenueFilter,orderFilter]);

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="dashboard-content">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            Reports
          </Index.Typography>

          <Index.Box className="user-search-main">
            <Index.Box className="user-search-box">
              <Index.Box className="form-group table-head-date-main">
                <Index.Box className="branch-name" sx={{ marginRight: "5px" }}>
                  <Index.Typography>Revenue type</Index.Typography>
                  <Index.Select
                    value={revenueFilter}
                    displayEmpty
                    onChange={(e) => setRevenueFilter(e.target.value)}
                    className="form-control"
                  >
                    <Index.MenuItem value="all">All</Index.MenuItem>
                    <Index.MenuItem value="day">Day</Index.MenuItem>
                    <Index.MenuItem value="week">Week</Index.MenuItem>
                    <Index.MenuItem value="month">Month</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Box className="branch-name">
                  <Index.Typography>Order Type</Index.Typography>
                  <Index.Select
                    value={orderFilter}
                    displayEmpty
                    onChange={(e) => setOrderFilter(e.target.value)}
                    className="form-control"
                  >
                    <Index.MenuItem value="all">All</Index.MenuItem>
                    <Index.MenuItem value="hour">Hour</Index.MenuItem>
                    <Index.MenuItem value="day">Day</Index.MenuItem>
                    <Index.MenuItem value="week">Week</Index.MenuItem>
                    <Index.MenuItem value="month">Month</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="admin-dashboad-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total revenue
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {reportData?.total_revenue}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon1}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total orders
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {reportData?.total_orders}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon2}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total restaurant users
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {reportData?.total_restaurant_users}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon3}
                          className="dash-icons"
                          alt="dashboard icon"
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total users
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {reportData?.total_users}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon3}
                          className="dash-icons"
                          alt="dashboard icon"
                        />

                        {/* <img
                      src={Index.Svg.dashicon4}
                      className="dash-icons"
                      alt="dashboard icon"
                    /> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="admin-dashboad-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total branches
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {reportData?.total_branches}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon3}
                          className="dash-icons"
                          alt="dashboard icon"
                        />

                        {/* <img
                      src={Index.Svg.dashicon4}
                      className="dash-icons"
                      alt="dashboard icon"
                    /> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total cuisines
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {reportData?.total_cuisines}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon3}
                          className="dash-icons"
                          alt="dashboard icon"
                        />

                        {/* <img
                      src={Index.Svg.dashicon4}
                      className="dash-icons"
                      alt="dashboard icon"
                    /> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total categories
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {reportData?.total_categories}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon3}
                          className="dash-icons"
                          alt="dashboard icon"
                        />

                        {/* <img
                      src={Index.Svg.dashicon4}
                      className="dash-icons"
                      alt="dashboard icon"
                    /> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total food items
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {reportData?.total_food_items}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon3}
                          className="dash-icons"
                          alt="dashboard icon"
                        />

                        {/* <img
                      src={Index.Svg.dashicon4}
                      className="dash-icons"
                      alt="dashboard icon"
                    /> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="admin-dashboad-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Pending restaurant request
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {reportData?.pending_restaurant_food_request}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon3}
                          className="dash-icons"
                          alt="dashboard icon"
                        />

                        {/* <img
                      src={Index.Svg.dashicon4}
                      className="dash-icons"
                      alt="dashboard icon"
                    /> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Pending users inquires
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {reportData?.pending_users_inquires}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon3}
                          className="dash-icons"
                          alt="dashboard icon"
                        />

                        {/* <img
                      src={Index.Svg.dashicon4}
                      className="dash-icons"
                      alt="dashboard icon"
                    /> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total suggestions
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {reportData?.total_suggestions}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon3}
                          className="dash-icons"
                          alt="dashboard icon"
                        />

                        {/* <img
                      src={Index.Svg.dashicon4}
                      className="dash-icons"
                      alt="dashboard icon"
                    /> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Pending add food request
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {reportData?.pending_restaurant_food_request}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon3}
                          className="dash-icons"
                          alt="dashboard icon"
                        />

                        {/* <img
                      src={Index.Svg.dashicon4}
                      className="dash-icons"
                      alt="dashboard icon"
                    /> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="admin-dashboad-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Cancelled orders
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {reportData?.cancelled_orders}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon3}
                          className="dash-icons"
                          alt="dashboard icon"
                        />

                        {/* <img
                      src={Index.Svg.dashicon4}
                      className="dash-icons"
                      alt="dashboard icon"
                    /> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 3",
                    lg: "span 3",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Active offers
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h1"
                          component="h1"
                        >
                          {reportData?.active_offers}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="admin-dash-right">
                        <img
                          src={Index.Svg.dashicon3}
                          className="dash-icons"
                          alt="dashboard icon"
                        />

                        {/* <img
                      src={Index.Svg.dashicon4}
                      className="dash-icons"
                      alt="dashboard icon"
                    /> */}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default DashboardReport;
