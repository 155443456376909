import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import { useForm, Controller } from "react-hook-form";
import { Api } from "../../../../../config/Api";
import DataService from "../../../../../config/DataService";

const AddFeaturedRestaurant = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [foodTypeData, setFoodTypeData] = useState([]);
  const [imageName, setImageName] = useState("");
  const [imageError, setImageError] = useState("");
  const [images, setImages] = useState(null);

  const handleImageChange = (e) => {
    setImageName(e.target.files[0].name);
    setImages(e.target.files[0]);
  };

  const getAllFoodType = async () => {
    await DataService.get(Api.Common.getAllFoodType).then((response) => {
      if (response.data.status === 200) {
        setFoodTypeData(response.data.data);
      }
    });
  };

  useEffect(() => {
    if (props.row) {
      setValue("itemName", props?.row?.name);
      setValue("foodType", props?.row?.food_type_id?._id);
      setImageName(props?.row?.image);
    }
  }, [props.row]);
  
  const onSubmit = async (data) => {
    const formdata = new FormData();
    formdata.append("name", data.itemName);
    formdata.append("food_type_id", data.foodType);
    formdata.append("image", images ? images : imageName);
    props?.row && formdata.append("id", props?.row?._id);

    await DataService.post(Api.Admin.addEditFeaturedRestaurant, formdata).then(
      (response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          if (props.row) {
            Index.toast.success("Restaurant updated successfully");
          } else {
            Index.toast.success("Restaurant addedd successfully");
          }
          props.getAllFeaturedRestaurant();
          props.handleClose();
          reset();
        }
      }
    );
  };

  useEffect(() => {
    getAllFoodType();
  }, []);
  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            {props?.row ? "Update" : "Add"} Featured restaurant
          </Index.Typography>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Restaurant Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                defaultValue={
                  props?.row?.item_name ? props?.row?.item_name : ""
                }
                name="itemName"
                {...register("itemName", {
                  required: "Restaurant Name is required",
                })}
                error={Boolean(errors.itemName)}
                helperText={errors.itemName?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Food Type
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.Box className="dropdown-box">
                <Index.FormControl className="form-control">
                  <Controller
                    name="foodType"
                    control={control}
                    {...register("foodType", {
                      required: "Please select foodType",
                    })}
                    render={({ field: { onChange, value } }) => (
                      <Index.Select
                        sx={{
                          "& .MuiFormHelperText-root": {
                            ml: 0,
                            mt: "5px",
                          },
                        }}
                        labelId="foodType"
                        error={Boolean(errors.foodType)}
                        className="dropdown-select"
                        id="foodType"
                        value={value}
                        onChange={onChange}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {foodTypeData?.map((data) => (
                          <Index.MenuItem value={data?._id}>
                            {data?.type}
                          </Index.MenuItem>
                        ))}
                      </Index.Select>
                    )}
                  />
                  {/* <span><img src={Svg.greydown} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
                </Index.FormControl>
                <Index.FormHelperText error id="accountId-error">
                  {errors.foodType?.message ? errors.foodType?.message : ""}
                </Index.FormHelperText>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Select Image
            </Index.FormHelperText>
            <Index.Button
              variant="outlined"
              fullWidth
              // startIcon={<Index.AddIcon/>}
              component="label"
              className="file-upload-btn"
              // onChange={(e) => handleImageChange(e)}
            >
              {imageName ? imageName : "Select File"}
              <input
                hidden
                accept="files/*"
                id="contained-button-file"
                // multiple
                type="file"
                onChange={(e) => {
                  handleImageChange(e);
                }}
              />
            </Index.Button>
          </Index.Box>

          <Index.Box className="modal-user-btn-flex">
            <Index.Box className="discard-btn-main border-btn-main">
              <Index.Button
                className="discard-user-btn border-btn"
                onClick={props.handleClose}
              >
                Discard
              </Index.Button>
            </Index.Box>
            <Index.Box className="save-btn-main border-btn-main">
              <Index.Button className="save-user-btn border-btn" type="submit">
                <img src={Index.Svg.save} className="user-save-icon"></img>Save
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddFeaturedRestaurant;
