import React, { useEffect, useState } from "react";
import DataService from "../../../../../../config/DataService";
import { Api } from "../../../../../../config/Api";
import Index from "../../../../../../component/Index";
import DeleteModal from "../../../../../../component/common/DeleteModal";

const Ratings = (props) => {
  const [ratingData, setRatingData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [ratingRow, setRatingRow] = useState("");

  const getAllRatings = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("branch_id", props.branchId);
    DataService.post(Api.Admin.getBranchRating, urlencoded)
      .then((res) => {
        setRatingData(res.data.data);
      })
      .catch((e) => {
        Index.toast.error(e.response.data.message || e.message);
      });
  };
  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const hanldeDeleteReocrd = async () => {
    await DataService.get(Api.Admin.deleteRating + '/' + ratingRow?._id).then((res) => {
      if (res.data.status === 200) {
        handleDeleteClose();
        setRatingRow("");
        getAllRatings();
        Index.toast.success(res.data.message);
      }
    });
  };

  useEffect(() => {
    getAllRatings();
  }, []);

  return (
    <Index.Box className="admin-dashboard-list-row">
      <Index.Box sx={{ width: 1 }} className="grid-main">
        <Index.Box
          display="grid"
          className="display-row"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column"
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="admin-dash-box">
                <Index.Box className="flex-all admin-dashboard-table-row">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h3"
                      variant="h3"
                    >
                      Rating management
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="page-table-main food-item-table">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table
                      sx={{ minWidth: 500 }}
                      aria-label="simple table"
                      className="table"
                    >
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Date
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            User name
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Rate overall
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Comment
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Action
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {ratingData.length > 0 ? (
                          ratingData &&
                          paginate(ratingData, rowsPerPage, page + 1).map(
                            (row, index) => (
                              <Index.TableRow
                                key={row._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {Index.displayDateValue(row?.createdAt)}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {Index.displayValue(row?.created_by?.name)}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {Index.displayNumberValue(row?.rate_over_all)}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {Index.displayValue(row?.comments)}
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Index.Button
                                    className="table-btn"
                                      onClick={() => {
                                        handleDeleteOpen();
                                        setRatingRow(row);
                                      }}
                                  >
                                    <img
                                      src={Index.Svg.trash}
                                      alt="delete-icon"
                                    ></img>
                                  </Index.Button>
                                </Index.TableCell>
                              </Index.TableRow>
                            )
                          )
                        ) : (
                          <Index.TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <Index.TableCell align="center" colSpan={4}>
                              No record found
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={ratingData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {deleteOpen && (
          <DeleteModal
            deleteOpen={deleteOpen}
            handleDeleteClose={handleDeleteClose}
            hanldeDeleteReocrd={hanldeDeleteReocrd}
          />
        )}
      </Index.Box>
    </Index.Box>
  );
};

export default Ratings;
