import React from "react";
import Index from "../../../../../component/Index";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const EditNotification = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("title", data.title);
    urlencoded.append("text", data.text);
    urlencoded.append("id", props.row._id);

    DataService.post(Api.Admin.addEditNotificationText, urlencoded)
      .then((res) => {
        Index.toast.success(res.data.message);
        props.handleClose();
        props.getAllNotification();
      })
      .catch((e) => {
        Index.toast.error(e.response.data.message || e.message);
      });
  };

  useEffect(() => {
    if (props.row) {
      setValue("title", props.row.title);
      setValue("text", props.row.text);
    }
  }, []);

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Edit Notification
          </Index.Typography>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Title
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="title"
                disabled
                {...register("title")}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Text
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="text"
                {...register("text", {
                  required: "Text is required",
                })}
                error={Boolean(errors.text)}
                helperText={errors.text?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="modal-user-btn-flex">
            <Index.Box className="discard-btn-main border-btn-main">
              <Index.Button
                className="discard-user-btn border-btn"
                onClick={props.handleClose}
              >
                Discard
              </Index.Button>
            </Index.Box>
            <Index.Box className="save-btn-main border-btn-main">
              <Index.Button className="save-user-btn border-btn" type="submit">
                <img src={Index.Svg.save} className="user-save-icon"></img>Save
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default EditNotification;
