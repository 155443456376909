import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { Api } from "../../../../config/Api";
import DataService from "../../../../config/DataService";

const RequestForm = () => {
  const [requestFormData, setRequestFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [requestFormRow, setRequestFormRow] = useState({});
  const [loading, setLoading] = useState(false);

  const getAllRequestForm = async () => {
    setLoading(true);
    await DataService.get(Api.Admin.getAllRequestFrom)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          setRequestFormData(response.data.data);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  const handleUpdateStatus = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", requestFormRow?._id);
    urlencoded.append("admin_action", action === "Accept" ? 1 : 2);

    await DataService.post(Api.Admin.updateRequestFrom, urlencoded).then(
      (res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          if (action === "Accept") {
            Index.toast.success("Reuqest Accepted successfully");
          } else {
            Index.toast.success("Reuqest Rejected successfully");
          }
          setOpen(false);
          setRequestFormRow("");
          setAction("");
          getAllRequestForm();
        }
      }
    );
  };
  useEffect(() => {
    getAllRequestForm();
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="dashboard-content">
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title"
              component="h2"
              variant="h2"
            >
              Request Form Management
            </Index.Typography>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="userlist-table-main page-table-main request-mangmnt">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                No
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                DATE
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                OWNER
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                RESTAURANT
                              </Index.TableCell>
                              {/* <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                >
                                  REQUEST FOR
                                </Index.TableCell> */}

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                CUISINE
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                CATEGORY
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                INGREDIENTS
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                IMAGE
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                ACTION
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {requestFormData.length > 0 ? (
                              requestFormData.map((row, index) => {
                                return (
                                  <Index.TableRow
                                    key={row?._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {index + 1}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {Index.moment(row.createdAt).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                    >
                                      {row?.created_by?.owner_name
                                        ? row?.created_by?.owner_name
                                        : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.created_by?.restaurant_name
                                        ? row?.created_by?.restaurant_name
                                        : "-"}
                                    </Index.TableCell>
                                    {/* <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row.requestFor}
                                  </Index.TableCell> */}
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.cuisine?.cuisine
                                        ? row?.cuisine?.cuisine
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.category?.category_name
                                        ? row?.category?.category_name
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.ingredients
                                        ? row?.ingredients
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <img
                                        src={
                                          Index.Constant.IMAGE_URL + row.image
                                        }
                                        height="80px"
                                        width="120px"
                                        alt="item-img"
                                      />
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td  accpt-rjct-btn-main"
                                    >
                                      <Index.Button
                                        variant="contained"
                                        className="accpt-rjct-btn succes-btn"
                                        size="small"
                                        onClick={() => {
                                          handleOpen();
                                          setAction("Accept");
                                          setRequestFormRow(row);
                                        }}
                                      >
                                        Accept
                                      </Index.Button>

                                      <Index.Button
                                        variant="contained"
                                        className="accpt-rjct-btn"
                                        color="error"
                                        size="small"
                                        onClick={() => {
                                          handleOpen();
                                          setAction("Reject");
                                          setRequestFormRow(row);
                                        }}
                                        style={{ marginLeft: "5px" }}
                                      >
                                        Reject
                                      </Index.Button>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                );
                              })
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                No Data Found
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete"
      >
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="delete-modal-inner-main"
        >
          <Index.Box className="modal-circle-main">
            <img
              src={
                action === "Accept"
                  ? Index.Svg.checkmark
                  : Index.Svg.closecircle
              }
              className="user-circle-img"
              alt="button-img"
            />
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            {action === "Accept"
              ? "Do you really want to Accept these record ? "
              : "Do you really want to Reject these record ?"}
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={handleClose}
            >
              Cancel
            </Index.Button>
            <Index.Button
              className={
                action === "Accept"
                  ? "modal-success-btn modal-btn"
                  : "modal-delete-btn modal-btn"
              }
              onClick={handleUpdateStatus}
            >
              {action}
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default RequestForm;
