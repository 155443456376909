import React from "react";
import { useState, useEffect } from "react";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import Index from "../../../../../component/Index";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const PaymentReportSummary = () => {
  const [transactionList, setTransactionList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [intervalType, setIntervalType] = useState("weekly");
  const [branches, setBranches] = useState([]);
  const [branchId, setBranchId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const navigate = Index.useNavigate();

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAllTransactionList = (type, startDate, endDate) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("interval_type", type);
    urlencoded.append("startDate", startDate);
    urlencoded.append("endDate", endDate);

    setLoading(true);
    DataService.post(Api.Admin.getPaymentSummaryReport, urlencoded)
      .then((response) => {
        setTransactionList(response.data.data);
        let filterData = response.data.data.filter(
          (row) => row?.type === intervalType
        );
        setFilterData(filterData);
        setPage((prev) => prev);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getAllBranches = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("type", "branch");
    DataService.post(Api.Admin.getResturantDetail, urlencoded)
      .then((response) => {
        if (response.data.status === 200) {
          setBranches(response.data.data);
        }
      })
      .catch((e) => {});
  };

  const handleUpdateStatus = (id) => {
    DataService.post(Api.Admin.updatePaymentSummaryReport, { id })
      .then((res) => {
        Index.successToast(res.data.message);
        getAllTransactionList("weekly", "", "");
      })
      .catch((e) => Index.errorToast(e));
  };

  useEffect(() => {
    getAllBranches();
    getAllTransactionList("weekly", "", "");
  }, []);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const changedNameData = apiData?.map((row) => {
      return {
        PYMT_PROD_TYPE_CODE: "PAB_VENDOR",
        PYMT_MODE: "NEFT",
        DEBIT_ACC_NO: "076205004407",
        BNF_NAME: Index.displayValue(row?.branch_id?.account_name),
        BENE_ACC_NO: Index.displayValue(
          row?.branch_id?.encrypted_account_number
        ),
        BENE_IFSC: Index.displayValue(row?.branch_id?.encrypted_ifsc_code),
        AMOUNT: Index.displayNumberValueWithToFixed(row?.total_amount),
        DEBIT_NARR: Index.displayValue(row?.debit_naar),
        CREDIT_NARR: Index.displayValue(row?.credit_naar),
        MOBILE_NUM: Index.displayValue(row?.branch_id?.branch_mobile_number),
        EMAIL_ID: Index.displayValue(row?.email_id),
        REMARK: Index.displayValue(row?.remark),
        PYMT_DATE: Index.displayValue(row?.payment_date),
        REF_NO: Index.displayValue(row?.ref_no),
        ADDL_INFO1: Index.displayValue(row?.add1),
        ADDL_INFO2: Index.displayValue(row?.add2),
        ADDL_INFO3: Index.displayValue(row?.add3),
        ADDL_INFO4: Index.displayValue(row?.add4),
        ADDL_INFO5: Index.displayValue(row?.add5),
        LEI_NUMBER: Index.displayValue(row?.landLineNumber),
      };
    });

    const ws = XLSX.utils.json_to_sheet(changedNameData);
    // delete ws["H1"];
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="view-btn-main border-btn-main filter-btn export-transaction-btn">
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!filterData.length}
                      onClick={() => exportToCSV(filterData, "Payment report")}
                    >
                      Export
                    </Index.Button>
                  </Index.Box>

                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Report
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group table-head-date-main">
                            <Index.Box className="from-to-date-table">
                              <Index.Box className="branch-name">
                                <Index.Typography>
                                  Select Type:
                                </Index.Typography>
                                <Index.Select
                                  value={intervalType}
                                  displayEmpty
                                  onChange={(e) => {
                                    setIntervalType(e.target.value);
                                  }}
                                  className="form-control"
                                >
                                  <Index.MenuItem value="weekly">
                                    Weekly
                                  </Index.MenuItem>
                                  <Index.MenuItem value="monthly">
                                    Monthly
                                  </Index.MenuItem>
                                </Index.Select>
                              </Index.Box>
                              <Index.Box className="from-to-date-table">
                                <Index.Box className="from-date-label fromto-date-label-same">
                                  <Index.Typography>From :</Index.Typography>
                                  <Index.TextField
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => {
                                      setStartDate(
                                        Index.moment(e.target.value).format(
                                          "YYYY-MM-DD"
                                        )
                                      );
                                      setEndDate("");
                                    }}
                                  />
                                </Index.Box>
                                <Index.Box className="to-date-label fromto-date-label-same">
                                  <Index.Typography>To :</Index.Typography>
                                  <Index.TextField
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => {
                                      setEndDate(
                                        Index.moment(e.target.value).format(
                                          "YYYY-MM-DD"
                                        )
                                      );
                                    }}
                                    inputProps={{
                                      min: startDate,
                                    }}
                                  />
                                </Index.Box>
                              </Index.Box>

                              <Index.Box className="view-btn-main border-btn-main filter-btn">
                                <Index.Button
                                  className="view-order-btn border-btn"
                                  onClick={() =>
                                    getAllTransactionList(
                                      intervalType,
                                      startDate,
                                      endDate
                                    )
                                  }
                                >
                                  Filter
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main transaction-history-table">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Branch name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Start Date
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                End Date
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Total Orders
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Net receivable amount
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Payment status
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData.length > 0 ? (
                              filterData &&
                              paginate(filterData, rowsPerPage, page + 1).map(
                                (row, index) => (
                                  <Index.TableRow
                                    key={row?._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayValue(
                                        row?.branch_id?.branch_name
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayDateValue(
                                        row?.report_start_date
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayDateValue(
                                        row?.report_end_date
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValue(
                                        row?.total_orders
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayNumberValueWithToFixed(
                                        row?.total_amount
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Typography
                                        variant="p"
                                        color={
                                          row?.payment_status.toLowerCase() ===
                                          "pending"
                                            ? "red"
                                            : "green"
                                        }
                                      >
                                        {Index.displayValue(
                                          row?.payment_status
                                        )}
                                      </Index.Typography>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      <Index.Button
                                        className="table-btn"
                                        onClick={() => {
                                          localStorage.setItem(
                                            "transactionTabId",
                                            "3"
                                          );
                                          navigate(
                                            `/payment-report/${
                                              row?.branch_id?._id
                                            }/${Index.moment(
                                              row?.report_start_date
                                            ).format(
                                              "YYYY-MM-DD"
                                            )}/${Index.moment(
                                              row?.report_end_date
                                            ).format("YYYY-MM-DD")}`,
                                            {
                                              state: {
                                                branch_name:
                                                  row?.branch_id?.branch_name,
                                              },
                                            }
                                          );
                                        }}
                                      >
                                        <img
                                          src={Index.Svg.yelloweye}
                                          alt="view-icon"
                                        ></img>
                                      </Index.Button>

                                      <Index.Button className="table-btn">
                                        <Index.DoneIcon
                                          onClick={() =>
                                            handleUpdateStatus(row?._id)
                                          }
                                        />
                                      </Index.Button>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                )
                              )
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell align="center" colSpan={9}>
                                  No record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filterData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Index.Box>

          {/* {open && (
            <ViewSingleTransactionDetail
              open={open}
              handleToggleOpen={handleToggleOpen}
              row={row}
              displayAmount={displayAmount}
            />
          )} */}
        </Index.Box>
      )}
    </>
  );
};

export default PaymentReportSummary;
