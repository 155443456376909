import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";

const Notifications = () => {
  const [NotificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAllNotificationData = async () => {
    setLoading(true);
    await DataService.get(Api.Admin.getNotificationList)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          setNotificationData(response.data.data);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllNotificationData();
  }, []);
  return (
    <>
      <Index.Box className="dashboard-content add-user-containt">
        <Index.Typography
          className="admin-page-title"
          component="h2"
          variant="h2"
        >
          Notifications
        </Index.Typography>

        {loading ? (
          <Index.Loader />
        ) : (
          <Index.Box>
            {NotificationData.length > 0 ? (
              NotificationData.map((item, index) => (
                <Index.Box className="notification_row" key={index}>
                  <Index.Box>
                    <img
                      src={Index.Png.singleLogo}
                      className="notification-icon"
                      alt="dashboard icon"
                    />
                  </Index.Box>

                  <Index.Box className="notification_body">
                    <Index.Typography
                      className="notification_title admin-dash-price"
                      variant="h6"
                      // component="h1"
                    >
                      {item.title}
                    </Index.Typography>
                    <Index.Typography
                      className="notification_subtitle common-para"
                      variant="body2"
                      // component="h1"
                    >
                      {item.text}
                    </Index.Typography>
                    <Index.Box className="notification_date">
                      {Index.moment(item.createdAt).format("DD-MM-YYYY hh:mm A")}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              ))
            ) : (
              <Index.Typography> No Data Found</Index.Typography>
            )}
          </Index.Box>
        )}
      </Index.Box>
    </>
  );
};

export default Notifications;
