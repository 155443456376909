import React, { useState } from "react";
import Index from "../../../../../component/Index";
import { Controller, useForm } from "react-hook-form";
import { Api } from "../../../../../config/Api";
import DataService from "../../../../../config/DataService";

const AddWallet = (props) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const formdata = new URLSearchParams();
    formdata.append("user_id", props?.userDetails?._id);
    formdata.append("description", data.description);
    formdata.append("transaction_type", data.transactionType);
    formdata.append("wallet_amount", data.amount);
    // formdata.append("date", Index.moment().format("YYYY-MM-DD"));
    // formdata.append("time", Index.moment().format("HH:mm"));

    await DataService.post(Api.Admin.creditAndDebitUserWallet, formdata)
      .then((res) => {
        Index.toast.success(res.data.message)
        props.getUserWalletById();
        props.handleClose();
      })
      .catch((e) => {
        Index.toast.error(e.response.data.message);
        setLoading(false);
      });
  };
  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Add wallet
          </Index.Typography>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="name"
                defaultValue={props?.userDetails?.name}
                disabled
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Description
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="description"
                {...register("description", {
                  required: "Description is required",
                })}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Amount
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="amount"
                {...register("amount", {
                  required: "Amount is required",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Please enter a valid amount",
                  },
                })}
                error={Boolean(errors.amount)}
                helperText={errors.amount?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box add-user-input radio-main">
            <Index.FormHelperText className="form-lable">
              Transaction type
            </Index.FormHelperText>
            <Index.FormControl className="form-control">
              <Controller
                name="transactionType"
                control={control}
                {...register("transactionType", {
                  required: "Transaction type is required",
                })}
                render={({ field: { onChange, value } }) => (
                  <Index.RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    className="radiogroup"
                    labelId="priceLimit"
                    value={value}
                    onChange={onChange}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <Index.FormControlLabel
                      value="Credit"
                      control={<Index.Radio className="radio" />}
                      label="Credit"
                    />
                    {/* <Index.FormControlLabel
                      value="Debit"
                      control={<Index.Radio className="radio" />}
                      label="Debit"
                    /> */}
                  </Index.RadioGroup>
                )}
              />
            </Index.FormControl>

            <Index.FormHelperText error id="accountId-error">
              {errors?.status?.message ? errors?.status?.message : ""}
            </Index.FormHelperText>
          </Index.Box>

          <Index.SubmitDiscardButton
            loading={loading}
            handleClose={props.handleClose}
          />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddWallet;
