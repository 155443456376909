import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { useForm } from "react-hook-form";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const AddCalanederEvent = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    setLoading(true)
    const urlencoded = new URLSearchParams();
    urlencoded.append("event_name", data.title);
    urlencoded.append("start_date", data.startDate);
    urlencoded.append("end_date", data.endDate);
    props.row && urlencoded.append("id", props.row?.id);

    DataService.post(Api.Admin.addEditCalenderSetting, urlencoded)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          setLoading(false);
          if (props.row) {
            Index.toast.success("Event updated successfully");
          } else {
            Index.toast.success("Event added successfully");
          }
          props.handleClose();
          props.getAllCalenderSetting();
          reset();
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    if (props.row) {
      setValue("title", props.row?.title);
      setValue(
        "startDate",
        Index.moment(props.row.start).format("YYYY-MM-DDTHH:mm")
      );
      setValue(
        "endDate",
        Index.moment(props.row.end).format("YYYY-MM-DDTHH:mm")
      );
    }
  }, [props.row]);

  const handleStardDate = (e) => {
    // Getting timestamps of dates
    const startDateTimeStamps = new Date(e.target.value).getTime();
    const endDateTimeStamps = new Date(watch("endDate")).getTime();

    if (startDateTimeStamps > endDateTimeStamps) {
      setError("startDate", {
        type: "manual",
        message: "Start date should not be grater than end date",
      });
    } else {
      clearErrors("startDate");
      clearErrors("endDate");
    }
  };

  const handleEndDate = (e) => {
    // Getting timestamps of dates
    const startDateTimeStamps = new Date(watch("startDate")).getTime();
    const endDateTimeStamps = new Date(e.target.value).getTime();

    if (endDateTimeStamps < startDateTimeStamps) {
      setError("endDate", {
        type: "manual",
        message: "End date should not be lesser than start date",
      });
    } else {
      clearErrors("startDate");
      clearErrors("endDate");
    }
  };

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            {props.row ? "Edit" : "Add"} Evenet
          </Index.Typography>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Title
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="title"
                {...register("title", {
                  required: "Title is required",
                })}
                error={Boolean(errors.title)}
                helperText={errors.title?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Start Date
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                type="datetime-local"
                fullWidth
                id="fullWidth"
                className="form-control"
                name="startDate"
                {...register("startDate", {
                  required: "Start date is required",
                  onChange: (e) => {
                    handleStardDate(e);
                  },
                })}
                error={Boolean(errors.startDate)}
                helperText={errors.startDate?.message}
                inputProps={{
                  max: watch("endDate") ? watch("endDate") : "",
                }}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              End Date
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                type="datetime-local"
                fullWidth
                id="fullWidth"
                className="form-control"
                name="endDate"
                {...register("endDate", {
                  required: "End date is required",
                  onChange: (e) => handleEndDate(e),
                })}
                error={Boolean(errors.endDate)}
                helperText={errors.endDate?.message}
                inputProps={{
                  min: watch("startDate") ? watch("startDate") : "",
                }}
              />
            </Index.Box>
          </Index.Box>

          <Index.SubmitDiscardButton
            handleClose={props.handleClose}
            loading={loading}
          />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddCalanederEvent;
