import React, { useEffect, useState } from "react";
import { Api } from "../../../../config/Api";
import DataService from "../../../../config/DataService";
import Index from "../../../../component/Index";

const PaneltyList = () => {
  const [paneltyList, setPaneltyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getAllPaneltyList = async () => {
    setLoading(true);
    await DataService.get(Api.Admin.getPaneltyList)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          setLoading(false);
          setPaneltyList(res.data.data);
          setFilterData(res.data.data);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllPaneltyList();
  }, []);

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterData = (e) => {
    if (e.target.value === "") {
      setFilterData(paneltyList);
    } else {
      const result = paneltyList.filter((item) => {
        return (
          item?.row?.branch_id?.branch_name
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          item?.penalty_amount
            .toString()
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          Index.moment(item?.createdAt)
            .format("L")
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) ||
          item?.reason?.toLowerCase().includes(e.target.value?.toLowerCase())
        );
      });
      setFilterData(result);
      setPage(0);
    }
  };

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="flex-all admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Panelty List
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="view-btn-main border-btn-main">
                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Search panelty"
                              onChange={(e) => handleFilterData(e)}
                            />

                            <img
                              src={Index.Svg.search}
                              className="search-grey-img"
                              alt="search grey img"
                            ></img>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      {/* <Index.Button
                        className="view-order-btn border-btn"
                        onClick={() => {
                          handleOpen();
                        }}
                      >
                        Add User
                      </Index.Button> */}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="page-table-main suscriber-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 500 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Branch Name
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Panelty Amount
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Date
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Reason
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filterData.length > 0 ? (
                            filterData &&
                            paginate(filterData, rowsPerPage, page + 1).map(
                              (row, index) => (
                                <Index.TableRow
                                  key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.branch_id?.branch_name || "-"}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.penalty_amount || "-"}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.createdAt
                                      ? Index.moment(row?.createdAt).format("L")
                                      : "-"}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.reason}
                                  </Index.TableCell>
                                </Index.TableRow>
                              )
                            )
                          ) : (
                            <Index.TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell align="center" colSpan={5}>
                                No record found
                              </Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filterData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Index.Box>
      )}
    </>
  );
};

export default PaneltyList;
