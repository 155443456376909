import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { useForm } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const AddFeatures = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [imageName, setImageName] = useState("");
  const [images, setImages] = useState(null);
  const [editorData, setEditorData] = useState("");
  const [imageError , setImageError] = useState('');

  const handleImageChange = (e) => {
    setImageName(e.target.files[0].name);
    setImages(e.target.files[0]);
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const onSubmit = async(data)=>{
        
    const formData = new FormData();
    formData.append("title", data.title)
    formData.append("image", images ? images : imageName);
    formData.append("description", editorData)

    props.row && formData.append("id", props.row?._id)
    await  DataService.post(Api.Admin.addEditAdminFeature, formData).then((res)=>{

      if(res.data.status === 200 || res.data.status === 201)
      {
        if(props.row)
        {
          Index.toast.success("Feature updated successfully")
        }
        else
        {
          Index.toast.success("Feature adedd successfully")
      
        }
        props.handleClose();
        props.getAllFeature();
        reset();
        setImages(null)
        setImageName("")
        
      }
    }).catch((e)=>{
      Index.toast.error(e.response.data.message)
    })
  }

  useEffect(()=>{
    if(props.row)
    {
      setValue("title", props.row.title)
      setImageName(props.row ? props?.row?.image : "Select File");
      setEditorData(props.row ? props?.row?.description : '');
    }
  },[])

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box sx={Index.Constant.MODAL_STYLE} className="add-food-item-modal-inner-main about-us-main">
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
           {props.row ? "Update" : "Add"} Features
          </Index.Typography>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Icon
            </Index.FormHelperText>

            <Index.Button
              variant="outlined"
              fullWidth
              // startIcon={<Index.AddIcon/>}
              component="label"
              className="file-upload-btn"
              // onChange={(e) => handleImageChange(e)}
            >
              {imageName != "" ? imageName : "Select File"}
              <input
                hidden
                accept="files/*"
                id="contained-button-file"
                // multiple
                type="file"
                onChange={(e) => {
                  handleImageChange(e);
                }}
              />
            </Index.Button>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Titile
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="title"
                {...register("title", {
                  required: "Title is required",
                })}
                error={Boolean(errors.title)}
                helperText={errors.title?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Description
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <CKEditor
                editor={ClassicEditor}
                data={editorData}
                onChange={handleEditorChange}
                //    name="description"
                //   {...register("description", {
                //     required: "Description is required",
                //   })}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="modal-user-btn-flex">
          <Index.Box className="discard-btn-main border-btn-main">
            <Index.Button
              className="discard-user-btn border-btn"
              onClick={props.handleClose}
            >
              Discard
            </Index.Button>
          </Index.Box>
            <Index.Box className="save-btn-main border-btn-main">
              <Index.Button className="save-user-btn border-btn" type="submit">
                <img src={Index.Svg.save} className="user-save-icon"></img>
                Submit
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddFeatures;
