import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import BranchList from "./BranchList";
import { CircularProgress } from "@mui/material";

const AddOffer = (props) => {
  const [eventsData, setEventsData] = useState([]);
  const [endUserList, setEndUserList] = useState([]);
  const [number, setNumbers] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [branchListByCity, setBranchListByCity] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = Index.useNavigate();
  const location = Index.useLocation();
  const row = location?.state;

  const [cityError, setCityError] = useState("");
  const [city, setCity] = useState(row?.city || "");
  const [branches, setBranches] = useState(
    row?.branch_ids?.length ? row.branch_ids : []
  );

  // Promotion Type
  const [promotionType, setPromotionType] = useState({
    promotionType: row?.category ? row?.category : "",
    percentageOff: row?.category_value
      ? row?.category === "Percentage Off"
        ? row?.category_value
        : ""
      : "",
    moneyOff: row?.category_value
      ? row?.category === "Money Off"
        ? row?.category_value
        : ""
      : "",
  });

  const [promotionTypeError, setPromotionTypeError] = useState({
    promotionType: "",
    percentageOff: "",
    moneyOff: "",
  });

  //End promotion type

  // Split offer
  const [splitOffer, setSplitOffer] = useState({
    adminSplit: row?.split_discount?.admin_split || "",
    restaurantSplit: row?.split_discount?.restaurant_split || "",
  });

  const [splitOfferError, setSplitOfferError] = useState({
    adminSplit: "",
    restaurantSplit: "",
  });
  // End split offer

  //Promotion sub type
  const [promotionSubType, setPromotionSubType] = useState({
    promotionSubType: row?.offer ? row?.offer : "",
    calenderEvent: row?.offer
      ? row?.offer === "Calender Event"
        ? row?.offer_value
        : ""
      : "",
    selectedPastCustomer: row?.offer
      ? row?.offer === "Selected Past Customer"
        ? row?.offer_value
        : ""
      : "",
    userList: row?.user_id
      ? row.user_id.map((user) => {
          return { user_id: user.user_id };
        })
      : [],
  });

  const [promotionSubTypeError, setPromotionSubTypeError] = useState({
    promotionSubType: "",
    calenderEvent: "",
    selectedPastCustomer: "",
  });

  //end promotion sub type

  //other details
  const [otherDetails, setOtherDetails] = useState({
    usage: row?.usage ? row?.usage : "Usage Per User",
    qty: row?.usage_value ? row?.usage_value : 1,
    startDate: row?.start_date
      ? Index.moment(row?.start_date).format("YYYY-MM-DD")
      : "",
    endDate: row?.end_date
      ? Index.moment(row?.end_date).format("YYYY-MM-DD")
      : "",
    minPurchaseAmount: row?.min_purchase_amount ? row?.min_purchase_amount : "",
    maxDiscountAmount: row?.max_discount_amount ? row?.max_discount_amount : "",
  });

  const [otherDetailsError, setOtherDetailsError] = useState({
    usage: "",
    qty: 1,
    startDate: null,
    endDate: null,
    minPurchaseAmount: "",
    maxDiscountAmount: "",
  });

  //end other details
  const handleChangePromotionType = (event) => {
    const { name, value } = event.target;
    setPromotionType({ ...promotionType, [name]: value });
    setPromotionTypeError({ ...promotionTypeError, [name]: "" });

    //disbaling maxdiscount if porpertyKey has money off value
    if (value === "Money Off") {
      setOtherDetails({
        ...otherDetails,
        maxDiscountAmount: "",
      });

      setOtherDetailsError({
        ...otherDetailsError,
        maxDiscountAmount: "",
      });
    }
  };

  const handleChangeSplitOffer = (e) => {
    const { name, value } = e.target;

    setSplitOffer({
      ...splitOffer,
      [name]: value,
    });

    if((name==="adminSplit" && +value===100) ||(name==="restaurantSplit" && +value===100))
    {
      setSplitOfferError({
        adminSplit:"",
        restaurantSplit:""
      })
    }
    else
    {
      setSplitOfferError({
        ...splitOfferError,
        [name]: "",
      });
    }
    
  };

  const handleChangeCity = (e) => {
    setCity(e.target.value);
    setCityError("");
  };

  const handleChangeBranches = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setBranches([...branches, value]);
    } else {
      const newArr = [...branches].filter((item) => item !== value);

      setBranches(newArr);
    }
  };

  const handleChangePromotionSubType = (event) => {
    const { name, value } = event.target;

    //if not calender event then empty calender event state and startdate and enddate value
    if (name == "promotionSubType") {
      setPromotionSubType({
        ...promotionSubType,
        [name]: value,
        calenderEvent: "",
      });
      setOtherDetails({
        ...otherDetails,
        startDate: "",
        endDate: "",
      });
    } else {
      setPromotionSubType({ ...promotionSubType, [name]: value });
    }

    //setting default date of calender event to start and end date
    if (name === "calenderEvent" && value) {
      let event = eventsData.find((row) => row.event_name === value);
      setOtherDetails((prev) => ({
        ...prev,
        startDate: Index.moment(event.start_date).format("YYYY-MM-DD"),
        endDate: Index.moment(event.end_date).format("YYYY-MM-DD"),
      }));
      setOtherDetailsError((prev) => ({
        ...prev,
        startDate: "",
        endDate: "",
      }));
    }

    setPromotionSubTypeError({ ...promotionSubTypeError, [name]: "" });
  };

  const handleChangeOtherDetails = (event) => {
    const { name, value } = event.target;
    setOtherDetails({ ...otherDetails, [name]: value });
    setOtherDetailsError({ ...otherDetailsError, [name]: "" });

    if (name === "minPurchaseAmount" && value) {
      if (
        promotionType.promotionType === "Money Off" &&
        promotionType.moneyOff !== ""
      ) {
        if (+value < +promotionType.moneyOff) {
          setOtherDetailsError((prev) => ({
            ...prev,
            minPurchaseAmount:
              "Minimum purchase amount should be grater than Money off amount",
          }));
        }
      }
    }
  };

  const handleChangeEndUser = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      const newArr = [...promotionSubType.userList];
      newArr.push({ user_id: value });
      setPromotionSubType((prev) => ({
        ...prev,
        userList: newArr,
      }));
    } else {
      const newArr = [...promotionSubType.userList].filter(
        (item) => item.user_id !== value
      );

      setPromotionSubType((prev) => ({
        ...prev,
        userList: newArr,
      }));
    }
  };

  const validate = () => {
    if (!promotionType.promotionType) {
      setPromotionTypeError((prev) => ({
        ...prev,
        promotionType: "Promotion type is required",
      }));
    }

    if (
      promotionType.promotionType === "Percentage Off" &&
      !promotionType.percentageOff
    ) {
      setPromotionTypeError((prev) => ({
        ...prev,

        percentageOff: "Percentage off is required",
      }));
    }
    if (
      promotionType.promotionType === "Money Off" &&
      !promotionType.moneyOff
    ) {
      setPromotionTypeError((prev) => ({
        ...prev,
        moneyOff: "Money off is required",
      }));
    }
    if (!promotionSubType.promotionSubType) {
      setPromotionSubTypeError((prev) => ({
        ...prev,
        promotionSubType: "Promotion sub type is required",
      }));
    }

    if (
      promotionSubType.promotionSubType === "Calender Event" &&
      !promotionSubType.calenderEvent
    ) {
      setPromotionSubTypeError((prev) => ({
        ...prev,
        calenderEvent: "Calender event is required",
      }));
    }

    if (!otherDetails.startDate) {
      setOtherDetailsError((prev) => ({
        ...prev,
        startDate: "Start date is required",
      }));
    }

    if (!otherDetails.endDate) {
      setOtherDetailsError((prev) => ({
        ...prev,
        endDate: "End date is required",
      }));
    }

    if (!otherDetails.usage) {
      setOtherDetailsError((prev) => ({
        ...prev,
        usage: "Usage is required",
      }));
    }

    if (
      !otherDetails.maxDiscountAmount &&
      promotionType.promotionType !== "Money Off"
    ) {
      setOtherDetailsError((prev) => ({
        ...prev,
        maxDiscountAmount: "Maximum discount amount is required",
      }));
    }
    if (!otherDetails.minPurchaseAmount) {
      setOtherDetailsError((prev) => ({
        ...prev,
        minPurchaseAmount: "Minimum purchase amount is required",
      }));
    }
    if (
      promotionSubType.promotionSubType === "Selected Past Customer" &&
      promotionSubType.selectedPastCustomer === ""
    ) {
      setPromotionSubTypeError((prev) => ({
        ...prev,
        selectedPastCustomer: "Selected Past Customer is required",
      }));
    }

    if (otherDetails.minPurchaseAmount) {
      if (
        promotionType.promotionType === "Money Off" &&
        promotionType.moneyOff !== ""
      ) {
        if (+otherDetails.minPurchaseAmount < +promotionType.moneyOff) {
          setOtherDetailsError((prev) => ({
            ...prev,
            minPurchaseAmount:
              "Minimum purchase amount should be grater than Money off amount",
          }));
        }
      }
    }

    if (!splitOffer.adminSplit && +splitOffer.restaurantSplit!== 100) {
      setSplitOfferError({
        ...splitOfferError,
        adminSplit: "Percentage for admin is required",
      });
    }
    if (!splitOffer.restaurantSplit && +splitOffer.adminSplit!== 100) {
      setSplitOfferError({
        ...splitOfferError,
        restaurantSplit: "Percentage for restaurant is required",
      });
    }
    if (!city) {
      setCityError("City is required");
    }
    if (!branches) {
      setCityError("Branch is required");
    }
    if (splitOffer.adminSplit && splitOffer.restaurantSplit) {
      if (+splitOffer.adminSplit + +splitOffer.restaurantSplit !== 100) {
        setSplitOfferError({
          ...splitOfferError,
          restaurantSplit: "Split offer total must be 100",
        });
      }
    }
  };

  console.log(splitOfferError,"error")
  const getAllEvents = async () => {
    await DataService.get(Api.Admin.getAllCalenderSetting).then((res) => {
      if (res) {
        setEventsData(res.data.data);
      }
    });
  };

  const getAllUserList = async () => {
    await DataService.post(Api.Admin.getAllUserList).then((res) => {
      if (res) {
        setEndUserList(res.data.data);
      }
    });
  };

  const getAllCityList = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("type", "city");
    DataService.post(Api.Common.getAllCityOrBranchesByCity, urlencoded).then(
      (res) => {
        setCityList(res.data.data);
      }
    );
  };

  const getBranchListByCity = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("city", city);
    DataService.post(Api.Common.getAllCityOrBranchesByCity, urlencoded).then(
      (res) => {
        setBranchListByCity(res.data.data);
      }
    );
  };

  useEffect(() => {
    getBranchListByCity();
  }, [city]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !promotionType.promotionType ||
      (promotionType.promotionType === "Percentage Off" &&
        !promotionType.percentageOff) ||
      (promotionType.promotionType === "Money Off" &&
        !promotionType.moneyOff) ||
      !promotionSubType.promotionSubType ||
      (promotionSubType.promotionSubType === "Calender Event" &&
        !promotionSubType.calenderEvent) ||
      !otherDetails.usage ||
      !otherDetails.startDate ||
      !otherDetails.endDate ||
      (!otherDetails.maxDiscountAmount &&
        promotionType.promotionType !== "Money Off") ||
      !otherDetails.minPurchaseAmount ||
      (promotionSubType.promotionSubType === "Selected Past Customer" &&
        promotionSubType.selectedPastCustomer === "") ||
      (otherDetails.minPurchaseAmount &&
        promotionType.promotionType === "Money Off" &&
        promotionType.moneyOff !== "" &&
        +otherDetails.minPurchaseAmount < +promotionType.moneyOff) ||
      (!splitOffer.restaurantSplit && +splitOffer.adminSplit!== 100)||
      (!splitOffer.adminSplit && +splitOffer.restaurantSplit!== 100) ||
      !city ||
      !branches ||
      +splitOffer.adminSplit + +splitOffer.restaurantSplit !== 100
    ) {
      validate();
      return false;
    }

    // const urlencoded = new URLSearchParams();
    // urlencoded.append("category", promotionType.promotionType);
    // urlencoded.append(
    //   "category_value",
    //   promotionType.promotionType === "Percentage Off"
    //     ? promotionType.percentageOff
    //     : promotionType.promotionType === "Money Off"
    //     ? promotionType.moneyOff
    //     : ""
    // );
    // urlencoded.append("offer", promotionSubType.promotionSubType);
    // urlencoded.append(
    //   "offer_value",
    //   promotionSubType.promotionSubType === "Calender Event"
    //     ? promotionSubType.calenderEvent
    //     : promotionSubType.promotionSubType === "Selected Past Customer"
    //     ? promotionSubType.selectedPastCustomer
    //     : ""
    // );
    // urlencoded.append("user_id", promotionSubType.userList);
    // urlencoded.append("start_date", otherDetails.startDate);
    // urlencoded.append("end_date", otherDetails.endDate);
    // urlencoded.append("usage", otherDetails.usage);
    // urlencoded.append("usage_value", otherDetails.qty);
    // urlencoded.append("min_purchase_amount", otherDetails.minPurchaseAmount);
    // urlencoded.append("max_discount_amount", otherDetails.maxDiscountAmount);
    // row?._id && urlencoded.append("id", row?._id);

    setLoading(true);
    const data = {
      category: promotionType.promotionType,
      category_value:
        promotionType.promotionType === "Percentage Off"
          ? promotionType.percentageOff
          : promotionType.promotionType === "Money Off"
          ? promotionType.moneyOff
          : "",
      offer: promotionSubType.promotionSubType,
      offer_value:
        promotionSubType.promotionSubType === "Calender Event"
          ? promotionSubType.calenderEvent
          : promotionSubType.promotionSubType === "Selected Past Customer"
          ? promotionSubType.selectedPastCustomer
          : "",
      user_id: promotionSubType.userList,
      start_date: otherDetails.startDate,
      end_date: otherDetails.endDate,
      usage: otherDetails.usage,
      usage_value: otherDetails.qty,
      min_purchase_amount: otherDetails.minPurchaseAmount,
      max_discount_amount: otherDetails.maxDiscountAmount,
      split_discount: {
        admin_split: +splitOffer.adminSplit,
        restaurant_split: +splitOffer.restaurantSplit,
      },
      branch_ids: branches,
      city: city,
    };

    if (row?._id) {
      data.id = row?._id;
    }

    DataService.post(Api.Admin.addEditOffer, data)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          Index.toast.success(res.data.message);
          // setData(intitalValue);
          navigate("/offer-management");
          setLoading(false);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  // const handleReset = (e) => {
  //   e.preventDefault();
  //   setData(intitalValue);
  // };
  useEffect(() => {
    if (row) {
      getAllUserList();
    }
  }, [row]);

  useEffect(() => {
    getAllEvents();
    getAllUserList();
    getAllCityList();

    for (let i = 1; i <= 100; i++) {
      if (i % 5 == 0) {
        setNumbers((prev) => [...prev, i]);
      }
    }
  }, []);

  console.log(row);
  return (
    <Index.Box className="add-offer-main dashboard-content">
      <Index.Box className="container">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column headercolumn-main  "
            >
              <Index.Box>
                <Index.Typography
                  variant="h5"
                  className="title admin-page-title"
                >
                  {" "}
                  Promotion Management
                </Index.Typography>
                <Index.Box />
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column "
              >
                <Index.Box className="promotion-type mb">
                  <Index.FormControl>
                    <Index.FormLabel className="sub-title ">
                      Promotion Type
                    </Index.FormLabel>
                    <Index.RadioGroup
                      row
                      className="radio-row"
                      onChange={handleChangePromotionType}
                      name="promotionType"
                      value={promotionType.promotionType}
                    >
                      <Index.FormControlLabel
                        value="Percentage Off"
                        control={<Index.Radio />}
                        label="Percentage off"
                        className="selector-title set-pro-lable"
                      />
                      <Index.FormControlLabel
                        value="Money Off"
                        control={<Index.Radio />}
                        label="Money off"
                        className="set-pro-lable"
                      />
                      {/* <Index.FormControlLabel
                        value="Buy 1 Get 1 Free"
                        control={<Index.Radio />}
                        label="Buy 1 get 1 free"
                        className="set-pro-lable"
                      /> */}
                    </Index.RadioGroup>
                    <Index.FormHelperText error>
                      {promotionTypeError.promotionType}
                    </Index.FormHelperText>
                  </Index.FormControl>

                  {promotionType.promotionType === "Percentage Off" && (
                    <>
                      <Index.Box className="promotion-type-subdiv">
                        <Index.Typography> Percentage</Index.Typography>
                        <Index.Box>
                          <Index.Select
                            className="promotion-type-sub-textfield"
                            onChange={handleChangePromotionType}
                            name="percentageOff"
                            value={promotionType.percentageOff}
                            size="small"
                          >
                            {number.map((num) => {
                              return (
                                <Index.MenuItem value={num}>
                                  {num}
                                </Index.MenuItem>
                              );
                            })}
                          </Index.Select>
                        </Index.Box>
                        <Index.FormHelperText error sx={{ marginTop: "5px" }}>
                          {promotionTypeError.percentageOff}
                        </Index.FormHelperText>
                      </Index.Box>
                    </>
                  )}

                  {promotionType.promotionType === "Money Off" && (
                    <>
                      <Index.Box className="promotion-type-subdiv">
                        <Index.Typography> Amount</Index.Typography>
                        <Index.Box>
                          <Index.TextField
                            type="number"
                            name="moneyOff"
                            className="promotion-type-sub-textfield"
                            value={promotionType.moneyOff}
                            onChange={handleChangePromotionType}
                            error={promotionTypeError.moneyOff}
                            helperText={promotionTypeError.moneyOff}
                            size="small"
                          />
                        </Index.Box>
                      </Index.Box>
                    </>
                  )}
                </Index.Box>
              </Index.Box>

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column "
              >
                <Index.Box className="offer-timeline mb di">
                  <Index.FormLabel className="sub-title">
                    Split offer
                  </Index.FormLabel>

                  <Index.Box className="offer-subtimeline display-custm">
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column  set-datepiker set-main-box-date "
                    >
                      <Index.Box className="clndr-wrap">
                        <Index.Typography variant="p" className="set-text-date">
                          Admin
                        </Index.Typography>

                        <Index.Box className="clndr-1">
                          <Index.TextField
                            type="number"
                            onChange={handleChangeSplitOffer}
                            value={splitOffer.adminSplit}
                            className="set-min-box"
                            name="adminSplit"
                            error={splitOfferError?.adminSplit}
                            helperText={splitOfferError?.adminSplit}
                            InputProps={{
                              endAdornment: (
                                <Index.InputAdornment position="end">
                                  <Index.Typography variant="subtitle1">
                                    %
                                  </Index.Typography>
                                </Index.InputAdornment>
                              ),

                              inputProps: {
                                min: 0,
                              },
                            }}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column set-datepiker set-main-box-date "
                    >
                      <Index.Typography variant="p" className="set-text-date">
                        Restaurant
                      </Index.Typography>
                      <Index.Box className="clndr-1 display-custm">
                        <Index.TextField
                          type="number"
                          onChange={handleChangeSplitOffer}
                          value={splitOffer?.restaurantSplit}
                          className="set-min-box"
                          name="restaurantSplit"
                          error={splitOfferError?.restaurantSplit}
                          helperText={splitOfferError?.restaurantSplit}
                          InputProps={{
                            endAdornment: (
                              <Index.InputAdornment position="end">
                                <Index.Typography variant="subtitle1">
                                  %
                                </Index.Typography>
                              </Index.InputAdornment>
                            ),
                            inputProps: {
                              min: 0,
                            },
                          }}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="offer-subtimeline display-custm">
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column  set-datepiker set-main-box-date "
                    >
                      <Index.Box className="promotion-type-subdiv">
                        <Index.Typography variant="p" className="set-text-date">
                          Select City
                        </Index.Typography>
                        <Index.Box>
                          <Index.Select
                            value={city}
                            onChange={(e) => {
                              handleChangeCity(e);
                              setBranches([]);
                              // getBranchListByCity(e.target.value);
                            }}
                            className="promotion-type-sub-textfield"
                            name="admin"
                            size="small"
                          >
                            {cityList?.map((row) => {
                              return (
                                <Index.MenuItem value={row.city}>
                                  {row.city}
                                </Index.MenuItem>
                              );
                            })}
                          </Index.Select>
                        </Index.Box>
                        <Index.FormHelperText error sx={{ marginTop: "5px" }}>
                          {cityError}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              {city &&
                (branchListByCity.length > 0 ? (
                  <BranchList
                    branchList={branchListByCity}
                    handleChangeBranches={handleChangeBranches}
                    branches={branches}
                    setBranches={setBranches} //passing to handle calculation of select all when we do filter
                  />
                ) : (
                  ""
                ))}

              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column  "
              >
                <Index.Box className="promotion-sub-type mb">
                  <Index.FormControl>
                    <Index.FormLabel className="sub-title">
                      Promotion Subtype
                    </Index.FormLabel>
                    <Index.RadioGroup
                      row
                      onChange={handleChangePromotionSubType}
                      name="promotionSubType"
                      value={promotionSubType.promotionSubType}
                    >
                      <Index.FormControlLabel
                        value="For Overall"
                        control={<Index.Radio />}
                        label="For Overall"
                        className="set-pro-lable3"
                      />
                      <Index.FormControlLabel
                        value="First Order"
                        control={<Index.Radio />}
                        label="First Order"
                        className="set-pro-lable3"
                      />
                      <Index.FormControlLabel
                        value="Calender Event"
                        control={<Index.Radio />}
                        label="Calender Event"
                        className="set-pro-lable3"
                      />
                      <Index.FormControlLabel
                        value="Selected Past Customer"
                        control={<Index.Radio />}
                        label="Selected Past Customer"
                        className="set-pro-lable3"
                      />
                    </Index.RadioGroup>
                    <Index.FormHelperText error>
                      {promotionSubTypeError.promotionSubType}
                    </Index.FormHelperText>
                  </Index.FormControl>

                  {promotionSubType.promotionSubType === "Calender Event" && (
                    <>
                      <Index.Box className="promotion-type-subdiv">
                        <Index.Typography> Select Event</Index.Typography>
                        <Index.Box>
                          <Index.RadioGroup
                            row
                            onChange={handleChangePromotionSubType}
                            name="calenderEvent"
                            value={promotionSubType.calenderEvent}
                          >
                            {eventsData.map((item) => (
                              <Index.FormControlLabel
                                value={item.event_name}
                                label={item.event_name}
                                control={<Index.Radio />}
                                className="set-pro-lable3"
                              />
                            ))}
                          </Index.RadioGroup>
                          <Index.FormHelperText error>
                            {promotionSubTypeError.calenderEvent}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </>
                  )}

                  {promotionSubType.promotionSubType ===
                    "Selected Past Customer" && (
                    <>
                      <Index.Box className="promotion-type-subdiv">
                        <Index.Typography> Select Customer</Index.Typography>
                        <Index.Box>
                          <Index.RadioGroup
                            row
                            onChange={handleChangePromotionSubType}
                            name="selectedPastCustomer"
                            value={promotionSubType.selectedPastCustomer}
                          >
                            <Index.FormControlLabel
                              value="Users Above 4 Stars"
                              control={<Index.Radio />}
                              label="Users Above 4 Stars"
                              className="set-pro-lable3"
                            />
                            <Index.FormControlLabel
                              value="Users Above 3 Stars"
                              control={<Index.Radio />}
                              label="Users Above 3 Stars"
                              className="set-pro-lable3"
                            />
                            <Index.FormControlLabel
                              value="Frequently Visiting Users"
                              control={<Index.Radio />}
                              label="Frequently Visiting Users"
                              className="set-pro-lable3"
                            />
                            <Index.FormControlLabel
                              value="Huge Group Users"
                              control={<Index.Radio />}
                              label="Huge Group Users"
                              className="set-pro-lable3"
                            />
                            <Index.FormControlLabel
                              value="User"
                              control={<Index.Radio />}
                              label="User"
                              className="set-pro-lable3"
                            />
                          </Index.RadioGroup>
                          <Index.FormHelperText error>
                            {promotionSubTypeError.selectedPastCustomer}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </>
                  )}

                  {promotionSubType.promotionSubType ===
                    "Selected Past Customer" &&
                    promotionSubType.selectedPastCustomer === "User" && (
                      <UserList
                        userList={endUserList}
                        handleChangeEndUser={handleChangeEndUser}
                        userId={promotionSubType.userList}
                      />
                    )}
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column "
              >
                <Index.Box className="offer-timeline mb di">
                  <Index.FormLabel className="sub-title">
                    Offer Timeline
                  </Index.FormLabel>

                  <Index.Box className="offer-subtimeline display-custm">
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column  set-datepiker set-main-box-date "
                    >
                      <Index.Box className="clndr-wrap">
                        <Index.Typography variant="p" className="set-text-date">
                          Offer Start Date
                        </Index.Typography>
                        <Index.Box className="clndr-1">
                          <Index.TextField
                            type="date"
                            onChange={handleChangeOtherDetails}
                            value={otherDetails.startDate}
                            className="set-min-box"
                            name="startDate"
                            error={otherDetailsError.startDate}
                            helperText={otherDetailsError.startDate}
                            disabled={promotionSubType.calenderEvent}
                            inputProps={{
                              min: Index.moment().format("YYYY-MM-DD"),
                            }}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column set-datepiker set-main-box-date "
                    >
                      <Index.Typography variant="p" className="set-text-date">
                        Offer End Date
                      </Index.Typography>
                      <Index.Box className="clndr-1 display-custm">
                        <Index.TextField
                          type="date"
                          onChange={handleChangeOtherDetails}
                          value={otherDetails.endDate}
                          className="set-min-box"
                          name="endDate"
                          inputProps={{
                            min: otherDetails.startDate
                              ? otherDetails.startDate
                              : "",
                          }}
                          error={otherDetailsError.endDate}
                          helperText={otherDetailsError.endDate}
                          disabled={promotionSubType.calenderEvent}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column  set-add-minus-box"
            >
              <Index.Box className="add-minus-main display-custm">
                <Index.RadioGroup
                  row
                  name="usage"
                  onChange={handleChangeOtherDetails}
                  value={otherDetails.usage}
                >
                  <Index.FormControlLabel
                    value="Usage Per User"
                    control={<Index.Radio/>}
                    label="Usage per user"
                    className="set-pro-lable2"
                  />
                  <Index.FormControlLabel
                    value="Total No Of Usage"
                    control={<Index.Radio />}
                    label="Total no of usage"
                    className="set-pro-lable2"
                  />
                </Index.RadioGroup>

                <Index.Box className="increment-btn display-custm display-custm2">
                  <Index.Button
                    onClick={() =>
                      setOtherDetails((prev) => ({
                        ...prev,
                        qty: prev.qty === 1 ? prev.qty : prev.qty - 1,
                      }))
                    }
                  >
                    <Index.RemoveIcon className="add-offer-inc-dec-btn" />
                  </Index.Button>
                  <Index.Typography className="btn-text">
                    {otherDetails.qty}
                  </Index.Typography>
                  <Index.Button
                    onClick={() =>
                      setOtherDetails((prev) => ({
                        ...prev,
                        qty: prev.qty + 1,
                      }))
                    }
                  >
                    <Index.AddIcon className="add-offer-inc-dec-btn" />
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.FormHelperText error>
              {otherDetailsError.usage}
            </Index.FormHelperText>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column display-custm "
            >
              <Index.Box className="min-prchase-main mb display-custm ">
                <Index.Box className="min-prchase-main2">
                  <Index.Typography className="sub-title">
                    Min Purchase amount
                  </Index.Typography>
                  <Index.TextField
                    type="number"
                    className="set-min-box"
                    name="minPurchaseAmount"
                    onChange={handleChangeOtherDetails}
                    value={otherDetails.minPurchaseAmount}
                    error={otherDetailsError.minPurchaseAmount}
                    helperText={otherDetailsError.minPurchaseAmount}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </Index.Box>
                <Index.Box className="min-prchase-main2">
                  <Index.Typography className="sub-title">
                    Max Discount amount
                  </Index.Typography>
                  <Index.TextField
                    type="number"
                    className="set-min-box"
                    name="maxDiscountAmount"
                    onChange={handleChangeOtherDetails}
                    value={otherDetails.maxDiscountAmount}
                    disabled={promotionType.promotionType === "Money Off"}
                    error={otherDetailsError.maxDiscountAmount}
                    helperText={otherDetailsError.maxDiscountAmount}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="modal-user-btn-flex">
              {/* <Index.Box className="discard-btn-main border-btn-main">
                <Index.Button
                  className="discard-user-btn border-btn"
                  onClick={handleReset}
                >
                  Discard
                </Index.Button>
              </Index.Box> */}
              <Index.Box className="save-btn-main border-btn-main">
                <Index.Button
                  className="save-user-btn border-btn loader-set"
                  type="submit"
                  onClick={handleSubmit}
                >
                  <img src={Index.Svg.save} className="user-save-icon"></img>
                  {loading ? <CircularProgress /> : "Save"}
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};
const UserList = (props) => {
  const [userData,setUserData] = useState([])
  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(()=>{
    setUserData(props.userList)
    setFilterData(props.userList)
  },[props.userList])

  //pagination section

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //#end pagination

  //Seasrch section

  const handleFilterData = (e) => {
    if (e.target.value === "") {
      setFilterData(userData);
    } else {
      const result = userData.filter((item) => {
        return (
          item?.name?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
          item?.email?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
          item?.mobile_number
            ?.toString()
            .toLowerCase()
            .includes(e.target.value?.toLowerCase())
        );
      });

      setFilterData(result);
      setPage(0);
    }
  };

  //#end serach section
  return (
    <Index.Box className="admin-dashboard-list-row">
      <Index.Box sx={{ width: 1 }} className="grid-main">
        <Index.Box
          display="grid"
          className="display-row"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column"
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="admin-dash-box">
                <Index.Box className="flex-all admin-dashboard-table-row">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h3"
                      variant="h3"
                    >
                      User List
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="view-btn-main border-btn-main">
                    <Index.Box className="user-search-main">
                      <Index.Box className="user-search-box">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder="Search user"
                            onChange={(e) => handleFilterData(e)}
                          />

                          <img
                            src={Index.Svg.search}
                            className="search-grey-img"
                            alt="search grey img"
                          ></img>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="page-table-main food-item-table">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table
                      sx={{ minWidth: 500 }}
                      aria-label="simple table"
                      className="table"
                    >
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Action
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Name
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Email
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Mobile number
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {filterData.length > 0 ? (
                          filterData &&
                          paginate(filterData, rowsPerPage, page + 1).map(
                            (row, index) => (
                              <Index.TableRow
                                key={row._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  <Index.FormControlLabel
                                    value={row._id}
                                    control={<Index.Checkbox />}
                                    onChange={(e) => {
                                      props.handleChangeEndUser(e);
                                    }}
                                    checked={props.userId.some(
                                      (e) => e.user_id === row._id
                                    )}
                                  />
                                </Index.TableCell>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row?.name}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row?.email}
                                </Index.TableCell>

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row?.mobile_number}
                                </Index.TableCell>
                              </Index.TableRow>
                            )
                          )
                        ) : (
                          <Index.TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <Index.TableCell
                              component="td"
                              variant="td"
                              className="table-td"
                            >
                              No Record Found
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filterData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Index.Box>
    </Index.Box>
  );
};

export default AddOffer;
