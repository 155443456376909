import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import {
  downloadReport,
  downloadPdfRepport,
} from "../../../../../common/functions";

const OrderRating = () => {
  const [ordersData, setOrdersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRating, setSelectedRating] = useState([]);
  const [selectedType, setSelectedType] = useState("");

  const ratingOption = [
    {
      key: "1",
      name: "1",
    },
    {
      key: "2",
      name: "2",
    },
    {
      key: "3",
      name: "3",
    },
    {
      key: "4",
      name: "4",
    },
    {
      key: "5",
      name: "5",
    },
  ];

  const typeOptions = [
    {
      key: "today",
      name: "Today",
    },
    {
      key: "weekly",
      name: "Weekly",
    },
    {
      key: "monthly",
      name: "Monthly",
    },
    {
      key: "quarterly",
      name: "Quarterly",
    },
    {
      key: "yearly",
      name: "Yearly",
    },
  ];

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAllOrderList = async () => {
    setLoading(true);
    const data = {
      rating: selectedRating,
      type:selectedType
    };
    await DataService.post(Api.Admin.orderRatingReport, data)
      .then((response) => {
        if (response.data.status === 200) {
          setOrdersData(response.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        setOrdersData([]);
        setLoading(false);
      });
  };

  const handleDownloadReport = () => {
    const data = ordersData.map((row) => {
      return {
        "Order Id": Index.displayValue(row?.order_id?._id),
        Rating: Index.displayValue(row?.rate_over_all),
        "Branch Name": Index.displayValue(row?.branch_id?.branch_name),
        Date: Index.displayDateValue(row?.order_id?.date),
        Time: Index.displayValue(row?.order_id?.time),
        FoodItems: Index.displayValue(row?.order_id?.food_items?.length),
        Amount: Index.displayNumberValueWithToFixed(
          row?.order_id?.total_amount
        ),
      };
    });
    downloadReport(data, "Order rating report");
  };

  const handleExportPDF = () => {
    const tableHeaders = [
      "Order Id",
      "Rating",
      "Branch Name",
      "Date",
      "Time",
      "Fooditems",
      "Amount",
    ];
    const tableData = ordersData.map((row) => [
      Index.displayValue(row?.order_id?._id),
      Index.displayValue(row?.rate_over_all),
      Index.displayValue(row?.branch_id?.branch_name),
      Index.displayDateValue(row?.order_id?.date),
      Index.displayValue(row?.order_id?.time),
      Index.displayNumberValueWithToFixed(row?.order_id?.total_amount),
    ]);
    downloadPdfRepport(tableHeaders, tableData, "Order rating report");
  };

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="view-btn-main border-btn-main filter-btn export-transaction-btn">
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!ordersData.length}
                      onClick={() => {
                        handleDownloadReport();
                      }}
                    >
                      Export
                    </Index.Button>
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!ordersData.length}
                      onClick={() => {
                        handleExportPDF();
                      }}
                    >
                      Export PDF
                    </Index.Button>
                  </Index.Box>

                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Orders Report
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group table-head-date-main ">
                            <Index.Box className="branch-name">
                              <Index.Typography>Select rating</Index.Typography>
                              <Index.Select
                                value={selectedRating}
                                multiple
                                displayEmpty
                                onChange={(e) => {
                                  setSelectedRating(e.target.value);
                                }}
                                className="form-control"
                              >
                                {ratingOption?.map((row) => {
                                  return (
                                    <Index.MenuItem value={row?.key}>
                                      {row?.name}
                                    </Index.MenuItem>
                                  );
                                })}
                              </Index.Select>
                            </Index.Box>
                            <Index.Box className="branch-name">
                              <Index.Typography>Type:</Index.Typography>
                              <Index.Select
                                value={selectedType}
                                displayEmpty
                                disabled={!selectedRating.length}
                                onChange={(e) =>
                                  setSelectedType(e.target.value)
                                }
                                className="form-control"
                              >
                                {typeOptions?.map((row) => {
                                  return (
                                    <Index.MenuItem value={row?.key}>
                                      {row?.name}
                                    </Index.MenuItem>
                                  );
                                })}
                              </Index.Select>
                            </Index.Box>
                            <Index.Box className="view-btn-main border-btn-main filter-btn">
                              <Index.Button
                                className="view-order-btn border-btn"
                                disabled={!selectedRating.length}
                                onClick={() => getAllOrderList()}
                              >
                                Filter
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main food-item-table table-order-list-res order-rating-table">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Order Id
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Rating
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Branch Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Date
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Time
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                FoodItems
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Amount
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {ordersData.length > 0
                              ? ordersData &&
                                paginate(ordersData, rowsPerPage, page + 1).map(
                                  (row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {Index.displayValue(row?.order_id?._id)}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayValue(row?.rate_over_all)}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayValue(
                                          row?.branch_id?.branch_name
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayDateValue(
                                          row?.order_id?.date
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayValue(
                                          row?.order_id?.time
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayValue(
                                          row?.order_id?.food_items?.length
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.displayNumberValueWithToFixed(
                                          row?.order_id?.total_amount
                                        )}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  )
                                )
                              : "No Data Found"}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={ordersData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default OrderRating;
