import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import AddFoodItem from "./AddfoodItem";
import DeleteModal from "../../../../../component/common/DeleteModal";
import ImageView from "react-single-image-viewer";
import "react-single-image-viewer/dist/index.css";

const FoodItem = () => {
  const [foodItemData, setFoodItemData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [multipleDeleteOpen, setMultipleDeleteOpen] = useState(false);
  const [cuisineRow, setCuisineRow] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const [deleteIds, setDeleteIds] = useState([]);

  console.log(rowsPerPage, "rowsPerPage");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleMultipleDeleteOpen = () => {
    setMultipleDeleteOpen(true);
  };

  const handleMultipleDeleteClose = () => {
    setMultipleDeleteOpen(false);
  };

  const hanldeDeleteReocrd = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", cuisineRow?._id);
    await DataService.post(Api.Admin.deleteFoodItem, urlencoded)
      .then((res) => {
        if (res.data.status === 200) {
          handleDeleteClose();
          getAllFoodItem();
          Index.toast.success(res.data.message);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
      });
  };

  const hanldeDeleteMultipleReocrd = async () => {
    await DataService.post(Api.Admin.multipleDeleteFoodItems, {
      ids: deleteIds,
    })
      .then((res) => {
        if (res.data.status === 200) {
          handleMultipleDeleteClose();
          setDeleteIds([]);
          getAllFoodItem();
          Index.toast.success(res.data.message);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
      });
  };

  console.log(multipleDeleteOpen);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getAllFoodItem();
  }, []);

  console.log(filterData, "filterData");
  const getAllFoodItem = async () => {
    setLoading(true);
    await DataService.get(Api.Common.getAllFoodItemList)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          setFoodItemData(response.data.data);
          setFilterData(response.data.data);

          searchValue &&
            handleFilterData(
              searchValue,
              "isCallingFromGetApi",
              response.data.data
            );
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  const handleFilterData = (e, flag, data) => {
    console.log(e, flag, "uwais23");
    e = e.trim();
    if (e === "") {
      setFilterData(data);
    } else {
      const result = data?.filter((item) => {
        return (
          item.item_name.toLowerCase().includes(e.toLowerCase()) ||
          item.food_type?.type.toLowerCase().includes(e.toLowerCase()) ||
          item.category?.category_name.toLowerCase().includes(e.toLowerCase())
          //item.spice_level.toString().toLowerCase().includes(e.target.value.toLowerCase())
          //item.protein.toLowerCase().includes(e.target.value.toLowerCase())
        );
      });
      setFilterData(result);
      flag !== "" ? setPage((prev) => prev) : setPage(0);

      //    const totalPages = Math.ceil(result / rowsPerPage);

      //  if (page > totalPages) {
      //    setPage(totalPages); // Redirect to the last page
      //  }
      //  else
      //  {

      //  }
    }
  };

  const handleChangeSelectFoodItems = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setDeleteIds([...deleteIds, value]);
    } else {
      const newArr = [...deleteIds].filter((item) => item !== value);

      setDeleteIds(newArr);
    }
  };

  const handleChangeSelectAllFoodItems = (e) => {
    const { checked } = e.target;

    if (checked) {
      setDeleteIds(() => {
        return filterData?.map((row) => row?._id);
      });
    } else {
      setDeleteIds([]);
    }
  };

  console.log(deleteIds, "deleteIds");
  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main fooditem-count">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Food Item
                        </Index.Typography>

                        <Index.Typography
                          className=""
                          component="p"
                          variant="p"
                        >
                          {`(${deleteIds.length} Items are selected out of ${foodItemData?.length})`}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="view-btn-main border-btn-main">
                        <Index.Box className="user-search-main">
                          <Index.Box className="user-search-box user-small-close">
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Search item"
                                value={searchValue}
                                onChange={(e) => {
                                  setSearchValue(e.target.value);
                                  handleFilterData(
                                    e.target.value,
                                    "",
                                    foodItemData
                                  );
                                  setDeleteIds([]);
                                }}
                              />

                              <img
                                src={Index.Svg.search}
                                className="search-grey-img"
                                alt="search grey img"
                              ></img>
                              <Index.HighlightOffIcon
                                onClick={() => {
                                  setSearchValue("");
                                  handleFilterData("", "", foodItemData);
                                  setDeleteIds([]);
                                }}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Button
                          className="view-order-btn border-btn"
                          onClick={() => {
                            handleOpen();
                            setCuisineRow("");
                          }}
                        >
                          Add New
                        </Index.Button>

                        {deleteIds?.length > 0 && (
                          <Index.Button
                            className="view-order-btn border-btn"
                            sx={{ marginLeft: "5px" }}
                            onClick={() => handleMultipleDeleteOpen()}
                          >
                            Delete
                          </Index.Button>
                        )}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main food-item-table">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                {searchValue !== "" && filterData?.length ? (
                                  <Index.FormControlLabel
                                    control={<Index.Checkbox />}
                                    onChange={(e) => {
                                      handleChangeSelectAllFoodItems(e);
                                    }}
                                    checked={filterData?.every((row) => {
                                      return deleteIds?.some(
                                        (id) => id === row?._id
                                      );
                                    })} //default check while edit and filtering data
                                  />
                                ) : (
                                  ""
                                )}
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Image
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Item
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Cuisines
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Food Type
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Category
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Details
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Spice Level
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData?.length > 0 ? (
                              filterData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                                ?.map((row, index) => {
                                  return (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        <Index.FormControlLabel
                                          value={row._id}
                                          control={<Index.Checkbox />}
                                          onChange={(e) => {
                                            handleChangeSelectFoodItems(e);
                                          }}
                                          checked={deleteIds?.some(
                                            (e) => e === row?._id
                                          )}
                                        />
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        sx={{ cursor: "pointer" }}
                                      >
                                        <ImageView
                                          src={
                                            Index.Constant.IMAGE_URL + row.image
                                          }
                                          height="80px"
                                          width="120px"
                                          alt="food-item"
                                          closeOnClickOutside={true}
                                        />
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.item_name}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.cuisine?.map((item, index) => {
                                          return (
                                            <>
                                              {item.cuisine_id?.cuisine}
                                              {row?.cuisine?.length - 1 ===
                                              index
                                                ? " "
                                                : ", "}
                                            </>
                                          );
                                        })}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.food_type?.type}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.category?.category_name}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.ingredients?.substring(0, 30) +
                                          "..."}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.spice_level === true
                                          ? "Yes"
                                          : "No"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row.protein}

                                        <Index.Button
                                          className="table-btn"
                                          onClick={() => {
                                            handleOpen();
                                            setCuisineRow(row);
                                          }}
                                        >
                                          <img
                                            src={Index.Svg.blueedit}
                                            alt="edit-icon"
                                          ></img>
                                        </Index.Button>
                                        <Index.Button
                                          className="table-btn"
                                          onClick={() => {
                                            handleDeleteOpen();
                                            setCuisineRow(row);
                                          }}
                                        >
                                          <img
                                            src={Index.Svg.trash}
                                            alt="delete-icon"
                                          ></img>
                                        </Index.Button>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  );
                                })
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell align="center" colSpan={8}>
                                  No record found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>

                      <Index.TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={filterData?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />

                      {open && (
                        <AddFoodItem
                          open={open}
                          handleClose={handleClose}
                          getAllFoodItem={getAllFoodItem}
                          row={cuisineRow}
                        />
                      )}

                      {deleteOpen && (
                        <DeleteModal
                          deleteOpen={deleteOpen}
                          handleDeleteClose={handleDeleteClose}
                          hanldeDeleteReocrd={hanldeDeleteReocrd}
                        />
                      )}

                      {multipleDeleteOpen && (
                        <DeleteModal
                          deleteOpen={multipleDeleteOpen}
                          handleDeleteClose={handleMultipleDeleteClose}
                          hanldeDeleteReocrd={hanldeDeleteMultipleReocrd}
                        />
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default FoodItem;
