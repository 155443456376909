import React, { useState, useEffect } from "react";
import Index from "../../../../component/Index";
import AddSubAdmin from "./AddSubAdmin";
import { Api } from "../../../../config/Api";
import DataService from "../../../../config/DataService";

const Subadmin = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [subAdminData, setSubAdminData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [subAdminRowId, setSubAdminRowId] = useState("");
  const [loading, setLoading] = useState(false);

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const getAllSubAdmin = async () => {
    setLoading(true);
    await DataService.get(Api.Admin.getAllSubAdmin)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          setSubAdminData(res.data.data);
          setFilterData(res.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };
  const handleFilterData = (e) => {
    if (e.target.value === "") {
      setFilterData(subAdminData);
    } else {
      const result = subAdminData.filter((item) => {
        return (
          item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
          item.email.toLowerCase().includes(e.target.value.toLowerCase())
        );
      });
      setFilterData(result);
      setPage(0);
    }
  };

  const deleteSubAdmin = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", subAdminRowId?._id);
    DataService.post(Api.Admin.deleteSubAdmin, urlencoded)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201)
          Index.toast.success("Sub Admin deleted successfully");
        handleDeleteClose();
        setSubAdminRowId("");
        getAllSubAdmin();
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
      });
  };

  useEffect(() => {
    getAllSubAdmin();
  }, []);
  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Sub Admin Management
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="view-btn-main border-btn-main">
                        <Index.Box className="user-search-main">
                          <Index.Box className="user-search-box">
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Search admin"
                                onChange={(e) => handleFilterData(e)}
                              />

                              <img
                                src={Index.Svg.search}
                                className="search-grey-img"
                                alt="search grey img"
                              ></img>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Button
                          className="view-order-btn border-btn"
                          onClick={() => {
                            handleOpen();
                            setSubAdminRowId("");
                          }}
                        >
                          Add admin
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main food-item-table">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Email
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData.length > 0
                              ? filterData &&
                                paginate(filterData, rowsPerPage, page + 1).map(
                                  (row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.name}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.email}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        <Index.Button
                                          className="table-btn"
                                          onClick={() => {
                                            handleOpen();
                                            setSubAdminRowId(row);
                                          }}
                                        >
                                          <img src={Index.Svg.blueedit}></img>
                                        </Index.Button>
                                        <Index.Button
                                          className="table-btn"
                                          onClick={() => {
                                            handleDeleteOpen();
                                            setSubAdminRowId(row);
                                          }}
                                        >
                                          <img src={Index.Svg.trash}></img>
                                        </Index.Button>
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  )
                                )
                              : "No Data Found"}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            {open && (
              <AddSubAdmin
                open={open}
                handleClose={handleClose}
                getAllSubAdmin={getAllSubAdmin}
                row={subAdminRowId}
              />
            )}

            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filterData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Index.Box>
          <Index.Modal
            open={deleteOpen}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete"
          >
            <Index.Box
              sx={Index.Constant.MODAL_STYLE}
              className="delete-modal-inner-main"
            >
              <Index.Box className="modal-circle-main">
                <img src={Index.Svg.closecircle} className="user-circle-img" />
              </Index.Box>
              <Index.Typography
                className="delete-modal-title"
                component="h2"
                variant="h2"
              >
                Are you sure?
              </Index.Typography>
              <Index.Typography
                className="delete-modal-para common-para"
                component="p"
                variant="p"
              >
                Do you really want to delete these records? This process cannot
                be undone.
              </Index.Typography>
              <Index.Box className="delete-modal-btn-flex">
                <Index.Button
                  className="modal-cancel-btn modal-btn"
                  onClick={handleDeleteClose}
                >
                  Cancel
                </Index.Button>
                <Index.Button
                  className="modal-delete-btn modal-btn"
                  onClick={deleteSubAdmin}
                >
                  Delete
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Modal>
        </Index.Box>
      )}
    </>
  );
};

export default Subadmin;
