import React, { useState, useEffect } from "react";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import Index from "../../../../component/Index";

const OrderWalkinList = () => {
  const [orderList, setOrderList] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [branchId, setBranchId] = useState("");
  const [branches, setBranches] = useState([]);
  const [date, setDate] = useState(null);

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const getAllOrderList = async () => {
    setLoading(true);
    await DataService.get(Api.Admin.getAllWalkinOrderList)
      .then((response) => {
        if (response.data.status === 200) {
          setOrderList(response.data.data);
          setSearchedData(response.data.data);
          setFilteredData(response.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllOrderList();
    getAllBranches();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterData = (e) => {
    if (e.target.value === "") {
      setSearchedData(filterData);
    } else {
      const result = filterData.filter((item) => {
        return (
          item?.created_by?.name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          item?.branch_id?.branch_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        );
      });
      setSearchedData(result);
      setPage(0);
    }
  };

  const getAllBranches = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("type", "branch");
    DataService.post(Api.Admin.getResturantDetail, urlencoded)
      .then((response) => {
        if (response.data.status === 200) {
          setBranches(response.data.data);
        }
      })
      .catch((e) => {});
  };

  // Get date and branch wise filter

  useEffect(() => {
    let filterdata = [];
    if (date && branchId) {
      filterdata = orderList.filter(
        (item) =>
          item.branch_id._id === branchId &&
          Index.moment(item?.createdAt).format("YYYY-MM-DD") === date
      );
    } else if (branchId) {
      filterdata = orderList.filter((item) => item.branch_id._id === branchId);
    } else if (date) {
      filterdata = orderList.filter(
        (item) => Index.moment(item?.createdAt).format("YYYY-MM-DD") === date
      );
    }
    else
    {
      setSearchedData(orderList);  
      setFilteredData(orderList);  
    }

    setSearchedData(filterdata);
    setFilteredData(filterdata);  
  }, [date, branchId]);


  const handleClear = () =>{
    setBranchId("");
    setDate(null);
    getAllOrderList()
  }

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Walk In Order List
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group table-head-date-main">
                            <Index.Box className="branch-name">
                              <Index.Typography>Branch :</Index.Typography>
                              <Index.Select
                                value={branchId}
                                displayEmpty
                                onChange={(e) => setBranchId(e.target.value)}
                                className="form-control"
                              >
                                <Index.MenuItem value="" disabled>
                                  Select branch
                                </Index.MenuItem>
                                {branches &&
                                  branches?.map((row) => {
                                    return (
                                      <Index.MenuItem value={row?._id}>
                                        {row?.branch_name}
                                      </Index.MenuItem>
                                    );
                                  })}
                              </Index.Select>
                            </Index.Box>
                            <Index.Box className="from-to-date-table">
                              <Index.Box className="from-date-label fromto-date-label-same">
                                <Index.Typography>Date :</Index.Typography>
                                <Index.TextField
                                  type="date"
                                  value={date}
                                  onChange={(e) => {
                                    setDate(
                                      Index.moment(e.target.value).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                  }}
                                />
                              </Index.Box>

                              <Index.Button  onClick={handleClear}>Clear</Index.Button>
                              {/* <Index.Box className="to-date-label fromto-date-label-same">
                                <Index.Typography>To :</Index.Typography>
                                <Index.TextField
                                  type="date"
                                  value={endDate}
                                  onChange={(e) => {
                                    setEndDate(
                                      Index.moment(e.target.value).format(
                                        "YYYY-MM-DD"
                                      )
                                    );
                                  }}
                                  inputProps={{
                                    min: startDate,
                                  }}
                                />
                              </Index.Box> */}
                            </Index.Box>

                            {/* <Index.Box className="view-btn-main border-btn-main filter-btn">
                              <Index.Button
                                className="view-order-btn border-btn"
                                onClick={() =>
                                  getAllTransactionList(
                                    startDate,
                                    endDate,
                                    branchId
                                  )
                                }
                              >
                                Filter
                              </Index.Button>
                            </Index.Box> */}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Search order"
                              onChange={(e) => handleFilterData(e)}
                            />

                            <img
                              src={Index.Svg.search}
                              className="search-grey-img"
                              alt="search grey img"
                            ></img>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main food-item-table">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Order Id
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                User Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Branch Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                FoodItems
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Amount
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Order type
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Status
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Created Date
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {searchedData.length > 0
                              ? searchedData &&
                                paginate(searchedData, rowsPerPage, page + 1).map(
                                  (row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {row._id || "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.created_by?.name || "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.branch_id?.branch_name || "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.food_items.length || "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.total_amount.toFixed(2) || "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.order_type}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {row?.booking_status || "-"}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                      >
                                        {Index.moment(row?.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  )
                                )
                              : "No Data Found"}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={searchedData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default OrderWalkinList;
