import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import { useForm } from "react-hook-form";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const AddFoodType = (props) => {
  const [imageName, setImageName] = useState("");
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true)
    const formdata = new FormData();
    formdata.append("type", data.foodType);
    formdata.append("image", data.image);
    if (props.row) {
      formdata.append("id", props.row._id);
    }

    await DataService.post(Api.Admin.addEditFoodType, formdata).then(
      (response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          setLoading(false)
          if (props.row) {
            Index.toast.success("Food type updated successfully");
          } else {
            Index.toast.success("Food type addedd successfully");
          }

          props.getAllFoodType();
          props.handleClose();
          reset();
        }
      }
    ).catch((e)=>{
      Index.toast.error(e.response.data.message);
      setLoading(false)
    });
  };

  const handleImageChange = (e) => {
    setImageName(e.target.files[0].name);
    setValue("image",e.target.files[0],{shouldValidate:true});
  };

  useEffect(() => {
    setValue("foodType", props.row ? props.row.type : "");
    setImageName(props.row ? props?.row?.image : "Select File");
    setValue('image' , props?.row?.image)
  }, [props.row]);

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title" 
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box
          sx={Index.Constant.MODAL_STYLE}
          className="add-user-modal-inner-main"
        >
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Add/Edit Food Type
          </Index.Typography>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Food Type
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="foodType"
                {...register("foodType", {
                  required: "Food type is required",
                })}
                error={Boolean(errors.foodType)}
                helperText={errors.foodType?.message}
              />
            </Index.Box>
          </Index.Box>

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Select Image
            </Index.FormHelperText>

            <Index.Button
              variant="outlined"
              fullWidth
              component="label"
              className="file-upload-btn"
            >
              {imageName  ? imageName : "Select File"}
              <input
                hidden
                accept="files/*"
                id="contained-button-file"
                type="file"
                name="image"
                ref={register("image",{
                  required:"Image is required" 
                })}
                onChange={(e) => {
                  handleImageChange(e);
                }}
              />
            </Index.Button>
            <Index.FormHelperText error>
              {errors.image?.message}
            </Index.FormHelperText>
          </Index.Box>
          
          <Index.SubmitDiscardButton loading={loading} handleClose={props.handleClose}  />

        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddFoodType;
