import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { Api } from "../../../../../config/Api";
import DataService from "../../../../../config/DataService";
import AddTeam from "./AddTeam";

const Team = () => {
  const [teamData, setTeamData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [teamRow, setTeamRow] = useState({})
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };


  const handleFilterData = (e) => {
    if (e.target.value === "") {
      setFilterData(teamData);
    } else {
      const result = teamData.filter((item) => {
        return (
          item?.name?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
          item?.bio?.toLowerCase().includes(e.target.value?.toLowerCase())||
          item?.position?.toLowerCase().includes(e.target.value?.toLowerCase())
        );
      });
      setFilterData(result);
      setPage(0)
    }
  };

  const hanldeDeleteReocrd = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", teamRow?._id);
    await DataService.post(Api.Admin.deleteTeam, urlencoded).then((res) => {
      if (res.data.status === 200 || res.data.status === 201) {
        handleDeleteClose();
        setTeamRow("");
        getAllTeam();
        Index.toast.success("Team deleted successfully");
      }
    }).catch((e) => {
      Index.toast.error(e.response.message);
    });;
  };


  const getAllTeam = async() =>{
    await DataService.get(Api.Common.getAllTeam).then((res)=>{
      if(res.data.data)
      {
        setTeamData(res.data.data)
        setFilterData(res.data.data)
      }
    })
  }

  useEffect(()=>{
    getAllTeam();
  },[])
  return (
    <Index.Box className="admin-dashboard-list-row">
      <Index.Box sx={{ width: 1 }} className="grid-main">
        <Index.Box
          display="grid"
          className="display-row"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column"
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 12",
              }}
              className="grid-column"
            >
              <Index.Box className="admin-dash-box">
                <Index.Box className="flex-all admin-dashboard-table-row">
                  <Index.Box className="admin-sub-title-main">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h3"
                      variant="h3"
                    >
                      Team
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="view-btn-main border-btn-main">
                    <Index.Box className="user-search-main">
                      <Index.Box className="user-search-box">
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder="Search team"
                            onChange={(e) => handleFilterData(e)}
                          />

                          <img
                            src={Index.Svg.search}
                            className="search-grey-img"
                            alt="search grey img"
                          ></img>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Button
                      className="view-order-btn border-btn"
                      onClick={() => {
                        handleOpen();
                        setTeamRow('')
                      }}
                    >
                      Add Team
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="page-table-main food-item-table">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table
                      sx={{ minWidth: 500 }}
                      aria-label="simple table"
                      className="table"
                    >
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Image
                          </Index.TableCell>
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Name
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Position
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Bio
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Facebook Link
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Insta Link
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                            Twitter Link
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                          >
                          Action
                          </Index.TableCell>

                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {filterData.length > 0
                          ? filterData &&
                            paginate(filterData, rowsPerPage, page + 1).map(
                              (row, index) => (
                                <Index.TableRow
                                  key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    <img
                                      src={Index.Constant.IMAGE_URL + row.image}
                                      height="80px"
                                      width="120px"
                                    />
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row.name ? row.name : "-"}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.position ? row?.position : "-"}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.bio ? row?.bio : "-"}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.facebook_link
                                      ? row?.facebook_link
                                      : "-"}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.instagram_link ? row?.instagram_link : "-"}
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.twitter_link ? row?.twitter_link : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    <Index.Button
                                      className="table-btn"
                                      onClick={() => {
                                        handleOpen();
                                        setTeamRow(row);
                                      }}
                                    >
                                      <img src={Index.Svg.blueedit}></img>
                                    </Index.Button>
                                    <Index.Button
                                      className="table-btn"
                                      onClick={() => {
                                        handleDeleteOpen();
                                        setTeamRow(row);
                                      }}
                                    >
                                      <img src={Index.Svg.trash}></img>
                                    </Index.Button>
                                  </Index.TableCell>
                                </Index.TableRow>
                              )
                            )
                          : "No Data Found"}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        {
          open && 
          (<AddTeam
            open={open}
            handleClose={handleClose}
            getAllTeam={getAllTeam}
            row={teamRow}
          /> )
        }

      {deleteOpen && (
        <Index.DeleteModal
          deleteOpen={deleteOpen}
          handleDeleteClose={handleDeleteClose}
          hanldeDeleteReocrd={hanldeDeleteReocrd}
        />
      )}
        <Index.TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filterData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Index.Box>
    </Index.Box>
  );
};

export default Team;
