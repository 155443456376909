import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import {
  downloadReport,
  downloadPdfRepport,
} from "../../../../../common/functions";

const FeaturedVsNonFeatured = () => {
  const [selectedType, setSelectedType] = useState("featured");
  const [restaurantData, setRestaurantData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const typeOptions = [
  //   {
  //     key: "featured",
  //     name: "Featured",
  //   },
  //   {
  //     key: "non-featured",
  //     name: "Non Featured",
  //   },
  // ];

  const getAllRestaurantData = async () => {
    setLoading(true);
    const data = {
      type: "featured",
    };
    await DataService.post(
      Api.Admin.featuredVsNonFeaturedRestaurantReport,
      data
    )
      .then((response) => {
        if (response.data.status === 200) {
          setRestaurantData(response.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        setRestaurantData([]);
        setLoading(false);
      });
  };

  const handleDownloadReport = () => {
    const data = restaurantData.map((item) => {
      const rowData = {
        "Branch name": item?.branch_name,
        "Campaign name": item?.campaigns.campaign_name,
        "Start date": Index.displayDateValue(
          item?.matched_subscriptions.start_date
        ),
        "End date": Index.displayDateValue(
          item?.matched_subscriptions.end_date
        ),
        "Total orders": item?.totalOrder,
      };

      return rowData;
    });

    downloadReport(data, "Featured restaurant");
  };

  const handleExportPDF = () => {
    const tableHeaders = [
      "Branch name",
      "Campaign name",
      "Start date",
      "End date",
      "Total orders",
    ];
    const tableData = restaurantData.map((row) => {
      let data = [
        row.branch_name,
        row?.campaigns.campaign_name,
        Index.displayDateValue(row?.matched_subscriptions.start_date),
        Index.displayDateValue(row?.matched_subscriptions.end_date),
        row.totalOrder,
      ];
      return data;
    });
    downloadPdfRepport(tableHeaders, tableData, "Featured restaurant");
  };

  useEffect(() => {
    getAllRestaurantData();
  }, []);

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="view-btn-main border-btn-main filter-btn export-transaction-btn">
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!restaurantData.length}
                      onClick={() => {
                        handleDownloadReport();
                      }}
                    >
                      Export
                    </Index.Button>
                    <Index.Button
                      className="view-order-btn border-btn"
                      startIcon={<Index.DownloadIcon />}
                      disabled={!restaurantData.length}
                      onClick={() => {
                        handleExportPDF();
                      }}
                    >
                      Export PDF
                    </Index.Button>
                  </Index.Box>

                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Featured Restaurant
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group table-head-date-main"></Index.Box>
                        </Index.Box>
                      </Index.Box>

                      {/* <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                          <Index.Box className="form-group table-head-date-main">
                            <Index.Box className="branch-name">
                              <Index.Typography>Type:</Index.Typography>
                              <Index.Select
                                value={selectedType}
                                displayEmpty
                                onChange={(e) =>
                                  setSelectedType(e.target.value)
                                }
                                className="form-control"
                              >
                                <Index.MenuItem value="" disabled>
                                  Select Type
                                </Index.MenuItem>
                                {typeOptions?.map((row) => {
                                  return (
                                    <Index.MenuItem value={row?.key}>
                                      {row?.name}
                                    </Index.MenuItem>
                                  );
                                })}
                              </Index.Select>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box> */}
                    </Index.Box>
                    <Index.Box className="page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Branch Name
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Campaign Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Start date
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                End date
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Total orders
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {restaurantData.length > 0
                              ? restaurantData &&
                                paginate(
                                  restaurantData,
                                  rowsPerPage,
                                  page + 1
                                ).map((row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.branch_name || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.campaigns?.campaign_name || "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayDateValue(
                                        row?.matched_subscriptions?.start_date
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {Index.displayDateValue(
                                        row?.matched_subscriptions?.end_date
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.totalOrder || "-"}
                                    </Index.TableCell>
                                  </Index.TableRow>
                                ))
                              : "No Data Found"}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={restaurantData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default FeaturedVsNonFeatured;
