import React,{useState} from 'react'
import Index from '../../../../component/Index';
import { useNavigate } from 'react-router-dom';

const Cms = () => {
  const navigate = useNavigate();

  const [cmsData, setCmsData] = useState([
    {
      content :"About Us"
    },
    {
      content:"Restaurant"
    },
    {
      content :"User App"
    },
    {
      content:"Add User"
    },
    {
      content :"Promo Images"
    },
    {
      content :"Offers"
    },
    {
      content :"Features"
    },
    {
      content :"Featured Restaurant"
    },
    {
      content :"FAQs"
    },
    {
      content :"Team"
    },
    {
      content :"Social Media"
    },
    {
      content :"Contact Us"
    },
    {
      content :"Terms & Condition"
    },
    {
      content :"Privacy Policy"
    },
    {
      content :"Notification"
    },
  ]);
  const [filterData, setFilterData] = useState(cmsData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterData = (e) =>{
 
    if(e.target.value === "")
    {
      setFilterData(cmsData);
    }
    else
    {
      const result = cmsData.filter((item) => {
        return (
          item.content.toLowerCase().includes(e.target.value.toLowerCase())
  
        );
      });
      setFilterData(result);
      setPage(0);
    }
  }

  const handleNavigate = (content) =>{
    if(content === "About Us")
    {
      navigate('/content-management/about-us')
    }
    else if(content === "Promo Images")
    {
      navigate('/content-management/promo')
    }
    else if(content === "Offers")
    {
      navigate('/content-management/offers')
    }

    else if(content === "Features")
    {
      navigate('/content-management/features')
    }
    else if(content === "Add User"){
      navigate('/content-management/add-user')
    }

    else if(content === "FAQs")
    {
      navigate('/content-management/faqs')
    }
    else if(content === "Team")
    {
      navigate('/content-management/team')
    }
    else if(content === "Restaurant"){
      navigate('/content-management/restaurant')
    }
    else if(content === "User App"){
      navigate('/content-management/user')
    }
    else if(content === "Social Media")
    {
      navigate('/content-management/social-links')
    }
    else if(content === "Privacy Policy")
    {
      navigate('/content-management/privacy-policy')
    }
    else if(content === "Terms & Condition")
    {
      navigate('/content-management/terms-and-condition')
    }
    else if(content === "Contact Us")
    {
      navigate('/content-management/contact-us')
    }
    else if(content === "Featured Restaurant")
    {
      navigate('/content-management/featured-restaurant')
    }
    else if(content === "Notification")
    {
      navigate('/content-management/notification')
    }
  }
  return (
    <Index.Box className="admin-dashboard-list-row">
    <Index.Box sx={{ width: 1 }} className="grid-main">
      <Index.Box
        display="grid"
        className="display-row"
        gridTemplateColumns="repeat(12, 1fr)"
        gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
      >
        <Index.Box
          gridColumn={{
            xs: "span 12",
            sm: "span 12",
            md: "span 12",
            lg: "span 12",
          }}
          className="grid-column"
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column"
          >
            <Index.Box className="admin-dash-box">
              <Index.Box className="flex-all admin-dashboard-table-row">
                <Index.Box className="admin-sub-title-main">
                  <Index.Typography
                    className="admin-sub-title"
                    component="h3"
                    variant="h3"
                  >
                    Content Management System
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="view-btn-main border-btn-main">
                  <Index.Box className="user-search-main">
                    <Index.Box className="user-search-box">
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          placeholder="Search content"
                          onChange={(e) => handleFilterData(e)}
                        />

                        <img
                          src={Index.Svg.search}
                          className="search-grey-img"
                          alt="search grey img"
                        ></img>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  {/* <Index.Button
                    className="view-order-btn border-btn"
                      onClick={() => {
                        handleOpen();
                      }}
                  >
                    Add Offer
                  </Index.Button> */}
                </Index.Box>
              </Index.Box>
              <Index.Box className="page-table-main food-item-table">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table
                    sx={{ minWidth: 500 }}
                    aria-label="simple table"
                    className="table"
                  >
                    <Index.TableHead className="table-head">
                      <Index.TableRow className="table-row">
                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Content 
                        </Index.TableCell>
                        

                        <Index.TableCell
                          component="th"
                          variant="th"
                          className="table-th"
                        >
                          Action
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    <Index.TableBody className="table-body">
                      {filterData.length > 0
                        ? filterData &&
                          paginate(filterData, rowsPerPage, page + 1).map(
                            (row, index) => (
                              <Index.TableRow
                                key={row._id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >

                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  {row?.content ? row?.content : '-'}
                                </Index.TableCell>

                                <Index.TableCell>
                                  <Index.Button className="table-btn" onClick={()=>handleNavigate(row.content)}>
                                    <img src={Index.Svg.blueedit}></img>
                                  </Index.Button>
                                  {/* <Index.Button className="table-btn">
                                    <img src={Index.Svg.trash}></img>
                                  </Index.Button> */}
                                </Index.TableCell>
                              </Index.TableRow>
                            )
                          )
                        : "No Data Found"}
                    </Index.TableBody>
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filterData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Index.Box>
  </Index.Box>
  )
}

export default Cms