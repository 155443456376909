import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { Api } from "../../../../../config/Api";
import DataService from "../../../../../config/DataService";
import AddPromo from "./AddPromo";

const Promo = () => {
  const [open, setOpen] = useState(false);
  const [promoData, setPromoData] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [promoRow, setPromoRow] = useState({});

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const hanldeDeleteReocrd = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", promoRow?._id);
    await DataService.post(Api.Admin.deletePromo, urlencoded)
      .then((res) => {
        if (res.data.status === 200 || res.data.status === 201) {
          handleDeleteClose();
          setPromoRow("");
          getAllPromo();
          Index.toast.success("Promo deleted successfully");
        }
      })
      .catch((e) => {
        Index.toast.error(e.response.message);
      });
  };

  const getAllPromo = async () => {
    await DataService.get(Api.Common.getAllPromo).then((res) => {
      if (res.data.data) {
        setPromoData(res.data.data);
      }
    });
  };

  useEffect(() => {
    getAllPromo();
  }, []);

  return (
    <Index.Box className="admin-dashboard-list-row promo-border">
      <Index.Box sx={{ width: 1 }} className="grid-main">
        <Index.Box
          display="grid"
          className="display-row"
          gridTemplateColumns="repeat(12, 1fr)"
          gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column"
          >
            <Index.Box className="admin-dash-box">
              <Index.Box className="flex-all admin-dashboard-table-row">
                <Index.Box className="admin-sub-title-main">
                  <Index.Typography
                    className="admin-sub-title"
                    component="h3"
                    variant="h3"
                  >
                    Promo
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="view-btn-main border-btn-main">
                  <Index.Box className="user-search-main">
                    {/* <Index.Box className="user-search-box">
                        <Index.Box className="form-group">
                            <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder="Search team"
                            //onChange={(e) => handleFilterData(e)}
                            />

                            <img
                            src={Index.Svg.search}
                            className="search-grey-img"
                            alt="search grey img"
                            ></img>
                        </Index.Box>
                        </Index.Box> */}
                  </Index.Box>
                  <Index.Button
                    className="view-order-btn border-btn"
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    Add Promo
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {promoData && promoData.length > 0 ? (
            promoData.map((row, index) => {
              return (
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 4",
                    md: "span 4",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="inner-main-images  ">
                    <Index.Box className="promo-img-details ">
                      <img
                        src={Index.Constant.IMAGE_URL + row.image}
                        height="150px"
                        width="100px"
                      ></img>
                    </Index.Box>
                    <span>{row?.name ? row.name : "-"}</span>
                    <Index.Button
                      onClick={() => {
                        handleDeleteOpen();
                        setPromoRow(row);
                      }}
                    >
                      <Index.DeleteIcon />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              );
            })
          ) : (
            <span>No Data Found</span>
          )}
        </Index.Box>
        {open && (
          <AddPromo
            open={open}
            handleClose={handleClose}
            getAllPromo={getAllPromo}
          />
        )}

        {deleteOpen && (
          <Index.DeleteModal
            deleteOpen={deleteOpen}
            handleDeleteClose={handleDeleteClose}
            hanldeDeleteReocrd={hanldeDeleteReocrd}
          />
        )}
      </Index.Box>
    </Index.Box>
    //   </Index.Box>
    // </Index.Box>
  );
};

export default Promo;
