import React from "react";
import Index from "../../../../../../component/Index";
import { useForm, Controller } from "react-hook-form";
import DataService from "../../../../../../config/DataService";
import { Api } from "../../../../../../config/Api";
import { useEffect } from "react";

const PaymentInterval = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();

  const onSubmit = (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("interval_type", data.interval_type);
    urlencoded.append("branch_id", props.branchId);

    DataService.post(Api.Admin.updateBranchPaymentInterval, urlencoded)
      .then((res) => {
        Index.toast.success(res.data.message);
        props.getRestaurantData();
      })
      .catch((e) => {
        Index.toast.error(e.response.data.message);
      });
  };

  useEffect(() => {
    setValue(
      "interval_type",
      props.restaurantDetail.payment_interval
        ? props.restaurantDetail.payment_interval
        : ""
    );
  }, [props.restaurantDetail]);

  console.log(watch("interval_type"), "interval_type");
  return (
    <Index.Box>
      <form onSubmit={handleSubmit(onSubmit)} className="gst-main-form">
        <Index.Box className="gst-row">
          <Index.Typography>Select Interval:</Index.Typography>
          <Index.Box className="input-gst interval-input">
            <Controller
              control={control}
              name="interval_type"
              {...register("interval_type", {
                required: "Interval type is required",
              })}
              render={({ field: { onChange, value } }) => (
                <Index.Select
                  error={Boolean(errors.interval_type)}
                  className="dropdown-select"
                  value={
                    value ? value : props.restaurantDetail.payment_interval
                  }
                  onChange={onChange}
                  inputProps={{ "aria-label": "Without label" }}
                  fullWidth
                  displayEmpty
                >
                  <Index.MenuItem value=""></Index.MenuItem>
                  <Index.MenuItem value="weekly">Weekly</Index.MenuItem>
                  <Index.MenuItem value="monthly">Monthly</Index.MenuItem>
                </Index.Select>
              )}
            />
            <Index.FormHelperText error id="accountId-error">
              {errors.interval_type?.message
                ? errors.interval_type?.message
                : ""}
            </Index.FormHelperText>
          </Index.Box>
        </Index.Box>

        <Index.Box className="gst-row-btn">
          <Index.Button type="submit" variant="contained">
            Update
          </Index.Button>
        </Index.Box>
      </form>
    </Index.Box>
  );
};

export default PaymentInterval;
