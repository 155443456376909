import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";
import { useNavigate } from "react-router-dom";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";

const OfferManagement = () => {
  const [offerData, setOfferData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [row, setRow] = useState({});

  const navigate = useNavigate();

  function paginate(array, page_size, page_number) {
    return array?.slice((page_number - 1) * page_size, page_number * page_size);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const getAllOffer = () => {
    setLoading(true);
    DataService.get(Api.Admin.getAllOffer)
      .then((res) => {
        if (res.data.status === 200) {
          setOfferData(res.data.data);
          setFilterData(res.data.data);
          setLoading(false);
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  const hanldeDeleteReocrd = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("offerId", row?._id);
    await DataService.post(Api.Admin.deleteOffer, urlencoded)
      .then((res) => {
        if (res.data.status === 200) {
          handleDeleteClose();
          setRow("");
          getAllOffer();
          Index.toast.success(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e, "89");
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
      });
  };

  const handleEdit = (row) => {
    navigate("/add-offer", {
      state: row,
    });
  };
  useEffect(() => {
    getAllOffer();
  }, []);

  return (
    <>
      {loading ? (
        <Index.Loader />
      ) : (
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="flex-all admin-dashboard-table-row">
                      <Index.Box className="admin-sub-title-main">
                        <Index.Typography
                          className="admin-sub-title"
                          component="h3"
                          variant="h3"
                        >
                          Offer Management
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="view-btn-main border-btn-main">
                        <Index.Box className="user-search-main">
                          <Index.Box className="user-search-box">
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Search offer"
                                //onChange={(e) => handleFilterData(e)}
                              />

                              <img
                                src={Index.Svg.search}
                                className="search-grey-img"
                                alt="search grey img"
                              ></img>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Button
                          className="view-order-btn border-btn"
                          onClick={() => {
                            navigate("/add-offer");
                          }}
                        >
                          Add Offer
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="page-table-main food-item-table">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 500 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Start Date
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                End Date
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Promotion
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Amount
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Min order amount
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Max discount amount
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                No of usage allow
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Target audience
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                              >
                                Action
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {filterData.length > 0 ? (
                              filterData &&
                              paginate(filterData, rowsPerPage, page + 1).map(
                                (row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.start_date
                                        ? Index.moment(row?.start_date).format(
                                            "L"
                                          )
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.end_date
                                        ? Index.moment(row?.end_date).format(
                                            "L"
                                          )
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.category ? row?.category : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.category_value
                                        ? row?.category_value
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.min_purchase_amount
                                        ? row?.min_purchase_amount
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.max_discount_amount
                                        ? row?.max_discount_amount
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.usage
                                        ? `${row?.usage} (${row?.usage_value})`
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                    >
                                      {row?.offer ? row?.offer : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell>
                                      <Index.Button
                                        className="table-btn"
                                        onClick={() => handleEdit(row)}
                                      >
                                        <img src={Index.Svg.blueedit}></img>
                                      </Index.Button>
                                      <Index.Button
                                        className="table-btn"
                                        onClick={() => {
                                          handleDeleteOpen();
                                          setRow(row);
                                        }}
                                      >
                                        <img src={Index.Svg.trash}></img>
                                      </Index.Button>
                                    </Index.TableCell>
                                  </Index.TableRow>
                                )
                              )
                            ) : (
                              <Index.TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  className="table-td"
                                >
                                  No Record Found
                                </Index.TableCell>
                              </Index.TableRow>
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filterData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {deleteOpen && (
              <Index.DeleteModal
                deleteOpen={deleteOpen}
                handleDeleteClose={handleDeleteClose}
                hanldeDeleteReocrd={hanldeDeleteReocrd}
              />
            )}
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default OfferManagement;
