import React, { useState, useEffect } from "react";
import Index from "../../../../../component/Index";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import { useForm } from "react-hook-form";

const AddSpiceLevel = (props) => {
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("name", data.name);
    formdata.append("image", data.image);
    if (props.row) {
      formdata.append("id", props.row._id);
    }

    await DataService.post(Api.Admin.addEditSpicyLevel, formdata)
      .then((response) => {
        if (response.data.status === 200 || response.data.status === 201) {
          setLoading(false);
          if (props.row) {
            Index.toast.success("Spice Level Updated successfully");
          } else {
            Index.toast.success("Spice Level Added successfully");
          }
          props.getAllSpiceLevel();
          props.handleClose();
          reset();
        }
      })
      .catch((e) => {
        Index.toast.error(
          e.response.data.message ? e.response.data.message : e.message
        );
        setLoading(false);
      });
  };

  const handleImageChange = (e) => {
    setImageName(e.target.files[0].name);
    setValue("image", e.target.files[0] ,{shouldValidate:true});
  };

  useEffect(() => {
    setImageName(props.row ? props?.row?.image : "Select File");
  }, []);


  useEffect(() => {
    if(props.row)
    {
      setValue("name", props.row?.name || "" );
      setImageName(props?.row?.image || "Select File");
      setValue('image' , props?.row?.image || "")
    }
  }, []);

  return (
    <Index.Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Index.Box sx={props.style} className="add-user-modal-inner-main">
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          >
            Add/Edit Spice Level
          </Index.Typography>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                name="name"
                {...register("name", {
                  required: "Name is required",
                })}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </Index.Box>
          </Index.Box>

          {/* <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Select Image
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                {...register("files", {
                  required: "Please select Image",
                })}
              />
              <label htmlFor="raised-button-file">
                <Index.Button
                  variant="outlined"
                  fullWidth
                  startIcon={<Index.AddIcon />}
                  component="span"
                >
                  Select file
                </Index.Button>
              </label>

              <Index.FormHelperText error id="accountId-error">
                {errors.files?.message ? errors.files?.message : ""}
              </Index.FormHelperText>
            </Index.Box>
          </Index.Box> */}

          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Select File
            </Index.FormHelperText>

            <Index.Button
              variant="outlined"
              fullWidth
              component="label"
              className="file-upload-btn"
            >
              {imageName !== "" ? imageName : "Select File"}
              <input
                hidden
                accept="image/*"
                type="file"
                ref={register("image",{
                  required:"Image is required"
                })}
                onChange={(e) => {
                  handleImageChange(e);
                }}
              />
            </Index.Button>

            <Index.FormHelperText error>
              {errors.image?.message}
            </Index.FormHelperText>
          </Index.Box>
          <Index.SubmitDiscardButton
            handleClose={props.handleClose}
            loading={loading}
          />
        </Index.Box>
      </form>
    </Index.Modal>
  );
};

export default AddSpiceLevel;
