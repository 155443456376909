import React, { useEffect, useState } from "react";
import Index from "../../../../../component/Index";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const Restaurant = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [editorData, setEditorData] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [editorError, setEditorError] = useState("");
  const [loading,setLoading] = useState(false)

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);

    if (data == "") {
      setEditorError("Details is required");
    } else if (data != "") {
      setEditorError("");
    }
  };
  const getRestaurantData = async () => {
    await DataService.get(Api.Admin.getRestaurantListing).then((res) => {
      setValue("title", res.data.data[0].title);
      setRestaurantId(res.data.data[0]._id);
      setEditorData(res.data.data[0].description);
    });
  };
  const onSubmit = async (data) => {
    setLoading(true)
    const urlencoded = new URLSearchParams();
    urlencoded.append("title", data.title);
    urlencoded.append("description", editorData);
    urlencoded.append("id", restaurantId);

    DataService.post(Api.Admin.editRestaurantData, urlencoded)
      .then((response) => {
        setLoading(false)
        Index.toast.success(response.data.message);
        getRestaurantData();
      })
      .catch((e)=>{
        Index.toast.error(e.response.data.message ? e.response.data.message : e.message );
        setLoading(false)
      });
  };

  useEffect(() => {
    getRestaurantData();
  }, []);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Index.Box className="add-user-modal-inner-main about-us-main">
        <Index.Typography
          id="modal-modal-title"
          className="modal-title"
          variant="h6"
          component="h2"
        >
          Restaurant
        </Index.Typography>
        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Title
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.TextField
              fullWidth
              id="fullWidth"
              className="form-control"
              name="title"
              {...register("title", {
                required: "Title is required",
              })}
              error={Boolean(errors.title)}
              helperText={errors.title?.message}
            />
          </Index.Box>
        </Index.Box>

        <Index.Box className="input-box modal-input-box">
          <Index.FormHelperText className="form-lable">
            Details
          </Index.FormHelperText>
          <Index.Box className="form-group">
            <Index.CKEditor
              editor={Index.ClassicEditor}
              data={editorData}
              onChange={handleEditorChange}
            />

            <Index.FormHelperText error>
              {editorError && editorError}
            </Index.FormHelperText>
          </Index.Box>
        </Index.Box>

        <Index.Box className="modal-user-btn-flex">
          <Index.Box className="save-btn-main border-btn-main">
          <Index.LoadingButton
              className="save-user-btn border-btn"
              type="submit"
              loadingPosition="start"
              loading={loading}
              startIcon={<Index.SaveIcon />}
            >
              Save
            </Index.LoadingButton>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </form>
  );
};

export default Restaurant;
